import { BaseActions } from '..'
import { streamDataServices } from '../services/streamdata.services'

let options = {}
options.service = streamDataServices
options.resourceName = 'streamData'

class StreamDataActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
}
let streamDataActions = new StreamDataActionModel(options)
export const getStreamData = (queryStringArr, t, apiUrl) => streamDataActions.getAll(queryStringArr, t, apiUrl)
