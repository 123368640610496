import { combineReducers } from 'redux'
import { agentqueueReducers, ticketCategoryReducers, userqueueReducers, userslistReducers } from '../app/agent-queue'
import { agentchatReducers } from '../app/agentchat'
import { BaseReducer, botReducers } from '../app/base-ui'
import {
	alertReducers,
	identityReducers,
	LocaleReducer,
	namespaceReducers,
	profileReducers,
	settingsReducers,
	tenantConfigReducer,
	tenantReducers,
	whiteLabelReducers,
	WorkspaceReducers,
} from '../app/common'
import { DashboardReducer } from '../app/common/analytics'
import { EntityListReducer, EntityReducer } from '../app/common/base/entity'
import {
	caseDocumentConfig,
	caseFieldConfig,
	caseMasterListConfig,
	caseNoteConfig,
	caseSectionConfig,
	caseSectionFieldConfig,
	caseUserConfig,
	caseValueConfig,
	relatedCaseConfig,
	supportCaseDocumentConfig,
	supportCaseFieldConfig,
	supportCaseNoteConfig,
	supportCaseSectionConfig,
	supportCaseSectionFieldConfig,
	supportCaseValueConfig,
} from '../app/common/ticketing'
import { reportsReducers } from '../app/reports'
import { agentListReducers, reviewchatReducers } from '../app/review-chat'
// @ts-ignore
const rootReducer = combineReducers({
	...identityReducers,
	...settingsReducers,
	...alertReducers,
	...tenantReducers,
	...EntityReducer,
	...EntityListReducer,
	...profileReducers,
	...BaseReducer,
	...agentchatReducers,
	...agentqueueReducers,
	...userqueueReducers,
	...ticketCategoryReducers,
	...userslistReducers,
	...botReducers,
	...namespaceReducers,
	...DashboardReducer,
	...reviewchatReducers,
	...caseValueConfig,
	...caseFieldConfig,
	...caseSectionConfig,
	...caseSectionFieldConfig,
	...caseMasterListConfig,
	...caseNoteConfig,
	...caseDocumentConfig,
	...caseUserConfig,
	...relatedCaseConfig,
	...supportCaseValueConfig,
	...supportCaseFieldConfig,
	...supportCaseSectionConfig,
	...supportCaseSectionFieldConfig,
	...supportCaseNoteConfig,
	...LocaleReducer,
	...supportCaseDocumentConfig,
	...agentListReducers,
	...tenantConfigReducer,
	...whiteLabelReducers,
	...WorkspaceReducers,
	...reportsReducers,
})

export default rootReducer
