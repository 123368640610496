/**
 * may not be needed once moved to entity based
 */
import { identityServices } from '../'
import { localeUrl } from '../../../config'

export const changeStatus = (status, id, apiUrl) => {
	const requestOptions = {
		url: `${apiUrl}/globalsettings/getGlobalSettingsChangeStatus/${status}/${id}`,
		method: 'GET',
	}
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getAllGlobalSettings = (customerTenantID, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrl}/globalSetting?ts=${new Date().getTime()}`,
		method: 'GET',
	}
	identityServices.identityApi.interceptors.request.use(config => {
		config.headers.common['x-tenantId'] = customerTenantID
		return config
	})
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const updateGlobalSetting = (settingData, tenantId, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	let tenantConfig = {
		configType: 'uiFeature',
		tenantId,
		properties: settingData.productSetting.streams,
		name: 'streams',
		status: 'Published',
		appModule: 'common',
	}

	const requestOptions = {
		url: `${apiUrl}/tenantConfig/publish`,
		method: 'POST',
		data: tenantConfig,
	}
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status >= 200 && response.status < 207) {
			return response.data
		}
		return Promise.reject(response)
	})
}

export const getSettingByCustomer = (serviceName, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrl}/settings/getSettingByCustomerName/${serviceName}`,
		method: 'GET',
	}
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getSsoData = (tenanId, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/sso-management/idp`,
	}
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch SSO Configurations : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getTenantConfig = (configType, customerTenantID, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrl}${localeUrl}/TenantConfig${configType ? `/${configType}/${customerTenantID}` : ''}`,
		method: 'GET',
	}
	identityServices.identityApi.interceptors.request.use(config => {
		config.headers.common['x-tenantId'] = customerTenantID
		return config
	})
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getAllTenantConfig = (tenantId, apiUrl, userInfo) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', usecase: localStorage.getItem('product') || (userInfo && userInfo.defaultTeam) },
		url: `${apiUrl}${localeUrl}/tenantConfig`,
		method: 'GET',
	}
	// identityServices.identityApi.interceptors.request.use(config => {
	//     config.headers.common['x-tenantId'] = tenantId;
	//     return config
	// })
	// @ts-ignore
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}
