import Joi from 'joi-browser'
import { identityServices } from '../../..'
import { appRootPath, localTest } from '../../../../../config'
import { auditConfigSchema } from '../../entities/auditConfig/auditConfig.schema'
import { getUiFieldsMeta } from '../schemas'
// import { localTest } from '../../../../../config'
// have joi schema validation on service results
export class EntityService {
	constructor(options) {
		//super(options)
		this.entityUrlPaths = options.entityUrlPaths
		this.methodSchemas = options.methodSchemas
		this.entitySchema = options.entitySchema
		this.linkedItemTypes = options.linkedItemTypes
		this.resourceName = options.resourceName
		this.wsEnabled = options.wsEnabled || false
		this.wsType = options.wsType || null
		this.apiUrl = options.apiUrl
	}
	prepareOdataQueryStr_({ filter, orderBy = null, skip = null, limit = null, top = null, select = null, querySymb = '', searchText = '', searchName = '' }) {
		let query = querySymb ? querySymb : '?'
		let wsData = localStorage.getItem('workspace')
		let wsData1 = (wsData && JSON.parse(wsData)) || null
		if (filter && filter.pop && filter.length > 0) {
			query = query + '$filter='
			if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData[this.wsType] && wsData[this.wsType]) {
				query = query + `workspace_key eq '${wsData1[this.wsType].key}' and workspace_value eq '${wsData1[this.wsType].value}' and `
			}
			filter.forEach((p, i, a) => {
				if (i !== 0) query += ` ${p.cond ? p.cond : 'and'} `
				if (p.operator === 'like') query += `(substringof('${p.value}', ${p.name}))`
				else if (p.name && p.value.pop) {
					let choices = p.value.map(c => {
						return `${p.name} eq ${(typeof c === 'string' && "'" + c + "'") || c}`
					})
					query += `(${choices.join(' or ')})`
				} else query += `${p.name} ${p.operator ? p.operator : 'eq'} '${p.value}'`
				//if (i !== a.length - 1 && !p.cond) query += ' and '
			})
			// query += ';'
		} else if (filter && typeof filter === 'object' && Object.keys(filter).length > 0) {
			query = '?$filter='
			if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
				query = query + `workspace_key eq '${wsData1[this.wsType].key}' and workspace_value eq '${wsData1[this.wsType].value}' and `
			}
			Object.keys(filter).forEach((p, i, a) => {
				if (filter[p].pop) {
					let choices = filter[p].map(c => {
						return `${p} eq ${(typeof c === 'string' && "'" + c + "'") || c}`
					})
					query += `(${choices.join(' or ')})`
				} else if (filter[p] && filter[p].slice(-1) === '%' && filter[p].slice(0, 1) === '%') query += `(substringof('${filter[p].slice(1, -1)}', ${p}))`
				else query += `${p} eq '${filter[p]}'`
				if (i !== a.length - 1) query += ' and '
			})
			//  query += '&'
		} else if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
			query = query + `workspace_key eq '${wsData1[this.wsType].key}' and workspace_value eq '${wsData1[this.wsType].value}' and `
		}
		if (searchText) {
			if (query && query.includes('filter')) query = query + ' and '
			else query = '?$filter='
			query += `substringof('${searchText.toLowerCase()}'${searchName ? ',' + searchName : ''})`
		}
		if (select) {
			if (query.length > 1) query += '&'
			query += '$select='
			query += `${select.join(',')}`
		}
		if (orderBy) {
			if (query.length > 1) query += '&'
			query += '$orderby='
			query += `${orderBy.join(',')}`
		}
		if (skip) {
			if (query.length > 1) query += '&'
			query += '$skip='
			query += `${skip}`
		}
		if (limit) {
			if (query.length > 1) query += '&'
			query += '$limit='
			query += `${limit}`
		}
		if (top) {
			if (query.length > 1) query += '&'
			query += '$top='
			query += `${top}`
		}
		return query && query !== '?' ? query : null
	}

	getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText, searchName, id }) {
		// let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${apiUrl}/${tenantId}` : apiUrl;
		// queryStringArr = (queryStringArr && queryStringArr != undefined && queryStringArr.length > 0) ? queryStringArr.join("&") : ''
		let that = this
		let reqPath = this.entityUrlPaths.getAll || this.entityUrlPaths.default + '/findAndCountAll'
		// let querySymb;
		// if(reqPath.indexOf('?')!=-1)
		//     querySymb='&'
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top, select, searchText, searchName })
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let url = `${this.apiUrl}`
		if (baseUrl) {
			url += `${baseUrl}`
		}
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			//url: `${url}/${this.entityUrlPaths.getAll || this.entityUrlPaths.default || this.entityUrlPaths}${query}`
			url: `${url}/${reqPath}${query}`,
		}
		if (id) {
			requestOptions.url = `${url}/${reqPath}/${id}${query ? query : ''}`
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
						// return res
					}
					if (response && response.data == null) return []
					if (that.methodSchemas && that.methodSchemas.getAll) {
						val = Joi.validate(response.data.rows, that.methodSchemas.getAll)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
					// return res
					// console.log("user queue", response)
					return response.data
				})
				.catch(err => {
					// for testing
					if (localTest) {
						let now = new Date()
						let res = {
							data: [
								{
									key: 'Some',
									value: { a: 1, b: 2 },
									status: 'Published',
									updatedBy: 'me',
									updatedAt: now,
									notes: [
										{ note: 'Some note', createdAt: now, createdBy: 'me' },
										{ note: 'second note', createdAt: now, createdBy: 'me' },
									],
								},
								{
									key: 'Any',
									value: { a: 2, b: 2 },
									status: 'Published',
									updatedBy: 'me',
									updatedAt: now,
									notes: [
										{ note: 'Any note', createdAt: now, createdBy: 'me' },
										{ note: 'Any second note', createdAt: now, createdBy: 'me' },
									],
								},
								{
									key: 'Cool',
									value: { a: 3, b: 2 },
									status: 'Published',
									updatedBy: 'me',
									updatedAt: now,
									notes: [
										{ note: 'Cool note', createdAt: now, createdBy: 'me' },
										{ note: 'Cool second note', createdAt: now, createdBy: 'me' },
									],
								},
							],
						}
						if (filter) {
							res.data = res.data.filter(r => Object.keys(filter).every(k => r[k] === filter[k]))
						}
						if (that.methodSchemas && that.methodSchemas.getAll) {
							val = Joi.validate(res.data, that.methodSchemas.getAll)
							if (!val.error) return res.data
							else {
								let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
								return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
							}
						}
					} else return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	getOne({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, id }) {
		let reqPath = this.entityUrlPaths.getOne || this.entityUrlPaths.default || this.entityUrlPaths
		let querySymb
		if (reqPath.indexOf('?') !== -1) querySymb = '&'
		let that = this
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top, select, querySymb })
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let url = `${this.apiUrl}`
		if (baseUrl) {
			url += `${baseUrl}`
		}
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${url}/${reqPath}${query ? query : ''}`,
		}

		if (id) {
			requestOptions.url = `${url}/${reqPath}/${id}${query ? query : ''}`
		}

		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
						// return res
					}
					if (response && response.data == null) return []
					let data
					if (response.data.rows) {
						data = response.data.rows[0]
					} else {
						data = Array.isArray(response.data) ? response.data[0] : response.data
					}
					if (that.methodSchemas && that.methodSchemas.getOne) {
						val = Joi.validate(data, that.methodSchemas.getOne)
						if (!val.error) return data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
					// return res
					// console.log("user queue", response)
					return data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	//entityValues, workflowState, id
	assignToState({ workflowState, id }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let url = `${this.apiUrl}`
		let requestOptions = {
			method: 'PUT',
			data: workflowState,
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${url}/${this.entityUrlPaths.assignToState || this.entityUrlPaths.default}/${id}`,
		}
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 202) {
						return Promise.reject(response)
						// return res
					}
					if (response && response.data == null) return []
					if (that.methodSchemas && that.methodSchemas.assignToState) {
						val = Joi.validate(response.data, that.methodSchemas.assignToState)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
					// return res
					// console.log("user queue", response)
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	getAllCount({ tenantId, filter }) {
		let that = this
		let query = this.prepareOdataQueryStr_({ filter })
		query = query ? query + '&$count' : '?$count'
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },

			url: `${this.apiUrl}/${this.entityUrlPaths.getAll || this.entityUrlPaths.default}${query}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
						// return res
					}
					if (response && response.data == null) return 0
					if (that.methodSchemas && that.methodSchemas.getAllCount) {
						val = Joi.validate(response.data, that.methodSchemas.getAllCount)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
					// return res
					return response.data && response.data.pop ? response.data.length : response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	addNew({ item, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		let wsData = localStorage.getItem('workspace')
		let wsData1 = (wsData && JSON.parse(wsData)) || null
		if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
			item.workspace = { key: wsData1[this.wsType].key, value: wsData1[this.wsType].value }
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: item,
			url: `${apiUrlCustom}/${this.entityUrlPaths.add || this.entityUrlPaths.default}`,
		}
		if (that.methodSchemas && that.methodSchemas.addItem) {
			let val = Joi.validate(item, that.methodSchemas.addItem)
			if (val.error) {
				// let errMsg = val.error.message.substring(
				//     val.error.message.lastIndexOf("[") + 1,
				//     val.error.message.lastIndexOf("]")
				// );
				//return Promise.reject(`Request data did not pass validation for Add item '${errMsg}'`)
				return Promise.reject(val.error)
			}
		}

		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (((response.status / 200) | 0) !== 1) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.addItem) {
						let val = Joi.validate(response.data, that.methodSchemas.addItem)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Add item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	upsertItems({ items, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: items,
			url: `${apiUrlCustom}/${this.entityUrlPaths.bulkUpsert || this.entityUrlPaths.default}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (((response.status / 200) | 0) !== 1) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.appendItems) {
						let val = Joi.validate(response.data, that.methodSchemas.appendItems)
						if (!val.error) return Promise.resolve(response.data)
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for append items '${errMsg}'`)
						}
					}
					return Promise.resolve(response.data)
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	importItems({ items, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		let wsData = localStorage.getItem('workspace')
		wsData = (wsData && JSON.parse(wsData)) || null
		if (this.wsType && this.wsEnabled && wsData && wsData[this.wsType] && wsData[this.wsType] && wsData[this.wsType]) {
			items.workspace = wsData[this.wsType]
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: items,
			url: `${apiUrlCustom}/${this.entityUrlPaths.import || this.entityUrlPaths.default}`,
		}

		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.importItems) {
						let val = Joi.validate(response.data, that.methodSchemas.importItems)
						if (!val.error) return Promise.resolve(response.data)
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for import items '${errMsg}'`)
						}
					}
					return Promise.resolve(response.data)
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	editRecord({ item, tenantId, editId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.entityUrlPaths.update || this.entityUrlPaths.default}/${editId}`,
		}

		if (that.methodSchemas && that.methodSchemas.editItem) {
			let val = Joi.validate(item, that.methodSchemas.editItem)
			if (val.error) {
				let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
				return Promise.reject(`Api response did not pass validation for Add items '${errMsg}'`)
			}
		}
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status >= 300) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.editItem) {
						let val = Joi.validate(response.data[0], that.methodSchemas.editItem)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Edit item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	customUpdate({ item, tenantId, path }) {
		// let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		let wsData = localStorage.getItem('workspace')
		let wsData1 = (wsData && JSON.parse(wsData)) || null
		if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
			item.workspace = { key: wsData1[this.wsType].key, value: wsData1[this.wsType].value }
		}
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${path}`,
		}
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	publishRecord({ item, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		//console.log("upsert",apiUrlCustom )
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.entityUrlPaths.publish || this.entityUrlPaths.default`/${'publish'}`}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.publish) {
						let val = Joi.validate(response.data[0], that.methodSchemas.publish)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Publish item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	testConnection({ item, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		//console.log("upsert",apiUrlCustom )
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.entityUrlPaths.testConnection || this.entityUrlPaths.default`/${'testConnection'}`}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	publishCopyRecord({ item, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		//console.log("upsert",apiUrlCustom )
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.entityUrlPaths.copyItem}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.publish) {
						let val = Joi.validate(response.data[0], that.methodSchemas.publish)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Publish item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	publishBulkRecord({ items, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		//console.log("upsert",apiUrlCustom )
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(items),
			url: `${apiUrlCustom}/${this.entityUrlPaths.bulkPublish || this.entityUrlPaths.default`/${'bulkPublish'}`}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.bulkPublish) {
						let val = Joi.validate(response.data, that.methodSchemas.bulkPublish)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Publish item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	upsertRecord({ item, tenantId, appendUrl, itemType }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		if (this.entityUrlPaths.upsert) {
			apiUrlCustom += `/${this.entityUrlPaths.upsert}`
		} else {
			apiUrlCustom += `/${this.entityUrlPaths.default}/upsert`
		}
		if (appendUrl) {
			apiUrlCustom += `${appendUrl}`
		}
		let wsData = localStorage.getItem('workspace')
		let wsData1 = (wsData && JSON.parse(wsData)) || null
		if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
			item.workspace = { key: wsData1[this.wsType].key, value: wsData1[this.wsType].value }
		}
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1], itemType: itemType ? itemType : 'default' },
			data: JSON.stringify(item),
			url: apiUrlCustom,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status > 202) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.upsert) {
						let val = Joi.validate((response.data && response.data['data'] && response.data['data'].data) || response.data, that.methodSchemas.upsert)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Edit item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					let errResponse =
						err && err.response && err.response.data && err.response.data.message && err.response.data.message
							? err.response.data.message
							: (err && err.data && err.data.message) || `Error in api call`
					return Promise.reject(errResponse)
				})
		)
	}
	deleteRecord({ id, tenantId }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${apiUrlCustom}/${this.entityUrlPaths.delete || this.entityUrlPaths.default}/${id}`,
		}

		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (that.methodSchemas && that.methodSchemas.deleteItem) {
						let val = Joi.validate(response.data, that.methodSchemas.deleteItem)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Delete item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	getAllLinked({ itemType, tenantId, filter, orderBy, skip, limit, top }) {
		// let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${apiUrl}/${tenantId}` : apiUrl;
		// queryStringArr = (queryStringArr && queryStringArr != undefined && queryStringArr.length > 0) ? queryStringArr.join("&") : ''
		let that = this
		if (!itemType || !tenantId) {
			return Promise.reject(`TenantId : ${tenantId} or LinkedType : ${itemType} missing`)
		}
		let linkedItemType = this.linkedItemTypes[itemType]
		if (!linkedItemType) {
			return Promise.reject(` LinkedType Defnition missing for ${itemType}`)
		}
		let urlItemTypePath = linkedItemType.urlGetAllPath
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top })
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: linkedItemType.urlGetAllMethod || 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiUrl}/${urlItemTypePath}${query}`,
		}
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200 && response.status !== 304) {
					return Promise.reject(response)
					// return res
				}
				if (that.methodSchemas && that.methodSchemas.linked && that.methodSchemas.linked[itemType] && that.methodSchemas.linked[itemType].getAll) {
					let schema = that.methodSchemas.linked[itemType].getAll
					val = Joi.validate(response.data, schema)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
					}
				}
				// return res
				return response.data
			})
			.catch(err => {
				// for testing
				if (localTest) {
					let now = new Date()
					let res = {
						data: [
							{
								key: 'Some',
								value: { a: 1, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Some note', createdAt: now, createdBy: 'me' },
									{ note: 'second note', createdAt: now, createdBy: 'me' },
								],
								doSomething: [
									{ label: 'Button1', toState: '1' },
									{ label: 'Button2', toState: '2' },
								],
							},
							{
								key: 'Any',
								value: { a: 2, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Any note', createdAt: now, createdBy: 'me' },
									{ note: 'Any second note', createdAt: now, createdBy: 'me' },
								],
							},
							{
								key: 'Cool',
								value: { a: 3, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Cool note', createdAt: now, createdBy: 'me' },
									{ note: 'Cool second note', createdAt: now, createdBy: 'me' },
								],
							},
						],
					}
					if (filter) {
						res.data = res.data.filter(r => Object.keys(filter).every(k => r[k] === filter[k]))
					}
					if (that.methodSchemas) {
						val = Joi.validate(res.data, that.methodSchemas.getAll)
						if (!val.error) return res.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
				} else return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}

	getAllLinkedCount({ itemType, tenantId, filter }) {
		// let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${apiUrl}/${tenantId}` : apiUrl;
		// queryStringArr = (queryStringArr && queryStringArr != undefined && queryStringArr.length > 0) ? queryStringArr.join("&") : ''
		let that = this
		if (!itemType || !tenantId) {
			return Promise.reject(`TenantId : ${tenantId} or LinkedType : ${itemType} missing`)
		}
		let linkedItemType = that.linkedItemTypes[itemType]
		if (!linkedItemType) {
			return Promise.reject(` LinkedType Defnition missing for ${itemType}`)
		}
		let urlItemTypePath = linkedItemType.urlGetAllPath

		let query = this.prepareOdataQueryStr_({ filter })
		query = query ? '$count&' + query : '?$count'
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: linkedItemType.urlGetAllMethod || 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiUrl}/${urlItemTypePath}${query}`,
		}
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200 && response.status !== 304) {
					return Promise.reject(response)
					// return res
				}
				if (that.methodSchemas && that.methodSchemas.linked && that.methodSchemas.linked[itemType] && that.methodSchemas.linked[itemType].getAllCount) {
					let schema = that.methodSchemas.linked[itemType].getAllCount
					val = Joi.validate(response.data, schema)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
					}
				}
				// return res
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}

	addLinkedNew({ itemType, item, tenantId }) {
		let that = this
		if (!itemType || !tenantId) {
			return Promise.reject(`Not defined Error:  TenantId : ${tenantId} or LinkedType : ${itemType} `)
		}
		let linkedItemType = this.linkedItemTypes[itemType]
		if (!linkedItemType) {
			return Promise.reject(` LinkedType Defnition missing for ${itemType}`)
		}
		let urlItemTypePath = linkedItemType.urlCreatePath
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: item,
			url: `${this.apiUrl}/${urlItemTypePath}`,
		}

		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas.linked && that.methodSchemas.linked[itemType] && that.methodSchemas.linked[itemType].add) {
						let schema = that.methodSchemas.linked[itemType].add
						let val = Joi.validate(response.data, schema)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Add item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
	editLinked({ itemType, item, tenantId, editId }) {
		let that = this
		if (!itemType || !tenantId) {
			return Promise.reject(`Not defined Error:  TenantId : ${tenantId} or LinkedType : ${itemType} `)
		}
		let linkedItemType = this.linkedItemTypes[itemType]
		if (!linkedItemType) {
			return Promise.reject(` LinkedType Defnition missing for ${itemType}`)
		}
		let urlItemTypePath = linkedItemType.urlEditPath
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: linkedItemType.urlEditMethod || 'PUT',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${this.apiUrl}/${urlItemTypePath}/${editId}`,
		}

		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200 && response.status !== 304) {
					return Promise.reject(response)
				}
				if (that.methodSchemas && that.methodSchemas.linked && that.methodSchemas.linked[itemType] && that.methodSchemas.linked[itemType].update) {
					let schema = that.methodSchemas.linked[itemType].update
					let val = Joi.validate(response.data, schema)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Edit item '${errMsg}'`)
					}
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	deleteLinked({ itemType, id, tenantId }) {
		let that = this
		if (!itemType || !tenantId) {
			return Promise.reject(`Not defined Error:  TenantId : ${tenantId} or LinkedType : ${itemType} `)
		}
		let linkedItemType = this.linkedItemTypes[itemType]
		if (!linkedItemType) {
			return Promise.reject(` LinkedType Defnition missing for ${itemType}`)
		}
		let urlItemTypePath = linkedItemType.urlDeletePath
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: linkedItemType.urlDeleteMethod || 'DELETE',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiUrl}/${urlItemTypePath}/${id}`,
		}

		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (that.methodSchemas && that.methodSchemas.linked && that.methodSchemas.linked[itemType] && that.methodSchemas.linked[itemType].delete) {
					let schema = that.methodSchemas.linked[itemType].delete
					let val = Joi.validate(response.data, schema)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Delete item '${errMsg}'`)
					}
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}

	//added advanced filter or search
	getAllAdvanceSearch({ tenantId, filter, orderBy, skip, limit, top }) {
		let that = this
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiUrl}/${tenantId}` : `${this.apiUrl}`
		filter.limit = limit
		filter.offset = skip
		// filter.order = [
		// [orderBy]
		// ]
		let wsData = localStorage.getItem('workspace')
		let wsData1 = (wsData && JSON.parse(wsData)) || null
		if (this.wsType && this.wsEnabled && wsData1 && wsData1[this.wsType] && wsData1[this.wsType] && wsData1[this.wsType]) {
			filter.workspace = wsData1[this.wsType]
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: filter,
			url: `${apiUrlCustom}/${this.entityUrlPaths.search || this.entityUrlPaths.default}`,
		}

		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
					}
					if (that.methodSchemas && that.methodSchemas != undefined && that.methodSchemas.getAll) {
						let val = Joi.validate(response.data, that.methodSchemas.getAll)
						if (!val.error) return response.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Add item '${errMsg}'`)
						}
					}
					return response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}

	getAuditConfig(apiUrl) {
		// let that = this
		let query = `?$filter=tolower(modelname) eq '${this.resourceName.toLowerCase()}' and status eq 'Published'`
		let url
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl') || apiUrl
		// if (localeUrl && localeUrl !== '') {
		// 	url = `${this.apiUrl}${localeUrl}/AuditConfig${query}`
		// } else
		//  if (appRootPath && appRootPath !== '') {
		// 	url = `${this.apiUrl}${appRootPath}/AuditConfig${query}`
		// } else {
		url = `${this.apiUrl}/AuditConfig${query}`
		// }
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: url,
		}
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
					}
					let data = response.data && response.data.length > 0 && response.data[0]
					if (!data) {
						return
					}
					val = Joi.validate(data, auditConfigSchema)
					if (!val.error) return data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
					}
				})
				.catch(err => {
					Promise.reject(`Error in api call ${err.message}`)
				})
		)
	}

	getRefItems({ apiDef, tenantId, filter, orderBy, skip, limit, top, params }) {
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		let apiPathUrl = this.apiUrl
		// let that = this
		if (!apiDef) {
			return Promise.reject(`refItems api def : ${apiDef} missing`)
		}
		if (!apiDef.path) return Promise.reject(`refItems api def path : ${apiDef.path} missing`)
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top })
		if (apiDef.path) {
			apiPathUrl += `/${apiDef.path}`
		}
		if (params && params.length > 0) {
			params.forEach(name => {
				apiPathUrl += `/${name}`
			})
		}
		if (query) {
			apiPathUrl += `${query}`
		}
		const requestOptions = {
			method: apiDef.method || 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${apiPathUrl}`,
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status < 200 && response.status > 202) {
					return Promise.reject(response)
				}
				if (apiDef.response && apiDef.response.ref) {
					let responseDef = apiDef.response.ref.split('.')
					let res = responseDef.reduce((p, c) => {
						return p[c]
					}, response.data)
					if (!res) res = []
					return res
				}

				return response.data
			})
			.catch(err => {})
	}

	async getUiFieldsMeta() {
		// let that = this
		let query = `?$filter=tolower(modelname) eq '${this.resourceName.toLowerCase()}' and status eq 'Published'`
		let url
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl')
		if (appRootPath && appRootPath !== '') {
			url = `${this.apiUrl}${appRootPath}/UIFieldsMeta${query}`
		} else {
			url = `${this.apiUrl}/UIFieldsMeta${query}`
		}
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: url,
		}
		let val
		return (
			identityServices.identityApi
				// @ts-ignore
				.request(requestOptions)
				.then(response => {
					if (((response.status / 400) | 0) === 1) {
						return []
					}
					if (response.status !== 200 && response.status !== 304) {
						return Promise.reject(response)
					}
					if (response && response.data == null) return []

					val = Joi.validate(response.data.rows, getUiFieldsMeta)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for getUiFieldsMeta '${errMsg}'`)
					}
				})
				.catch(err => {
					if (((err.response.status / 400) | 0) === 1) {
						return []
					} else return Promise.reject(`Error in api call for getUiFieldsMeta : ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		)
	}
}
