import { BaseActions, baseConstants } from '../../base-ui'
import { agentListServices } from '../services/agentlist.services'

let options = {}
options.service = agentListServices
options.resourceName = 'AgentList'

class AgentListActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
	getAllAgents(tenantId) {
		return dispatch => {
			dispatch(this.getAllAgentsRequest())
			agentListServices.getAllAgents(tenantId).then(
				records => dispatch(this.getAllAgentsSuccess(records)),
				error => dispatch(this.getAllAgentsFailure(error))
			)
		}
	}

	getAllAgentsRequest() {
		return { type: `${baseConstants.GETALL_REQUEST}_${options.resourceName}` }
	}

	getAllAgentsSuccess(records) {
		return { type: `${baseConstants.GETALL_SUCCESS}_${options.resourceName}`, item: records }
	}

	getAllAgentsFailure(error) {
		return { type: `${baseConstants.GETALL_FAILURE}_${options.resourceName}`, error: error }
	}
}

let AgentLists = new AgentListActionModel(options)
export const getAllAgents = tenantId => AgentLists.getAllAgents(tenantId)
