import { BaseActions } from '..'
import { categoryServices } from '../services/category.services'

let options = {}
options.service = categoryServices
options.resourceName = 'category'

class CategoryActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
}
let categoryActions = new CategoryActionModel(options)
export const getData = (queryStringArr, t, apiUrl) => categoryActions.getAll(queryStringArr, t, apiUrl)
export const addData = (data, t, apiUrl) => categoryActions.addNewItem(data, t, apiUrl)
export const deleteData = (id, t, apiUrl) => categoryActions.deleteItem(id, t, apiUrl)
export const updateData = (data, id, t, apiUrl) => categoryActions.editItem(data, id, t, apiUrl)
