// export const hostUrl = process.env.REACT_APP_UI_URL
// export const idpUrl = process.env.REACT_APP_IDP_URL
// export const defaultSubdomain = process.env.REACT_APP_DEFAULT_SUBDOMAIN
// export const rootDomain = process.env.REACT_APP_ROOT_DOMAIN
// export const apiHostUrl = process.env.REACT_APP_API_HOST
// export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
// export const apiUrl = `${apiHostUrl}${apiBaseUrl}`;
// export const botId = process.env.REACT_APP_BOT_ID;
// export const publicPageUsers = process.env.REACT_APP_PUBLICPAGE_USERS || 'patelco,nexera,patelconewtest,ms';
// export const localTest = process.env.REACT_APP_LOCAL === undefined ? false : process.env.REACT_APP_LOCAL;
// export const defaultTenantId = process.env.REACT_APP_DEFAULTTENANTID;
// export const pageLimit = process.env.REACT_APP_PAGELIMIT;
// export const appRootPath = process.env.REACT_APP_APPROOTPATH != undefined ? '/' + process.env.REACT_APP_APPROOTPATH : '/';
// export const appRootPathPrefix=function appRootPathPrefix (){return appRootPath}
// // export const apiMainUrl = process.env.REACT_APP_API_MAIN
// export const apiBaseUrlPath = ""
// export const socketUrl = process.env.REACT_APP_API_HOST
// export const directlineSecret = process.env.REACT_APP_BOT_DIRECTLINE;
// export const botName = 'emma' //process.env.REACT_APP_BOT_NAME||"";
// export const localeUrl = process.env.REACT_APP_LOCALEURL
// export const TARGET_TENANT_DOMAIN = process.env.REACT_APP_TARGET_TENANT_DOMAIN || 'asc-ticket.virtualpeople.ai'
// export const appName = process.env.REACT_APP_APPROOTPATH || 'liveChat'
// export const headerParams = process.env.headerParams || null
// export const cdnUri = process.env.REACT_APP_CDN_URI || "https://nonprodcdn.blob.core.windows.net"
// export const i18nSaving = process.env.REACT_APP_I18N_SAVING_MISSIG === 'true' ? true : false
// export const landingPage = process.env.REACT_APP_LANDINGPAGE
// export const realmName = process.env.REACT_APP_REALNAME
// export const singleTenant = process.env.REACT_APP_SINGLETENANT
// export const websiteLiveUrl = process.env.websiteLiveUrl || ''
// export const effect = 'scaleFade'

// export default {
//     appName,
//     appRootPath,
//     TARGET_TENANT_DOMAIN
// }

export const localTest = process.env.REACT_APP_LOCAL === undefined ? false : process.env.REACT_APP_LOCAL
// export const defaultTenantId = process.env.REACT_APP_DEFAULTTENANTID
// export const pageLimit = process.env.REACT_APP_PAGELIMIT
export const appRootPath = process.env.REACT_APP_APPROOTPATH != undefined ? '/' + process.env.REACT_APP_APPROOTPATH : '/'
// export const appRootPathPrefix=function appRootPathPrefix (){return appRootPath}
export const localeUrl = process.env.REACT_APP_LOCALEURL || ''
// export const socketUrl = process.env.REACT_APP_API_HOST
// export const apiBaseUrlPath = ''
export const appName = process.env.REACT_APP_APPROOTPATH || 'livechat'
// export const TARGET_TENANT_DOMAIN = process.env.REACT_APP_TARGET_TENANT_DOMAIN || 'asc-ticket.virtualpeople.ai'
// export const cdnUri = process.env.REACT_APP_CDN_URI
// export const headerParams = process.env.headerParams || null
export const i18nSaving = process.env.REACT_APP_I18N_SAVING_MISSIG || false
// export const landingPage = process.env.REACT_APP_LANDINGPAGE
export const realmName = process.env.REACT_APP_REALNAME
export const singleTenant = process.env.REACT_APP_SINGLETENANT
export const enableBotStudio = process.env.REACT_APP_ENABLE_BOT_STUDIO === 'true' || false
export const enableQnaStudio = process.env.REACT_APP_ENABLE_QNA_STUDIO === 'true' || false
export const disableReduxLogger = process.env.REACT_APP_DISABLE_REDUX_LOGGER === 'true' || false
// export const websiteLiveUrl = process.env.websiteLiveUrl || ''
export const session = new Map()
export default {
	appName,
	appRootPath,
}
let envs = ['dev', 'test', 'staging', 'stage', 'alpha', 'beta']
let hn = process.env.REACT_APP_GLOBALTenant_SERVICE_URL
// let hn
// if (gTSUrl) {
// 	if (gTSUrl.includes('://')) hn = gTSUrl.split('://')[1].split('/')[0]
// 	else hn = gTSUrl.split('/')[0]
// 	if (hn.split('.').length == 1) hn = hn + '.' + window.location.hostname.split('/')[0].split('.').slice(1).join('.')
// 	if (hn.includes(':')) hn = gTSUrl
// }
let globalTSUrl = addEnv1(hn, 'apps.actionable-science.com')
let cdn = process.env.REACT_APP_BOT_CDN || process.env.REACT_APP_CDN_URI
let cdnU = addEnv1(cdn)

function addEnv1(str, rootDomain) {
	if (!str) return
	if (!str.includes('://')) str = 'https://' + str
	let str1 = 'https://' + str.split('://')[1].split('/')[0]
	let path = str.split('://')[1].split('/')[1] ? '/' + str.split('://')[1].split('/').slice(1).join('/') : ''
	let retStr = str
	if (str1 && !str1.includes('localhost')) {
		envs.some(e1 => {
			let e = `.${e1}.`
			if (window.location.hostname.includes(e) && !str1.includes(e)) {
				if (!str1.includes('.')) {
					// append entire root here.
					if (str1.includes('actionable-science')) rootDomain = 'apps.actionable-science.com'
					else rootDomain = 'apps.rezolve.ai'
					retStr = `${str1}-${e.slice(1)}${rootDomain}${path || ''}`
				} else {
					// insert e after prefix domain...
					let x = str1.split('.')
					x.splice(1, 0, e.slice(1, -1))
					retStr = x.join('.') + (path || '')
				}
				if (e.includes('staging')) {
					retStr = retStr.replace('staging', 'uat')
				}
				if (e.includes('stage')) {
					retStr = retStr.replace('stage', 'preprod')
				}
				return retStr
			}
		})
		return retStr
	} else return str
}

export const cdnUri = cdnU || process.env.REACT_APP_CDN_URI || process.env.REACT_APP_BOT_CDN
export const globalTenantServiceUrl =
	(globalTSUrl || process.env.REACT_APP_API_HOST) + (hn && !hn.includes('/') ? process.env.REACT_APP_API_BASE_URL || '/api/v1' : '')
function addEnv(str) {
	let retStr = str
	if (str && !str.includes('localhost')) {
		envs.forEach(e1 => {
			let e = `.${e1}.`
			if (window.location.hostname.includes(e) && !str.includes(e)) {
				let rootDomain = !str.includes('.') ? window.location.hostname.split(e)[1] : str.split('.').slice(1).join('.')
				let newStr
				if (str.includes('://')) newStr = str.split('://')[1].split('/')[0]
				else newStr = str.split('/')[0]
				newStr = newStr.split('.')[0]
				retStr = str.replace(newStr, newStr + e.slice(0, -1))
				retStr = !str.includes('.') ? retStr + '.' + rootDomain : retStr
				return
			}
		})
		return retStr
	}
	return retStr
}
export const TARGET_TENANT_DOMAIN = process.env.REACT_APP_TARGET_TENANT_DOMAIN || 'asc-ticket.virtualpeople.ai'

export const registerSubDomain = process.env.REACT_APP_REGISTER_SUBDOMAIN || process.env.REACT_APP_DEFAULT_SUBDOMAIN || ['www', 'register']
const env = envs.some(e => window.location.hostname.includes(e)) && window.location.hostname.split('.')[1]
export const rootDomains = process.env.REACT_APP_ROOT_DOMAIN || env ? `${env}.virtualpeople.ai,${env}.rezolve.ai` : `virtualpeople.ai,rezolve.ai`
export const apiBaseUrlPath = ''
export const PUSH_PUBLIC_KEY = process.env.PUSH_PUBLIC_KEY || 'BNDrByJXmY7ISZTcjo-SG5XKxORKhP_0Y4MhCj3gNuLowNBBJLyZdupIvdD8p5j6cP6BaMp_02LknzTd6YrUSdw'
