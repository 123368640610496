import moment from 'moment'
import React, { Component } from 'react'
import * as Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label } from 'reactstrap'
import '../style.css'

class FilterReview extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectFrom: false,
			selectTo: false,
			filters: {
				start_date: moment().add(-2, 'M').format('YYYY-MM-DD'),
				end_date: '',
			},
		}
	}
	searchChatReviewNow(showme, performanceFilter, toggle, txt, agent, date) {
		let t = this.props.t
		this.props.searchChatReview(showme, performanceFilter, toggle, txt, agent, date, t)
	}

	toggleCal(type) {
		if (type == 'from') {
			this.setState({
				selectFrom: !this.state.selectFrom,
				selectTo: false,
			})
		} else {
			this.setState({
				selectFrom: false,
				selectTo: !this.state.selectTo,
			})
		}
	}

	dateTimeChange(type, event) {
		let filters = this.state.filters
		event = event.format('YYYY-MM-DD')
		if (type == 'From') {
			var selectFrom = false
			var selectTo = true
			filters.start_date = event
		} else {
			var selectFrom = false
			var selectTo = false
			filters.end_date = event
		}
		this.props.setDateRange(filters)
		this.props.searchChatReview(
			this.props.showme,
			this.props.performanceFilter,
			1,
			this.props.filterFlag,
			this.props.agentFilterFlag,
			{ from: filters.start_date, to: filters.end_date },
			this.props.t
		)
		this.setState({
			selectFrom,
			selectTo,
			filters,
		})
	}

	render() {
		const { profile, t, AgentList } = this.props
		let customerPublicAccess = false
		let date = {
			From: {
				value: this.state.filters.start_date,
				open: this.state.selectFrom,
				onClick: this.toggleCal.bind(this, 'from'),
			},
			To: {
				value: this.state.filters.end_date,
				open: this.state.selectTo,
				onClick: this.toggleCal.bind(this, 'to'),
			},
		}

		let valid = (type, current) => {
			let today = moment()
			let created_at = this.props.tenant.createdAt
			created_at = moment(created_at).subtract(1, 'day')
			if (type == 'From') return current.isBefore(today) && current.isAfter(created_at)

			return current.isAfter(moment(this.state.filters.start_date).subtract(1, 'days').format('YYYY-MM-DD')) && current.isBefore(today)
		}

		return (
			<div className="row">
				<div className="col-sm-6">
					<div className="show-section">
						<span className="show-title">
							<strong>{t('Show Me')}</strong>
						</span>
						<Dropdown isOpen={this.props.dropdownOpenFlag} toggle={this.props.toggleFlag}>
							<DropdownToggle className="nav-link dropdown-toggle">
								<span style={{ textTransform: 'capitalize' }}>{t(this.props.filterFlag)}</span>
							</DropdownToggle>
							<DropdownMenu right className={this.props.dropdownOpenFlag ? 'show' : ''}>
								<DropdownItem
									onClick={this.searchChatReviewNow.bind(this, null, this.props.performance, 1, 'All', this.props.agentFilterFlag, {
										from: this.state.filters.start_date,
										to: this.state.filters.end_date,
									})}>
									{t('All')}
								</DropdownItem>
								<DropdownItem
									onClick={this.searchChatReviewNow.bind(this, 1, this.props.performance, 1, 'Flagged', this.props.agentFilterFlag, {
										from: this.state.filters.start_date,
										to: this.state.filters.end_date,
									})}>
									{t('Flagged')}
									<i className="fa fa-flag"></i>{' '}
								</DropdownItem>
								<DropdownItem
									onClick={this.searchChatReviewNow.bind(this, 0, this.props.performance, 1, 'Not Flagged', this.props.agentFilterFlag, {
										from: this.state.filters.start_date,
										to: this.state.filters.end_date,
									})}>
									{t('Not Flagged')}
									<i className="fa fa-ban"></i>{' '}
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>

					<div className="show-section">
						<span className="show-title">
							<strong>{t('Agent(s)')}</strong>
						</span>
						<Dropdown isOpen={this.props.agentDD} toggle={this.props.toggleFlagDD}>
							<DropdownToggle className="nav-link dropdown-toggle">
								<span>
									{this.props.agentFilterFlag == 'All' ? this.props.agentFilterFlag : AgentList.itemData.filter(elem => elem.username == this.props.agentFilterFlag)[0].username}
								</span>
							</DropdownToggle>
							<DropdownMenu right className={this.props.agentDD ? 'show' : ''}>
								<DropdownItem
									onClick={this.searchChatReviewNow.bind(this, this.props.showme, this.props.performance, 1, this.props.filterFlag, 'All', {
										from: this.state.filters.start_date,
										to: this.state.filters.end_date,
									})}>
									{t('All')}
								</DropdownItem>
								{AgentList &&
									!AgentList.loading &&
									AgentList.itemData &&
									AgentList.itemData.length > 0 &&
									AgentList.itemData.map((elem, i) => (
										<DropdownItem
											key={i}
											onClick={this.searchChatReviewNow.bind(this, this.props.showme, this.props.performance, 1, this.props.filterFlag, elem.username, {
												from: this.state.filters.start_date,
												to: this.state.filters.end_date,
											})}>
											{elem.username}
										</DropdownItem>
									))}
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>

				{/* <div className="col-sm-4">
                    
                </div> */}

				<div className="filters col-sm-6">
					{Object.keys(date).map((key, i) => (
						<FormGroup className="custom-margin-form-group" key={i}>
							<Label for="ex-url">{t(key)}</Label>
							<div className="custom-calendar">
								<span className="set-date-sec" name="schedule_date" onClick={date[key].onClick}>
									{date[key].value ? moment(date[key].value, 'YYYY-MM-DD').format('DD MMM YY') : t('Select')}
									<span className="cal-ico">
										<i className="fa fa-calendar fa-lg" />
									</span>
								</span>
								<div className={`custom-calendar-inner ${key == 'From' ? 'cal-left-zero' : 'cal-right-zero'}`} style={{ display: date[key].open ? 'block' : 'none' }}>
									<Datetime
										isValidDate={valid.bind(this, key)}
										defaultValue={date[key].value}
										input={false}
										onChange={this.dateTimeChange.bind(this, key)}
										viewDate={date[key].value}
										timeFormat={false}
									/>
								</div>
							</div>
						</FormGroup>
					))}
				</div>
			</div>
		)
	}
}

export default withTranslation()(FilterReview)
