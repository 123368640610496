import classNames from 'classnames'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Nav, NavItem, NavLink as NavLink1 } from 'reactstrap'
import {
  localTest
} from '../../../config'
import { history } from '../../../redux/history'
import { routeList } from '../../../redux/routes'
// Locale
import { withTranslation } from 'react-i18next'
//Locale

class Sidebar extends Component {

  constructor(){
    super()
    this.sidebarHover = this.sidebarHover.bind(this)
}
sidebarHover(flag){
 if(flag){
   document.querySelector('body').classList.add('sidebarExtend')
 }
 else{
   document.querySelector('body').classList.remove('sidebarExtend')
 }


}



  componentWillMount() {
    const { identity } = this.props
    if (identity) {
      // let { profile } = identity;
      // if (profile)
      //   this.props.getAllGlobalSettings(profile.tenantUid, profile.preferred_username);
    }

  }
  componentDidMount() {
    var childElement = document.querySelector('.nav-link.active')
    if (childElement) {
      var parentElement = childElement.parentElement.parentElement.parentElement
      parentElement.classList.toggle('open')
      parentElement.firstChild.classList.toggle('open')
      parentElement.lastChild.classList.toggle('show-panel')
      var parentMostElement = childElement.parentElement.parentElement.parentElement.parentElement.parentElement
      if (parentMostElement) {
        parentMostElement.classList.toggle('open')
        parentMostElement.firstChild.classList.toggle('open')
        parentMostElement.lastChild.classList.toggle('show-panel')
      }
    }
  }

  handleClick(e) {
    e.preventDefault()
    e.target.parentElement.parentElement.classList.toggle('open')
    e.target.parentElement.classList.toggle('open')
    e.target.parentElement && e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.classList.toggle('show-panel')

  }

  activeRoute(routeName, props) {
    return 'nav-item nav-dropdown drop-togle'
  }

  handleAppClick(route) {
    let { tenant } = this.props
    window.location.href = route.app && route.app.path !== '/' ? '../../../' + route.app.path : '/'
    localStorage.setItem("routeName", route.name)
  }


  render() {

    const {t}=this.props
    const checkAlowedRoutes = (route, ProductSettigs1) => {
      return route.children && route.children.map(route2 => {
        if (ProductSettigs1[route2.name] && (ProductSettigs1[route2.name].status || ProductSettigs1[route2.name].status === 1)) {
          route2.isNav = true
          if (route2.children) {
            route2.children = checkAlowedRoutes(route2, ProductSettigs1[route2.name])
          }
        }
        else if (ProductSettigs1[route2.name] && (ProductSettigs1[route2.name].status || ProductSettigs1[route2.name].status === 0)) {
          route2.isNav = false
        }
        return route2
      })
    }

    const { identity } = this.props
    const activeRoute = this.activeRoute
    const handleClick = this.handleClick

    const { globalSetting } = this.props
    if (globalSetting) {
      const { uiFeature } = globalSetting
      let settingData =  uiFeature

      // let isContainsApproval=routeList.filter(obj => obj.path == '/approvals')
      // //console.log("************globalSetting",globalSetting)
      // if(!settingData.tickets.showRequestsManagement && isContainsApproval.length > 0){routeList.find(obj => obj.path == '/approvals').allowedRole = []}

      settingData && routeList && routeList.map(route => {
        if (settingData[route.name] && (settingData[route.name].status || settingData[route.name].status === 1)) {
          //if(settingData.tickets.showRequestsManagement){}
          route.isNav = true
          if (route.children) {
            route.children = checkAlowedRoutes(route, settingData[route.name])
          }
          if(!settingData.tickets.showRequestsManagement && route.path=='/approvals')
          {
            route.isNav=false
          }
        }
        else if (settingData[route.name] && (settingData[route.name].status || settingData[route.name].status === 0)) {
          route.isNav = false
        }

        return route
      })
    }

    // badge addon to NavItem
    const badge = (badge) => {
      if (badge) {
        const classes = classNames(badge.class)
        return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>)
      }
    }

    // simple wrapper for nav-title item
    const wrapper = item => { return (!item.wrapper ? item.name : (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name))) }

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class)
      return (<li key={key} className={classes}>{wrapper(t(title))} </li>)
    }

    // nav list divider
    const divider = (divider, key) => (<li key={key} className="divider"></li>)

    // nav item with nav link
    const navItem = (item, key) => {
      // const classes = classNames("nav-link", item.class);
      if (item.app && item.app.path) {
        return (
          <div key={key} className="nav-item nav-dropdown">
            <NavLink1 onClick={this.handleAppClick.bind(this, item)} className={"nav-link"} >
              <div className="sp-col-1 sp-frame">  <i className={item.icon}></i></div><span className="text-span">{t(item.label) || t(item.name)}</span>{badge(item.badge)}
            </NavLink1>
          </div>
        )
      }
      return (
        <NavItem key={key} className="nav-item nav-dropdown">
          <NavLink to={item.app ? '../' + item.app.path : item.path} className={"nav-link"} activeClassName="active">
            <div className="sp-col-1 sp-frame">  <i className={item.icon}></i></div><span className="text-span">{t(item.label) || t(item.name)}</span>{badge(item.badge)}
          </NavLink>
        </NavItem>
      )
    }
    const filterNavLink = (children) => {
      return children.filter(item => (localTest && item.isNav) || (item.isNav && identity.roles && identity.roles.some(role => item.allowedRole && item.allowedRole.pop && item.allowedRole.includes(role))))
    }
    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={activeRoute(item.path, this.props)}>
          <a className="nav-link" onClick={handleClick.bind(this)}><div className="sp-col-1 sp-frame">  <i className={item.icon}></i></div> <span className="drop-text">{t(item.label) || t(item.name)}</span></a>
          <ul className="nav nav-list-items">
            {navList(filterNavLink(item.children))}
          </ul>
        </li>)
    }

    // nav link
    const navLink = (item, idx) =>
      item.title ? title(item, idx) :
        item.divider ? divider(item, idx) :
          item.children ? navDropdown(item, idx)
            : navItem(item, idx)

            // let isContainsApproval=routeList.filter(obj => obj.path == '/approvals')
            // //console.log("************globalSetting",globalSetting)
            // if(!globalSetting.uiFeature.tickets.showRequestsManagement && isContainsApproval.length > 0){routeList.find(obj => obj.path == '/approvals').allowedRole = []}

    //pop excluded module for customer
    let navList
    let filterNav = routeList.filter(item => {
      if (identity && identity.roles)
        return item.isNav && identity.roles && identity.roles.some(role => item.allowedRole && item.allowedRole.pop && item.allowedRole.includes(role))
      else if (localTest)
        return item.isNav
      else return false
    })
    navList = (filterNav) => {
      return filterNav.map((item, index) => navLink(item, index))
    }

    // sidebar-nav root
    if (filterNav.length > 1 || (filterNav.length === 1 && filterNav[0].children))
      return (

        <div className="sidebar" onMouseOver={this.sidebarHover.bind(this,true)}  onMouseOut={this.sidebarHover.bind(this,false)} >
          <nav className="sidebar-nav">
            <Nav>
              {navList(filterNav)}
            </Nav>
          </nav>
        </div>

      )
    else if (filterNav.length === 1) {
      let item = filterNav[0]
      if (item.app && item.app.path) {
        window.location = `${window.location.protocol}//${window.location.hostname}/${item.app.path}`
      } else {
        history.push(item.path)
      }
      return (<></>)
    }
    else return <></>
  }
}
export default withTranslation()(Sidebar)