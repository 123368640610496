import { agentqueueConstants } from '..'
import { BaseActions } from '../../base-ui'
import { alertActions } from '../../common'
import agentqueueServices from '../services/agentqueue.services'

let options = {}
options.service = agentqueueServices
options.resourceName = 'agentqueue'

class QueueLiveChatActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}

	updateInfo(queueData, t) {
		let that = this
		return dispatch => {
			dispatch(that.updateInfoRequest())

			that.service.editQueueInfo(queueData).then(
				item => {
					if (item) {
						dispatch(that.updateInfoSuccess(item))
						dispatch(alertActions.success('Queue information updated successfully'))
					} else {
						let errorMsg = 'Cannot update the `Queue information` at this moment please try again later'
						dispatch(that.updateInfoError(errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot update the `Queue information` at this moment please try again later' + (error ? error.message : '')
					dispatch(that.updateInfoError(agentqueueConstants.UPDATEINFO_FAILURE_agentqueue))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	updateQueueAssignment(queueData, id, t) {
		let that = this
		return dispatch => {
			dispatch(super.request('ADDNEW'))
			let errorMsg =
				(t && t('Cannot update the Queue information at this moment, please try again later')) || 'Cannot update the Queue information at this moment, please try again later'
			that.service.updateQueueAssignment(queueData, id).then(
				item => {
					if (item) {
						dispatch(super.success('ADDNEW', item.newQueueData))
						dispatch(alertActions.success('Queue information updated successfully'))
					} else {
						// let errorMsg = "Cannot update the `Queue information` at this moment please try again later"
						dispatch(super.error(errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					// let errorMsg = "Cannot update the `Queue information` at this moment please try again later" + (error?error.message:'')
					errorMsg = errorMsg + ' ' + (error ? error.message : '')
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	updateInfoRequest() {
		return { type: agentqueueConstants.UPDATEINFO_REQUEST_agentqueue }
	}
	updateInfoSuccess(record) {
		return { type: agentqueueConstants.UPDATEINFO_SUCCESS_agentqueue, item: record }
	}
	updateInfoError(error) {
		return { type: agentqueueConstants.UPDATEINFO_FAILURE_agentqueue, error: error }
	}
}

let QueueLiveChatActions = new QueueLiveChatActionModel(options)

export const getAgentQueueData = (tenantId, filter) => QueueLiveChatActions.getAll(tenantId, filter)
export const addAgentQueue = (itemData, tenantId) => QueueLiveChatActions.addNewItem(itemData, tenantId)
export const updateAgentQueue = (itemData, editId) => QueueLiveChatActions.updateQueueAssignment(itemData, editId)
export const deleteQueue = (deletedId, tenantId) => QueueLiveChatActions.deleteItem(deletedId, tenantId)
export const updateInfoQueue = (tenantId, queueData) => QueueLiveChatActions.updateInfo(tenantId, queueData)
