import { reportsConstants } from '..'
import { alertActions } from '../../common'
import { reportsServices } from '../services/reports.services'

let options = {}
options.service = reportsServices
options.resourceName = 'Reports'
class ReportsActionModel {
	constructor(options) {
		this.service = options.service
		this.resourceName = options.resourceName
	}
	getStdReport(tenantId, reportName, startDate, endDate, username, filter, workingHour, t) {
		return dispatch => {
			dispatch(this.getStdReportRequest())
			reportsServices
				.getStdReport(tenantId, reportName, startDate, endDate, username, filter, workingHour)
				.then(
					records => {
						if (records.name === 'error') {
							dispatch(this.getStdReportRequestFailure(records.detail))
							dispatch(alertActions.error((t && t(reportsConstants.ERROR_MESSAGE)) || reportsConstants.ERROR_MESSAGE))
						} else if (records.type === 'warning' || records.warn) {
							dispatch(this.getStdReportRequestFailure(records.msg || records.warn))
							dispatch(alertActions.info((t && t(reportsConstants.WARNING_MESSAGE)) || reportsConstants.WARNING_MESSAGE))
						} else {
							dispatch(this.getStdReportRequestSuccess(records))
							dispatch(alertActions.success((t && t(reportsConstants.SUCCESS_MESSAGE)) || reportsConstants.SUCCESS_MESSAGE))
							const { url } = records
							// window.open(url, '_blank')
						}
					},
					error => dispatch(this.getStdReportRequestFailure(reportsConstants.ERROR_MESSAGE + error))
				)
				.catch(e => {
					dispatch(this.getStdReportRequestFailure(reportsConstants.ERROR_MESSAGE + e))
				})
		}
	}
	getStdReportRequest() {
		return { type: reportsConstants.REPORT_REQUEST }
	}
	getStdReportRequestSuccess = records => ({
		type: reportsConstants.REPORT_SUCCESS,
		records,
	})
	getStdReportRequestFailure = error => ({
		type: reportsConstants.REPORT_FAILURE,
		error,
	})
}
let ReportsActions = new ReportsActionModel(options)
export const getStdReport = (tenantId, reportName, startDate, endDate, username, filter, workingHour) =>
	ReportsActions.getStdReport(tenantId, reportName, startDate, endDate, username, filter, workingHour)
