import { ticketCategoryConstants } from '../';

export const ticketcategory = (state = {}, action) => {
    switch (action.type) {
        case ticketCategoryConstants.GETALL_REQUEST_ticketcategory:
            return {
                ...state,
                loading: true
            }
        case ticketCategoryConstants.GETALL_SUCCESS_ticketcategory:
            return {
                ...state,
                loading: false,
                catData: action.data,

            }
        case ticketCategoryConstants.GETALL_FAILURE_ticketcategory:
            return {
                ...state,
                loading: false
            }
            
        default:
            return state
    }
}