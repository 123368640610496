/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment';
import { DashboardT } from '../common/analytics';
// import {EntityComponent} from '../entity'
import { methodSchemas } from './schemas';
// import {    baseConstants} from '../../entity';


class AgentDashboardT extends DashboardT {
     constructor(options){
         super(options);
     }
    
    getPropsFilterValues({profile}){
        if(profile.roles.includes('chat_agent_supervisor') && !profile.roles.includes('chat_agent')){
            // Need to get all agent data, skip the filter
            return ;
        }
        let agentFilter = 
        {
            field: 'agent',
            operator: 'is',
            value: profile.preferred_username
        }
        if(!this.apisMeta.liveChatMetrics.filters.some(f=> f.field === 'agent')){
            this.apisMeta.liveChatMetrics.filters.push(agentFilter);
        }
        this.cardsMeta.liveChatCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.cardsMeta.liveChatOpenCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.cardsMeta.liveChatClosedCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.cardsMeta.avgFeedback.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.chartsMeta.avgResponseTime.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.chartsMeta.nonAvailableByReason.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.chartsMeta.avgConversationTime.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }

        this.sectionsMeta.filters.items = this.sectionsMeta.filters.items.filter(f =>{
            return f.name != 'agent'
        })

        delete this.fieldsMeta['agent']
        return ;
    }
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 * 
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 * 
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *   
 *  cards: livechatCount, avgFeedback
 *  line: avg Response, avgResolution
 *  bar: missedChat
 *  % escalations: pie.
 * 
 */




let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let dayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate())
let dayBeginF =
    moment(dayBegin).tz(moment.tz.guess()).format().slice(0, -6)

let weekBegin = new Date(now.getFullYear(),now.getMonth(), now.getDate() - now.getDay())
let weekBeginF =
    moment(weekBegin).tz(moment.tz.guess()).format().slice(0, -6)

let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF =
    moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
    liveChatMetrics: {
        name: 'liveChatMetrics',
        /**
         * get most metrics if not all associated with the page.
         */
        dependsOnFields: [ 'agent', 'queue'], // page filters
        filters: [{
            field: 'createdAt',
            operator: 'is within',
            //values: ['fromDate', 'toDate'],
            values: [monthBeginF, nowF]
           // defaultValues: [monthBeginF, nowF] // to be injected
        },
        // {
        //     field: 'metricName',
        //     operator: 'contains',
        //     values: ['feedback_live', 'live_chat_count', 'resolution_time', 'response_time', 'missed_chat_count', 'chat_session_count'] // to be injected
        // }
        ],
        select: ['metricName', 'createdAt', 'duration', 'reason'],
        // groupBy: [ 'agent'], // date, metricname are default grouped
        groupByAgg: [{ field: 'count', operation: 'sum', as: 'metric' }, { field: 'sum', operation: 'sum', as: 'summetric' }, { field: "avg", operation: "average", as: "avg"}],
        //  
        path: 'analytics',
        method: 'POST',
        groupBy: ['agent', 'queue'],
        
        response: {
            valueKey: 'data',  //  data attribute of the reducer... <reducer>.data.metrics
            // computeFn : 'flatternJson',
            // colToFlat : 'dim'
        },
        orderBy: [{ field: 'createdAt', order: 'asc' }]
    },
    liveChatMetricsOpen: {
        name: 'liveChatMetricsOpen',
        dependsOnFields: [ 'agent', 'queue'], // page filters
        filters: [{
            field: 'createdAt',
            operator: 'is within',
            values: [monthBeginF, nowF]
        },
        ],
        select: ['metricName', 'createdAt', 'duration', 'reason'],
        groupByAgg: [{ field: 'count', operation: 'sum', as: 'metric' }, { field: 'sum', operation: 'sum', as: 'summetric' }, { field: "avg", operation: "average", as: "avg"}],  
        path: 'analytics',
        method: 'POST',
        groupBy: ['agent', 'queue'],
        
        response: {
            valueKey: 'data',
        },
        orderBy: [{ field: 'createdAt', order: 'asc' }]
    }
}

const cardsMeta = {
    liveChatOpenCount: {
        title: 'Live Chat Open Count',
        metrics: [{ name: 'LiveChat', fields: ['summetric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
        api: 'liveChatMetricsOpen',
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    liveChatClosedCount: {
        title: 'Live Chat Closed Count',
        metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_closed_count' }],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        //size: 'xs',
        // dependsOnFields: ['fromDate', 'toDate'],
        size: 'xl',
        classes: 'bg-gradient-ly',
        // ? where is the data reference?
        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'responseSLA' }, { field: 'createdAt', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    liveChatCount: {
        title: 'Live Chat Count',
        metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_count' }, ],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        //size: 'xs',
        // dependsOnFields: ['fromDate', 'toDate'],
        size: 'xl',
        classes: 'bg-gradient-ly',
        // ? where is the data reference?
        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'responseSLA' }, { field: 'createdAt', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    avgFeedback: {
        title: 'CSAT Average',
        metrics: [{ name: 'avgFeedback', fields: ['avg'], operations: ['mean'] }], // need summetric / metric as value
        filters: [{ field: 'metricName', value: 'feedback_live' }, ],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        size: 'xl',
        dependsOnFields: ['fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'bg-gradient-mb',
    },
    reassignedCount:{
        name: 'reassignedCount',
        title: 'Reassigned Count',
        metrics: [{ name: 'reassignedCount', fields: ['avg'], operation: ['mean'] }], // need summetric / metric as value
        filters: [{ field: 'metricName', value: 'escalation_count' }],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        size: 'xl',
        dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'bg-gradient-mb',
    }
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {
    /**
     * dualAxis chart eg.
     
     */
    
    avgResponseTime: {
        padding: [20,40,100,80],
        name: 'avgResponseTime',
        title: 'Avg Response Time',
        chartType: 'lines',
        xy: 'createdAt*avg',
        axes: [  { field: 'createdAt', alias: 'Date',  type: 'cat'}, { field: 'avg', alias: 'Response Time (sec)', label:'mins' }],
        metrics:[ {series: 'Mine', field:'avg', operation: ['mean'], groupBy: ['createdAt'] }],
        filters: [{field: 'metricName', value: 'response_time'}], // static filters
        dynFilters: { // dynamic filters
           createdAt: { operator: 'range', values: ['fromDate', 'toDate']},
           agent: 'agent'
        },
        dependsOnFields: ['agent','fromDate','toDate'],
        height : 200
    },
    /** need to supply overall chat count too as data point here. */
    nonAvailableByReason: {
        padding: [20,40,100,80],
        name: 'nonAvailableByReason',
        title: 'NonAvailable Time',
        chartType: 'lines',
        xy: 'reason*avg',
        axes: [  { field: 'avg', alias: 'Duration'}, { field: 'reason', alias: 'Reason' }],
        metrics: [{series: 'Duration', field:'avg', operation: ['sum'], groupBy: ['reason']}],
        filters: [{field: 'metricName', value: 'non_availability'}], // static filters
        dependsOnFields: ['fromDate','toDate'],
        dynFilters: { // dynamic filters
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        height : 200
    },
    avgConversationTime: {
        padding: [20,40,100,80],
        name: 'avgConversationTime',
        title: 'Avg Conversation Time',
        chartType: 'lines',
        xy: 'createdAt*avg',
        axes: [{ field: 'createdAt', alias: 'Date',  type: 'cat'}, { field: 'avg', alias: 'Conversation Time (mins)', label:'mins' }],
        metrics:[{series: 'Mine', field:'avg', operation: ['mean'], groupBy: ['createdAt'] }],
        filters: [{field: 'metricName', value: 'conversation_time'}], // static filters
        dynFilters: { // dynamic filters
           createdAt: { operator: 'range', values: ['fromDate', 'toDate']},
           agent: 'agent'
        },
        dependsOnFields: ['agent', 'fromDate','toDate'],
        height : 200
    }
}
const fieldsMeta = {
    fromDate: {
        label: 'From Date',
        name: 'fromDate',
        type: 'text',
        value : dayBeginF,
        valueFn : ( period) => {
            if(!period) return
            switch( period) {
                case 'Day':
                    return dayBeginF
                case 'Week': 
                    return weekBeginF
                case 'Month': 
                    return monthBeginF
                default:
                    return monthBeginF
            }
        },
        dependsOnFields: ['period'],
        readOnly : true 
    },
    toDate: {
        label: 'To Date',
        name: 'toDate',
        type: 'calculated',
        value: nowF,
        readOnly : true 
    },
    // need to map to appropriate api to get all live chat agents by name/email
    agent: { // list of queues associated with the user..
        label: 'Agent',
        type: 'select',
        required: true,
        name: 'agent',
        api: {
            path: 'active_agents/getAllAgents',
           // params: { filter: ['agent'] },
            response: {
                ref: 'data',
                labelKey: 'agentName',
                valueKey: 'username'
            }
        },
        allowedRole : 'chat_agent_supervisor'
    },
    period: {
        label: 'Period',
        type: 'select',
        name: 'period',
        options: [{label: 'Day', value: 'Day'}, {label: 'Week', value : 'Week'}, {label: 'Month', value: 'Month'}],
        default: 'Month'
    }

}


const listsMeta = {
    exampleList: {
        // api: 
        // dependsOnFields:
        // dataProp: '' ,
        title: 'My List of Items',
        name: 'exampleList',
        filters: [{ field: 'metricName', value: 'resolutionSLA' }], // static filters
        dynFilters: { // dynamic filters
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            //queue: 'queue'
        },
        height: 400,
        colProps: [{field: 'metricName', width: 20, route: '/ticket/settings/update/some'}, {field: 'metric', width: 15 }, {field: 'queue'}, {field: 'agent'},{field: 'createdAt', label: 'Date'}]
    }
}
const sectionsMeta = {
    headerComponent: {
        component: 'DetailHeader',

        // props: [] by default pass all parent props as props to the component.
    },

    basic: {
        title: 'Query Details',
        items: [{ name: 'period', type: 'field' },  { name: 'agent', type: 'field' },  { name: 'queue', type: 'field' }]
    },
    filters: {
        items: [{ name: 'period', type: 'field', col: 1 } , { name: 'agent', type: 'field', col: 2 }, { name: 'fromDate', type: 'field', col: 3}],
        title: 'Filters',
        cols: [4, 4, 4]
    },
    cards: {
        cols: [4, 4, 4],
        items: [{ name: 'liveChatCount', type: 'card', col: 1 }, {name: 'liveChatOpenCount', type: 'card', col: 2}, { name: 'avgFeedback', type: 'card', col: 3 }, {name: 'liveChatClosedCount', type: 'card', col: 1}, {name: 'reassignedCount',  type:'card', col:2} ]
    },
    
    charts: {
        cols: [6, 6],
        items: [
            { name: 'avgResponseTime', type: 'chart', col: 1 },
            { name: 'avgConversationTime', type: 'chart', col: 2},
            {name: 'nonAvailableByReason', type:'chart', col:1},
        ]
    }

}

const dashboardMeta = {
    items: [{ name: 'filters', type: 'section' }, { name: 'cards', type: 'section' }, { name: 'charts', type: 'section' } ],
    classes: 'composite-form',
    title: 'Agent Dashboard',
    initialValues: {
        filterValues: {
            fromDate: monthBeginF,
            toDate: nowF,
            period: 'Month'
        }
    },
    apis: ['liveChatMetrics']
}

const AgentDashboard = new AgentDashboardT({ fieldsMeta, sectionsMeta, dashboardMeta, apisMeta, cardsMeta, chartsMeta, listsMeta, name: 'liveChatDashboard', title: 'Agent Dashboard', methodSchemas })

const AgentDashboardContainer = AgentDashboard.getContainer('Dashboard')
export { AgentDashboardContainer };
