// import { apiUrl } from '../../../config';
import { qnaConstants } from '../'
import { identityServices } from '../../common'

export const addNewQna = (data, tenantId, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna`,
		data: data,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const addTrainQna = (data, selectedCategorySave, customerTenantID, failedUtteranceOperationId, qnaAppId, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	data['failedUtteranceOperationId'] = failedUtteranceOperationId
	const requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qnav4/addQnaTrain`,
		data: data,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		let qnaData = {
			qnaPairs: { question: [data.question], answer: data.answer, answer_id: data.answer_id },
			selectedCategorySave: selectedCategorySave,
			userid: data.userid,
			qnaAppId: qnaAppId,
			metadata: data.metadata,
		}
		const requestOptions = {
			method: 'POST',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/qnav4/updateTrainStatus`,
			data: qnaData,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}
			return response.data
		})
	})
}

export const updateQna = (data, tenantId, qnaIdPk, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/${qnaIdPk}`,
		data: data,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const updateTrainStatus = (data, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/deleteFailedUttrances`,
		data: data,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const deleteQna = (qnaIdPk, tenantId, updatedBy, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/${qnaIdPk}?updatedBy=${updatedBy}`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const publishQna = (tenantId, type, userId, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/publishQna?type=${type}&updatedBy=${userId}`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getAllQna = (page, search, orderColumn, orderType, filter, filterByCategoryArr, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	// let data = {
	//     filterByCategoryArr: filterByCategoryArr
	// }

	let limit = qnaConstants.RECORD_LIMIT
	if (window.location.pathname.indexOf('/train') > -1) limit = qnaConstants.RECORD_TRAIN

	const requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/qnaRecords?limit=${limit}&page=${page}&search=${search}&sortName=${orderColumn}&sortOrder=${orderType}&filter=${filter}&filterByCategoryArr=${filterByCategoryArr.join(
			','
		)}&type=qna&ts=${new Date().getTime()}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getTrainQuestions = (customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/getTrainQuestions?ts=${new Date().getTime()}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getTrainQuestionsFiltered = (multiCategory, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	let newArr = {
		multiCategory: multiCategory,
	}
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/getTrainQuestionsFiltered?ts=${new Date().getTime()}`,
		data: newArr,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getQnabyId = (id, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/qnaRecordById/${id}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const downloadKB = (customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/downloadKB?type=qna`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}
export const bulkUpdateTrainStatus = (data, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/bulkUpdateTrainStatus`,
		data: data,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const associateCategory = (qnaSelected, multiCategory, customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	let newArr = {
		qnaSelected: qnaSelected,
		multiCategory: multiCategory,
		customerId: customerTenantID,
	}
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/associateCategory`,
		data: newArr,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getMultipleSA = (customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/getMultipleSA`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const getUniqueSA = (customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/getUniqueAppId`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

//luis add uttrance
export const addTrainIntent = async (data, customerTenantID, luisAppId, apiUrl) => {
	try {
		let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
		apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
		let qnaData = { qnaPairs: { question: [data.question], answer: data.answer }, luisAppId: luisAppId }

		// First request
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/qna/addTrainIntent`,
			data: qnaData,
		}
		let response = await identityServices.identityApi.request(requestOptions)
		if (response.status !== 200) {
			return 'Failed to fetch data from addTrainIntent'
		}

		// second request
		const requestOptions1 = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/qna/updateTrainStatus`,
			data: data,
		}
		let response1 = await identityServices.identityApi.request(requestOptions1)
		if (response1.status !== 200) {
			return 'Failed to fetch data from addTrainIntent'
		}

		// third request
		const requestOptions2 = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/qna/publishTrainIntents`,
			data: qnaData,
		}
		let response2 = await identityServices.identityApi.request(requestOptions2)
		if (response2.status !== 200) {
			return 'Failed to fetch data from addTrainIntent'
		}

		return response2.data
	} catch {
		return 'Failed to fetch data from addTrainIntent'
	}
}

export const getUnpublishedCount = (customerTenantID, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/qnaUnpublishedCount?ts=${new Date().getTime()}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const questionExists = (tenantId, question, i, id, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${apiUrlCustom}/qna/questionExist?question=${question}&id=${id}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const uploadImage = (customerTenantID, formData, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'multipart/form-data' },
		url: `${apiUrlCustom}/qnav4/editorImage`,
		data: formData,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const uploadQnaKB = (customerTenantID, formData, updatedBy, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrlCustom
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'multipart/form-data' },
		url: `${apiUrlCustom}/qna/uploadKB?updatedBy=${updatedBy}`,
		data: formData,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}

export const syncExternalKb = (tenantId, updatedBy, apiUrl) => {
	let apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
	apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'multipart/form-data' },
		url: `${apiUrlCustom}/qna/syncQnaServicePro?updatedBy=${updatedBy}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}
