import Joi from 'joi-browser'

const emailTemplates = Joi.object().keys({
	properties: Joi.object().keys({
		type: Joi.string(),
		whenObj: Joi.string().allow('', null),
		notifyExternalSystem: Joi.string().allow('', null),
		notifyExternalSystemCond: Joi.string().allow('', null),
		redirectAction: Joi.string().allow('', null),
		notificationPayload: Joi.array().items(Joi.object().keys({
			size: Joi.string().required(),
			text: Joi.string().required(),
			type: Joi.string().required(),
			wrap: Joi.boolean().required(),
			color: Joi.string().required(),
			weight: Joi.string().required(),
			fontType: Joi.string().required(),
			})
		).allow('', null),
	}),
	id: Joi.number(),
	eventType: Joi.string().required().allow('', null),
	conditionName: Joi.string().allow(null),
	templateName: Joi.string(),
	queue: Joi.string().max(100).allow(null),
	action: Joi.string().max(100).allow(null),
	emailTemplate: Joi.string(),
	toConfig: Joi.array().items(Joi.string()).allow(null),
	ccConfig: Joi.array().items(Joi.string()).allow(null),
	fromConfig: Joi.array().items(Joi.string()).allow(null),
	additionalTemplateConfig: Joi.object().allow(null),
	sub_prefix_config: Joi.string().allow(null),
	subject: Joi.string().allow(null),
	tenantId: Joi.string().uuid().required(), // used as namespace in i18n // both ui appName.featureName or appModule.featureName allowed in feature
	status: Joi.string().valid('Published', 'UnPublished', 'unPublished', 'Deleted'),
	createdBy: Joi.string().allow(null),
	updatedBy: Joi.string().allow(null),
	createdAt: Joi.date().allow(null),
	updatedAt: Joi.date().allow(null),
	deletedSeq: Joi.number().integer(),
	lookupProps: Joi.object().keys({
		usecase: Joi.string().required(),
		clientApp: Joi.string().required().valid('tickets', 'approvals', 'botapi', 'livechat'),
		itemType: Joi.string().optional().allow('', null),
	}),
})

// These are the schemas of the return object from the api server

export const methodSchemas = {
	getAll: Joi.array().items(emailTemplates),
	getOne: emailTemplates,
	addItem: emailTemplates,
	editItem: emailTemplates,
	upsert: emailTemplates,
}
export const collectionSchemas = {
	//  notes: note
}
export const entitySchema = emailTemplates
