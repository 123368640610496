/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment';
import { DashboardT } from '../common/analytics';
import { methodSchemas } from './schemas';


class RealTimeInsights extends DashboardT {
     constructor(options){
         super(options);
     }
    
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 * 
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 * 
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *   
 *  cards: TotalLiveChatsRequested, Responded, Missed, avgResponseTime, avgChatDuration, avgCSAT 
 * 
 */




let now = new Date()
let last24Hours = moment(now).utc().subtract(24,"hours").format().slice(0, -1);
let last8Hours = moment(now).utc().subtract(8,"hours").format().slice(0, -1);
let last1Hour = moment(now).utc().subtract(1,"hours").format().slice(0, -1);

const apisMeta = {
    liveChatMetrics: {
        name: 'liveChatMetrics',
        /**
         * get most metrics if not all associated with the page.
         */
        dependsOnFields: ['queue', 'startHours'], // page filters
        filters: [{
            field: 'updatedAt',
            operator: '>=',
            value: last1Hour,
            valueRef: 'startHours'
        },
        ],
        metricType: 'Day',
        select: ['metricName', 'createdAt', 'afterHours', 'active_agents', 'inactive_agents'],
        groupByAgg: [{ field: 'value_number', operation: 'sum', as: 'metric' }, { field: 'value_number', operation: 'average', as: 'avg' }],
        path: 'analytics',
        method: 'POST',
        groupBy: ['queue'],
        response: {
            valueKey: 'data',  //  data attribute of the reducer... <reducer>.data.metrics
        },
        orderBy: [{ field: 'createdAt', order: 'asc' }]
    }
}

const cardsMeta = {
    missedChatCount: {
        title: 'Missed - Business Hours',
        metrics: [{ name: 'missedChatCount', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'missed_chat_count' }, { field: 'afterHours', operator: 'is', value: 'No' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    },
    missedChatCountAfterHours: {
        title: 'Missed - After Hours',
        metrics: [{ name: 'missedChatCountAfterHours', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'missed_chat_count' },{ field: 'afterHours', operator: 'is', value: 'Yes' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    },
    avgResponseTime: {
        title: 'Average Response Time (sec)',
        metrics: [{ name: 'avgResponseTime', fields: ['avg'], operations: ['mean'] }],
        filters: [{ field: 'metricName', value: 'response_time' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        // dependsOnFields: [ 'period'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    avgConversationTime: {
        title: 'Average Chat Duration (mins)',
        metrics: [{ name: 'avgConversationTime', fields: ['avg'], operations: ['mean'] }],
        filters: [{ field: 'metricName', value: 'conversation_time' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    activeChats: {
        title: 'Total Active Chats',
        metrics: [{ name: 'activeChats', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_count' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    },
    awaitingChats: {
        title: 'Total Waiting Chats',
        metrics: [{ name: 'awaitingChats', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
        dynFilters: {
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    },
    activeAgents: {
        title: 'Agents Online',
        metrics: [{ name: 'activeAgentsactiveAgents', fields: ['metric'] }],
        filters: [{ field: 'metricName', value: 'active_agents' }],
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    },
    inActiveAgents: {
        title: 'Agents Offline',
        metrics: [{ name: 'inActiveAgents', fields: ['metric'] }],
        filters: [{ field: 'metricName', value: 'inactive_agents' }],
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'startHours'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    }
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {}

const fieldsMeta = {
    startHours: {
        label: 'Start Hours',
        name: 'startHours',
        type: 'text',
        value : last1Hour,
        valueFn : ( period) => {
            if(!period) return
            switch( period) {
                case '1Hour':
                    return last1Hour
                case '8Hours': 
                    return last8Hours
                case '24Hours': 
                    return last24Hours
                default:
                    return last1Hour
            }
        },
        dependsOnFields: ['period'],
        readOnly : true 
    },
    period: {
        label: 'Hours Ago',
        type: 'select',
        name: 'period',
        options: [{label: '1Hour', value: '1Hour'}, {label: '8Hours', value : '8Hours'}, {label: '24Hours', value: '24Hours'}],
        default: '1Hour'
    },
    queue: {
        label: 'Queue',
        type: 'select',
        required: true,
        name: 'queue',
        lengthCheck:1,
        api: {
            path: 'queue_livechat/getqueuesdistinct',
            response: {
                ref: 'data',
                labelKey: 'queuelabel',
                valueKey: 'queuename'
            }
        },
        allowedRole : 'chat_agent_supervisor'
    },

}

const sectionsMeta = {
    headerComponent: {
        component: 'DetailHeader',

        // props: [] by default pass all parent props as props to the component.
    },

    basic: {
        title: 'Query Details',
        items: [{ name: 'period', type: 'field' }]
    },
    filters: {
        items: [{ name: 'period', type: 'field', col: 1}, { name: 'startHours', type: 'field', col: 3}, { name: 'queue', type: 'field', col: 2 }],
        title: 'Filters',
        cols: [4, 4, 4]
    },
    cards: {
        cols: [4, 4, 4],
        items: [{name:'activeAgents', type:'card', col: 1},{name:'inActiveAgents', type:'card', col: 2},{name:'activeChats', type:'card', col: 3},{name:'awaitingChats', type:'card', col: 1},{ name: 'missedChatCount', type: 'card', col: 2 }, {name: 'missedChatCountAfterHours' , type:'card', col: 3}, {name: 'avgResponseTime',  type:'card', col:1}, {name: 'avgConversationTime', type: 'card', col:2}]
    }

}

const dashboardMeta = {
    items: [{ name: 'filters', type: 'section' }, { name: 'cards', type: 'section' }],
    classes: 'composite-form',
    title: 'RealTime Insights',
    initialValues: {
        filterValues: {
            startHours: last1Hour,
            period: '1Hour'
        }
    },
    refresh: true,
    apis: ['liveChatMetrics']
}

const RealTimeInsightsDashboard = new RealTimeInsights({ fieldsMeta, sectionsMeta, dashboardMeta, apisMeta, cardsMeta, name: 'RealTimeInsights', title: 'RealTime Insights', methodSchemas })

const RealTimeInsightsDashboardContainer = RealTimeInsightsDashboard.getContainer('Dashboard')
export { RealTimeInsightsDashboardContainer }