import * as AdaptiveCards from 'adaptivecards'
import $ from 'jquery'
import React, { Component } from 'react'
import AdaptiveCard from 'react-adaptivecards'
import { withTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Input, Label, Modal, ModalBody } from 'reactstrap'
var adaptiveCard = new AdaptiveCards.AdaptiveCard()
class ReviewChatMessage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			flagCheck: false,
		}
	}

	flagNow(id, flag) {
		this.setState({ flagCheck: true })
		setTimeout(() => {
			this.setState({ flagCheck: false })
		}, 2000)
		let { activePage, t } = this.props
		this.props.flagIt(id, flag, activePage, t)
	}

	toggleModalCloseNow() {
		this.props.toggleModalClose()
	}
	render() {
		const { chatData, t } = this.props
		let chatMessages
		if (chatData && chatData.length > 0) {
			chatMessages = chatData
		}
		function AdaptiveHtmlBot(data) {
			for (let i = 0; i < data.card.body.length; i++) {
				if (data.card.body[i] && data.card.body[i].value) data.card.body[i].value = ''
			}
			data.card['version'] = '1.0'
			return (
				<div>
					<AdaptiveCard payload={data.card} />
				</div>
			)
		}
		function createMarkup(html) {
			return { __html: html }
		}
		const AdaptiveHtml = data => {
			var card = data.card.attachments[0].content
			for (let i = 0; i < card.body.length; i++) {
				for (var key in card.body[i]) {
					if (card.body[i].id == key) {
						card.body[i].value = ''
					}
				}
			}
			adaptiveCard.parse(card)
			var renderedCard = adaptiveCard.render()
			$(`#adcard`).html(renderedCard)
			let html = $(`#adcard`).html()
			return (
				<div id={data.cardid}>
					<div dangerouslySetInnerHTML={createMarkup(html)} />
				</div>
			)
		}

		const Chatuserratings = data => {
			let rating = data.ratings ? data.ratings.trim() : ''
			rating = parseInt(rating)
			rating = rating >= 5 ? 5 : rating
			let html = ''
			if (rating) {
				for (let i = 0; i < parseInt(rating); i++) {
					html += '<span class="" style="width:25px !important"><i class="fa fa-star fa-lg"></i></span>'
				}
			} else {
				html = 'NA'
			}
			//return html;
			return <span className="rating-user" dangerouslySetInnerHTML={{ __html: html }}></span>
		}

		const FeedBackCard = ({ cardData, cardId }) => {
			return (
				<div className="rating">
					<div>
						{t('Rating')}: <Chatuserratings ratings={cardData.feedback} />
					</div>
					<div>
						{t('Comments')}: {cardData.comments || cardData.comments != '' ? cardData.comments : 'N/A'}
					</div>
				</div>
			)
			//   var card = feedbackCard;
			//   for (let i = 0; i < card.body.length; i++) {
			//     for (var key in cardData) {
			//       if (card.body[i].id == key) {
			//         card.body[i].value = cardData[key];
			//       }
			//     }
			//   }
			//   console.log("card::", card)
			//   adaptiveCard.parse(card);
			//   var renderedCard = adaptiveCard.render();
			//   $(`#adcard`).html(renderedCard);
			//   let html = $(`#adcard`).html()
			//   return (
			//     <div id={cardId}>
			//       <div dangerouslySetInnerHTML={createMarkup(html)} />
			//     </div>
			//   );
		}

		const AnswerHtml = data => {
			let html = ''
			if (data.ans) {
				let splitAns = data.ans.split('~')
				html = splitAns[0].replace(/\\n/g, ' \n')
			}
			//return html;
			return <ReactMarkdown escapeHtml={false} source={html} renderers={{ link: LinkRenderer }} />
		}

		const LinkRenderer = props => {
			return (
				<a href={props.href} target="_blank" rel="noreferrer">
					{props.children}
				</a>
			)
		}
		return (
			<div className="chat-modal">
				<Modal autoFocus={false} isOpen={this.props.modal} backdrop="static" toggle={this.toggleModalchat} className={this.props.className} className="delete-card editor-frame">
					<ModalBody>
						<div className="chat-frame">
							<div className="chat-header">
								<h3>{t('Chat Review')}</h3>
								<span className="cl-btn" onClick={this.toggleModalCloseNow.bind(this)}>
									<i className="fa fa-close"></i>
								</span>
							</div>
							<div id="adcard" style={{ display: 'none' }}></div>
							<div className="chat-body">
								{chatData &&
									chatData.length > 0 &&
									chatData.map(function (item, idx) {
										const sentBy = item.sent_by && item.bot && item.sent_by.toLowerCase() === item.bot.toLowerCase()
										return (
											<div className="clearfix" key={idx}>
												<div className={sentBy ? 'convo-wrap from-bot col-white' : 'convo-wrap from-me col-blue'}>
													{/* showing chat list */}
													{item.text && item.text != '' && (
														<div className="convo-wrapper">
															<AnswerHtml ans={item.text} />
															{item.text == 'Did you mean:' && (
																<div>
																	{item.attachments &&
																		item.attachments.length > 0 &&
																		item.attachments[0] &&
																		item.attachments[0].content &&
																		item.attachments[0].content.buttons.map((chat, index) => <p key={index}>{chat.title}</p>)}
																	{item.attachments &&
																		item.attachments[0] &&
																		item.attachments[0].content &&
																		item.attachments[0].content.buttons.map((chat, index) => <p key={index}>{chat.title}</p>)}
																</div>
															)}
														</div>
													)}

													{item.attachments &&
														item.attachments.length > 0 &&
														item.attachments[0] &&
														item.attachments[0].content &&
														item.attachments[0].contentType == 'application/vnd.microsoft.keyboard' && (
															<div className="convo-wrapper clearfix ">
																<div>
																	{item.attachments[0].content.buttons.map((chat, index) => (
																		<p key={index}>
																			<button className="ac-pushButton">{chat.title}</button>
																		</p>
																	))}
																</div>
															</div>
														)}

													{item.attachments &&
														item.attachments.length > 0 &&
														item.attachments[0] &&
														item.attachments[0].content &&
														item.attachments[0].contentType == 'application/vnd.microsoft.card.hero' && (
															<div className="convo-wrapper clearfix ">
																<p>
																	<strong>{item.attachments[0].content.title}</strong>
																</p>
																{item.attachments[0].content.text && <AnswerHtml ans={item.attachments[0].content.text} />}

																<div>
																	{item.attachments[0].content.buttons.map((chat, index) => (
																		<p key={index}>
																			<button className="ac-pushButton">{chat.title}</button>
																		</p>
																	))}
																</div>
															</div>
														)}

													{/* attachment list shows */}

													{/* Card input with attachments list */}
													{/* {(item.text || item.card_input || item.attachments) && */}
													{/* <div className='convo-wrapper'> */}
													{(item.card_input == null || (item.card_input && Object.keys(item.card_input).length == 0)) &&
														item.attachments &&
														item.attachments[0] &&
														item.attachments[0].content &&
														item.attachments[0].contentType === 'application/vnd.microsoft.card.adaptive' && (
															<div className="convo-wrapper">
																<AdaptiveHtml cardid={item.id} text={item.card_input} card={item} id={idx} pos="1" />
															</div>
														)}
													{item.attachments &&
														item.attachments[0] &&
														item.attachments[0].content &&
														item.attachments[0].contentType == 'application/vnd.microsoft.card.adaptive' &&
														item.attachments[0].content.actions &&
														item.attachments[0].content.actions[0] &&
														item.attachments[0].content.actions[0].card &&
														item.attachments[0].content.actions[0].card.body[0] &&
														item.attachments[0].content.actions[0].card.body[1] &&
														item.attachments[0].content.actions[0].card.body.length == 1 && (
															<div className="convo-wrapper">
																<p>
																	<strong>{item.attachments[0].content.actions[0].card.body[0].items[0].text}</strong>
																</p>
																<AnswerHtml ans={item.attachments[0].content.actions[0].card.body[1].items[0].text} />
															</div>
														)}

													{/* {
                              item.attachments && item.attachments.length > 0 && item.attachments[0] && item.attachments[0].content && item.attachments[0].contentType == 'application/vnd.microsoft.card.adaptive' &&
                              <div>
                                <BotAdaptive cardid={item.id} card={item.attachments[0].content} />
                              </div>
                            } */}

													{item.card_input && Object.keys(item.card_input).length > 0 && Object.keys(item.card_input).filter(key => key.indexOf('feedback') != -1).length != 0 && (
														<div className="convo-wrapper">
															<FeedBackCard cardId={item.id} cardData={item.card_input} id={idx} pos="2" />
														</div>
													)}
													{item.card_input &&
														Object.keys(item.card_input).length > 0 &&
														Object.keys(item.card_input).filter(key => key.indexOf('feedback') != -1).length == 0 &&
														Object.values(item.card_input).map(cardText => {
															return <div className="convo-wrapper">{cardText}</div>
														})}

													{/* </div>
                        } */}

													{/* Card input with attachments list */}

													{/* sent by name */}
													{(item.text || item.card_input || (item.attachments && item.attachments.length > 0)) && (
														<span className={sentBy ? 'user-name' : 'sender-name'}>
															{item.sent_by && item.sent_by === 'You'
																? 'User'
																: item.user_info && item.user_info.name && item.user_info.agentId
																? item.user_info.name
																: item.sent_by || item.bot || (item.user_info && item.user_info.name)}
														</span>
													)}
													{/* sent by name */}
												</div>
											</div>
										)
									})}

								{chatData && chatData.length == 0 && (
									<div className="no-content">
										<p>{t('You don’t have any Notification Data')}!</p>
									</div>
								)}
							</div>
							<div className="flag-togle clearfix">
								<span className="al-icon-col">
									<Label className="switch switch-icon switch-primary switch-pill">
										{this.props.flag == '1' && (
											<Input
												type="checkbox"
												disabled={this.state.flagCheck}
												className="switch-input"
												defaultChecked
												onClick={this.flagNow.bind(this, this.props.currentId, this.props.flag)}
											/>
										)}
										{this.props.flag == '0' && (
											<Input type="checkbox" disabled={this.state.flagCheck} className="switch-input" onClick={this.flagNow.bind(this, this.props.currentId, this.props.flag)} />
										)}
										<span className="switch-label" data-on={'\uf024'} data-off={'\uf024'}></span>
										<span className="switch-handle"></span>
									</Label>
								</span>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default withTranslation()(ReviewChatMessage)
