import React, { Component } from 'react';
import * as helper from './jsonInput/helper';
import { JsonDictComponent } from './jsonInput/jsonDict';
import { JsonListComponent, JsonListingComponent } from './jsonInput/JsonList';
import { JsonObjComponent, JsonObjListingComponent } from './jsonInput/JsonObj';
import './style.css';
class JsonInput extends Component {
    constructor(props) {
        super(props);
        this.state={
            parentProps:props || null,
            inputProps:null,
            inputValue:'',
            propValue:props && props.value ? props.value : '',
        }

    }

    componentWillMount() {
        
        this.mapInputProps()
        this.setState({propValue:this.props && this.props.value ? this.props.value : ''})
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.mapInputProps()
        this.setState({propValue:nextProps && nextProps.value ? nextProps.value : ''})
    }

    // shouldComponentUpdate(nextProps, nextState) {

    // }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

// handling jsonList
handleListInputChange=(inputName,inputValue)=>{
    this.setState((state, props) => { return { inputValue:inputValue }});
}


handleListInputSubmit=()=>{
    const {inputValue,parentProps}=this.state;
    let changedValue=this.props.value || [];
    inputValue && inputValue!='' && changedValue.push(inputValue);
    changedValue && changedValue.length>0 && parentProps.onComponentChange(parentProps.name,changedValue);
    this.setState((state, props) => { return { inputValue:'' }});
}

handleListInputUpdate=(editValue)=>{
    const {parentProps}=this.state;
    let updatedValue=editValue
    if (/^(""|''|)$/.test(editValue) || editValue == null){
        updatedValue=[]
    }
    parentProps.onComponentChange(parentProps.name,updatedValue);
    this.setState((state, props) => { return { inputValue:'' }});
}

handleJsonListInputUpdate=(editValue)=>{
    const {parentProps}=this.state;
    let updatedValue=editValue
    if (/^(""|''|)$/.test(editValue) || editValue == null){
        updatedValue=[]
    }
    parentProps.onComponentChange(parentProps.name,updatedValue);
    this.setState((state, props) => { return { inputValue:'' }});
}

handleDelete=(index)=>{
    const {value}=this.props
    let filterFromList = value && value.pop && value.length > 0 && value.filter((x, idx) => idx !== index);
    if (filterFromList && filterFromList.pop && filterFromList.length>0) {
        this.props.onComponentChange(this.props.name, filterFromList)
    }
    else {this.props.removeKey(this.props.name)}
}

// handling jsonList





// handling jsonObj
handleObjListInputChange=(inputName,inputValue)=>{
    this.setState((state, props) => { return { inputValue:inputValue }});
}


handleObjListInputSubmit=()=>{
    const {inputValue,parentProps}=this.state;
    let value = helper.checkValueType(this.props,inputValue)
    parentProps.onComponentChange(parentProps.name,value)
    // let changedValue=this.props.value || [];
    // inputValue && inputValue!='' && changedValue.push(inputValue);
    // changedValue && changedValue.length>0 && parentProps.onComponentChange(parentProps.name,changedValue);
    this.setState((state, props) => { return { inputValue:'' }});
    
}

handleObjListInputUpdate=(editValue)=>{
    // const {parentProps}=this.state;
    // parentProps.onComponentChange(parentProps.name,editValue);
    // this.setState((state, props) => { return { inputValue:'' }});
    
}

handleObjDelete=(index)=>{
    // const {value}=this.props
    // let filterFromList = value && value.pop && value.length > 0 && value.filter((x, idx) => idx != index);
    // if (filterFromList && filterFromList.pop) {
    //     this.props.onComponentChange(this.props.name, filterFromList)
    // }
}

// handling jsonObj




//handle jsonDict
handleJsonDictSubmit=()=>{
    const {inputValue,parentProps}=this.state;
    parentProps.onComponentChange(parentProps.name,inputValue)
    this.setState((state, props) => { return { inputValue:'' }});
    
}
//handle jsonDict


    mapInputProps=()=>{
        let inputProps=helper.mapProps(this.props);
        this.setState((state, props) => { return { inputProps }});
    }
    preTag=(value)=>{
        return (
            <pre>{JSON.stringify(value,null,2)}</pre>
        )
    }


    switchInputList=()=>{
        const {parentProps,inputProps}=this.state;

        switch (parentProps.type) {
            case 'jsonList':
                return (
                    <JsonListingComponent
                    {...inputProps}
                    preTag={this.preTag}
                    value={this.props.value}
                    remove={this.handleDelete}
                    handleInputChange={this.handleListInputChange}
                    handleInputUpdate={this.handleJsonListInputUpdate}

                />
                )
                case 'jsonText':
                    return (
                        <JsonListingComponent
                        {...inputProps}
                        preTag={this.preTag}
                        value={this.props.value}
                        remove={this.handleDelete}
                        handleInputChange={this.handleListInputChange}
                        handleInputUpdate={this.handleJsonListInputUpdate}
    
                    />
                    )

                case 'jsonObj':
                return (
                    <JsonObjListingComponent
                    {...inputProps}
                    preTag={this.preTag}
                    value={this.props.value}
                    remove={this.handleDelete}
                    handleInputChange={this.handleListInputChange}
                    handleInputUpdate={this.handleListInputUpdate}

                />
                )

                case 'jsonDict':
                return (
                    <JsonObjListingComponent
                    {...inputProps}
                    preTag={this.preTag}
                    value={this.props.value}
                    remove={this.handleDelete}
                    handleInputChange={this.handleListInputChange}
                    handleInputUpdate={this.handleListInputUpdate}

                />
                )
        
            default:
                break;
        }
    }



    switchInput=()=>{
        const {parentProps,inputProps}=this.state;
        switch (parentProps.type) {
            case 'jsonList':
                return (
                    <JsonListComponent
                    {...inputProps}
                    preTag={this.preTag}
                    handleInputChange={this.handleListInputChange}
                    handleInputSubmit={this.handleListInputSubmit}
                    value={this.state.inputValue}
    
                />
                )

                case 'jsonText':
                    return (
                        <JsonListComponent
                        {...inputProps}
                        preTag={this.preTag}
                        handleInputChange={this.handleListInputChange}
                        handleInputSubmit={this.handleListInputSubmit}
                        value={this.state.inputValue}
        
                    />
                    )

                case 'jsonObj':
                return (
                    <JsonObjComponent
                    {...inputProps}
                    preTag={this.preTag}
                    handleInputChange={this.handleObjListInputChange}
                    handleInputSubmit={this.handleObjListInputSubmit}
                    value={this.state.inputValue}
                    parentValue={this.props.value}
    
                />
                )

                case 'jsonDict':
                return (<JsonDictComponent
                {...inputProps}
                preTag={this.preTag}
                handleInputChange={this.handleObjListInputChange}
                handleInputSubmit={this.handleJsonDictSubmit}
                value={this.state.inputValue}
                parentValue={this.props.value}

            />
                )
        
            default:
                break;
        }
    }


    render() {
        // const {inputProps}=this.state

        return (
            <div>
                {this.switchInputList()}
                {this.switchInput()}
          
            </div>
        );
    }
}



export default JsonInput;