import React, { Component } from 'react';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './app/common/css/_custom.css'
import './app/common/css/style.css'
import './assets/scss/agent-app.css'
import './assets/scss/v4.css'
import { routeList } from './redux/routes'


// Containers
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import configureStore from './redux/store'
import { Root } from './app/common'
import { ChakraProvider } from "@chakra-ui/react"
import colors   from '@asc/ui-theme/theme/colors'
import ascTheme from '@asc/ui-theme'

let { store, persistor } = configureStore()

class App extends Component {
  render () {
    return (
      <ChakraProvider theme={ascTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className='App'>
            <Root routeList= { routeList }/>
          </div>
        </PersistGate>
      </Provider>
      </ChakraProvider>
    )
  }
}

export default App