import moment from 'moment'
import React, { Component } from 'react'
import Pagination from 'react-js-pagination'
import { Input, Label } from 'reactstrap'

class ReviewChatList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			flagOn: false,
			flagOff: false,
		}
	}
	componentWillMount() {
		var { profile, pageSize, showme } = this.props
		this.props.getAllAgents(profile.tenantUid)
		this.props.getReviewChats(
			profile.tenantUid,
			1,
			pageSize,
			showme,
			this.props.performance,
			this.props.orderColumn,
			this.props.orderType,
			this.props.agentFilterFlag
		)
	}

	openChatMessage(sid, flag, id) {
		this.props.toggleModalchat(sid, flag, id)
	}

	flagNow(id, flag) {
		this.setState({ flagOff: true })
		setTimeout(() => {
			this.setState({ flagOff: false })
		}, 2000)
		let { activePage } = this.props
		this.props.flagIt(id, flag, activePage)
	}

	render() {
		const { reviewChat, t } = this.props
		let totalCount = 0
		if (reviewChat && !reviewChat.loading && reviewChat.rows.length > 0) {
			totalCount = reviewChat.count
		}

		const padLeftZero = str => (str && str.length < 2 ? str.padStart(2, '0') : str)

		const Duration = data => {
			if (!data || !data.item) return true
			let start = moment(data.item.start_timestamp)
			let end = moment(data.item.end_datetime)
			let duration = ''
			let diff = moment.duration(end.diff(start))
			if (diff._data.days > 0) duration += diff._data.days + ' days '
			if (diff._data.hours > 0) duration += padLeftZero(diff._data.hours) + ':'
			else duration += '00:'
			if (diff._data.minutes > 0) duration += padLeftZero(diff._data.minutes) + ':'
			else duration += '00:'
			if (diff._data.seconds > 0) duration += padLeftZero(diff._data.seconds)
			else duration += '00'
			return duration
		}

		const Chatuserratings = data => {
			let rating = data.ratings ? data.ratings.trim() : ''
			rating = parseInt(rating)
			rating = rating >= 5 ? 5 : rating
			let html = ''
			if (rating) {
				for (let i = 0; i < parseInt(rating); i++) {
					html += '<span class=""><i class="fa fa-star fa-lg"></i></span>'
				}
			} else {
				html = 'NA'
			}
			//return html;
			return <p dangerouslySetInnerHTML={{ __html: html }}></p>
		}
		return (
			<div className="row">
				<div className="col-sm-12">
					<div className="main-list-chat">
						<ul className="chat-list">
							{reviewChat &&
								!reviewChat.loading &&
								reviewChat.rows &&
								reviewChat.rows.map((record, index) => (
									<li key={index}>
										<div className="User-name">
											<h4>{t('User name')}</h4>

											{record.user_info && (
												<p>
													{record && record.user_info && record.user_info.customerProfilePrimary
														? record.user_info.customerProfilePrimary
														: record.user_info.email
														? record.user_info.email
														: record.user_info?.username || 'Anonymous'}
												</p>
											)}
										</div>

										<div className="user-attributes">
											<h4>{t('Attributes')}</h4>

											{!record || !record.user_info || !record.user_info.attributes || Object.keys(record.user_info.attributes).length == 0
												? 'N/A'
												: Object.keys(record.user_info.attributes).length > 0 &&
												  Object.keys(record.user_info.attributes).map((key, i) => {
														if (key === 'profile_pic') return true
														if (Array.isArray(record.user_info.attributes[key]))
															return (
																<span className="attr-wrapper-span" key={i}>
																	{`${key} : ${record.user_info.attributes[key].join(', ')} `}
																</span>
															)
														else
															return (
																<span className="attr-wrapper-span" key={i}>
																	{`${key} : ${record.user_info.attributes[key]} `}
																</span>
															)
												  })}
										</div>

										{/* <div className="chat-id">
                    <h4>Chat Id</h4>
                    <p id="chat_id">{record.session_id}</p>
                  </div> */}
										<div className="User-name">
											<h4>{t('Agent')}</h4>
											<p>{record.agent || 'N/A'}</p>
										</div>
										<div className="chat-du">
											<h4>{t('Chat duration')}</h4>
											<p>{record.chatDuration}</p>
										</div>
										<div className="chat-date">
											<h4>{t('Date')}</h4>
											<p>{moment(record.start_timestamp).tz(moment.tz.guess()).format('MMM-DD-YYYY hh:mm:ss A z')}</p>
										</div>
										<div className="rating">
											<h4>{t('Rating')}</h4>
											<Chatuserratings ratings={record.ratings} />
										</div>
										<div className="Action">
											<h4>{t('Action')}</h4>
											<p className="action-list">
												<span className="al-icon-col" onClick={this.openChatMessage.bind(this, record.session_id, record.flag, record.id)}>
													<i className="fa fa-eye fa-lg"></i>
												</span>{' '}
												|
												<span className="al-icon-col">
													<Label className="switch switch-icon switch-primary switch-pill">
														{record.flag == 1 && (
															<Input
																type="checkbox"
																disabled={this.state.flagOff}
																className="switch-input"
																defaultChecked
																onClick={this.flagNow.bind(this, record.session_id, record.flag)}
															/>
														)}
														{record.flag == 0 && (
															<Input
																type="checkbox"
																disabled={this.state.flagOff}
																className="switch-input"
																onClick={this.flagNow.bind(this, record.session_id, record.flag)}
															/>
														)}
														<span className="switch-label" data-on={'\uf024'} data-off={'\uf024'}></span>
														<span className="switch-handle"></span>
													</Label>
												</span>
											</p>
										</div>
									</li>
								))}
						</ul>
						{reviewChat && reviewChat.rows && reviewChat.rows.length === 0 && (
							<div className="no-content">
								<p>{t('No data found')}!</p>
							</div>
						)}
						<div className="pagination-control pull-right clearfix">
							<Pagination
								activePage={this.props.activePage}
								itemsCountPerPage={this.props.pageSize}
								totalItemsCount={parseInt(totalCount)}
								pageRangeDisplayed={5}
								onChange={this.props.handlePageChange}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default ReviewChatList
