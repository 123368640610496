import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, FormGroup, Input, Label, Modal, ModalBody, Table } from 'reactstrap'
import { session } from '../../../config'
import { identityServices, wsHelper } from '../../common'

class UserQueueComponent extends Component {
	constructor(props) {
		super(props)
		this.closeModalEdit = this.closeModalEdit.bind(this)
		this.state = {
			errorMsg: false,
			editData: {},
			addData: {},
			modalEdit: false,
			editId: null,
			availableUsers: [],
			assignedUsers: [],
			selectedAvailableUsers: [],
			selectedAssignedUsers: [],
			selectedOption: null,
			agentqueue: [],
			queues: [],
			modalAdd: false,
			wsInit: false,
		}
	}

	getliveChatQueues() {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let apiUrlCustom = apiUrl
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
			url: `${apiUrlCustom}/queue_livechat?$filter=status ne 'Deleted'&$orderby=createdAt asc`,
		}
		requestOptions = wsHelper.getWsQuery({ wsType: 'livechat', req: requestOptions })
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status == 200 && response.data) {
				this.setState({ agentqueue: response.data })
			}
		})
	}

	componentWillMount() {
		let { identity, t } = this.props
		let { profile } = identity
		this.props.getUserQueueData(["$filter=status ne 'Deleted'", '$orderby=createdAt asc'], t)
		this.props.getAllUsersList(["$filter=status ne 'Deleted'", '$orderby=createdAt asc'], t)
		this.getliveChatQueues()
	}

	componentDidUpdate(prevProps) {
		let {
			identity: { profile },
			userRoles,
			tenantConfig,
			workspace,
			t,
		} = this.props
		let { wsInit } = this.state

		if (tenantConfig && !tenantConfig.loading && tenantConfig.data && workspace && !workspace.loading && !wsInit) {
			console.log('prevProps::: ', prevProps)
			console.log('Updated Props:: ', this.props)

			//   if(workspace.selectedWs != null && prevProps.workspace.selectedWs !=  workspace.selectedWs) {
			this.props.getUserQueueData(["$filter=status ne 'Deleted'", '$orderby=createdAt asc'], t)
			this.props.getAllUsersList(["$filter=status ne 'Deleted'", '$orderby=createdAt asc'], t)
			this.getliveChatQueues()
			this.setState({ wsInit: true })
			//   }
		}
	}

	toggleModalEdit(data) {
		let { userqueue, userslist } = this.props
		let { editData } = this.state,
			availableUsers = [],
			assignedUsers = []
		editData = userqueue.queueData.find(o => o.queueName == data.queueName)
		if (userslist && userslist.userData && userslist.userData.length > 0) {
			userslist.userData.map((elem, i) => {
				if (data && data.userName && data.userName.find(username => username == elem.username)) {
					assignedUsers.push(elem.username)
				} else {
					if (elem.roles && elem.roles) {
						if (
							elem.roles.indexOf('chat_agent') != -1 ||
							(elem.roles.indexOf('chat_agent_supervisor') != -1 && elem.roles.indexOf('Chat Agent') != -1) ||
							elem.roles.indexOf('Chat Agent Supervisor') != -1
						) {
							availableUsers.push(elem.username)
						}
					} else {
						availableUsers.push(elem.username)
					}
				}
			})
		}

		this.setState({
			modalEdit: true,
			editId: data.id,
			editData: editData,
			availableUsers,
			assignedUsers,
		})
	}

	toggleModalAdd() {
		let { userslist, userqueue } = this.props
		let { agentqueue, queues } = this.state
		let availableUsers = [],
			assignedUsers = []
		let userqueues = []

		if (agentqueue && agentqueue.length > 0) {
			agentqueue.map(function (d, i) {
				if (queues.length > 0) {
					if (!queues.find(o => o.value == d.queueName)) {
						queues.push({ value: d.queueName, label: d.queueLabel })
					}
				} else {
					queues.push({ value: d.queueName, label: d.queueLabel })
				}
			})
			console.log('++++++++++++++++++++++++++uniqueQueueArray+++++++++++++++++++++++++++++++++++', queues)
		}

		if (userqueue && userqueue.queueData.length > 0) {
			userqueue.queueData.map(function (d, i) {
				userqueues.push({ value: d.queueName, label: d.queueLabel })
			})
		}
		if (userqueues && queues && userqueues.length > 0 && queues.length > 0) {
			queues = queues.filter(({ value: id1 }) => !userqueues.some(({ value: id2 }) => id2 === id1))
		}

		if (userslist && userslist.userData && userslist.userData.length > 0) {
			userslist.userData.map((elem, i) => {
				if (elem.roles && elem.roles) {
					if (
						elem.roles.indexOf('chat_agent') != -1 ||
						(elem.roles.indexOf('chat_agent_supervisor') != -1 && elem.roles.indexOf('Chat Agent') != -1) ||
						elem.roles.indexOf('Chat Agent Supervisor') != -1
					) {
						availableUsers.push(elem.username)
					}
				} else {
					availableUsers.push(elem.username)
				}
			})
		}

		this.setState({
			modalAdd: true,
			availableUsers,
			assignedUsers,
			queues,
		})
	}

	closeModalEdit() {
		this.setState({
			modalEdit: false,
			modalAdd: false,
			editId: null,
		})
	}

	handleChange = selectedOption => {
		this.setState({ selectedOption })
		//console.log(`Option selected:`, selectedOption);
	}

	userLivechatQueues(event) {
		event.preventDefault()
		let { profile, t } = this.props
		let editData = this.state.editData
		if (editData.id) {
			editData.updatedBy = profile.userId
		} else {
			editData.createdBy = profile.userId
		}

		editData.userName = this.state.assignedUsers
		this.setState({
			modalEdit: !this.state.modalEdit,
		})
		// console.log("_+_+_", editData)
		this.props.userLivechatQueues(editData, t)
	}

	userLivechatQueuesAdd(event) {
		event.preventDefault()
		let { selectedOption, addData, queues, assignedUsers } = this.state
		let { profile, t } = this.props
		addData.queueLabel = selectedOption && selectedOption.label
		addData.queueName = selectedOption && selectedOption.value
		addData.createdBy = profile.userId
		addData.userName = assignedUsers
		this.setState({
			modalAdd: !this.state.modalAdd,
			queues,
			selectedOption: null,
			//addData:{}
		})
		this.props.userLivechatQueues(addData, t)
		setTimeout(() => {
			this.props.getUserQueueData(["$filter=status ne 'Deleted'", '$orderby=createdAt asc'], t)
		}, 1000)
	}

	moveToAssignedUser() {
		let { availableUsers, assignedUsers, selectedAvailableUsers, selectedAssignedUsers } = this.state

		if (selectedAvailableUsers && selectedAvailableUsers.length > 0) {
			availableUsers = availableUsers.filter(val => selectedAvailableUsers.indexOf(val) == -1)
			assignedUsers = assignedUsers.concat(selectedAvailableUsers)
		}
		// console.log("*~*~*~*~**~~**~*", assignedUsers)
		this.setState({
			assignedUsers,
			availableUsers,
			selectedAvailableUsers: availableUsers[0],
		})
	}

	moveToUnAssignedUser() {
		let { availableUsers, assignedUsers, selectedAvailableUsers, selectedAssignedUsers } = this.state

		if (selectedAssignedUsers && selectedAssignedUsers.length > 0) {
			assignedUsers = assignedUsers.filter(val => selectedAssignedUsers.indexOf(val) == -1)
			availableUsers = availableUsers.concat(selectedAssignedUsers)
		}

		// console.log("*~*~*~*::moveToUnAssignedUser::~**~~**~*", assignedUsers)

		this.setState({
			assignedUsers,
			availableUsers,
			selectedAssignedUsers: assignedUsers[0],
		})
	}

	onChangeAvailable(e) {
		let selectedAvailableUsers = [].slice.call(e.target.selectedOptions).map(o => {
			return o.value
		})

		// console.log("^#^#^::selectedAssignedUsers::#^#^#^#^#", selectedAvailableUsers)

		this.setState({
			selectedAvailableUsers,
		})
	}

	onChangeAssignedUsers(e) {
		let selectedAssignedUsers = [].slice.call(e.target.selectedOptions).map(o => {
			return o.value
		})

		// console.log("^#^#^::selectedAssignedUsers::#^#^#^#^#", selectedAssignedUsers)

		this.setState({
			selectedAssignedUsers,
		})
	}

	render() {
		const { alert, userqueue, t } = this.props
		let errorMsg = this.state.errorMsg
		const { selectedOption } = this.state
		return (
			<div className="col-sm-9 col-xl-9 col-md-9">
				<div className="gb-setting-bg">
					<div className="mf-common smallTalks Manage-Task">
						<h3>{t('Assign Agent(s) To Queue')}</h3>
						<div className="row">
							<div className="col-sm-6">
								<div className="show-section ag-chat clearfix">
									<div className="pull-left">
										<span className="add-ico" onClick={this.toggleModalAdd.bind(this)}>
											<i className="fa fa-plus-square-o" aria-hidden="true"></i>
										</span>
										<h4 className="ad-faq">{t('ADD AGENT')}</h4>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<Table className="table">
									<thead>
										<tr>
											<th width="15%">{t('Sr no')}</th>
											<th width="25%">{t('Queue Label')}</th>
											<th width="20%">{t('Users')}</th>
											<th width="20%">{t('Action')}</th>
										</tr>
									</thead>
									<tbody>
										{userqueue &&
											userqueue.queueData &&
											userqueue.queueData.map((el, i) => (
												<tr key={i}>
													<td>{i + 1}</td>
													<td>{el.queueLabel}</td>
													<td>{el.userName && el.userName.length > 0 ? el.userName.join(', ') : 'N/A'}</td>
													<td>
														<span onClick={this.toggleModalEdit.bind(this, el)} className="al-icon-col">
															<i className="fa fa-pencil fa-lg "></i>
														</span>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>

				<Modal isOpen={this.state.modalEdit} toggle={this.closeModalEdit} className={this.props.className || 'delete-card editor-frame add-faq'}>
					<ModalBody>
						{errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
						<h4 className="modal-min-heading">{t('Edit Queue')}</h4>
						<form className="custom-from add-remove-tool" onSubmit={this.userLivechatQueues.bind(this)}>
							<div>
								{t('Queue Name')}: {this.state.editData.queueLabel}
							</div>
							<div className="row">
								<div className="col-sm-5">
									<FormGroup className="two-col-input rt-left">
										<Label for="AvailableUsers">{t('Available Agent(s)')}</Label>
										{this.state.availableUsers && (
											<div className="ms-out">
												<Input type="select" name="availableUsers" id="AvailableUsers" onChange={this.onChangeAvailable.bind(this)} multiple>
													{this.state.availableUsers &&
														this.state.availableUsers.length > 0 &&
														this.state.availableUsers.map((elem, i) => (
															<option key={i} value={elem}>
																{elem}
															</option>
														))}
												</Input>
											</div>
										)}
									</FormGroup>
								</div>
								<div className="col-sm-2">
									<div className="tools adar-tools">
										<Button outline color="primary" onClick={this.moveToAssignedUser.bind(this)}>
											{t('Add Selected')} &gt;
										</Button>
										<Button outline color="primary" onClick={this.moveToUnAssignedUser.bind(this)}>
											&lt; {t('Remove Selected')}{' '}
										</Button>
									</div>
								</div>
								<div className="col-sm-5">
									<FormGroup className="two-col-input">
										<Label for="SelectedUserName">{t('Assigned Agent(s)')}</Label>
										{this.state.assignedUsers && (
											<div className="ms-out">
												<Input type="select" name="userName" id="SelectedUserName" onChange={this.onChangeAssignedUsers.bind(this)} multiple>
													{this.state.assignedUsers &&
														this.state.assignedUsers.length > 0 &&
														this.state.assignedUsers.map((elem, i) => (
															<option key={i} value={elem}>
																{elem}
															</option>
														))}
												</Input>
											</div>
										)}
									</FormGroup>
								</div>
							</div>

							<div className="tools col-sm-12">
								<Button color="secondary" onClick={this.closeModalEdit}>
									<img src="assets/img/no.png" />
									{t('Close')}
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" />
									{t('Update')}
								</Button>{' '}
							</div>
						</form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.modalAdd} toggle={this.closeModalEdit} className={this.props.className || 'delete-card editor-frame add-faq'}>
					<ModalBody>
						{errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
						<h4 className="modal-min-heading">{t('Add Agent')}</h4>
						<form className="custom-from add-remove-tool" onSubmit={this.userLivechatQueuesAdd.bind(this)}>
							<Select value={selectedOption} onChange={this.handleChange} options={this.state.queues} placeholder={t('Select Queue')} />
							<div className="row">
								<div className="col-sm-5">
									<FormGroup className="two-col-input rt-left">
										<Label for="AvailableUsers">{t('Available Agent(s)')}</Label>
										{this.state.availableUsers && (
											<div className="ms-out">
												<Input type="select" name="availableUsers" id="AvailableUsers" onChange={this.onChangeAvailable.bind(this)} multiple>
													{this.state.availableUsers &&
														this.state.availableUsers.length > 0 &&
														this.state.availableUsers.map((elem, i) => (
															<option
																key={i}
																value={elem}
																disabled={this.state.selectedOption == null ? true : false}
																style={{ backgroundColor: this.state.selectedOption == null ? 'silver' : 'white' }}>
																{elem}
															</option>
														))}
												</Input>
											</div>
										)}
									</FormGroup>
								</div>
								<div className="col-sm-2">
									<div className="tools adar-tools">
										<Button outline color="primary" onClick={this.moveToAssignedUser.bind(this)} disabled={this.state.selectedOption == null ? true : false}>
											{t('Add Selected')} &gt;
										</Button>
										<Button outline color="primary" onClick={this.moveToUnAssignedUser.bind(this)} disabled={this.state.selectedOption == null ? true : false}>
											&lt; {t('Remove Selected')}{' '}
										</Button>
									</div>
								</div>
								<div className="col-sm-5">
									<FormGroup className="two-col-input">
										<Label for="SelectedUserName">{t('Assigned Agent(s)')}</Label>
										{this.state.assignedUsers && (
											<div className="ms-out">
												<Input type="select" name="userName" id="SelectedUserName" onChange={this.onChangeAssignedUsers.bind(this)} multiple>
													{this.state.assignedUsers &&
														this.state.assignedUsers.length > 0 &&
														this.state.assignedUsers.map((elem, i) => (
															<option key={i} value={elem}>
																{elem}
															</option>
														))}
												</Input>
											</div>
										)}
									</FormGroup>
								</div>
							</div>
							<div className="tools col-sm-12">
								<Button color="secondary" onClick={this.closeModalEdit}>
									<img src="assets/img/no.png" />
									{t('Cancel')}
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" />
									{t('Add')}
								</Button>{' '}
							</div>
						</form>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}
export default withTranslation()(UserQueueComponent)
