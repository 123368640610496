import moment from 'moment'
import React, { Component } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import { session } from '../../../config'
import { identityServices, Loader } from '../../common'
import '../virtualagentmgmt.css'
import ChatMessageModal from './ChatMessageModal'
import FilterReview from './FilterReview'
import ReviewChatList from './ReviewChatList'
//Locale
class ReviewChat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			search: '',
			orderColumn: 'id',
			orderType: 'desc',
			dropdownOpenFlag: false,
			agentDD: false,
			modal: false,
			flag: 0,
			currentId: 0,
			filterFlag: 'All',
			agentFilterFlag: 'All',
			dropdownOpen: false,
			dropdownOpen1: false,
			pageSize: 10,
			showme: null,
			performance: null,
			loaderModal: false,
			agent: null,
			filters: {
				start_date: moment(props.tenant.createdAt).format('YYYY-MM-DD'),
				end_date: '',
			},
		}
		this.search = this.search.bind(this)
		this.searchChatReview = this.searchChatReview.bind(this)
		this.toggleModalchat = this.toggleModalchat.bind(this)
		this.toggleFlag = this.toggleFlag.bind(this)
		this.toggleFlagDD = this.toggleFlagDD.bind(this)
		this.toggle = this.toggle.bind(this)
		this.toggle1 = this.toggle1.bind(this)
		this.handlePageChange = this.handlePageChange.bind(this)
		this.flagIt = this.flagIt.bind(this)
		this.setDateRange = this.setDateRange.bind(this)
	}

	componentWillMount() {}

	search(e) {
		const { orderColumn, orderType, pageSize, agentFilterFlag } = this.state
		var { profile } = this.props
		// @ts-ignore
		var search = document.getElementById('search-chat').value
		var pageNumber = 1
		this.setState({ activePage: pageNumber })
		this.setState({ search: search })
		this.props.getReviewChats(profile.tenantUid, pageNumber, pageSize, search, orderColumn, orderType, agentFilterFlag, {
			from: this.state.filters.start_date,
			to: this.state.filters.end_date,
		})
	}

	setDateRange(filters) {
		this.setState({ filters })
	}

	searchChatReview(showme, performanceFilter, toggle, txt, agent, date) {
		var { profile } = this.props
		this.props.getReviewChats(profile.tenantUid, 1, this.state.pageSize, showme, performanceFilter, this.state.orderColumn, this.state.orderType, agent, date)
		this.setState({
			showme: showme,
			performance: performanceFilter,
			//filterFlag:txt?txt:this.state.filterFlag
		})
		this.setState({
			filterFlag: txt ? txt : this.state.filterFlag,
			agentFilterFlag: agent ? agent : this.state.agentFilterFlag,
			dropdownOpenFlag: false,
			agentDD: false,
		})
	}

	handlePageChange(pageNumber) {
		this.setState({ activePage: pageNumber })
		var { profile, pageSize } = this.props
		this.props.getReviewChats(
			profile.tenantUid,
			pageNumber,
			this.state.pageSize,
			this.state.showme,
			this.state.performance,
			this.state.orderColumn,
			this.state.orderType,
			this.state.agentFilterFlag,
			{ from: this.state.filters.start_date, to: this.state.filters.end_date }
		)
	}

	toggleModalchat(sid, flag, cid) {
		this.setState({ loaderModal: true })
		const { profile } = this.props
		if (this.state.modal === true) {
			this.setState({
				modal: !this.state.modal,
			})
		} else {
			let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			let ep = apiUrl

			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				url: `${ep}/train/getChatMessages/${sid}`,
			}

			return identityServices.identityApi.request(requestOptions).then(response => {
				if (response.status !== 200) {
					return Promise.reject(response)
				}
				let respData = response.data
				this.setState({
					modal: !this.state.modal,
					data: respData,
					flag: flag,
					currentId: sid,
					loaderModal: false,
				})
			})
		}
	}

	toggleModalClose() {
		this.setState({
			modal: !this.state.modal,
			data: {},
		})
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		})
	}
	toggleFlag() {
		this.setState({
			dropdownOpenFlag: !this.state.dropdownOpenFlag,
		})
	}
	toggleFlagDD() {
		this.setState({
			agentDD: !this.state.agentDD,
		})
	}
	toggle1() {
		this.setState({
			dropdownOpen1: !this.state.dropdownOpen1,
		})
	}

	flagIt(id, flag, activePage) {
		var { profile } = this.props
		const { pageSize, showme, performance, orderColumn, orderType } = this.state
		let changeFlag = '0'
		if (flag == '0') changeFlag = '1'
		this.setState({
			flag: changeFlag,
		})
		this.props.reviewChatFlagIt(profile.tenantUid, id, changeFlag)
		setTimeout(() => {
			this.props.getReviewChats(profile.tenantUid, activePage ? activePage : 1, pageSize, showme, performance, orderColumn, orderType, this.state.agentFilterFlag, {
				from: this.state.filters.start_date,
				to: this.state.filters.end_date,
			})
		}, 1000)
	}

	render() {
		const newProps = { ...this.props, ...this.state }
		const { loading, t } = this.props
		const { loaderModal } = this.state
		return (
			<div className="animated fadeIn">
				{(loading == true || loaderModal) && <Loader />}
				<div className="common-heading row">
					<div className="col-sm-12">
						<div className="heading">
							<h5>{t('Review Chat')}</h5>
						</div>
					</div>
				</div>

				{alert.message && <div className={`alert ${alert.type}`}>{t(alert.message)}</div>}

				{/* <SearchReview  {...this.props} searchHandle={this.search} /> */}
				<div className="main-section">
					<div className="main-inner">
						<FilterReview {...newProps} searchChatReview={this.searchChatReview} toggleFlag={this.toggleFlag} toggleFlagDD={this.toggleFlagDD} setDateRange={this.setDateRange} />
						<ReviewChatList {...newProps} handlePageChange={this.handlePageChange} toggleModalchat={this.toggleModalchat} flagIt={this.flagIt} />
						{this.state.modal && <ChatMessageModal toggleModalClose={this.toggleModalClose.bind(this)} {...newProps} flagIt={this.flagIt} chatData={this.state.data} />}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ReviewChat)
