import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { i18n } from '..'
import { singleTenant } from '../../../config'
// import axios from "axios";
// import { apiUrl } from  '../../../config';

class LangSelect extends Component {
	constructor() {
		super()
		this.state = {
			activeLang: '',
		}
	}

	componentWillMount() {
		if (!singleTenant) this.getLangs()
	}

	componentDidMount() {
		this.setActiveLang()
	}

	setActiveLang() {
		let lng = localStorage.getItem('i18nextLng')
		if (lng) {
			this.setState({
				activeLang: lng.split('-')[0],
			})
		}
	}

	handleLangChange = event => {
		let val = event.target.value
		this.setState({
			activeLang: val,
		})
		i18n.changeLanguage(val)
	}

	getLangs = () => {
		let { tenant } = this.props
		if (tenant && tenant.id) {
			let tenantId = tenant.id
			this.props.getAvailableLangs(null, [`$select=lang&$filter=status eq 'Published' and tenantId eq '${tenantId}'`], this.props.t)
		}
	}

	render() {
		let { localeReducers } = this.props
		let lang = localeReducers.itemData
		if (lang && Array.isArray(lang) && lang.length === 1 && this.state.activeLang && lang[0].lang !== this.state.activeLang) {
			lang.push({ lang: this.state.activeLang })
		}

		if (!lang || !Array.isArray(lang) || lang.length < 2) {
			return null
		}

		if (lang) {
			lang = lang.map(el => (
				<option value={el.lang} key={el.lang}>
					{el.lang}
				</option>
			))
		}

		return (
			<div className="locale-select-container">
				<span className="locale-select-con-value">{this.state.activeLang}</span>
				<select className="locale-select-con" value="" onChange={this.handleLangChange}>
					<option value="" disabled>
						&#127758;
					</option>
					{lang}
				</select>
			</div>
		)
	}
}

export default withTranslation()(LangSelect)
