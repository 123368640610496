'use strict'

import { alertActions } from '../common'
import { EntityActions } from '../common/base/entity'
// import {EntityComponent} from '../entity'
import { getExactError } from '../common/base/entity/joiErrorWrapper'

export class jobAction extends EntityActions {
	constructor(options) {
		super(options)
	}

	postActionsHooks({ actionName, item, tenantId }) {
		switch (actionName) {
			case 'getAll':
				item = item.map(elem => {
					if (elem?.successChunk && elem?.successChunk?.successData && elem?.successChunk?.successData?.downloadLink) {
						elem.successChunk = elem.successChunk.successData.downloadLink
					} else if (elem?.successChunk && elem?.successChunk?.successData?.publishStatus) {
						elem.successChunk = elem.successChunk.successData.publishStatus
					} else if (elem?.successChunk && elem?.successChunk?.successData && elem?.successChunk?.successData.message) {
						elem.successChunk = elem.successChunk.successData.message
					} else if (elem?.successChunk && elem?.successChunk?.successData) {
						if (elem?.successChunk?.successData.constructor == Object && Object.keys(elem?.successChunk?.successData).length == 0) {
							elem.successChunk = ''
						} else {
							elem.successChunk = JSON.stringify(elem.successChunk.successData)
						}
					} else if (elem.successChunk == null) {
						elem.successChunk = ''
					} else {
						elem.successChunk = JSON.stringify(elem.successChunk)
					}

					if (elem?.failedChunk && elem?.failedChunk?.errorData && elem?.failedChunk?.errorData.length > 0 && Array.isArray(elem.failedChunk.errorData)) {
						elem.failedChunk = elem.failedChunk.errorData.join('; ')
					} else if (elem?.failedChunk && elem?.failedChunk?.errorData) {
						if (elem.failedChunk.errorData.length == 0) {
							elem.failedChunk = ''
						} else {
							elem.failedChunk = JSON.stringify(elem.failedChunk.errorData)
						}
					} else if (elem.failedChunk == null) {
						elem.failedChunk = ''
					} else {
						elem.failedChunk = JSON.stringify(elem.failedChunk)
					}

					// elem.id = 'R#' + elem.createdAt.replaceAll('-', '').replaceAll(':', '').replaceAll(/\.[0-9]{0,5}Z/ig, '')

					return elem
				})

				return item
			default:
				return item
		}
	}

	load({ tenantId, filter, orderBy, skip, limit, top, queryStr, baseUrl, select, searchText }) {
		//queryStr = queryStr || { status: 'Published' }
		if (filter && !filter.pop) filter = Object.assign({}, filter, queryStr)
		else if (queryStr) filter = queryStr
		return this.getAll({ tenantId, filter: filter, orderBy, skip: skip, limit, top, baseUrl, select: select, searchText })
	}

	getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALL' }))

			that.service
				.getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText })
				.then(items => {
					if (items.rows) {
						filter &&
							!filter.pop &&
							Object.keys(filter).forEach(k => {
								if (filter[k].slice(0, 1) === filter[k].slice(-1) && filter[k].slice(-1) === '%') filter[k] = filter[k].slice(1, -1)
							})
						// @ts-ignore
						if (that.postActionsHooks) items.rows = that.postActionsHooks({ actionName: 'getAll', item: items.rows })

						setTimeout(() => {
							// @ts-ignore
							dispatch(that.success({ method: 'GETALL', item: items, data: { filter } }))
							// @ts-ignore
							dispatch(that.success({ method: 'GETONE', item: {} }))
						}, 1000)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						// @ts-ignore
						dispatch(that.failure({ method: 'GETALL', error: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					let errorMsg = error && error.details ? getExactError(error) : `Error from server. ${(error && error.message) || error || 'Please try again later.'}`
					dispatch(that.failure({ method: 'GETALL', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	// preActionsHooks({ actionName, item, propFields }) {
	//     let obj = {}
	//     let fileExtension;
	//     if (item.content) {
	//         obj.size = item.content[0].size;
	//         //fileExtension= item.content[0].name.slice((item.content[0].name.lastIndexOf(".") - 1 >>> 0) + 2)
	//         obj.blobName = item.blobName //`${item.blobName}.${fileExtension}`;
	//         obj.contentType = item.content[0].type;
	//     } else {
	//         //fileExtension= item.contentType.slice((item.contentType.lastIndexOf(".") - 1 >>> 0) + 2)
	//         obj.blobName = item.blobName //`${item.blobName}.${fileExtension}`;
	//         obj.contentType = item.contentType;
	//         obj.size = item.size && Math.round(item.size * 1024);
	//         if (item.metadata) obj.metadata = item.metadata
	//         else obj.metadata = { purpose: 'botMedia' }
	//         if (item.tenantId) obj.tenantId = item.tenantId
	//         if (item.urlExpiryTime) obj.urlExpiryTime = item.urlExpiryTime * 1000
	//         if (item.content) obj.content = item.content;
	//         if (item.id) obj.id = item.id;
	//         obj.access = item.access;
	//         obj.status = item.status;
	//     }

	//     return obj
	// }
}
