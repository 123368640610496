import Joi from 'joi-browser'
import { apiSchema, cardsMeta } from '../../../schemas'

/**
 *  entity : { itemData, loading, entityName}
 *  identity: {profile:{tenantUid}},
 *  name,
 *  meta: {fields:{<f>:{        label,
        min,
        max,
        type,
        required,
        placeholder,
        readOnly:,
        regexPattern,
        width
    }}: , actions: {<a>:{label, action, condition, classes}}, title, sections:{<s>:{fields:[], object:{ref}, component: {name, props, propFns}}}, screens:{<s>:{fields, actions, sections}}  }
 */
const item = Joi.any()
const width = Joi.string()
	.optional()
	.regex(/^(100|([0-9]{1}|[1-9][0-9]))%/)
const widthInCol = Joi.number().integer().positive().min(1).max(12)
const entityList = Joi.object().keys({
	count: Joi.number().integer().positive().allow(0),
	itemData: Joi.array().items(item),
	loading: Joi.boolean(),
	entityName: Joi.string(),
	searchParams: Joi.any(),
	data: Joi.any(), // for list screen other api calls
	inputs: Joi.object(), // other api calls
	filteredData: Joi.any(),
	searchText: Joi.string(),
	userAttrData: Joi.any(),
})
const entitySchema = Joi.object().keys({
	entityValues: item,
	loading: Joi.boolean(),
	entityName: Joi.string(),
	count: Joi.any(),
	error: Joi.string().allow(null),
	auditConfig: Joi.object().allow(null).optional(),
	uiFieldsMeta: Joi.array().items(Joi.any()),
	refEntityList: Joi.any(),
})
const mapSchema = Joi.object().keys({
	name: Joi.string(),
	type: Joi.string().valid(
		'action',
		'field',
		'section',
		'component',
		'actions',
		'link',
		'tabGroup',
		'card',
		'chart',
		'list',
		'reportTable',
		'seprator',
		'cardList',
		'grid',
		'jsonList',
		'accordianGrid',
		'workflowAction',
		'workflowActions',
		'lookUp',
		'chat',
		'player',
		'treeView',
		'contentView',
		'appComponent',
		'thumbnail',
		'accordian',
		'actionButton',
		'date'
	),
	disabled: Joi.boolean(),
	col: Joi.number().integer().positive().max(5),
	position: Joi.number().integer().positive(),
	actions: Joi.array().items(Joi.string()).min(1).when('type', {
		is: 'actions',
		then: Joi.required(),
		otherwise: Joi.forbidden(),
	}),
	formatter: Joi.any(),
	key: Joi.string(),
	shown: Joi.boolean(),
	htmlTag: Joi.string(),
	row: Joi.number().integer().positive(),
	afterRow: Joi.number().integer().positive(),
	component: Joi.string().when('type', {
		// span image classes are required
		is: 'component',
		then: Joi.required(),
		otherwise: Joi.optional(),
	}),
	colWidth: Joi.number().integer().optional(),
	hidden: Joi.boolean(),
	classNames: Joi.string().optional(),
	params: Joi.any(),
	// hideLabel: Joi.boolean().default(false)
})

const dynamicFieldsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		includesFields: Joi.object().pattern(/^/, Joi.array().items(mapSchema)),
		fields: Joi.array().items(mapSchema),
		excludeSections: Joi.object().pattern(/^/, Joi.array().items(Joi.string())),
		sectionName: Joi.string(),
	})
)

export const modalMeta = Joi.object().keys({
	label: Joi.string(),
	name: Joi.string(),
	min: Joi.number().integer().positive(),
	max: Joi.number().integer().positive(),
	type: Joi.string()
		.valid(
			'text',
			'email',
			'select',
			'file',
			'radio',
			'checkbox',
			'textarea',
			'button',
			'reset',
			'submit',
			'date',
			'datetime-local',
			'image',
			'month',
			'number',
			'range',
			'search',
			'tel',
			'url',
			'week',
			'password',
			'datetime',
			'time',
			'color',
			'hidden',
			'json',
			'jsonObj',
			'jsonDict',
			'jsonList',
			'jsonTree',
			'jsonObjFixed',
			'component',
			'textEditor',
			'TextEditor',
			'lookUp',
			'thumbnail',
			'jsonText',
			'label',
			'downloadLink',
			'calender',
			'monaco-editor',
			'customJsonClone',
			'customJsonList'
		)
		.default('text')
		.required(),
	fieldType: Joi.string().valid('text', 'date'),
	required: Joi.boolean().default(true),
	placeholder: Joi.string(),
	readOnly: Joi.boolean(),
	suffix: Joi.string(),
	regexPattern: Joi.string(),
	error: Joi.string(),
	prompt: Joi.boolean(),
	promptMessage: Joi.string(),
	display: Joi.boolean(),
	disabled: Joi.boolean(),
	disabledEval: Joi.string(),
	valueMatch: Joi.string(),
	readOnlyOnEdit: Joi.boolean(),
	borderRead: Joi.boolean(),
	width: width,
	value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.any()),
	dependsOn: Joi.array()
		.items(Joi.string())
		.when('type', {
			is: ['select', 'text', 'monaco-editor'],
			then: Joi.optional(),
			otherwise: Joi.forbidden(),
		}), // these are normally used in the api query and if they change fire the api query to fetch the options again
	dependsOnMeta: Joi.object().pattern(/^/, {
		ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
		labelKey: Joi.string(),
		valueKey: Joi.string(),
	}),
	options: Joi.array()
		.items({ label: Joi.string().required(), value: Joi.alternatives().try(Joi.string(), Joi.boolean()) })
		.min(1)
		.when('type', {
		is: 'select',
		then: Joi.optional(),
		otherwise: Joi.forbidden(),
	}),
	dynamicOptions: Joi.boolean(),
	action: Joi.string().when('type', {
		/// on upload call this action if specified
		is: 'file',
		then: Joi.optional(),
		otherwise: Joi.forbidden(),
	}),
	dynamicFields: Joi.boolean().when('type', {
		/// on upload call this action if specified
		is: 'select',
		then: Joi.optional(),
		otherwise: Joi.forbidden(),
	}),
	isMulti: Joi.boolean().when('type', {
		/// on upload call this action if specified
		is: 'select',
		then: Joi.optional(),
		otherwise: Joi.forbidden(),
	}),
	api: Joi.object()
		.keys({
			path: Joi.string().required(),
			method: Joi.string().default('GET'),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()),queryString: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			filters: Joi.array().items(Joi.object()),
			body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
				labelKey: Joi.string(),
				valueKey: Joi.string(),
				customKey: Joi.array().items(Joi.string()),
				delimiter: Joi.string(),
			}), // if not present then response is a collection of strings that are considered as label options
			uiFilter: Joi.object()
				.keys({
					cond: Joi.string(),
					excludedValues: Joi.array().items(Joi.string()),
				})
				.optional(),
		})
		.when('type', {
			is: 'select',
			then: Joi.optional(),
			otherwise: Joi.forbidden(),
		}),
	default: Joi.alternatives().try(Joi.string(), Joi.boolean()),
	props: Joi.array().items(
		Joi.alternatives().try(
			Joi.string(),
			Joi.object().keys({
				label: Joi.string(),
				type: Joi.string(),
				name: Joi.string(),
				options: Joi.any().when('type', {
					is: 'select',
					then: Joi.optional(),
					otherwise: Joi.forbidden(),
				}),
				props: Joi.any(),
			})
		)
	), // used for jsonList or jsonObj when value is of type json object.
	propsMeta: Joi.any(),
	keyLabel: Joi.string(), // jsonObj
	valueLabel: Joi.string(), //  jsonObj
	valueType: Joi.string(), // for further qualifying the value type of jsonObj/jsonList
	keys: Joi.array().items(Joi.string()), // used when type is json
	valueTypes: Joi.array().items(Joi.string()), // used when type is json for specifying type of key
	filterable: Joi.boolean().default(true).optional(), // If not part of filter make false
	sortable: Joi.boolean().default(true).optional(), // If not part of sort make false
	dimension: Joi.number().integer().positive(), // add dimension when layoutType is "Card"
	backgroundColor: Joi.string().optional(),
	lang: Joi.string(),
	autoCompletionApiProps: Joi.object().keys({
		api: Joi.object().keys({
			path: Joi.string().required(),
			method: Joi.string().default('GET'),
			filters: Joi.array().items(Joi.object()),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()),queryString: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
				value: Joi.string(),
			}), // if not present then response is a collection of strings that are considered as label options
		}),
	}),
})
export const fieldsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		label: Joi.string(),
		name: Joi.string(),
		min: Joi.number().integer().positive(),
		max: Joi.number().integer().positive(),
		type: Joi.string()
			.valid(
				'text',
				'email',
				'select',
				'file',
				'radio',
				'checkbox',
				'textarea',
				'button',
				'reset',
				'submit',
				'date',
				'datetime-local',
				'image',
				'month',
				'number',
				'range',
				'search',
				'tel',
				'url',
				'week',
				'password',
				'datetime',
				'time',
				'color',
				'hidden',
				'json',
				'jsonObj',
				'jsonDict',
				'jsonList',
				'jsonTree',
				'jsonObjFixed',
				'component',
				'textEditor',
				'TextEditor',
				'lookUp',
				'thumbnail',
				'jsonText',
				'label',
				'downloadLink',
				'calender',
				'monaco-editor',
				'customJsonClone',
				'customJsonList'
			)
			.default('text')
			.required(),
		fieldType: Joi.string().valid('text', 'date'),
		required: Joi.boolean().default(true),
		allowedRole: Joi.string().allow('').allow(null),
		valueFn: Joi.any(),
		dependsOnFields: Joi.array().items(Joi.string()),
		lengthCheck: Joi.number(),
		placeholder: Joi.string(),
		readOnly: Joi.boolean(),
		suffix: Joi.string(),
		regexPattern: Joi.string(),
		error: Joi.string(),
		prompt: Joi.boolean(),
		promptMessage: Joi.string(),
		display: Joi.boolean(),
		editEval: Joi.string(),
		disabled: Joi.boolean(),
		disabledEval: Joi.string(),
		valueMatch: Joi.string(),
		readOnlyOnEdit: Joi.boolean(),
		borderRead: Joi.boolean(),
		height: Joi.string(),
		labelClass: Joi.string(),
		classes: Joi.string(),
		ignoreT: Joi.boolean(),
		width: width,
		value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.any()),
		dependsOn: Joi.array()
			.items(Joi.string())
			.when('type', {
				is: ['select', 'text', 'component', 'textarea'],
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}), // these are normally used in the api query and if they change fire the api query to fetch the options again

			dependsOnMeta: Joi.object().pattern(/^/, {
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
				labelKey: Joi.string(),
				valueKey: Joi.string(),
				filter: Joi.string(),
			}),
		options: Joi.array()
			.items({ label: Joi.string().required(), value: Joi.alternatives().try(Joi.string(), Joi.boolean()) })
			.min(1)
			.when('type', {
				is: 'select',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			dynamicOptions: Joi.boolean(),
			action: Joi.string().when('type', {
				/// on upload call this action if specified
				is: 'file',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			dynamicFields: Joi.boolean().when('type', {
				/// on upload call this action if specified
				is: 'select',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			isMulti: Joi.boolean().when('type', {
				/// on upload call this action if specified
				is: 'select',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			allowCreate: Joi.string().when('type', {
				/// on upload call this action if specified
				is: 'select',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			filterDropDown: Joi.boolean().when('type', {
				/// on upload call this action if specified
				is: 'select',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
			api: Joi.object()
				.keys({
					path: Joi.string().required(),
					method: Joi.string().default('GET'),
					createPath: Joi.string(),
					params: Joi.object().keys({ ref: Joi.string(), filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()),queryString: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
					filters: Joi.array().items(Joi.object()),
					body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
					limit: Joi.number(),
					response: Joi.object().keys({
						ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
						labelKey: Joi.string(),
						valueKey: Joi.string(),
						customKey: Joi.array().items(Joi.string()),
						delimiter: Joi.string(),
					}), // if not present then response is a collection of strings that are considered as label options
					uiFilter: Joi.object()
						.keys({
							cond: Joi.string(),
							excludedValues: Joi.array().items(Joi.string()),
						})
						.optional(),
				})
				.when('type', {
					is: ['select', 'text', 'component', 'textarea'],
					then: Joi.optional(),
					otherwise: Joi.forbidden(),
				}),
		default: Joi.alternatives().try(Joi.string(), Joi.boolean()),
			props: Joi.array().items(
				Joi.alternatives().try(
					Joi.string(),
					Joi.object().keys({
						label: Joi.string(),
						type: Joi.string(),
						name: Joi.string(),
						options: Joi.any().when('type', {
							is: 'select',
							then: Joi.optional(),
							otherwise: Joi.forbidden(),
						}),
						props: Joi.any(),
					})
				)
			), // used for jsonList or jsonObj when value is of type json object.
			propsMeta: Joi.any(),
			keyLabel: Joi.string(), // jsonObj
			valueLabel: Joi.string(), //  jsonObj
			valueType: Joi.string(), // for further qualifying the value type of jsonObj/jsonList
			keys: Joi.array().items(Joi.string()), // used when type is json
			valueTypes: Joi.array().items(Joi.string()), // used when type is json for specifying type of key
			filterable: Joi.boolean().default(true).optional(), // If not part of filter make false
			sortable: Joi.boolean().default(true).optional(), // If not part of sort make false
			dimension: Joi.number().integer().positive(), // add dimension when layoutType is "Card"
			backgroundColor: Joi.string().optional(),
			component: Joi.string().when('type', {
				// span image classes are required
				is: 'component',
				then: Joi.required(),
				otherwise: Joi.optional(),
			}),
			source: Joi.string(),
			hideLabel: Joi.boolean().default(false),
			hideEval: Joi.string(),
			disableTab: Joi.object().keys({ upload: Joi.boolean(), url: Joi.boolean() }).optional(),
			maxUpload: Joi.number()
				.integer()
				.positive()
				.when('component', {
					is: 'Video',
					then: Joi.required(),
				})
				.when('component', {
					is: 'Image',
					then: Joi.required(),
				})
				.when('component', {
					is: 'Audio',
					then: Joi.required(),
				})
				.optional(),
			maxUploadSize: Joi.number()
				.integer()
				.positive()
				.when('component', {
					is: 'Video',
					then: Joi.required(),
				})
				.when('component', {
					is: 'Image',
					then: Joi.required(),
				})
				.when('component', {
					is: 'Audio',
					then: Joi.required(),
				})
				.optional(),
			language: Joi.string(),
			autoCompletionApiProps: Joi.object().keys({
				api: Joi.object().keys({
					path: Joi.string().required(),
					method: Joi.string().default('GET'),
					params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
					filters: Joi.array().items(Joi.object()),
					response: Joi.object().keys({
						ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
						value: Joi.string(),
					}), // if not present then response is a collection of strings that are considered as label options
				}),
			}),
			create: Joi.boolean(),
			modalMeta: Joi.array().items(modalMeta),
			storeOptions: Joi.boolean(),
			dimensions: Joi.string(), // Image upload or File upload recommended dimensions
			fileNameTimeStamp: Joi.boolean(),
			uploadImageApi: Joi.object().keys({
				path: Joi.string().required(),
				method: Joi.string().default('GET'),
				params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
				filters: Joi.array().items(Joi.object()),
				body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
				response: Joi.object().keys({
					ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
					labelKey: Joi.string(),
					valueKey: Joi.string(),
				}),
			}),
			disabledVideo: Joi.boolean(),
			disableImage: Joi.boolean(),
			colorPicker: Joi.boolean(),
			editorList: Joi.boolean(),
			uploadVideoApi: Joi.object().keys({
				path: Joi.string().required(),
				method: Joi.string().default('GET'),
				params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
				filters: Joi.array().items(Joi.object()),
				body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
				response: Joi.object().keys({
					ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
					labelKey: Joi.string(),
					valueKey: Joi.string(),
				}),
			}),
			ignoreExtnValidation: Joi.boolean(),
			modalActionMeta: Joi.object().keys({
				headerMessage: Joi.string(),
			fields: Joi.any(),
			}),
			inputAccept: Joi.array().items(Joi.string()),
			uploadPrompt: Joi.string(),
		})
		.unknown(true)
)
export const gridMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		items: Joi.array().items(mapSchema),
		name: Joi.string(),
		title: Joi.string(),
		lookUp: Joi.boolean().default(false),
		editEval: Joi.string(),
		type: Joi.string().valid('grid', 'cardList', 'accordianGrid').default('grid').required(),
		dataSource: Joi.object().keys({
			type: Joi.string().valid('api', 'parent'),
			api: Joi.object()
				.keys({
					path: Joi.string().required(),
					method: Joi.string().valid('GET', 'POST').required(),
					entityName: Joi.string().required(),
					response: Joi.object().keys({
						ref: Joi.string().allow('').optional(),
					}),
				})
				.when('type', {
					is: 'api',
					then: Joi.required(),
					otherwise: Joi.forbidden(),
				}),
			ref: Joi.string(),
			filter: Joi.array(),
			params: Joi.array().items(Joi.string()),
		}),
		cardPerRow: Joi.number().integer().when('type', {
			is: 'cardList',
			then: Joi.required(),
			otherwise: Joi.forbidden(),
		}),
		backgroundColor: Joi.string().when('type', {
			is: 'cardList',
			then: Joi.required(),
			otherwise: Joi.forbidden(),
		}),
		color: Joi.string().when('type', {
			is: 'cardList',
			then: Joi.required(),
			otherwise: Joi.forbidden(),
		}),
		detailPage: Joi.object()
			.keys({
				items: Joi.array().items(mapSchema),
			})
			.when('type', {
				is: 'grid',
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
		addMore: Joi.object().keys({ item: Joi.any(), incrementField: Joi.string() }),
		pathName: Joi.string(),
		customAction: Joi.object().keys({
			collectFields: Joi.array().items({
				stateName: Joi.string().valid('videoUrl', 'flowName', 'refUrl', 'type'),
				fieldName: Joi.string(),
			}),
			otherProps: Joi.array().items(Joi.string()),
			actions: Joi.array().items({ name: Joi.string().required(), type: Joi.string().valid('action') }),
		}),
		multiselect: Joi.boolean().default(false),
	})
)

export const lookUpSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		title: Joi.string(),
		api: Joi.object().keys({
			path: Joi.string(),
			entityName: Joi.string(),
			method: Joi.string().default('GET', 'POST'),
			filter: Joi.array().items({
				name: Joi.string(),
				value: Joi.string(),
				cond: Joi.string(),
			}),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
			}),
		}),
		gridColumns: Joi.array(),
		searchFields: Joi.array(),
		fieldToCollect: Joi.array(),
		bKeys: Joi.array(),
		parentOf: Joi.string().allow(null),
		actions: Joi.array().items({ name: Joi.string().required(), type: Joi.string().valid('action') }),
		multiselect: Joi.boolean().default(false),
	})
)

export const categoriesSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		title: Joi.string(),
		api: Joi.object().keys({
			path: Joi.string(),
			entityName: Joi.string(),
			method: Joi.string().default('GET', 'POST'),
			filter: Joi.array().items({
				name: Joi.string(),
				value: Joi.string(),
				cond: Joi.string(),
			}),
			top: Joi.number().integer().positive(),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
			}),
		}),
		fieldToCollect: Joi.array(),
	})
)
export const contentSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		contentType: Joi.string().required(),
		content: Joi.array().items({
			name: Joi.string().required(),
			title: Joi.string().allow(null),
			api: Joi.object().keys({
				path: Joi.string(),
				entityName: Joi.string(),
				method: Joi.string().default('GET', 'POST'),
				filter: Joi.array().items({
					name: Joi.string(),
					value: Joi.string(),
					cond: Joi.string(),
				}),
				top: Joi.number().integer().positive(),
				params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
				body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
				response: Joi.object().keys({
					ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
				}),
			}),
			fieldToCollect: Joi.array(),
		}),
	})
)

export const appComponentSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		contentType: Joi.string().required(),
		component: Joi.string().required(),
		content: Joi.array().items({
			name: Joi.string().required(),
			title: Joi.string().allow(null),
			api: Joi.object().keys({
				path: Joi.string(),
				entityName: Joi.string(),
				method: Joi.string().default('GET', 'POST'),
				filter: Joi.array().items({
					name: Joi.string(),
					value: Joi.string(),
					cond: Joi.string(),
				}),
				top: Joi.number().integer().positive(),
				params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()),queryString: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
				body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
				response: Joi.object().keys({
					ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
				}),
			}),
			fieldToCollect: Joi.array(),
		}),
	})
)

export const tagsSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		title: Joi.string(),
		api: Joi.object().keys({
			path: Joi.string(),
			entityName: Joi.string(),
			method: Joi.string().default('GET', 'POST'),
			filter: Joi.array().items({
				name: Joi.string(),
				value: Joi.string(),
				cond: Joi.string(),
			}),
			top: Joi.number().integer().positive(),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
			}),
		}),
		fieldToCollect: Joi.array(),
	})
)

export const dimensionsSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		title: Joi.string(),
		api: Joi.object().keys({
			path: Joi.string(),
			entityName: Joi.string(),
			method: Joi.string().default('GET', 'POST'),
			filter: Joi.array().items({
				name: Joi.string(),
				value: Joi.string(),
				cond: Joi.string(),
			}),
			top: Joi.number().integer().positive(),
			params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
			body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
			response: Joi.object().keys({
				ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
			}),
		}),
	})
)

export const treeViewSchema = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		title: Joi.string(),
		items: Joi.any(),
		//    fieldToCollect:Joi.array(),
	})
)
//export const accordianGridMeta = gridMeta

export const tabsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		name: Joi.string(),
		tabs: Joi.array().items({ name: Joi.string().required(), type: Joi.string().valid('section') }),
		defaultActive: Joi.string(),
		isToggle: Joi.boolean().optional(),
	})
)
export const selectedFieldsMeta = Joi.array().optional()
export const actionsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		label: Joi.string(),
		name: Joi.string(),
		action: Joi.string().required(),
		path: Joi.string(),
		condition: Joi.string(),
		disabled: Joi.boolean(),
		disabledEval: Joi.string(),
		classes: Joi.string().when('type', {
			// span image classes are required
			is: 'span',
			then: Joi.required(),
			otherwise: Joi.optional(),
		}),
		type: Joi.string().valid('button', 'span', 'link', 'redirect').default('button'),
		actionType: Joi.string().valid('ui', 'server'),
		confirmAction: Joi.boolean(),
		confirmActionMessage: Joi.string(),
		callApiAction: Joi.boolean(),
		backgroundColor: Joi.string(),
		body: Joi.any(),
		redirectPath: Joi.string().when('type', {
			// span image classes are required
			is: 'redirect',
			then: Joi.required(),
			otherwise: Joi.optional(),
		}),
		color: Joi.optional(),
		viewPath: Joi.string(),
		pathName: Joi.string(),
		ignoreT: Joi.boolean(),
	})
)

export const workflowActionsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		action: Joi.string(),
		ref: Joi.string(),
		label: Joi.string(),
	})
)

export const sectionsMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		items: Joi.array().items(mapSchema), // index is proxy for position of field withing section
		// actions: Joi.array().items(mapSchema),
		cols: Joi.array().items(widthInCol),
		type: Joi.string().valid('group', 'tab'),
		name: Joi.string(),
		title: Joi.string(),
		component: Joi.string(),
		props: Joi.object().pattern(/^/, Joi.string()).when('component', {
			is: Joi.exist(),
			then: Joi.optional(),
			otherwise: Joi.forbidden(),
		}), // component var to parent prop var mapping
		otherProps: Joi.object().pattern(/^/, Joi.string()).when('component', {
			is: Joi.exist(),
			then: Joi.optional(),
			otherwise: Joi.forbidden(),
		}), // for passing other static properties
		loadList: Joi.object()
			.keys({
				itemType: Joi.string(),
				targetProp: Joi.string(),
				filter: Joi.object(),
				orderBy: Joi.array().items(Joi.string()),
				top: Joi.number().positive().integer(),
			})
			.when('component', {
				is: Joi.exist(),
				then: Joi.optional(),
				otherwise: Joi.forbidden(),
			}),
		layout: Joi.string().valid('labelAbove', 'labelLeft').default('labelLeft'),
		classes: Joi.string(),
		hide: Joi.boolean().default(false),
	})
)

export const accordianMeta = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		items: Joi.array().items(mapSchema),
	})
)
// position is relative across types of items like field, action or section within the parent in the col.
export const propsSchema = Joi.object()
	.keys({
		entityList: entityList,
		entity: entitySchema,
		identity: Joi.object().keys({ profile: { tenantUid: Joi.string().uuid().required() } }),
		name: Joi.string(),

		meta: Joi.object()
			.keys({
				fields: fieldsMeta,
				tabs: tabsMeta,
				apis: Joi.object().pattern(/^/, apiSchema),
				lists: Joi.object().pattern(
					/^/,
					Joi.object().keys({
						name: Joi.string(),
						api: Joi.object().keys({
							path: Joi.string().required(),
							method: Joi.string().default('GET'),
							params: Joi.object().keys({ filter: Joi.array().items(Joi.string()), orderBy: Joi.array().items(Joi.string()) }), // list of fields to pass in filter Query with their values from the state. OrderBy fields ,
							body: Joi.array().items(Joi.string()), // just in case post is used for getting the options,
							response: Joi.object().keys({
								ref: Joi.string(), // ref key .. could be defaulted to body. Prop that holds the collection
							}),
						}),
					})
				),
				actions: actionsMeta,
				sideBarMinimized: Joi.boolean(),
				screens: Joi.object().pattern(
					/^/,
					Joi.object().keys({
						items: Joi.array().items(mapSchema), //
						cols: Joi.array().items(widthInCol), // no of columns in the screen
						// actions: Joi.array().items(mapSchema),
						// sections: Joi.array().items(mapSchema),
						name: Joi.string(),
						forceDisable: Joi.boolean(), // force disable fields
						forceEnable: Joi.boolean(), // force enable fields even if they are defined as disabled
						disable: Joi.boolean(), // default mode for the screen fields
						title: Joi.string(),
						layout: Joi.string().valid('labelAbove', 'labelLeft').default('labelLeft'),
						classes: Joi.string(),
						loadLists: Joi.array().items({
							itemType: Joi.string(),
							targetProp: Joi.string(),
							filter: Joi.object(),
							orderBy: Joi.array().items(Joi.string()),
							top: Joi.number().positive().integer(),
						}),
						renderItems: Joi.array().items({ name: Joi.string(), type: Joi.string() }),
						identifierField: Joi.string(),
						apis: Joi.array().items(Joi.string()),
						layoutType: Joi.object().keys({
							type: Joi.array().items(Joi.string().valid('Grid', 'Card')),
							backgroundColor: Joi.string(),
							color: Joi.string(),
							cardInRow: Joi.number().integer().positive(),
							cardPerRow: Joi.number().integer().positive(),
							excluded: Joi.object().keys({
								hideField: Joi.boolean().default(false),
								filter: Joi.boolean().default(false),
								sort: Joi.boolean().default(false),
								paging: Joi.boolean().default(false),
								grid: Joi.boolean().default(false),
								all: Joi.boolean().default(false),
							}),
							defaultCards: Joi.array().items(Joi.object().keys({ name: Joi.string(), type: Joi.string() })),
						}),
						type: Joi.string().valid('tabs', 'treeView', 'contentView', 'appComponent').optional(),
						hideList: Joi.boolean().optional(),
						multiselect: Joi.boolean().default(false),
						filter: Joi.array().items({
							name: Joi.string(),
							value: Joi.any(),
							operator: Joi.string(),
						}),
						orderBy: Joi.array(),
						storeList: Joi.boolean(),
					})
				),
				sections: sectionsMeta,
				entityUrlPaths: Joi.object(),
				accordianMeta: accordianMeta,
				dynamicFields: dynamicFieldsMeta,
				cards: Joi.object().pattern(/^/, cardsMeta),
				othersMeta: Joi.object().keys({
					htmlType: Joi.string(),
					ref: Joi.string(), // if the value is obtained from prop or other elements.
					textRef: Joi.string(),
				}),
				title: Joi.string(),
				idKey: Joi.string().default('id'), // the get query primary key used for findByPK in getAll action
				bKeys: Joi.array().items(Joi.string()),
				fetchDetailByApi: Joi.boolean(),
				importKeys: Joi.array(),
				importLabel: Joi.array(),
				gridMeta: gridMeta,
				lookUpMeta: lookUpSchema,
				categoriesMeta: categoriesSchema,
				contentMeta: contentSchema,
				appComponentMeta: appComponentSchema,
				tagsMeta: tagsSchema,
				dimensionsMeta: dimensionsSchema,
				treeViewMeta: treeViewSchema,
				workflowActions: workflowActionsMeta,
				updateOnlyWritable: Joi.boolean(),
				selectedFields: selectedFieldsMeta,
				isUiFieldsMetaEnabled: Joi.boolean(),
				entitySchema: Joi.any(),
				// accordianGridMeta: accordianGridMeta
			})
			.required(),
	})
	.pattern(/./, Joi.any()) // to allow for props functions... otherwise Joi could crib

/** the state of the component changes when data changes
 * data is mapped to entity reducer
 */
export const stateSchema = {
	entity: Joi.array().items(item),
	filteredData: Joi.array().items(item),
	limit: Joi.number().positive().integer(),
	activePage: Joi.number().positive().integer(),
	totalCount: Joi.number().integer().min(0),
	itemData: Joi.array().items(item),
	filter: Joi.object(),
}

export const linkedItemTypes = Joi.object().pattern(
	/^/,
	Joi.object().keys({
		itemType: Joi.string(),
		urlGetAllPath: Joi.string(),
		urlGetAllMethod: Joi.string().valid('GET', 'POST'),
		urlCreatePath: Joi.string(),
		urlEditPath: Joi.string(),
		urlEditMethod: Joi.string().valid('POST', 'PUT'),
		urlDeletePath: Joi.string(),
		urlDeleteMethod: Joi.string().valid('POST', 'DELETE'),
	})
)

const UiFieldMeta = Joi.object().keys({
	fieldName: Joi.string(),
	fieldProperties: Joi.object(),
	modelName: Joi.string(),
})

export const getUiFieldsMeta = {
	entity: Joi.array().items(UiFieldMeta),
}
