import React, { Component } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
//Locale
import { ManageQueueComponent, UserQueueComponent } from '..'
import { Loader } from '../../common'
import './style.css'

class LiveChatQueueComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			menuAgentQueue: true,
			menuAgentQueueAssociate: false,
			menuEmailTemplateQueue: false,
		}
	}
	componentWillMount() {
		let { profile } = this.props
		// this.props.getAllGlobalSettings(profile.tenantUid);
	}

	render() {
		let showAgentSetting = false
		const { globalSetting, agentqueue, userqueue, userslist, t } = this.props
		let liveChat = globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat

		if (globalSetting && globalSetting.loading == false && liveChat) {
			if (liveChat && liveChat.status === 1) {
				showAgentSetting = true
			}
		}

		return (
			<div className="animated fadeIn">
				{(agentqueue.loading || userqueue.loading || userslist.loading) && <Loader />}
				<div className="common-heading">
					<div className="common-heading row">
						<div className="col-sm-12">
							<div className="heading">
								<h5>{t('Live Chat Settings')}</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="main-section">
					<div className="main-inner">
						<div className="row">
							<div className="col-sm-3 col-xl-2">
								<div className="asc-accord">
									<ul className="accord-row">
										{/* {showAgentSetting && 
                                <li>
                                    <div className="accord-wrap">
                                        <Collapsible trigger="Agent Setting" className="collap-list">
                                            <ul className="child-setting">

                                                <li>
                                                    <a href="#" className="g-select"
                                                        onClick={(e) => this.setState({
                                                            menuAgentQueue: true,
                                                            menuAgentQueueAssociate: false
                                                        })}
                                                    >Manage Queue
                                                    </a>
                                                </li>
                                                <li><a href="#" className="g-select"
                                                    onClick={(e) => this.setState({
                                                        menuAgentQueue: false,
                                                        menuAgentQueueAssociate: true
                                                    })}
                                                >Associate Queue</a>
                                                </li>
                                            </ul>
                                        </Collapsible>
                                    </div>
                                </li>
                                } */}

										<li>
											<a
												href="#"
												className="active g-select"
												onClick={e => {
													this.setState({
														menuAgentQueue: true,
														menuAgentQueueAssociate: false,
														menuEmailTemplateQueue: false,
													})
													var a = document.getElementsByTagName('a')
													for (let i = 0; i < a.length; i++) {
														a[i].classList.remove('active')
													}
													e.target.classList.add('active')
												}}>
												{t('Manage Queue')}
											</a>
										</li>

										<li>
											<a
												href="#"
												className="g-select"
												onClick={e => {
													this.setState({
														menuAgentQueue: false,
														menuAgentQueueAssociate: true,
														menuEmailTemplateQueue: false,
													})
													var a = document.getElementsByTagName('a')
													for (let i = 0; i < a.length; i++) {
														a[i].classList.remove('active')
													}
													e.target.classList.add('active')
												}}>
												{t('Associate Agent(s)')}
											</a>
										</li>
									</ul>
								</div>
							</div>

							{this.state.menuAgentQueue && <ManageQueueComponent {...this.props} />}
							{this.state.menuAgentQueueAssociate && <UserQueueComponent {...this.props} />}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(LiveChatQueueComponent)
