export const GETALL_REQUEST_agentqueue = 'GETALL_REQUEST_agentqueue';
export const GETALL_SUCCESS_agentqueue = 'GETALL_SUCCESS_agentqueue';
export const GETALL_FAILURE_agentqueue = 'GETALL_FAILURE_agentqueue';


export const ADDNEW_REQUEST_agentqueue = 'ADDNEW_REQUEST_agentqueue';
export const ADDNEW_SUCCESS_agentqueue = 'ADDNEW_SUCCESS_agentqueue';
export const ADDNEW_FAILURE_agentqueue = 'ADDNEW_FAILURE_agentqueue';

export const EDIT_REQUEST_agentqueue = 'EDIT_REQUEST_agentqueue';
export const EDIT_SUCCESS_agentqueue = 'EDIT_SUCCESS_agentqueue';
export const EDIT_FAILURE_agentqueue = 'EDIT_FAILURE_agentqueue';

export const DELETE_REQUEST_agentqueue = 'DELETE_REQUEST_agentqueue';
export const DELETE_SUCCESS_agentqueue = 'DELETE_SUCCESS_agentqueue';
export const DELETE_FAILURE_agentqueue = 'DELETE_FAILURE_agentqueue';


export const UPDATEINFO_REQUEST_agentqueue = 'UPDATEINFO_REQUEST_agentqueue';
export const UPDATEINFO_SUCCESS_agentqueue = 'UPDATEINFO_SUCCESS_agentqueue';
export const UPDATEINFO_FAILURE_agentqueue = 'UPDATEINFO_FAILURE_agentqueue';