import React from 'react'
import Table from './ReactTable'
import { withTranslation } from 'react-i18next'

function MainTable(props) {
    let data = props.data
    let columnFields = props.columns;
    let columns = React.useMemo(
        () => [
            ...columnFields
        ],
        [columnFields]
    )
    if (props.isExpandedRow)
        columns.unshift({
            Header: () => null,
            id: 'expander', 
            Cell: ({ row }) => (
                <span className="row-expender" {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded 
                    ? <i className="fa fa-minus-square-o" aria-hidden="true"></i> 
                    : <i className="fa fa-plus-square-o" aria-hidden="true"></i>
                    }
                </span>
            ),
        })
    return (
        <Table
            columns={columns}
            data={data}
            classNames={props.classNames}
            rowExpendDetailPage={props.rowExpendDetailPage}
            isExpandedRow={props.isExpandedRow}
            onHandleCheckbox={props.onHandleCheckbox}
            selectedIndexes={props.selectedIndexes}
            multiselect={props.multiselect}
            mapExpendedRowIndex={props.mapExpendedRowIndex}
            preSelectedIndexes={props.preSelectedIndexes}
            i18n={props.i18n}
        />
    )
}

export default withTranslation()(MainTable)
