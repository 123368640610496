import { BaseActions } from '..'
import { alertActions } from '../../common'
import * as botConstant from '../constants/bot.constants'
import { botService } from '../services/bot.services'
let options = {}
options.resourceName = 'Bot'
options.service = botService

class botActions extends BaseActions {
	constructor(options) {
		super(options)
	}

	getBotData(item, customerTenantID, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(this.getBotRequest())

			botService.getBotData(item, customerTenantID, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.getBotSuccess(item))
					} else {
						let errorMsg = 'Cannot fetch ' + this.resourceName + ' at this moment please try again later'
						dispatch(that.getBotFailure(errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + this.resourceName + ' at this moment please try again later'
					dispatch(that.getBotFailure(errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	getBotRequest() {
		return { type: `${botConstant.GETCONFIG_REQUEST_BOT}` }
	}
	getBotSuccess(record) {
		return { type: `${botConstant.GETCONFIG_SUCCESS_BOT}`, item: record }
	}
	getBotFailure(error) {
		return { type: `${botConstant.GETCONFIG_FAILURE_BOT}`, error: error }
	}

	getBotConfigsAgentChat(tenantId, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(this.getBotRequest())
			let errorMsg = (t && t('Cannot fetch at this moment please try again later')) || 'Cannot fetch at this moment please try again later'
			botService.getBotConfigsAgentChat(tenantId, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.getBotSuccess(item))
					} else {
						dispatch(that.getBotFailure(errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					dispatch(that.getBotFailure(errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
}

let botAction = new botActions(options)

export const getBot = (item, tenantId, apiUrl) => botAction.getBotData(item, tenantId, apiUrl)

export const getBotConfigsAgentChat = (tenantId, t, apiUrl) => botAction.getBotConfigsAgentChat(tenantId, t, apiUrl)
