import React from 'react'
import { withTranslation } from 'react-i18next'
// import  {SearchTextField} from './'
import {
	// Label,
	Input,
} from 'reactstrap'
/**
 * front end actions
 *  handleTextSearch
 *  resetTextFilter
 *  handleChange
 *  handleSearch
 *  resetFilter
 * props actions
 *  handleTextSearch
 *  handleSearch
 * Props props
 *  searchItems, searchForm, resetFilter, isAdvancedSearch, searchText
 */

class FullSearchComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchItems: null, // array of entityValues that are used to render the form.
			meta: props.meta, // fields defnition meta
			fields: null,
			searchForm: null, // dictionary of fields and filter values
			resetFilter: false,
			isAdvanceSearch: props.isAdvanceSearch || false,
			searchText: '',
			searchActions: null,
		}
		this.state.fields = props.meta && props.meta.fields // fields definitions
		this.state.searchItems =
			props.meta && props.meta.screens && props.meta.screens.search && props.meta.screens.search.items && props.meta.screens.search.items.filter(item => item.type === 'field')
		this.state.searchActions =
			props.meta && props.meta.screens && props.meta.screens.search && props.meta.screens.search.items && props.meta.screens.search.items.filter(item => item.type === 'action')
		this.handleChange = this.handleChange.bind(this)
	}

	componentWillMount() {
		let props = this.props
		this.setState({
			searchItems:
				props.meta && props.meta.screens && props.meta.screens.search && props.meta.screens.search.items && props.meta.screens.search.items.filter(item => item.type === 'field'),
			fields: props.meta && props.meta.fields,
		})
	}

	handleTextSearch(e) {
		this.setState({ searchText: e.target.value })
	}

	searchText() {
		const { searchText } = this.state
		this.props.handleTextSearch(searchText)
	}

	resetTextFilter() {
		const { searchText } = this.state
		this.setState({ searchText: '' })
		this.props.handleTextSearch(null)
	}

	handleChange(name, value, apiOptions) {
		const { searchForm } = this.state
		this.setState({ loading: true })
		// setTimeout(() => {
		this.setState({
			searchForm: {
				...searchForm,
				[name]: value,
			},
			loading: false,
		})
		// }, 100);
		// need to iterate through all fields that contain this field as dependsOn and trigger their state change.
	}
	handleKeyPress(target) {
		if (target.charCode === 13) {
			this.searchText()
		}
	}
	handleSearch() {
		const { searchForm } = this.state
		if (searchForm) {
			this.props.handleSearch(searchForm)
		}
	}
	handleAction(action) {
		const { searchForm } = this.state
		if (searchForm) {
			this.props.handleAction(searchForm, action)
		}
	}
	resetFilter() {
		this.setState({ searchForm: {}, resetFilter: !this.state.resetFilter })
		this.props.handleSearch(null)
	}
	componentWillReceiveProps(props) {
		this.setState({ searchText: props.searchText, searchForm: props.searchForm })
	}

	render() {
		const { meta } = this.state
		const { fields } = meta
		const searchText = this.state.searchText
		let placeHolder =
			this.props.meta &&
			this.props.meta.screens &&
			this.props.meta.screens.search &&
			this.props.meta.screens.search.items &&
			this.props.meta.screens.search.items.filter &&
			this.props.meta.screens.search.items.filter(field => {
				if (field.type === 'link') return field
				return null
			})
		placeHolder = (placeHolder && placeHolder.length && `${(placeHolder[0] && fields && fields[placeHolder[0].name] && fields[placeHolder[0].name].label) || ''}`) || 'text'
		placeHolder = this.props.t(`Enter the ${placeHolder} to search`)
		// const searchForm = this.state.searchForm ||  this.props.searchForm
		// let baseStyleClass=meta && meta.sections && meta.sections.basic && meta.sections.basic.classes ? meta.sections.basic.classes : '';
		// const t = this.props.t
		return (
			<>
				<div className="search-min">
					<Input
						type="text"
						placeholder={placeHolder}
						name="search"
						value={searchText || ''}
						onChange={this.handleTextSearch.bind(this)}
						onKeyPress={this.handleKeyPress.bind(this)}
					/>
					<button className="search-btn-new btn btn-xs" onClick={this.searchText.bind(this)}>
						<i className="fa fa-search fa-lg mt-4"></i>
					</button>
					<button className="btn btn-link" onClick={this.resetTextFilter.bind(this)}>
						<i className="fa fa-refresh  "></i>
					</button>
				</div>
				{/* <div className="advance-search-on clearfix">
            <div className="adv-text">
              <h3>{t('Advanced Search')}</h3>

            </div>
            <p className="ad-switcj">
              <Label className="switch switch-text switch-pill switch-primary">
                <Input type="checkbox" className="switch-input" onClick={(e) => this.setState({
                  isAdvanceSearch: !this.state.isAdvanceSearch
                })} />
                <span className="switch-label" data-on="On" data-off="Off"></span>
                <span className="switch-handle"></span>
              </Label>
            </p>
          </div> */}

				{/* advance search start */}
				{/* {this.state.isAdvanceSearch && <div className="advance-seach">
          <div className="advance-search-option">
            <div className="row">
              <div className="col-sm-12">
                <div className="search-heading"><h3>Search By</h3></div>
              </div>
            </div>
            <div className="row">


              {searchItems && searchItems.length > 0 && searchItems.map((field, index) => {
                let fDef = meta.fields[field.name]
                if (!fDef.name) fDef.name = field
                let dependsOn = {}
                if (fDef.dependsOn && fDef.dependsOn.length && searchForm) {
                  fDef.dependsOn.forEach(f => {
                    dependsOn[f] = searchForm[f]
                  })
                }
                if (fDef)
                  return (
                    <div className="col-md-4" key={index}>
                      <div className="custom-select-search">
                        <SearchTextField
                          dependsOn={dependsOn}
                          key={index}
                          resetValue={null}
                          field={fDef}
                          handleChange={this.handleChange.bind(this)}
                          handleSearch={this.handleSearch.bind(this)}
                          resetFilter={this.state.resetFilter}
                          filters={this.props.filters}
                          formValues={searchForm||{}}
                        />
                      </div>
                    </div>
                  )
                else return <></>
              })}


              <div className="col-sm-12">
              <div className="ad-filter pull-right">
                { searchActions && searchActions.length > 0 && searchActions.map((action, index)=> {
                      let itemDef = actions[action.name]
                      let label = itemDef.label ? t(itemDef.label) : ''
                      if (itemDef)
                          return (
                            <Button type="button" key={itemDef.name} className="min-btn pull-right" onClick={this.handleAction.bind(this, itemDef)}>{label}</Button>
                            // <Action 
                            // key={index} 
                            // actionMeta={itemDef}
                            // {...this.props}
                            // handleAction={this.props.handleAction}
                            //  />
                          )
                    }
                )}
                 { !(searchActions && searchActions.length > 0) 
                 && <button className="ad-f-ghostbtn btn-fill"
                    onClick={this.handleSearch.bind(this)}>
                    <i className="fa fa-search"></i>Search
                  </button>
                 }
                  <button className="ad-f-ghostbtn"
                    onClick={this.resetFilter.bind(this)}>
                    <i className="fa fa-refresh  "></i> Reset
                  </button>

                </div>
              </div>



            </div>



          </div>
        </div>
        } */}
				{/* advance search ends         */}
			</>
		)
	}
}
export default withTranslation()(FullSearchComponent)
