import { Switch, FormControl, Button } from '@chakra-ui/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { EntityDetail } from '../../common/base/entity/components/DetailComponent'
import Select from 'react-select'
import { Loader } from '../../common/index'

class AgentNotificationDetail extends EntityDetail {
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			listItems: [
				{ value: 'ActiveAgents', label: 'Active Agents' },
				{ value: 'AllAgents', label: 'All Agents' },
			],
			isMissedToggleOn: true,
			isIncomingToggleOn: true,
			missedChatAgentValue: '',
			incomingChatAgentValue: '',
		}
	}

	handleSelectValue(value) {
		if (value) {
			let filterValue = this.state.listItems.filter(item => item.value === value)
			return filterValue && filterValue[0]
		}
	}

	handleAgentChange(values, name) {
		this.setState({ entityValues: { ...this.state.entityValues, [name]: { ...this.state.entityValues[name], agentList: values && values.value } } }, () => {
			this.handleSelectValue(values && values.value)
		})
	}

	handleMissedChatAgentChange = values => {
		this.setState({ missedChatAgentValue: values })
	}

	handleIncomingChatAgentChange = values => {
		this.setState({ incomingChatAgentValue: values })
	}

	handleToggle(name) {
		this.setState({
			entityValues: {
				...this.state.entityValues,
				[name]: { ...this.state.entityValues[name], status: this.state.entityValues[name] && this.state.entityValues[name].status ? 0 : 1 },
			},
		})
	}

	handleAction() {
		let actionMeta = {
			label: 'Update',
			name: 'update',
			action: 'publishItem',
		}
		const { meta } = this.props
		let actionFn = actionMeta.action
		let idKey = meta.idKey

		if (!this.props[actionFn]) console.error(`Action Fn ${actionFn} is not found.`)
		else
			this.props[actionFn]({
				entityValues: this.state.entityValues,
				id: this.state.idKey && this.state.entityValues[idKey],
			})
	}
	render() {
		const {
			listItems,
			entityValues,
			entity: { loading },
		} = this.state
		console.log(this.state.entityValues)
		const { t } = this.props
		return (
			<div className="col-sm-9 col-xl-10">
				<div className="gb-setting-bg">
					<div className="mf-common smallTalks Manage-Task">
						<h3>{t('Email Actions')}</h3>
						{loading && <Loader />}
						<div className="row">
							<div className="col-sm-10">
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-5" style={{ paddingTop: '10px' }}>
										{t('Enable email notification on missed chat')}
									</div>
									<div className="col-sm-5">
										<Select
											onChange={newValue => this.handleAgentChange(newValue, 'missedChat')}
											options={listItems}
											value={this.handleSelectValue(entityValues && entityValues['missedChat'] && entityValues && entityValues['missedChat'].agentList)}
											placeholder={'Select Agents'}
										/>
									</div>

									<div className="col-sm-1">
										<FormControl display="flex" alignItems="center" height="100%">
											<Switch
												id="email-alerts"
												size="md"
												colorScheme="green"
												onChange={this.handleToggle.bind(this, 'missedChat')}
												isChecked={entityValues && entityValues['missedChat'] && entityValues && entityValues['missedChat'].status ? true : false}
											/>
										</FormControl>
									</div>
								</div>
								<div className="row " style={{ marginBottom: '10px' }}>
									<div className="col-sm-5" style={{ paddingTop: '10px' }}>
										{t('Enable email notification on incoming chat')}
									</div>
									<div className="col-sm-5">
										<Select
											onChange={newValue => this.handleAgentChange(newValue, 'incommingChat')}
											options={listItems}
											value={this.handleSelectValue(entityValues && entityValues['incommingChat'] && entityValues && entityValues['incommingChat'].agentList)}
											placeholder={'Select Agents'}
										/>
									</div>
									<div className="col-sm-1">
										<FormControl display="flex" alignItems="center" height="100%">
											<Switch
												id="email-alerts"
												size="md"
												colorScheme="green"
												onChange={this.handleToggle.bind(this, 'incommingChat')}
												isChecked={entityValues && entityValues['incommingChat'] && entityValues && entityValues['incommingChat'].status ? true : false}
											/>
										</FormControl>
									</div>
								</div>
							</div>
							<div className="col-sm-2" style={{ marginTop: '10px' }}>
								<Button colorScheme="teal" bgColor="#007bff" variant="solid" onClick={this.handleAction.bind(this)}>
									{t('Update')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withTranslation()(AgentNotificationDetail)
