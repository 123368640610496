import React from 'react'
import Loadable from 'react-loadable'
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom'
import Taskmodule from '../app/agentchat/components/Taskmodule'
import { AgentNotificationDetailContainer, AgentNotificationListSearchContainer } from '../app/agentNotifications/agentNotification'
import { CannedResponsesCreateContainer, CannedResponsesDetailContainer, CannedResponsesListSearchContainer } from '../app/cannned-responses/cannedResponses'
// Custom Imports
import { PrivateRoute, PrivateRouteCustom } from '../app/common'
import { SettingsDetailContainer, SettingsListSearchContainer } from '../app/common/base/entities/settings/settings'
import { EmailTemplateCreateContainer, EmailTemplateDetailContainer, EmailTemplateListSearchContainer } from '../app/common/emailTemplates/emailTemplates'
import {
	AgentDashboardContainer,
	LiveChatKPIDashboardContainer,
	OverallDashboardContainer,
	QueueDashboardContainer,
	RealTimeInsightsDashboardContainer,
	SupervisorDashboardContainer,
} from '../app/dashboard'
import { LiveChatDashboardContainer } from '../app/dashboard/liveChatDashboard'
import { jobListSearchContainer } from '../app/Job/job'
import { appRootPath } from '../config'
import { history } from './history'
import { unavailabilityCreateContainer, unavailabilityDetailContainer, unavailabilityListSearchContainer } from '../app/unavailability/unavailability'

// import { LandingPageSettingListSearchContainer, LandingPageSettingDetailContainer, LandingPageSettingCreateContainer} from '../app/LandingPageSettings/LandingPage'
function Loading() {
	return <div className="loader"></div>
}

const liveChatDashboard = Loadable({
	loader: () => LiveChatDashboardContainer,
	loading: Loading,
})

const QueueDashboard = Loadable({
	loader: () => QueueDashboardContainer,
	loading: Loading,
})

const AgentDashboard = Loadable({
	loader: () => AgentDashboardContainer,
	loading: Loading,
})

const SupervisorDashboard = Loadable({
	loader: () => SupervisorDashboardContainer,
	loading: Loading,
})

const OverallDashboard = Loadable({
	loader: () => OverallDashboardContainer,
	loading: Loading,
})

const LiveChatKPI = Loadable({
	loader: () => LiveChatKPIDashboardContainer,
	loading: Loading,
})

const RealTimeInsights = Loadable({
	loader: () => RealTimeInsightsDashboardContainer,
	loading: Loading,
})

const SettingsList = Loadable({
	loader: () => SettingsListSearchContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})
const SettingsDetail = Loadable({
	loader: () => SettingsDetailContainer,
	// let comp = import('../app/common/base/entities/settings/settings')
	loading: Loading,
})
const Home = Loadable({
	loader: () => import('../app/common/containers/Home'),
	loading: Loading,
})
const ErrorPage = Loadable({
	loader: () => import('../app/common/containers/ErrorPage'),
	loading: Loading,
})
const Logout = Loadable({
	loader: () => import('../app/common/containers/Logout'),
	loading: Loading,
})
const ProfileContainer = Loadable({
	loader: () => import('../app/common/containers/ProfileContainer'),
	loading: Loading,
})

const AgentChat = Loadable({
	loader: () => import('../app/agentchat/containers/AgentchatContainer'),
	loading: Loading,
})
const LiveChatSetting = Loadable({
	loader: () => import('../app/agent-queue/containers/QueueContainer'),
	loading: Loading,
})
const LiveChatSupervisor = Loadable({
	loader: () => import('../app/agent-supervisor/containers/AgentchatContainer'),
	loading: Loading,
})

const SupportTicketListing = Loadable({
	loader: () => import('../app/common/ticketing/containers/SupportTicketListingContainer'),
	loading: Loading,
})
const SupportTicket = Loadable({
	loader: () => import('../app/common/ticketing/containers/CreateSupportTicketContainer'),
	loading: Loading,
})

const DetailSupportTicket = Loadable({
	loader: () => import('../app/common/ticketing/containers/DetailSupportTicketContainer'),
	loading: Loading,
})

const ReviewChat = Loadable({
	loader: () => import('../app/review-chat/containers/ReviewChatContainer'),
	loading: Loading,
})

const CannedResponsesList = Loadable({
	loader: () => CannedResponsesListSearchContainer,
	loading: Loading,
})

const CannedResponsesCreate = Loadable({
	loader: () => CannedResponsesCreateContainer,
	loading: Loading,
})

const CannedResponsesDetail = Loadable({
	loader: () => CannedResponsesDetailContainer,
	loading: Loading,
})

const Reports = Loadable({
	loader: () => import('../app/reports/containers/ReportsContainer'),
	loading: Loading,
})

const jobList = Loadable({
	loader: () => jobListSearchContainer,
	loading: Loading,
})

const emailTemplatesList = Loadable({
	loader: () => EmailTemplateListSearchContainer,
	loading: Loading,
})
const emailTemplatesDetail = Loadable({
	loader: () => EmailTemplateDetailContainer,
	loading: Loading,
})

const emailTemplatesCreate = Loadable({
	loader: () => EmailTemplateCreateContainer,
	loading: Loading,
})

const agentNotificationList = Loadable({
	loader: () => AgentNotificationListSearchContainer,
	loading: Loading,
})
const agentNotificationDetail = Loadable({
	loader: () => AgentNotificationDetailContainer,
	loading: Loading,
})

const unavailabilityList = Loadable({
	loader: () => unavailabilityListSearchContainer,
	loading: Loading,
})
const unavailabilityDetail = Loadable({
	loader: () => unavailabilityDetailContainer,
	loading: Loading,
})
const unavailabilityCreate = Loadable({
	loader: () => unavailabilityCreateContainer,
	loading: Loading,
})

let subdomain = false
let full = window.location.host
let parts = full.split('.')
let sub = parts[0].toLowerCase()
// let lPage = BotPatelcoContainer;
// if (publicPageUsers) {
//     let publicUsers = publicPageUsers.split(',');

//     if (publicUsers.indexOf(sub) !== -1) {
//         subdomain = true;

//     }
//     if (sub=='ms') {
//         lPage = BotMSContainer;
//     }

// }

export const routeList = [
	// Open Routes
	{ path: '/login', exact: true, component: Home, isNav: false },
	{ path: '/logout', exact: true, component: Logout, isNav: false },
	{ path: '/home', exact: true, component: Home, isNav: false },
	{
		path: '/botmanagement',
		exact: true,
		app: { path: 'botmanagement' },
		isNav: true,
		name: 'virtualAgentMgmt',
		label: 'Virtual Agent Mgmt',
		icon: 'fa fa-puzzle-piece',
		allowedRole: ['bot_analyst', 'bot_admin'],
	},
	{ path: '/approvals', exact: true, app: { path: 'approvals' }, isNav: false, name: 'tickets', label: 'Requests Management', icon: 'fa fa-ticket', allowedRole: ['approver', 'requestor'] },
	{
		path: '/tickets',
		exact: true,
		app: { path: 'tickets' },
		isNav: false,
		name: 'tickets',
		label: 'Ticket Management',
		icon: 'fa fa-ticket',
		allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
	},
	{
		path: '/',
		exact: true,
		component: AgentChat,
		isNav: false,
		name: 'liveChat',
		label: 'Live Chat',
		allowedRole: ['chat_agent', 'chat_agent_admin', 'chat_agent_supervisor'],
		icon: 'fa fa-comments-o',
		children: [
			{
				path: '/my-chats',
				exact: true,
				component: AgentChat,
				isNav: true,
				name: 'liveChatScreen',
				label: 'My Chats',
				icon: 'fa fa-question-circle',
				allowedRole: ['chat_agent'],
			},
			{
				path: '/',
				exact: true,
				component: AgentChat,
				isNav: true,
				label: 'Settings',
				name: 'liveChatSetting',
				icon: 'fa fa-cogs',
				allowedRole: ['chat_agent_admin'],
				children: [
					{
						path: '/live_chat_setting',
						exact: true,
						component: LiveChatSetting,
						isNav: true,
						label: 'Queues',
						name: 'liveChatSetting',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_admin'],
					},
					{
						path: '/emailTemplates',
						exact: true,
						component: emailTemplatesList,
						isNav: true,
						label: 'Email Templates',
						name: 'emailTemplates',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_admin'],
					},
					{
						path: '/emailTemplates/update/:itemId',
						exact: true,
						component: emailTemplatesDetail,
						isNav: false,
						allowedRole: ['ASC Admin', 'chat_agent_admin', 'tenant_admin'],
						name: 'emailTemplatesDetail',
						label: 'Email Templates Detail',
					},
					{
						path: '/emailTemplates/create',
						exact: true,
						component: emailTemplatesCreate,
						isNav: false,
						allowedRole: ['ASC Admin', 'chat_agent_admin', 'tenant_admin'],
						name: 'emailTemplatesCreate',
					},
					{
						path: '/emailConfiguration/general',
						exact: true,
						component: agentNotificationList,
						isNav: true,
						label: 'Email Configuration',
						name: 'emailConfiguration',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_admin'],
					},
					{
						path: '/emailConfiguration/general/update/:itemId',
						exact: true,
						component: agentNotificationDetail,
						isNav: false,
						allowedRole: ['ASC Admin', 'chat_agent_admin', 'tenant_admin'],
						name: 'emailConfigurationDetail',
						label: 'Email Configuration Detail',
					},
					{
						path: '/unavailabilityReason',
						exact: true,
						component: unavailabilityList,
						isNav: true,
						label: 'Unavailability Reasons',
						name: 'unavailabilityyReason',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_admin'],
					},
					{
						path: '/unavailabilityReason/create',
						exact: true,
						component: unavailabilityCreate,
						isNav: false,
						allowedRole: ['chat_agent_admin'],
						name: 'unavailabilityReasonCreate',
					},
					{
						path: '/unavailabilityReason/update/:itemId',
						exact: true,
						component: unavailabilityDetail,
						isNav: false,
						allowedRole: ['chat_agent_admin'],
						name: 'unavailablityReasonDetail',
						label: 'unavailability Detail',
					},
				],
			},
			{
				path: '/monitor-chats',
				exact: true,
				component: LiveChatSupervisor,
				isNav: true,
				name: 'LiveChatSupervisor',
				label: 'Monitor Chats',
				icon: 'fa fa-book',
				allowedRole: ['chat_agent_supervisor'],
			},
			{
				path: '/dashboard',
				exact: true,
				component: LiveChatKPI,
				isNav: true,
				name: 'Dashboard',
				label: 'Live Chat Dashboard',
				icon: 'fa fa-cogs',
				allowedRole: ['chat_agent_supervisor'],
				routeProps: { filters: 'none' },
				children: [
					{
						path: '/LiveChatKPI',
						exact: true,
						component: LiveChatKPI,
						isNav: true,
						name: 'LiveChatKPI',
						label: 'Live Chat KPI',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_supervisor'],
					},
					{
						path: '/RealTimeInsights',
						exact: true,
						component: RealTimeInsights,
						isNav: true,
						name: 'RealTimeInsights',
						label: 'RealTime Insights',
						icon: 'fa fa-cogs',
						allowedRole: ['chat_agent_supervisor'],
					},
				],
			},
			{
				path: '/review-chat',
				exact: true,
				component: ReviewChat,
				isNav: true,
				name: 'reviewChat',
				label: 'Review Chat',
				icon: 'icon-speedometer',
				allowedRole: ['chat_agent_supervisor'],
			},
			{
				path: '/canned-responses',
				exact: true,
				component: CannedResponsesList,
				isNav: true,
				name: 'cannedResponses',
				label: 'Canned Responses',
				icon: 'fa fa-book',
				allowedRole: ['chat_agent_supervisor', 'chat_agent_admin', 'expert'],
			},
			{
				path: '/canned-responses/update/:itemId',
				exact: true,
				component: CannedResponsesDetail,
				isNav: false,
				allowedRole: ['chat_agent_supervisor', 'chat_agent_admin', 'expert'],
				name: 'cannedResponsesDetails',
				label: 'Canned Responses Detail',
			},
			{
				path: '/canned-responses/create',
				exact: true,
				component: CannedResponsesCreate,
				isNav: false,
				allowedRole: ['chat_agent_supervisor', 'chat_agent_admin', 'expert'],
				name: 'cannedResponsesCreate',
				label: 'Create Canned Responses',
			},
			{
				path: '/reports',
				exact: true,
				component: Reports,
				isNav: true,
				name: 'report',
				label: 'Reports',
				icon: 'icon-graph',
				allowedRole: [
					'coach',
					'tenant_admin',
					'content_author',
					'content_reviewer',
					'program_director',
					'expert',
					'chat_agent_supervisor',
					'chat_agent_admin',
					'bot_analyst',
				],
			},
			{
				path: '/requests',
				icon: 'fa fa-user-plus',
				exact: true,
				component: jobList,
				isNav: true,
				allowedRole: [
					'coach',
					'tenant_admin',
					'content_author',
					'content_reviewer',
					'program_director',
					'expert',
					'chat_agent_supervisor',
					'chat_agent_admin',
					'bot_analyst',
				],
				name: 'requests',
				label: 'Requests',
			},
		],
	},
	{
		path: '/learning',
		exact: true,
		app: { path: 'learning' },
		isNav: true,
		name: 'learningApp',
		label: 'Learning Mgmt',
		icon: 'fa fa-black-tie',
		allowedRole: ['trainee', 'content_author', 'coach', 'expert'],
	},
	{
		path: '/admin',
		exact: true,
		app: { path: '/' },
		label: 'Admin App',
		name: 'admin',
		icon: 'fa fa-user-circle-o',
		isNav: true,
		allowedRole: ['user_admin', 'tenant_admin'],
	},
	{
		path: '/virtualagent',
		exact: true,
		app: { path: 'virtualagent' },
		isNav: true,
		icon: 'fa fa-black-tie',
		name: 'virtualAgent',
		label: 'Virtual Agent',
		allowedRole: ['bot_user'],
	},
	{
		path: '/profile',
		exact: true,
		component: ProfileContainer,
		isNav: false,
		allowedRole: [
			'billing_analyst',
			'bot_admin',
			'bot_analyst',
			'bot_manager',
			'bot_user',
			'bot bpm_admin',
			'bpm_analyst',
			'chat_agent',
			'chat_agent_admin',
			'chat_agent_supervisor',
			'tenant_admin',
			'ticket_admin',
			'ticket_agent',
			'ticket_agent_supervisor',
			'ticket_user',
			'user_admin',
		],
	},
	{ component: ErrorPage, isNav: false },
	{
		path: '/Support',
		icon: 'fa fa-tags',
		exact: true,
		component: /* subdomain ? lPage :*/ SupportTicketListing,
		isNav: false,
		allowedRole: ['support_supervisor', 'support_user'],
		name: 'supportTickets',
		label: 'My Support Tickets',
	},
	{
		path: '/Support/createTicket',
		exact: true,
		component: SupportTicket,
		isNav: false,
		name: 'supportTicket',
		label: 'Support Ticket',
		icon: 'fa fa-support',
		allowedRole: ['support_supervisor', 'support_user'],
	},
	{
		path: '/Support/ticket-detail/:caseRefId',
		exact: true,
		component: DetailSupportTicket,
		isNav: false,
		name: 'supportTicketDetail',
		label: 'Support Ticket Detail',
		icon: 'fa fa-support',
		allowedRole: ['support_supervisor', 'support_user'],
	},
]
export const routeListCustom = [
	// { path: '/taskmodule', exact: true, component: AgentChat, isNav: false, name: 'Bot', allowedRole: 'user' },
]

export const routeChildren = routesValues => {
	let filterChildren = routesValues.filter(route => route.allowedRole && route.children)
	let childRoutes = []
	filterChildren &&
		filterChildren.forEach((route, index) => {
			let childs =
				route.children &&
				route.children
					.filter(route => route.allowedRole)
					.map(route => {
						let nextChilds =
							route.children && route.children.filter(route => route.allowedRole).map(route => <PrivateRoute key={route.path || 'error'} {...route} />)
						childRoutes.push(nextChilds)
						return <PrivateRoute key={route.path || 'error'} {...route} />
					})
			childRoutes.push(childs)
		})

	return childRoutes
}
export const RootRouter = () => {
	return (
		<BrowserRouter history={history} basename={appRootPath}>
			<Switch>
				<Route key="taskmodule" exact path="/taskmodule" render={() => <Taskmodule />}></Route>
				{routeList
					.filter(route => route.allowedRole)
					.map(route => (
						<PrivateRoute key={route.path || 'error'} {...route} />
					))}
				{routeChildren(routeList)}
				{routeListCustom
					.filter(route => route.allowedRole)
					.map(route => (
						<PrivateRouteCustom key={route.path || 'error'} {...route} />
					))}
				{routeChildren(routeListCustom)}
				{routeList
					.filter(route => !route.allowedRole)
					.map(route => (
						<Route key={route.path || 'error'} {...route} />
					))}
				<Route key="Home" Component={Home} isNav={false} />
			</Switch>
		</BrowserRouter>
	)
}

export const localeLoader = (routePath, tenantId) => {
	return false
}
