import { userqueueConstants } from '../';

export const userqueue = (state = {}, action) => {
    switch (action.type) {
        case userqueueConstants.GETALL_REQUEST_userqueue:
            return {
                ...state,
                loading: true
            }
        case userqueueConstants.GETALL_SUCCESS_userqueue:
            return {
                ...state,
                loading: false,
                queueData: action.item,

            }
        case userqueueConstants.GETALL_FAILURE_userqueue:
            return {
                ...state,
                loading: false
            }
            
        default:
            return state
    }
}