// import { apiUrl } from '../../../config';
import { identityServices } from '../../common'
import { BaseService } from './base.services'

let options = {}
options.urlItemName = 'categories'
options.wsType = 'qna'
// options.workspaceEnabled = true
class CategoryServiceModel extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}

	updateCategory(body, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(body),
			url: `${apiUrlCustom}/${this.urlItemName}/update`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	deleteBulkCategory(body, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(body),
			url: `${apiUrlCustom}/${this.urlItemName}/bulkDelete`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
}
export const categoryServices = new CategoryServiceModel(options)
