import React, {useState, useEffect } from "react";
import {
    FormGroup,
    Label,
    Input,
    Table,
    Button,
    ButtonGroup
} from "reactstrap";
// static components

const FilterButton = (props) => {
    const [rSelected, setRSelected] = useState(null);
    const {config}=props
    useEffect(() => {
        props.onChange(props.name,rSelected,props.parent)
        },[rSelected]);
    return (
      <div className={props.col}>
    <ButtonGroup>
        {config && config.type=='btn' && config.list && Array.isArray(config.list) && config.list.map((item,idx)=>{
            return (
                <Button key={idx} color="primary" onClick={() => setRSelected(item.value)} active={rSelected === item.value}>{item.label}</Button>
            )
        })}
    
      </ButtonGroup>
      </div>
    );
  }




  const FilterSelect = (props) => {
    const [selectedOption, setselectedOption] = useState('');
    const handleChange=evt=>{
        setselectedOption(evt.target.value)
    }
    useEffect(() => {
      props.onChange(props.name,selectedOption,props.parent)
      },[selectedOption]);
    return (
        <div className={props.col} style={{paddingTop:'4px'}}>
        <FormGroup>
        <Input type="select" onChange={handleChange.bind(this)} name={props.name} id="exampleSelect">
    <option value=''>---{props.label || 'select'}---</option>
            {
                props.options && Array.isArray(props.options) && props.options.length>0 && props.options.map((val,idx)=>{
                return(<option value={val[props.optionConfig.value] || ''} key={idx}>{val[props.optionConfig.label] || ''}</option>)
                })
            }
        </Input>
      </FormGroup>
      </div>
    );
  }



  export {
    FilterButton,
    FilterSelect
  }