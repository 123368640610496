import { BaseActions } from '..'
import { alertActions } from '../../common'
import { categoryServices } from '../services/category.services'

let options = {}
options.service = categoryServices
options.resourceName = 'categories'

class CategoryActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}

	updateCategory(body, queryString, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))

			that.service.updateCategory(body, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.getAll(queryString))
						dispatch(that.success('LOADER_FALSE_' + options.resourceName))
						dispatch(alertActions.success(that.resourceName + ' updated successfully.'))
					} else {
						let errorMsg =
							(t && t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot update the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('EDIT', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot update the ' + this.resourceName + ' at this moment, please try again later'
					dispatch(that.failure('EDIT', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	deleteBulkCategory(body, queryString, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('DELETE'))

			that.service.deleteBulkCategory(body, apiUrl).then(
				item => {
					if (item && item.isDeleted) {
						dispatch(that.getAll(queryString))
						dispatch(that.success('LOADER_FALSE_' + options.resourceName))
						dispatch(alertActions.success(that.resourceName + ' deleted successfully.'))
					} else if (item && !item.isDeleted) {
						dispatch(that.getAll(queryString))
						dispatch(that.success('LOADER_FALSE_' + options.resourceName))
						dispatch(alertActions.error((t && t('Category is associated to qna pair, please remove all references to this category and try again.')) || ''))
					} else {
						let errorMsg =
							(t && t('Cannot delete the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot delete the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('DELETE', errorMsg))
						dispatch(that.success('LOADER_FALSE_' + options.resourceName))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot delete the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot delete the ' + this.resourceName + ' at this moment, please try again later' + (error ? error.message : '')
					dispatch(that.success('LOADER_FALSE_' + options.resourceName))
					dispatch(that.failure('DELETE', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
}
let categoryActions = new CategoryActionModel(options)
export const getCategoryData = (filter, t, apiUrl) => categoryActions.getAll(filter, t, apiUrl)
export const addCategoryData = (data, t, apiUrl) => categoryActions.addNewItem(data, t, apiUrl)
export const deleteCategoryData = (id, t, apiUrl) => categoryActions.deleteItem(id, t, apiUrl)
export const updateCategoryData = (data, id, t, apiUrl) => categoryActions.editItem(data, id, t, apiUrl)
export const updateCustomCategoryData = (data, queryString, t, apiUrl) => categoryActions.updateCategory(data, queryString, t, apiUrl)
export const deleteBulkCategory = (data, queryString, t, apiUrl) => categoryActions.deleteBulkCategory(data, queryString, t, apiUrl)
