/**
 * may not be needed once moved to entity based
 */
// import { apiUrl } from  '../../../config';
import { identityServices } from '../'

export const getScoketNamespace = (tenantId, userName, module, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/appusers/getScoketNamespace`,
		data: { userName, module },
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(response)
		}

		return response.data
	})
}
