import Joi from 'joi-browser'

const unavailabilityReason = Joi.object().keys({
  id : Joi.number().integer(),
  tenantId: Joi.string().allow(null),
  reason: Joi.string().required(),
  teams: Joi.array().min(1).items(Joi.string()).required(),
  status: Joi.string().valid('Deleted', 'Published', 'Draft').default('Published'),
  createdAt: Joi.date().optional().allow(null),
  createdBy: Joi.string().optional().allow(null),
  updatedAt: Joi.date().optional().allow(null),
  updatedBy: Joi.string().optional().allow(null),
  deletedSeq: Joi.number().allow(null)
})

export const methodSchemas = {
    getAll: Joi.array().items(unavailabilityReason),
    getOne: unavailabilityReason,
    addItem: unavailabilityReason,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: unavailabilityReason,
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = unavailabilityReason