'use strict'

import { EntityActions } from '../common/base/entity';
// import {EntityComponent} from '../entity'

export class EmailSignatureAction extends EntityActions {
    constructor(options) {
        super(options);
    }


    preActionsHooks({actionName, item}){
        //console.log(item)
        if (!item || Object.keys(item).length === 0) return
        if (item && (actionName == 'addNewItem') || actionName == 'editItem' || actionName == 'publishItem') {
                let payload={...item};   
                if(!payload.status && actionName==='publishItem') payload.status='Published';                     
                let removeObjectProperties = function(obj, props) {
                    for(var i = 0; i < props.length; i++) {
                        if(obj.hasOwnProperty(props[i])) {
                            delete obj[props[i]];
                        }
                    }
                };                                 
                let properties = {
                    missedChat: item.missedChat || {},
                    incommingChat: item.incommingChat || {},
                }
                payload.properties = properties;
                item = payload;
                removeObjectProperties(item,["missedChat" ,"incommingChat"])
                return item;
        } else {
            return item
        }
    }

    postActionsHooks({actionName, item, tenantId }){
        let obj
        const spread = item => {
			if (item)
				item.configType = ['defaultSetting'].includes(item.configType)
					? 'general'
					: item.configType
			if (item.properties && typeof item.properties === 'object') {
				obj = Object.assign({}, item, item.properties);
				return obj
			} else {
				return item
			}
		}

        switch (actionName) {
            case 'getAll':
                let items = item && item.map((i)=>{
                    i = Object.assign({}, i, i.properties);
                    i.missedChat = i.properties.missedChat;
                    i.incommingChat = i.properties.incommingChat;
                    return i;
                })             
                return items
            case 'getOne':
                if(!item.properties) return item
                item = Object.assign({}, item, item.properties); 
                item.missedChat = item.properties.missedChat;
                item.incommingChat = item.properties.incommingChat;   
                return item
            default:
                if (!item) return
                if (item.pop) return item.map(spread)
                return spread(item) 
        }
    }
}