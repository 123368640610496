// import React, { useCallback, useMemo, useState, useEffect } from 'react';
// const  taskmodule = (params) => {
//     const getTrans = useCallback(()=>{
//         let t = ""
//         setTran(t)
//     }, [tran, setTran])
//     const [tran, setTran] = useState(null)
//     return <div dangerouslySetInnerHTML={{_html:tran}></div>
// }
// export default taskmodule
import * as AdaptiveCards from 'adaptivecards'
import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { session } from '../../../config'

var adaptiveCard = new AdaptiveCards.AdaptiveCard()

class Taskmodule extends Component {
	constructor(props) {
		super(props)
		this.state = {
			transcripts: {},
		}
	}
	componentWillMount() {
		let that = this
		let queryParms = this.getQueryParameters()
		let transData = queryParms['transData']
		let token = queryParms['acces_token']
		if (typeof transData == 'string') transData = JSON.parse(transData)
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		fetch(
			apiUrl +
				'/active_agents/getUserTranscriptWithBotTeams/' +
				transData.conversationId +
				'/' +
				transData.tenantId +
				((transData.startTime && `?startTime=${transData.startTime}`) || ''),
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-token': token,
					'x-tenantid': transData.tenantId,
				},
			}
		)
			.then(res => res.json())
			.then(res => {
				//alert(1);
				that.setState({
					transcripts: res,
				})
				//console.log("This is response\n\n\n\n\n\n",res)
				let userFullName = res && res.data && res.data.length > 0 ? res.data[0].userInfo.userFullName : ''
				that.setState({ userFullName: userFullName })
				// event.target.parentElement.classList.add('active-incoming-history');
				let userDetails =
					res &&
					res.data &&
					res.data.filter(message => {
						return message.userInfo && message.userInfo.role === 'user'
					})
				let userInfo = userDetails && userDetails[0] && userDetails[0].userInfo
				//console.log(res.data,userDetails,userInfo,"userInfo")
				// if (userInfo && userInfo.userFullName.toLowerCase().includes('anonymous')) this.getUserPrimaryChatSession(ep, token, sessionid, tenantId)
				// else {
				//     let splitStr = userInfo && userInfo.userFullName && userInfo.userFullName.trim().split(' ')
				//     let firstname = (splitStr && splitStr[0] && splitStr[0]) || '{{firstName}}'
				//     let lastname = (splitStr && splitStr[1] && splitStr[1]) || '{{lastName}}'
				//     if (splitStr) {
				//         userInfo['firstName'] = firstname
				//         userInfo['lastName'] = lastname
				//         this.updateCannedResponse(this.state.tempResponses, userInfo)
				//     } else this.setState(() => ({ cannedResponses: this.state.tempResponses }))
				// }
			})
		//    this.setState({transcripts:transData})
	}
	getQueryParameters() {
		let queryParams = {}
		window.location &&
			window.location.search &&
			window.location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					let s = item.split('='),
						k = s[0],
						v = s[1] && decodeURIComponent(s[1])
					queryParams[k] = v
				})
		return queryParams
	}
	render() {
		let tenantId
		function AnswerHtml(data) {
			let html = ''
			if (data.ans) {
				let splitAns = data.ans.split('~')
				html = splitAns[0].replace(/\\n/g, ' \n')
			}
			//return html;
			return <ReactMarkdown escapeHtml={false} source={html} renderers={{ link: LinkRenderer }} />
		}

		function UserChatTranscript(transcript) {
			// const sentBy = transcript.data.sentBy && (transcript.data.sentBy.toLowerCase() == transcript.botName.toLowerCase());

			if (
				transcript.data.attachments &&
				transcript.data.attachments.length > 0 &&
				transcript.data.attachments[0] &&
				transcript.data.attachments[0].content &&
				transcript.data.attachments[0].contentType == 'application/vnd.microsoft.card.hero'
			) {
				return (
					<div className="convo-wrapper clearfix ">
						<p>
							<strong>{transcript.data.attachments[0].content.title}</strong>
						</p>
						{transcript.data.attachments[0].content.text && <AnswerHtml ans={transcript.data.attachments[0].content.text} />}

						<div>
							{transcript.data.attachments[0].content.buttons.map((chat, index) => (
								<p key={index}>
									<button className="ac-pushButton">{chat.title}</button>
								</p>
							))}
						</div>
					</div>
				)
			}

			if (transcript.data.text == null || transcript.data.text == '') {
				if (transcript.data && transcript.data.attachments && transcript.data.attachments[0].content) {
					adaptiveCard.parse(transcript.data.attachments[0].content)
					var renderedCard = adaptiveCard.render()
					return (
						<div
							ref={n => {
								n && n.appendChild(renderedCard)
							}}
						/>
					)
				}
				return <div></div>
			} else {
				return <p>{transcript.data.text}</p>
			}
		}

		function LinkRenderer(props) {
			return (
				<a href={props.href} target="_blank">
					{props.children}
				</a>
			)
		}

		var CustomHistory = (
			<div className="pre-bot">
				<div className="chat-head">
					<h4>{this.state.userFullName && this.state.userFullName != 'Anonymous user' ? this.state.userFullName : 'Chat Session'}</h4>
				</div>
				<div className="chat-body">
					<div className="chat-frame">
						<div className="ch-convo clearfix">
							{this.state.transcripts &&
								this.state.transcripts.data &&
								this.state.transcripts.data.map((item, index) => {
									const sentBy = item.sentBy && item.bot && item.sentBy.toLowerCase() === item.bot.toLowerCase()
									return (
										<div className="clearfix" key={index}>
											<div className={sentBy ? 'convo-wrap from-me col-blue ' : ' convo-wrap from-bot col-white'}>
												<div id={'temp-id-' + index}>
													<UserChatTranscript key={index} data={item} index={index} botName={this.state.botName} />
												</div>
												<span className={!sentBy ? 'user-name' : 'sender-name'}>{item.sentBy && item.sentBy.toLowerCase() == 'you' ? 'User' : item.sentBy}</span>
											</div>
										</div>
									)
								})}
							{this.state.transcripts && this.state.transcripts == {} && <div>Loading</div>}
						</div>
					</div>
				</div>
				{/* <div className="chat-bottom">
					<div className="input-wrap">
						<input type="text" className="chat-input" placeholder={t('Enter Your Message')} />
						<a href="#">
							<span className="chat-begin" onClick={this.chatInitiate.bind(this, this.state.userid)}>
								{t('Click To Initiate Chat')}
							</span>
						</a>
					</div>
				</div> */}
			</div>
		)

		var ChatHistory = (
			<>
				<div className="chat-window card">{CustomHistory}</div>
				{/* <div>{!this.state.showHistory && this.state.isChatNow && ChatComp}</div> */}
			</>
		)

		return (
			<div className="animated fadeIn">
				<div className="main-section">
					<div className="main-inner mychat mychat-teams">
						<div className="row">
							<div className="col-sm-12">
								<div className="chat-user clearfix">{ChatHistory}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Taskmodule
