import { ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import * as expr from 'expression-eval'
import htmlToDraft from 'html-to-draftjs'
import isEqual from 'lodash.isequal'
import marked from 'marked'
import moment from 'moment'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import DropdownList from 'react-widgets/lib/DropdownList'
import Multiselect from 'react-widgets/lib/Multiselect'
import { Badge, Button, Input, Label, Modal, ModalBody } from 'reactstrap'
import { identityServices } from '../../../../common'
import { compressFile } from '../../../../common/helpers/compressFile'
import * as editorFunc from '../../../components/Editor/plugin/customBlock'
import { formFilters } from '../../../helpers/utils'
import * as Components from './'
import { FieldFile, FieldLabel } from './'
import CrudModal from './crudModal'
import CustomJsonClone from './CustomJsonClone'
import CustomJsonList from './CustomJsonList'
import JsonInput from './JsonInput'
import TextEditor from './jsonInput/TextEditor'
import MultiselectField from './MultiselectField'
import { JsonObjComponent } from './JsonObj'
import KeyValuePair from './KeyValuePair'
import { toast } from 'react-toastify'
/**
 * fe actions
 *  handleChange
 *  handleChangeSelect
 *  setDependency
 *  fetchApi
 *  textField
 *  selectField
 *  mapSelectFields
 *  setSelectField
 * prop actions
 *  handleChange ( parent component )
 * props prop
 *  field : {type, property : {options: {isStatic, apiUrl, moduleName, listMap: {key, value}}, enums:[] }}
 *  resetFilter
 *  apiOptions : { <field>: []}
 * state
 *  formValues, formField, selectOptions, options, isSelect, fieldType, formValue, resetValue, formOptions, apiOptions, shouldUpdate, onChangeOption
 */
class Field extends Component {
	constructor(props) {
		super(props)
		this.state = {
			field: props.field || false,
			options: (props.field && props.field.options) || [], // processed options
			isSelect: false, // bool indicating if type is select
			fieldType: (props.field && props.field.type) || 'text', // field.type
			dependsOn: props.dependsOn,
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
			isDisabled: props.field.disabled,
			updatedComponent: false,
			closeModal: false,
			readOnlyOnEdit: false,
			defaultValue: '',
			crudModalOpen: false,
			newOptions: {},
			initialOptions: {},
			editorValue: {},
		}
		this.handleChange = this.handleChange.bind(this)
		this.confirm = this.confirm.bind(this)
		this.addNew = this.addNew.bind(this)
		this.updateOptions = this.updateOptions.bind(this)
	}
	componentWillMount() {
		const { field, t } = this.props
		// let formOptions;
		this.setState({
			isSelect: field.type === 'select' ? true : false,
			fieldType: field.type || 'text',
			formValues: this.props.formValues,
		})
		if (field.dynamicFields && !field.isMulti && this.props.formValues && this.props.formValues[field.name]) {
			this.props.handleFieldsChange && this.props.handleFieldsChange({ name: field.name, value: this.props.formValues[field.name] })
		}
		if (field && field.options && field.options.length) {
			let applyTranslation = field.options.forEach(o => {
				o['label'] = t(o['label'])
			})
			this.setState({ options: applyTranslation || [] })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { field } = this.props
		if (!this.state.updatedComponent && field.dynamicFields && !field.isMulti && this.props.formValues && this.props.formValues[field.name]) {
			this.props.handleFieldsChange && this.props.handleFieldsChange({ name: field.name, value: this.props.formValues[field.name] })
			this.setState({
				updatedComponent: true,
			})
		}
		if ((field.type === 'text' || field.type === 'textarea') && field.dependsOn && prevState.defaultValue !== this.state.defaultValue) {
			this.props.handleChange && this.props.handleChange(field.name, this.state.defaultValue)
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		let that = this
		//  if(this.state.shouldUpdate) return true

		if (
			(this.state.formValues == undefined && nextState.formValues) ||
			(nextState.formValues == undefined && this.state.formValues) ||
			this.state.formValues !== nextState.formValues
		)
			return true

		if (
			(this.state.optionValues == undefined && nextState.optionValues) ||
			(nextState.optionValues == undefined && this.state.optionValues) ||
			this.state.optionValues !== nextState.optionValues
		)
			return true

		let df =
			that.props.formValues &&
			nextProps.formValues &&
			this.state.field.dependsOn &&
			this.state.field.dependsOn.some(d => {
				return that.props.formValues[d] !== nextProps.formValues[d]
			})
		if (df) return true

		if (!!that.props.formValues !== !!nextProps.formValues) return true

		df =
			that.props.optionValues &&
			nextProps.optionValues &&
			this.state.field.dependsOn &&
			this.state.field.dependsOn.some(d => {
				return that.props.optionValues[d] !== nextProps.optionValues[d]
			})
		if (df) return true

		if (!!that.props.optionValues !== !!nextProps.optionValues) return true

		if (
			(this.state.options == undefined && nextState.options) ||
			(nextState.options == undefined && this.state.options) ||
			this.state.options.length !== nextState.options.length
		) {
			return true
		}

		if (
			(this.state.defaultValue == undefined && nextState.defaultValue) ||
			(nextState.defaultValue == undefined && this.state.defaultValue) ||
			this.state.defaultValue !== nextState.defaultValue
		) {
			return true
		}

		if (this.state.closeModal || !this.state.closeModal) {
			return true
		}

		// if(this.state && this.state.value !==  nextProps.formValues && nextProps.formValues[nextProps.name]) return true
		return false
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		if (dependsOn && Object.keys(dependsOn).length > 0) {
			if (this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		if (props.params.itemId && props.field.readOnlyOnEdit) {
			this.setState({
				readOnlyOnEdit: props.field.readOnlyOnEdit,
			})
		}
		if (props.field.disabledEval) {
			const ast = expr.parse(props.field.disabledEval)
			let evaluated = expr.eval(ast, props.formValues)
			if (evaluated) {
				this.setState({
					isDisabled: true,
				})
			}
		}

		if (props.field.type === 'select' && (!this.state.options || this.state.options.length === 0)) {
			changed = true
		}
		if ((props.field.type === 'text' || props.field.type === 'textarea') && props.field.dependsOn) {
			changed = true
		}
		let shouldUpdate = false
		if (!isEqual(this.state.formValues, props.formValues)) shouldUpdate = true
		if (!isEqual(this.state.dependsOn, props.dependsOn)) shouldUpdate = true
		this.setState(
			{
				field: props.field || false,
				//options: (props.field && props.field.options) || [],  // processed options
				isSelect: false, // bool indicating if type is select
				fieldType: (props.field && props.field.type) || 'text', // field.type
				formValues: props.resetFilter ? {} : Object.assign({}, props.formValues), // dict of field name and field value passed from the parent.
				dependsOn: (changed && props.dependsOn) || this.state.dependsOn || this.props.dependsOn,
				forceDisable: props.forceDisable, // override field level disable false to true
				forceEnable: props.forceEnable, // overrided field level disable true to false
				defDisable: props.defDisable, // the default option if not specified at field level
				shouldUpdate,
			},
			() => {
				let object = {}
				if (props.field && props.field.component == 'TextEditor') {
					if (!props.formValues || !props.formValues[props.field.name]) return true
					let htmlVal = editorFunc.getMediaWithToken({
						text: props.formValues[props.field.name],
						tenantId: this.props.tenantId,
						key: this.props.purpose,
						SASToken: this.props.SASToken,
					})
					object[props.field.name] = EditorState.createEmpty()
					let data = editorFunc.convertCustomTags(htmlVal, true)
					let contentStateeditor = marked(data, { renderer: editorFunc.renderContent() })
					// @ts-ignore
					let contentBlock = htmlToDraft(contentStateeditor, (nodeName, node) => editorFunc.renderAttachment(nodeName, node))
					// @ts-ignore
					let contentState = ContentState.createFromBlockArray(contentBlock)
					//let contentAtomicBlock = await editorFunc.atomicBlocks(contentState)
					let drafVal = EditorState.createWithContent(contentState)
					object[props.field.name] = drafVal
					this.setState({
						editorValue: { ...this.state.editorValue, ...object },
					})
				}
				changed && this.getOptions()
			}
		)
		if (props.field && props.field.options) {
			this.setState({
				options: props.field.options,
			})
		}
	}

	async getOptions() {
		const { t } = this.props
		let api = this.state.field && this.state.field.api
		if (!api) {
			if (this.state.field && !this.state.field.options && Array.isArray(this.props.formValues[this.state.field.name])) {
				let options = this.props.formValues[this.state.field.name].map(f => {
					return { label: t(f), value: f }
				})
				this.setState(
					{
						...this.state,
						options:
							this.state.initialOptions[this.state.field.name] && this.state.initialOptions[this.state.field.name].length
								? this.state.newOptions[this.state.field.name] && this.state.newOptions[this.state.field.name].length
									? this.state.initialOptions[this.state.field.name].concat(this.state.newOptions[this.state.field.name])
									: this.state.initialOptions[this.state.field.name]
								: options,
					},
					() => {
						if (!this.state.initialOptions[this.state.field.name]) {
							this.setState({
								initialOptions: { [this.state.field.name]: options },
							})
						}
						let filterUnique = this.state.options.filter(
							(
								s => o =>
									(k => !s.has(k) && s.add(k))(['value', 'label'].map(k => o[k]).join('|'))
							)(new Set())
						)
						this.setState({ options: filterUnique })
					}
				)
				return options
			}
			if (!this.state.dependsOn) {
				return
			}

			let data,
				refData = [],
				coll,
				textVal
			if (this.state.field.dependsOnMeta) {
				this.state.field.dependsOn &&
					this.state.field.dependsOn.forEach(f => {
						let meta = this.state.field.dependsOnMeta && this.state.field.dependsOnMeta[f]
						let depData = this.props.optionValues && this.props.optionValues[f]
						data =
							depData &&
							meta &&
							depData
								.filter(i => {
									if (meta.filter) return i[meta.filter] === this.props.formValues[f]
									else return i === this.props.formValues[f] || (this.props.formValues && this.props.formValues[f])
								})
								.map(m => {
									return meta.ref !== undefined ? m[meta.ref] : m
								})
								.forEach(m => {
									refData = refData.concat(m)
								})
						if (this.state.field && (this.state.field.type === 'text' || this.state.field.type === 'textarea') && refData.length) {
							textVal = refData[0][meta.valueKey] || ''
						} else {
							coll = refData.map(o => {
								return { label: t(o[meta.labelKey]) || t(o), value: o[meta.valueKey] || o }
							})
						}
					})
			}

			this.setState({
				...this.state,
				defaultValue: textVal,
				options: coll ? coll : [],
			})
			return data
		}
		if (api && this.props.optionValues && this.props.optionValues[this.state.field.name]) {
			return
		}
		let useCase = localStorage.getItem('product')
		const requestOptions = {
			method: `${api.method || 'GET'}`,
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1], usecase: useCase, itemType: 'default' },
			url:
				api.createPath && this.props.params && !this.props.params.itemId
					? `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.createPath}`
					: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
		}
		if (api.params && api.params.queryString) {
			let filterValues = Object.assign({}, this.props.formValues, this.state.dependsOn || {})
			api.params.queryString.forEach((r, i, a)=>{
					requestOptions.url += `/${filterValues[r]}`;
			})
		}

		if (api.path.indexOf('?') !== -1) requestOptions.url += api.path.includes('ticketing/SLA/findWithDefaultTenant') ? '' : '&'
		else requestOptions.url += '?'
		if (api.filters && api.filters.pop) {
			requestOptions.url += '$filter='
			requestOptions.url += formFilters({ filters: api.filters })
		}
		if (api.params && api.params.filter && api.params.filter.pop) {
			let filterValues = Object.assign({}, this.state.formValues, this.state.dependsOn || {})
			if (requestOptions.url.indexOf('?') !== -1) requestOptions.url += requestOptions.url.indexOf('$filter=') !== -1 ? ' and ' : '&$filter='
			else requestOptions.url += '$filter='
			api.params.filter.forEach((p, i, a) => {
				if (i === a.length - 1) requestOptions.url += `${api.params.ref || p} eq '${filterValues[p] || null}'`
				else requestOptions.url += `${p} eq '${filterValues[p] || null}' and `
			})
		}

		if (api.params && api.params.orderBy && api.params.orderBy.pop) {
			requestOptions.url += '&$orderby='
			api.params.orderBy.forEach((p, i, a) => {
				if (i === a.length) requestOptions.url += `${p}`
				else requestOptions.url += `${p}`
			})
		}

		let limit = api.limit
		if (api && api.limit) {
			requestOptions.url += `&$top=${limit}`
		}

		if (api.body && api.body.pop) {
			requestOptions.json = true
			requestOptions.body = {}
			api.body.forEach(p => {
				requestOptions.body[p] = this.state.dependsOn[p]
			})
		}
		try {
			let coll1, textVal
			let response = await identityServices.identityApi.request(requestOptions)
			if (api.response) {
				if (api.response.ref) {
					let refs = api.response.ref.split('.')
					coll1 = refs.reduce((p, c) => {
						return p[c]
					}, response)
					// coll = response[api.response.ref]
				} else coll1 = response
				if (this.state.field && (this.state.field.type === 'text' || this.state.field.type === 'textarea') && coll1.length) {
					textVal = coll1[0][api.response.valueKey] || ''
				} else if (!api.response.customKey && (api.response.labelKey || api.response.valueKey) && coll1) {
					coll1 = coll1.map(o => {
						return { label: t(o[api.response.labelKey]) || t(o), value: o[api.response.valueKey] || o }
					})
				}
				if (api.response.customKey && coll1) {
					coll1 = coll1.map(o => {
						return {
							label: t(o[api.response.customKey[0]] + api.response.delimiter + o[api.response.customKey[1]]) || t(o),
							value: o[api.response.customKey[0]] + api.response.delimiter + o[api.response.customKey[1]] || o,
						}
					})
				}
				//this.state.field.storeOptions && this.props.storeFieldOptions && this.props.storeFieldOptions(this.state.field.name, response.data)
				this.setState({
					...this.state,
					defaultValue: textVal,
					options: coll1 ? coll1 : [],
				})
			}
			return coll1
		} catch (err) {
			if (err && err?.response?.data?.code === 400){
				toast.error(err.response?.data.message)
			}
			console.error(`Options fetch for ${this.state.field.name} failed with error ${err}. Url ${requestOptions.url}`)
		}
	}

	handleChange(event) {
		const { field, errors } = this.state
		if (event) {
			if (field.regexPattern) {
				let pattern = new RegExp(field.regexPattern)
				let passPattern = pattern.test(event.target.value)
				if (passPattern === false && event.target.value !== '') {
					this.setState({
						errors: {
							...this.state.errors,
							[field.name]: field.error,
						},
					})
				} else {
					this.setState({ errors: { ...errors, [field.name]: '' } })
				}
			}
			this.props.handleChange && this.props.handleChange(event.target.name, event.target.value)
		}
	}

	handleJsonChange(name, value) {
		if (name && value) {
			this.props.handleChange && this.props.handleChange(name, value)
		}
	}

	async handleTextEditorChange({ name }, value) {
		let errorMsg = this.props.validate && this.props.validate({ name, value })
		let htmlValue = draftToHtml(value, {}, false, editorFunc.customEntityTransform) || value
		htmlValue = await editorFunc.convertToOutput({
			value: htmlValue,
			tenantId: this.props.tenantId,
			outputFormat: 'markdown',
			purpose: this.props.purpose,
			colorPicker: this.state.field && this.state.field.colorPicker,
			list: this.state.field && this.state.field.editorList,
		})
		htmlValue = editorFunc.getMediaWithToken({ text: htmlValue, tenantId: this.props.tenantId, key: this.props.purpose, SASToken: this.props.SASToken })
		if (name && value) {
			this.props.handleChange && this.props.handleChange(name, htmlValue)
		}
	}

	getMeta(file) {
		return new Promise((resolve, reject) => {
			let img = new Image()
			var url = URL.createObjectURL(file)
			img.onload = () => resolve({ height: img.height.toString(), width: img.width.toString() })
			img.onerror = reject
			img.src = url
		})
	}
	uploadImage = async file => {
		let api = this.state.field && this.state.field.uploadImageApi
		if (!api) {
			return
		}
		if (file) {
			let resizeFile = await compressFile(file)
			let fileInfo = { type: 'image', access: 'private' }
			const formData = new FormData()
			let metadata = await this.getMeta(file)
			formData.append(
				'uploadConfig',
				JSON.stringify({
					access: 'public',
					uploadPublic: true,
				})
			)
			formData.append('upload', file)
			formData.append('metadata', JSON.stringify(metadata))

			const requestOptions = {
				method: `${api.method || 'GET'}`,
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
				data: formData,
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
			}
			let res = await identityServices.identityApi.request(requestOptions)
			if (res) {
				return res.data
			} else return { data: { link: null } }
		}
	}

	uploadVideo = async file => {
		let api = this.state.field && this.state.field.uploadVideoApi
		if (!api) {
			return
		}
		if (file) {
			let fileInfo = { type: 'image', access: 'private' }
			const formData = new FormData()
			formData.append(
				'uploadConfig',
				JSON.stringify({
					access: 'public',
					uploadPublic: true,
				})
			)
			formData.append('upload', file)

			const requestOptions = {
				method: `${api.method || 'GET'}`,
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
				data: formData,
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
			}
			let res = await identityServices.identityApi.request(requestOptions)
			if (res) {
				return res.data
			} else return { data: { link: null } }
		}
	}

	confirm() {
		this.setState({
			closeModal: false,
		})
	}

	addNew() {
		this.setState({ crudModalOpen: true })
	}

	updateOptions(modal, _value) {
		this.setState({ crudModalOpen: modal })
		const { field } = this.state
		if (_value) {
			if (field.type === 'select') {
				this.setState(
					{
						newOptions: {
							[this.state.field.name]: Array.isArray(this.state.newOptions[this.state.field.name])
								? this.state.newOptions[this.state.field.name].concat({ label: _value, value: _value })
								: [{ label: _value, value: _value }],
						},
						options: this.state.options.concat({ label: _value, value: _value }),
					},
					() => {
						let selectedValue = this.props.formValues[this.state.field.name]
						if (selectedValue && selectedValue.length) {
							selectedValue = selectedValue.map(sv => {
								return { label: sv, value: sv }
							})
						}
						const newValues = selectedValue && selectedValue.length ? selectedValue.concat({ label: _value, value: _value }) : [{ label: _value, value: _value }]
						this.handleChangeSelect(this.state.field, newValues)
					}
				)
			} else if (field.type === 'jsonObj') {
				// @ts-ignore
				this.handleKeyPairAdd({ name: field.name, value: _value, field, createModalOpen: modal })
			}
		}
	}

	cancel(field) {
		const { formValues } = this.state
		if (field.disabledEval && field.prompt) {
			const ast = expr.parse(field.disabledEval)
			let evaluated = expr.eval(ast, formValues)
			if (evaluated) {
				this.setState({
					formValues: { [field.name]: field.default },
					isDisabled: false,
				})
				this.props.handleChange && this.props.handleChange(field.name, field.default)
			}
		}
		this.setState({
			closeModal: false,
		})
	}

	handleChangeSelect(field, event) {
		// const { formValues } = this.state;
		if (event && field.prompt) {
			if (field.valueMatch) {
				const ast = expr.parse(field.valueMatch)
				let evaluated = expr.eval(ast, event)
				if (evaluated) {
					this.setState({
						closeModal: true,
					})
				}
			} else {
				this.setState({
					closeModal: true,
				})
			}
		}
		if (event && field.isMulti) {
			let value = event && event.map(o => o.value)
			this.props.handleChange && this.props.handleChange(field.name, value)
		} else if (event) {
			this.props.handleChange && this.props.handleChange(field.name, event.value || event.value === false ? event.value : event.label)
		}

		// if dynamicFields true than change included fields
		if (field.dynamicFields && !field.isMulti) {
			this.props.handleFieldsChange && this.props.handleFieldsChange({ name: field.name, value: event.value })
		}
	}

	jsonField(field) {
		// props ( combination of keys and valueTypes)
		// keys
		// valueTypes
		const { formValues } = this.state
		// const t = this.props.t
		let newSec = { name: field.name }
		if (field.props && field.props.pop) {
			newSec.items = field.props
		} else if (field.keys && field.keys.pop) {
			newSec.items = field.keys.map((k, i) => {
				return {
					type: (field.valueTypes && field.valueTypes[i]) || 'text',
					name: k,
					label: k,
				}
			})
		}
		//TODO: need to ensure the formValues are updating the object inside..
		return (
			<div key={`json_${field.name}`}>
				<div className="col-sm-12" key={`json_${field.name}`}>
					{newSec.items.map(prop => {
						return (
							<React.Fragment key={`${prop.name}`}>
								<Field key={prop.name} {...this.props} name={prop.name} field={prop} formValues={formValues} />
							</React.Fragment>
						)
					})}
				</div>
			</div>
		)
	}

	jsonText(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = (t && t(label)) || t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t && t(field.placeholder || field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		return (
			<>
				{field.readOnly && <FieldLabel {...this.props} />}
				{field.type === 'file' && <FieldFile {...this.props} />}
				{!field.readOnly && field.type !== 'file' && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							
							<Label for={field.name}>
								{t(field.label)} <sup>{field.required && '*'}</sup>
							</Label>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<JsonInput
								label={t(field.label)}
								key={field.type + `-` + field.name}
								type={field.type}
								name={field.name}
								value={formValues && formValues[field.name]}
								id={field.name}
								readOnly={field.readOnly ? true : false}
								placeholder={t(field.placeholder)}
								onComponentChange={this.handleJsonChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								// invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === "") ? true : false}
								// valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === "") ? false : true}
								rows={field.type === 'textarea' && 5}
							/>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)
	}
	jsonObj1(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		return (
			<>
				{field.readOnly && <FieldLabel {...this.props} />}
				{field.type === 'file' && <FieldFile {...this.props} />}
				{!field.readOnly && field.type !== 'file' && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							<Label for={field.name}>{t(field.label)}</Label>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<JsonInput
								{...field}
								label={field.label}
								key={field.type + `-` + field.name}
								type={field.type}
								name={field.name}
								value={formValues && formValues[field.name]}
								id={field.name}
								readOnly={field.readOnly ? true : false}
								placeholder={t(field.placeholder)}
								onComponentChange={this.handleJsonChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
								valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
								rows={field.type === 'textarea' && 5}
							/>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)
	}

	handleKeyPairAdd({ name, key, value, removedKey, isDeleted, field, createModalOpen }) {
		const { formValues } = this.state
		let fieldValue = formValues[name]
		if (isDeleted && fieldValue) {
			delete fieldValue[removedKey]
		} else {
			fieldValue = Object.assign({}, fieldValue, { [key]: value })
		}

		if (field && field.create) {
			this.setState({ crudModalOpen: createModalOpen }, () => {
				if (!createModalOpen) {
					//fieldValue = Object.assign({}, fieldValue, { [key]: value })
					//this.props.handleChange && this.props.handleChange(name, fieldValue)
				}
			})
			return
		}
		this.props.handleChange && this.props.handleChange(name, fieldValue)
	}

	handleCustomJsonClone(name, fieldValue) {
		const { formValues } = this.state
		// let fieldValue = formValues[name];
		//     fieldValue = Object.assign({}, fieldValue, { [key]: value })

		this.props.handleChange && this.props.handleChange(name, fieldValue)
	}

	handleCustomJsonList(name, fieldValue) {
		this.props.handleChange && this.props.handleChange(name, fieldValue)
	}
	jsonObj(field) {
		const { formValues } = this.state
		const t = this.props.t
		// let disabled = forceDisable ? true : (forceEnable ? false : (field.disabled != undefined ? field.disabled : defDisable))
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}

		return (
			<div>
				<KeyValuePair {...this.props} field={field} handleAddItem={this.handleKeyPairAdd.bind(this)} />
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3"></div>
					<div className={field.classes || `col-sm-9`}>
						{formValues &&
							formValues[field.name] &&
							Object.keys(formValues[field.name]).map((key, index) => (
								<Badge key={key + index} color="primary">
									{key}:{' '}
									{formValues[field.name][key] && typeof formValues[field.name][key] === 'object' ? JSON.stringify(formValues[field.name][key]) : formValues[field.name][key]}
									<i className="fa fa-times" aria-hidden="true" onClick={this.handleKeyPairAdd.bind(this, { name: field.name, removedKey: key, isDeleted: true })}></i>
								</Badge>
							))}
					</div>
					{this.state.crudModalOpen && (
						<CrudModal
							{...this.props}
							isOpen={this.state.crudModalOpen}
							promptMessage={field.promptMessage}
							fields={field.modalMeta}
							formValues={this.state.formValues}
							updateOptions={this.updateOptions}
						/>
					)}
				</div>
			</div>
		)
	}

	handleKeyPairListAdd({ name, key, value, isDeleted }) {
		const { formValues } = this.state
		let fieldValue = formValues[name] || []
		if (isDeleted) fieldValue = fieldValue && fieldValue.filter(c => !c[key])
		else fieldValue.push({ [key]: value })
		this.props.handleChange && this.props.handleChange(name, fieldValue)
		this.setState({
			key: '',
			value: '',
		})
	}
	jsonList(field) {
		const { formValues } = this.state
		const t = this.props.t
		// let disabled = forceDisable ? true : (forceEnable ? false : (field.disabled != undefined ? field.disabled : defDisable))
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}

		return (
			<div>
				<KeyValuePair {...this.props} field={field} handleAddItem={this.handleKeyPairListAdd.bind(this)} />
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3"></div>
					<div className={field.classes || `col-sm-9`}>
						{formValues &&
							formValues[field.name] &&
							formValues[field.name].pop &&
							formValues[field.name].map((o, index) => {
								return Object.keys(o).map(key => (
									<Badge key={key + index} color="primary">
										{key}:{o[key]}
										<i className="fa fa-times" aria-hidden="true" onClick={this.handleKeyPairListAdd.bind(this, { name: field.name, key, isDeleted: true })}></i>
									</Badge>
								))
							})}
					</div>
				</div>
			</div>
		)
	}

	jsonObjFixed(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		return (
			<>
				{field.readOnly && <FieldLabel {...this.props} />}
				{field.type === 'file' && <FieldFile {...this.props} />}
				{!field.readOnly && field.type !== 'file' && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							{t(label) || t(field.label)} <sup>{field.required && '*'}</sup>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<JsonObjComponent
								{...this.props}
								{...field}
								label={t(field.label)}
								key={field.type + `-` + field.name}
								type={field.type}
								name={field.name}
								value={formValues && formValues[field.name] ? formValues[field.name] : {}}
								id={field.name}
								readOnly={field.readOnly ? true : false}
								placeholder={t(field.placeholder)}
								onComponentChange={this.handleJsonChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
								valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
								rows={field.type === 'textarea' && 5}
							/>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)
	}

	jsonDict(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		return (
			<>
				{field.readOnly && <FieldLabel {...this.props} />}
				{field.type === 'file' && <FieldFile {...this.props} />}
				{!field.readOnly && field.type !== 'file' && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							<Label for={field.name}>{t(field.label)}</Label>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<JsonInput
								{...field}
								label={field.label}
								key={field.type + `-` + field.name}
								type={field.type}
								name={field.name}
								value={formValues && formValues[field.name]}
								id={field.name}
								readOnly={field.readOnly ? true : false}
								placeholder={t(field.placeholder)}
								onComponentChange={this.handleJsonChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
								valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
								rows={field.type === 'textarea' && 5}
							/>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)
	}

	fieldComponent(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		let CustomComponent = Components[field.component]
		if (Components && Components[field.component] && field.component === 'TextEditor') {
			return (
				<>
					{field.readOnly && <FieldLabel {...this.props} />}
					{field.type === 'file' && <FieldFile {...this.props} />}
					{!field.readOnly && field.type !== 'file' && (
						<div className={'custom-form-grp row clearfix'}>
							<div className="col-sm-3">
								<Label for={field.name}>{t(field.label)}</Label>
							</div>
							<div className={field.classes || `col-sm-9`}>
								<CustomComponent
									{...field}
									{...this.props}
									label={t(field.label)}
									key={field.type + `-` + field.name}
									type={field.type}
									name={field.name}
									id={field.name}
									readOnly={field.readOnly ? true : false}
									placeholder={t(field.placeholder)}
									defaultEditorState={this.state.editorValue && this.state.editorValue[field.name]}
									onChange={this.handleTextEditorChange.bind(this, { name: field.name })}
									uploadImage={this.uploadImage}
									uploadVideo={this.uploadVideo}
									maxLength={field.max || ''}
									disabled={disabled ? true : false}
									notModal={true}
									invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
									valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
									rows={field.type === 'textarea' && 5}
								/>
								{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
									<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
								)}
							</div>
						</div>
					)}
				</>
			)
		} else if (Components && Components[field.component]) {
			return (
				<>
					{field.readOnly && <FieldLabel {...this.props} />}
					{field.type === 'file' && <FieldFile {...this.props} />}
					{!field.readOnly && field.type !== 'file' && (
						<div className={'custom-form-grp row clearfix'}>
							<div className={field.labelClass || 'col-sm-3'}>
								<Label for={field.name}>{t(field.label)}</Label>
							</div>
							<div className={field.classes || `col-sm-9`}>
								<CustomComponent
									{...field}
									{...this.props}
									label={t(field.label)}
									key={field.type + `-` + field.name}
									type={field.type}
									name={field.name}
									value={formValues && formValues[field.name]}
									id={field.name}
									readOnly={field.readOnly ? true : false}
									placeholder={t(field.placeholder)}
									onComponentChange={this.handleJsonChange.bind(this)}
									maxLength={field.max || ''}
									disabled={disabled ? true : false}
									invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
									valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
									rows={field.type === 'textarea' && 5}
								/>
								{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
									<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}`}</div>
								)}
							</div>
						</div>
					)}
				</>
			)
		} else {
			return (
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3"></div>
					<div className="col-sm-9">
						<div className="alert alert-danger">
							<strong>{t(field.component) || ''}</strong> {t('Component is Missing or not Imported!')}
						</div>
					</div>
				</div>
			)
		}
	}

	textField(field, { props }) {
		const { formValues, forceDisable, forceEnable, defDisable, readOnlyOnEdit, defaultValue } = this.state
		let allowedMomentType = ['date', 'datetime', 'datetime-local', 'time']
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		if (!value && defaultValue) {
			value = defaultValue
		}
		let hideField = false
		if (field.hideEval && formValues) {
			const ast = expr.parse(field.hideEval)
			let evaluated = expr.eval(ast, formValues)
			if (evaluated) {
				hideField = true
			}
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: allowedMomentType.indexOf(field.type) ? moment(new Date(value)).format('MM/DD/YYYY') : value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			suffix: field.suffix,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		if (props?.entity?.entityValues?.id && field?.editEval) {
			let data = {
				profile: { preferred_username: props.profile.preferred_username },
				program: { createdBy: props.entity.entityValues.createdBy },
			}
			let fn = expr.compile(field.editEval)
			if (fn(data)) {
				htmlProps = { ...htmlProps, readOnly: true }
			}
		}
		return (
			<>
				{((field.readOnly && !hideField) || readOnlyOnEdit) && <FieldLabel {...this.props} />}
				{field.type === 'file' && !hideField && <FieldFile {...this.props} />}
				{!field.readOnly && !hideField && field.type !== 'file' && !readOnlyOnEdit && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							<Label for={field.name}>
								{t(label) || t(field.label)} <sup>{field.required && '*'}</sup>
							</Label>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<div className="row">
								<Input
									key={field.name}
									type={field.type}
									name={field.name}
									value={value || ''}
									id={field.name}
									readOnly={field.readOnly || htmlProps.readOnly ? true : false}
									placeholder={t(field.placeholder)}
									onChange={this.handleChange.bind(this)}
									maxLength={field.max || ''}
									disabled={disabled ? true : false}
									// invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === "") ? true : false}
									// valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === "") ? false : true}
									rows={field.type === 'textarea' ? 5 : undefined}></Input>
								{field.suffix && <span>{field.suffix}</span>}
								{this.state.errors && this.state.errors[field.name] && <div className="error-message">{this.state.errors[field.name]}</div>}
							</div>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)

		/* <>
				<Label  className="col-sm-3 col-form-label">{htmlProps.label}:</Label>
						<Input 
								{...htmlProps}
						/>
				</> */
	}

	textEditor(field, { props }, i18n) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}
		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		if (props?.entity?.entityValues?.id && field?.editEval) {
			let data = {
				profile: { preferred_username: props.profile.preferred_username },
				program: { createdBy: props.entity.entityValues.createdBy },
			}
			let fn = expr.compile(field.editEval)
			if (fn(data)) {
				htmlProps = { ...htmlProps, readOnly: true }
			}
		}
		return (
			<>
				{field.readOnly && <FieldLabel {...this.props} />}
				{field.type === 'file' && <FieldFile {...this.props} />}
				{!field.readOnly && field.type !== 'file' && (
					<div className={'custom-form-grp row clearfix'}>
						<div className="col-sm-3">
							<Label for={field.name}>
								{t(field.label)} <sup>{field.required && '*'}</sup>
							</Label>
						</div>
						<div className={field.classes || `col-sm-9`}>
							<TextEditor
								{...this.props}
								key={field.name}
								type={field.type}
								upload={false}
								name={field.name}
								value={value || ''}
								i18n={i18n}
								id={field.name}
								readOnly={field.readOnly || htmlProps.readOnly ? true : false}
								placeholder={t(field.placeholder)}
								onChange={this.handleJsonChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}
								valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? false : true}
								rows={field.type === 'textarea' ? 5 : undefined}></TextEditor>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{t(field.errorMessage) || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)

		/* <>
				<Label  className="col-sm-3 col-form-label">{htmlProps.label}:</Label>
						<Input 
								{...htmlProps}
						/>
				</> */
	}

	handleCreate(field, name) {
		if (name) {
			let newOption = { label: name, value: name }
			this.setState({ options: [newOption, ...this.state.options] })
		}
	}

	selectField(field, { props }) {
		let spinner = true
		const { formValues, forceDisable, forceEnable, defDisable, isDisabled, readOnlyOnEdit } = this.state
		const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let options = this.state.options || field.options
		if (field.api && field.api.uiFilter && this.state.formValues) {
			let cond = field.api.uiFilter.cond
			let excludedValues = field.api.uiFilter.excludedValues
			const expCond = expr.parse(cond)
			let evalTrue = expr.eval(expCond, this.state.formValues)
			if (evalTrue) {
				options = options && options.filter(f => excludedValues.indexOf(f.value) == -1)
			}
		}
		let label = field.label
		if (label) label = t(label)
		let selectedValue = null
		if (options && options.length > 0) {
			spinner = false
			selectedValue =
				field.isMulti && formValues && Array.isArray(formValues[field.name])
					? options.filter(o => formValues[field.name].indexOf(o.value) !== -1)
					: options.find(o => formValues && formValues[field.name] === o.value)
		} else {
			spinner = false
		}
		let htmlProps = {
			data: options,
			valueField: 'value',
			textField: 'label',
			containerClassName: 'asc-multiselect-dropdownlist',
			onChange: this.handleChangeSelect.bind(this, field),
			defaultValue: field.default ? { label: field.default, value: field.value } : null,
			required: field.required,
			value: selectedValue,
			placeholder: field.placeholder ? t(field.placeholder) : null,
			readOnly: field.readOnly ? true : false,
			disabled: isDisabled || readOnlyOnEdit ? isDisabled || readOnlyOnEdit : disabled ? disabled : false,
			busy: spinner ? true : false,
			allowCreate: field.allowCreate ? field.allowCreate : false,
			onCreate: field.allowCreate ? this.handleCreate.bind(this, field) : null,
			messages: {
				moveBack: t('Navigate back'),
				moveForward: t('Navigate forward'),
				dateButton: t('Select date'),
				timeButton: t('Select time'),
				openCombobox: t('open combobox'),
				openDropdown: t('open dropdown'),
				emptyList: t('There are no items in this list'),
				emptyFilter: t('The filter returned no results'),
			},
		}
		if (props?.entity?.entityValues?.id && field?.editEval) {
			let data = {
				profile: { preferred_username: props.profile.preferred_username },
				program: { createdBy: props.entity.entityValues.createdBy },
			}
			let fn = expr.compile(field.editEval)
			if (fn(data)) {
				htmlProps = { ...htmlProps, readOnly: true }
			}
		}
		if (field.filterDropDown) {
			htmlProps = { ...htmlProps, filter: field.filterDropDown }
		}
		return (
			options && (
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3">
						<Label className="col-form-label">
							{t(label) || t(field.label)} <sup>{field.required && '*'}</sup>
						</Label>
					</div>
					<div className={`col-sm-9 ${field.classes ? field.classes : ''}`}>
						{field && field.note &&
							<em >Note: {t(field.note)} </em> 
						}
						{
							// @ts-ignore
							field.isMulti ? <Multiselect {...htmlProps} /> : <DropdownList {...htmlProps} />
						}
						{this.state.closeModal && (
							<Modal autoFocus={false} backdrop={false} isOpen={this.state.closeModal} toggle={this.confirm} className="delete-card">
								<ModalBody>
									<h4 className="modal-min-heading">{field.promptMessage}</h4>
									<div>
										<p>{t('Are you sure, you want to confirm?')}</p>
									</div>
								</ModalBody>
								<div className="button-wrap">
									<Button color="primary" onClick={this.confirm}>
										<img src={`assets/img/yes.png`} alt={t('Yes')} />
										{t('Yes')}
									</Button>{' '}
									<Button color="secondary" onClick={this.cancel.bind(this, field)}>
										<img src={`assets/img/no.png`} alt={t('No')} />
										{t('No')}
									</Button>
								</div>
							</Modal>
						)}
						{field.create && (
							<Button className="min-btn" style={{ margin: '10px 0px' }} onClick={this.addNew}>
								{t('+ Add')}
							</Button>
						)}
						{this.state.crudModalOpen && (
							<CrudModal
								{...this.props}
								isOpen={this.state.crudModalOpen}
								promptMessage={field.promptMessage}
								fields={field.modalMeta}
								formValues={this.state.formValues}
								updateOptions={this.updateOptions}
							/>
						)}
					</div>
				</div>
			)
		)
	}

	multiSelect(field, { props }){
		let label = field.label
		if (label) label = props.t(label)
		return(
			<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3">
						<Label className="col-form-label">
							{props.t(label) || props.t(field.label)} <sup>{field.required && '*'}</sup>
						</Label>
					</div>
					{/* <div className={`col-sm-9 ${field.classes ? field.classes : ''}`}>
						{
							// @ts-ignore
							field.isMulti ? <Multiselect {...htmlProps} /> : <DropdownList {...htmlProps} />
						}
						{this.state.closeModal && (
							<Modal autoFocus={false} backdrop={false} isOpen={this.state.closeModal} toggle={this.confirm} className="delete-card">
								<ModalBody>
									<h4 className="modal-min-heading">{field.promptMessage}</h4>
									<div>
										<p>{t('Are you sure, you want to confirm?')}</p>
									</div>
								</ModalBody>
								<div className="button-wrap">
									<Button color="primary" onClick={this.confirm}>
										<img src={`assets/img/yes.png`} alt={t('Yes')} />
										{t('Yes')}
									</Button>{' '}
									<Button color="secondary" onClick={this.cancel.bind(this, field)}>
										<img src={`assets/img/no.png`} alt={t('No')} />
										{t('No')}
									</Button>
								</div>
							</Modal>
						)}
						{field.create && (
							<Button className="min-btn" style={{ margin: '10px 0px' }} onClick={this.addNew}>
								{t('+ Add')}
							</Button>
						)}
						{this.state.crudModalOpen && (
							<CrudModal
								{...this.props}
								isOpen={this.state.crudModalOpen}
								promptMessage={field.promptMessage}
								fields={field.modalMeta}
								formValues={this.state.formValues}
								updateOptions={this.updateOptions}
							/>
						)}
					</div> */}
					<MultiselectField {...props}  />
				</div>
		)
	}
	customJsonClone(field) {
		const { formValues } = this.state

		const t = this.props.t
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}

		return (
			<div>
				<CustomJsonClone field={field} handleCustomJsonClone={this.handleCustomJsonClone.bind(this)} props={this.props} formValues={formValues} />
			</div>
		)
	}

	customJsonList(field) {
		const { formValues } = this.state

		const t = this.props.t
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!!value && typeof value !== 'string') {
			value = JSON.stringify(value, null, 2)
		}

		return (
			<div>
				<CustomJsonList field={field} handleCustomJsonList={this.handleCustomJsonList.bind(this)} props={this.props} formValues={formValues} />
			</div>
		)
	}

	render() {
		const { field, fieldType } = this.state
		// const { formValues} = this.props
		switch (fieldType) {
			case 'select':
				return this.selectField(field, { props: this.props })
			case 'multiSelect':
				return this.multiSelect(field, { props: this.props })
			case 'json':
				return this.jsonField(field)
			// case 'jsonObj':
			//      return this.jsonDict(field)
			case 'jsonObj':
				return this.jsonObj(field)
			case 'jsonList':
				return this.jsonList(field)
			case 'jsonDict':
				return this.jsonDict(field)
			case 'textEditor':
				return this.textEditor(field, { props: this.props }, this.props.i18n)
			case 'component':
				return this.fieldComponent(field)
			case 'jsonTree':
			case 'jsonObjFixed':
				return this.jsonObjFixed(field)
			case 'jsonText':
				return this.jsonText(field)
			case 'customJsonClone':
				return this.customJsonClone(field)
			case 'customJsonList':
				return this.customJsonList(field)
			default:
				return this.textField(field, { props: this.props })
		}
	}
}
export default withTranslation()(Field)
