import { connect } from 'react-redux';
import {  reviewchatActions, agentListActions } from '..'
import { bindActionCreators } from 'redux'
import ReviewChatComponent from '../components'
const mapStateToProps = (state) => {
    const { identity, alert, globalSetting, reviewChat,reviewChat:{loading}, tenant, AgentList } = state;
    const { profile } = identity    
    return {
        identity,
        profile,
        alert,
        globalSetting,
        loading,
        reviewChat:reviewChat.itemData,
        tenant,
        AgentList
    };
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(
        {
          ...reviewchatActions,
          ...agentListActions
        },
        dispatch,
      ),
 })

export default connect(mapStateToProps,mapDispatchToProps)(ReviewChatComponent);


