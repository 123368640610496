import { botConstants } from '..';

export const bot = (state = {}, action) => {
    switch (action.type) {
        case botConstants.GETCONFIG_REQUEST_BOT:
            return {
                ...state,
                loading: true
            }
        case botConstants.GETCONFIG_SUCCESS_BOT:
            return {
                ...state,
                loading: false,
                botData: action.item,

            }
            case botConstants.GETCONFIG_FAILURE_BOT:
            return {
                ...state,
                loading: false
            }


            
        default:
            return state
    }
}