import { Box, Button } from '@chakra-ui/react'
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat'
import memoize from 'memoize-one'
import React from 'react'
const images = {
	restart: 'https://as-cdn.azureedge.net/cdn/restart-white.png',
	chatOption: 'https://as-cdn.azureedge.net/cdn/exp-icon.png',
	liveChatIcon: 'https://as-cdn.azureedge.net/cdn/agent-icon.png',
	minimizeIcon: 'https://as-cdn.azureedge.net/cdn/w-expand-sprit.png',
}

//   <WebChat chatTitle={t('Live Chat')} conversation={this.state.conversation}  directLine={{ conversationId: this.state.agentConversationId, secret: this.state.directlineSecret }}
//   user={{ id: this.state.chatAgentId, name: this.state.agentFullName || this.state.agentName, userName: this.state.agentName, agentFullName: this.state.agentFullName, agentConversationId: this.state.agentConversationId, incommingChatUserId: this.state.userid, agentId: this.state.chatAgentId, agentCheck: "Agent-" + this.state.chatAgentId, chatUserConversationId: this.state.chatUserConversationId, tenantId: tenantId }} bot={{ name: this.state.botName }} />
class WebChat extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			chatTitle: this.props.chatTitle,
			conversation: this.props.conversation,
			secret: this.props.secret,
			hideUploadButton: true,
			user: this.props.user,
			liveChatIcon: images.liveChatIcon,
			hideDisconnect: this.props.hideDisconnect,
			chatData: this.props.chatData,
			allowDisconnect: this.props.allowDisconnect,
			hideSendBox: this.props.hideSendBox,
			allowReassignAgent: this.props.allowReassignAgent || true,
			cannedResponses: this.props.cannedResponses,
			filteredResponses: [],
			showCR: false,
			loadTranscript: this.props.loadTranscript,

			store: createStore({ activities: this.props.conversation && this.hideAdaptiveCardButtons(this.props.conversationMessages) }, ({ dispatch, getState }) => next => action => {
				let { activities } = getState()
				const { t } = this.props
				if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
					// dispatch({
					//     type: 'WEB_CHAT/SEND_EVENT',
					//     payload: {
					//         type: 'event',
					//         value: "agentChatInit",
					//         from: { id: "Agent" },
					//         name: "agentChatInit"
					//     }
					// })
					if (this.props.commandToBotReassign && this.props.reAssignToAgentId) {
						this.state.user.reAssignToAgentId = this.props.reAssignToAgentId
						this.state.user.assignedFromAgent = this.props.assignedFromAgent
						this.state.user.prevAgentUsername = this.props.prevAgentUsername
						dispatch({
							type: 'WEB_CHAT/SEND_MESSAGE',
							payload: {
								text: 'connect',
								channelData: this.state.user,
								from: { name: this.state.user.name, role: 'agent', id: this.state.user.id, reassignStatus: 'OneTOAnother' },
							},
						})
					} else {
						!this.props.agentConversationId &&
							dispatch({
								type: 'WEB_CHAT/SEND_MESSAGE',
								payload: {
									text: 'connect',
									channelData: this.state.user,
									from: { name: this.state.user.name, role: 'agent', id: this.state.user.id },
								},
							})
					}
				}
				if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
					console.log(this.props, 'propsprops')
					if (action.payload && action.payload.activity && action.payload.activity.text) {
						const { t, globalSetting } = props
						console.log(globalSetting, 'globalSetting')
						const {
							text,
							from: { role },
						} = action.payload.activity
						if ((text.indexOf(t('You are connected to')) != -1 || text.indexOf('Merci de fournir les détails.') != -1) && role === 'bot') {
							let agentAttachment = (globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat && globalSetting.uiFeature.liveChat.agentAttachIcon) || false
							console.log(agentAttachment, 'agentAttachment')
							this.setState({ hideDisconnect: false, hideUploadButton: agentAttachment })
						}
						if (
							this.state.hideUploadButton &&
							this.state.loadTranscript &&
							!(globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat && globalSetting.uiFeature.liveChat.agentAttachIcon) &&
							(!this.state.hideDisconnect || this.props.agentConversationId)
						) {
							this.setState({ hideUploadButton: false })
						}
					}
					this.hideAdaptiveCardButtons(activities)
				}
				if (action.type === 'WEB_CHAT/SET_SEND_BOX') {
					const { showCR, text } = this.state

					console.log('Set Send box called')
					if (!showCR) {
						this.setState(() => ({
							text: action.payload.text,
						}))
					} else {
						let value = action.payload.text
						let responsesFiltered = []
						let check = value && value.substring(value.lastIndexOf('#'))
						const tempValue = value && value.substring(value.lastIndexOf('#') + 1)
						if (check.indexOf('#') < 0) {
							this.setState(() => ({
								showCR: false,
							}))
						}
						//  if (this.state.typingTimeout) {
						//      clearTimeout(this.state.typingTimeout);
						//   }
						//   if(tempValue.length===0){
						//       this.setState({
						//          typingTimeout: setTimeout(()=>this.clearModal(), 10000)
						//       })
						//  }
						if (tempValue.length >= 0 && check.indexOf('#') >= 0) {
							const regex = new RegExp(`^${tempValue}`, 'i')
							responsesFiltered = this.state.cannedResponses.sort().filter(v => regex.test(v))
							if (tempValue.length > 0 && responsesFiltered.length === 0) {
								//setTimeout(()=>this.clearModal(), 3000)
								this.clearModal()
							}
							//setTimeout(()=>this.clearModal(), 10000)
						}
						this.setState(() => ({
							filteredResponses: responsesFiltered,
							text: value,
						}))
					}
				}
				return next(action)
			}),
		}
		this.createDirectLine = memoize((token, agentConversationId) => createDirectLine({ secret: token, conversationId: agentConversationId, user: this.props.user }))
		this.hideAdaptiveCardButtons = this.hideAdaptiveCardButtons.bind(this)
	}

	handleCR = event => {
		console.log('Event triggered')
		if (event.key === '#') {
			this.setState(() => ({
				showCR: true,
			}))
		}
	}

	renderFilteredResponses() {
		const { filteredResponses } = this.state
		console.log(filteredResponses, 'filteredResponses')
		if (filteredResponses.length === 0) {
			return <></>
		}
		if (filteredResponses.length === 1) {
			return (
				<div>
					<ul className="ulStyle">
						{filteredResponses.map(res => (
							<li key={res} className="cursor">
								{res}
							</li>
						))}
					</ul>
					{/* {setTimeout(()=>this.responseSelected(filteredResponses[0]), 3000)} */}
					{this.randomSingleResponse(filteredResponses[0], 1000)}
				</div>
			)
		}
		return (
			<div>
				<ul>
					{/* {filteredResponses.map((res,idx)=><li key={res} id={'renderModal'+idx} onClick={this.re('renderModal'+idx,res)}>{res}</li>)} */}
					{filteredResponses.map(res => (
						<li key={res} onClick={() => this.responseSelected(res)}>
							{res}
						</li>
					))}
				</ul>
			</div>
		)
	}

	clearModal() {
		this.setState(() => ({
			showCR: false,
		}))
	}

	randomSingleResponse(response, timeout) {
		setTimeout(() => this.responseSelected(response), timeout)
	}

	responseSelected(value) {
		const { store, text } = this.state
		const tempValue = text && text.substring(0, text.lastIndexOf('#'))
		console.log(tempValue + value, 'tempValue+value')
		this.setState(() => ({
			text: tempValue + value,
			filteredResponses: [],
			showCR: false,
		}))
		store.dispatch({
			type: 'WEB_CHAT/SET_SEND_BOX',
			payload: {
				text: (document.getElementsByTagName('input')[1].value = tempValue + value),
			},
		})
		//document.getElementsByTagName("input")[document.getElementsByTagName("input").length-1].focus();
		document.querySelector('[aria-label="Sendbox"]').focus()
	}

	handleDisconnect() {
		const { t } = this.props
		const { store, hideDisconnect, agentConversationId } = this.state
		//if (hideDisconnect) return true;
		if (store) {
			store.dispatch({
				type: 'WEB_CHAT/SEND_MESSAGE',
				payload: {
					text: t('Disconnect'),
					channelData: this.state.user,
				},
			})
		}
		this.setState({
			hideDisconnect: true,
			hideUploadButton: true,
		})
	}

	componentDidMount() {
		if (this.props.agentConversationId) {
			this.setState({ hideDisconnect: false, hideUploadButton: false })
		}
	}

	componentWillReceiveProps(props) {
		this.setState({ cannedResponses: props.cannedResponses })
	}

	hideAdaptiveCardButtons(activities) {
		console.log(this.props.conversation, 'activitiestocheck')
		activities =
			activities &&
			activities.map((log, i) => {
				if (activities.length - 1 == i) return log
				if (log.attachments) {
					log.attachments =
						log.attachments &&
						log.attachments.map(att => {
							if (att.content && att.content.buttons) {
								delete att.content.buttons
								return att
							} else if (att.content && att.content.actions) {
								att.content.actions = att.content.actions.filter(action => action.type !== 'Action.Submit')
								return att
							} else return att
						})
				}
				return log
			})
		return activities
	}

	render() {
		const activityMiddleware = () => next => card => {
			const {
				activity: { text, attachments, name, type, from, timestamp, channelData },
			} = card
			const { t } = this.props
			if (type === 'message' && (text || attachments)) {
				if (attachments && attachments[0] && attachments[0].content && attachments[0].contentType == 'text/html') card.activity.attachments = []
				if (attachments && attachments.length > 0) {
					card.activity.attachments.filter(function (e, idx) { 
						if (e.contentType === 'text/html') card.activity.attachments = card.activity.attachments.splice(e, 1)
						if (e.contentType.includes("application/vnd.microsoft.teams.file.download.info")) {
							if (card.activity.attachments.length) card.activity.attachments = []
							card.activity.attachments.splice(card.activity.attachments.indexOf(e), 1)
							card.activity.attachments.push({
								name: e.name || 'image/*',
								contentUrl:e?.content?.downloadUrl.replace('&ApiVersion=2.0', '') || '',
								contentType: "text/html"
							})
						}

					})
				}
				let botNameWithTime =
					(from.name && from.sent === 'agent') || (from.role === 'user' && channelData)
						? from.name || t('You')
						: from.name && from.role === 'user'
						? t('You')
						: `${from.name === 'Anonymous user' || from.name === 'You' ? t('User') : from.name} ${t(`at`)} ${timestamp && new Date(timestamp).toLocaleTimeString()}`
				//return next(card)
				return children => {
					return (
						<div className={from.sent === 'agent' || (from.role === 'user' && channelData) ? 'from-agent-message' : from.sent === 'bot' ? 'from-bot-message' : 'from-user-message'}>
							{next(card)(children)}
							<span className={from.role === 'bot' ? 'from-bot-botname' : 'from-user-botname'}>{botNameWithTime}</span>
						</div>
					)
				}
			} else return next(card)
		}
		const { token, userId, botAvatar, username, botName, userAvatar, agentConversationId, incrementer, agentchat } = this.props
		const styleOptions = {
			botAvatarImage: botAvatar,
			avatarSize: 35,
			// botAvatarInitials: botName || '',
			userAvatarImage: userAvatar || '',
			// userAvatarInitials: username || 'User',
			hideUploadButton: this.state.hideUploadButton,
			bubbleBackground: '#FFF',
			hideSendBox: this.state.hideSendBox,
			backgroundColor: '#f6f5f9',
		}
		const { hideDisconnect, showCR } = this.state
		const { t } = this.props
		return (
			<div className={`chat-container`}>
				<div className="chat-header">
					<span className="bot-name pull-left">
						{this.props.userFullName && this.props.userFullName != 'Anonymous user' ? this.props.userFullName : t('Chat session') + `#${incrementer}`}
					</span>

					<span className="header-icons pull-right">
						{!this.state.loadTranscript && (
							<Box
								as="span"
								position="absolute"
								left="0"
								right="0"
								top="50px"
								bg="rgba(255,255,255, .4)"
								backdropFilter="blur(2px)"
								d="inline-block"
								p="10px"
								zIndex="9"
								textAlign="center">
								{' '}
								<Button bg="#1abc9c" color="#fff" border="1px" borderColor="#1abc9c" onClick={this.props.handleLoadTranscript}>
									{' '}
									{t('Load Transcript')}{' '}
								</Button>
							</Box>
						)}
						{this.state.allowDisconnect && (agentConversationId || !hideDisconnect) && (
							<span className="restart-icon live-chat" onClick={this.handleDisconnect.bind(this)}>
								<img alt="Disconnect" src={this.state.liveChatIcon}></img>
								<span className="notify connect">
									<span className="point"></span>
									<span className="beats"></span>
								</span>
								{/* {(!agentConversationId && hideDisconnect) && <span className="notify disconnect">
                                    <span className="point">
                                    </span>
                                </span>
                                } */}
							</span>
						)}
					</span>
				</div>
				<div onKeyPress={this.handleCR.bind(this)}>
					{token && (
						<ReactWebChat
							directLine={this.createDirectLine(token, agentConversationId)}
							userID={userId}
							username={username}
							styleOptions={styleOptions}
							store={this.state.store}
							disabled={false}
							groupTimestamp={false}
							activityMiddleware={activityMiddleware}
							// user={this.state.user}
						/>
					)}
				</div>
				{showCR && <div className="renderResponse">{this.renderFilteredResponses()}</div>}
			</div>
		)
	}
}
export default WebChat
