import { tenantConstants, tenantServices } from '..'
import { globalTenantServiceUrl, realmName, registerSubDomain, rootDomains, session, singleTenant } from '../../../config'
import { alertActions, identityServices } from '../../common'

const getTenantRequest = () => ({
	type: tenantConstants.GET_TENANT_REQUEST,
})

const getTenantSuccess = tenant => ({
	type: tenantConstants.GET_TENANT_SUCCESS,
	payload: {
		...tenant,
	},
})

const getTenantFailure = error => ({
	type: tenantConstants.GET_TENANT_FAILURE,
	payload: error,
})

export const selectTenant = t => {
	return async dispatch => {
		try {
			let tenant
			const hostname = window.location.hostname
			let hostDomain = hostname.substring(hostname.indexOf('.') + 1)
			let registerHost = rootDomains.split(',').includes(hostDomain) && hostname.substring(0, hostname.indexOf('.')) === registerSubDomain
			let result
			if (!registerHost) {
				dispatch(getTenantRequest())
				result = await tenantServices.getTenantService(hostname)
			}
			let data = (result && result.data) || result
			if (!data || Object.keys(data).length === 0) {
				if (rootDomains.split(',').includes(hostDomain) && hostname.substring(0, hostname.indexOf('.')) === registerSubDomain) {
					tenant = {
						tenantId: 'default',
						url: hostname,
						isTenant: false,
						landingPage: '/register',
						rootDomain: hostDomain,
					}
					dispatch(getTenantSuccess(tenant))
				} else if (singleTenant && realmName) {
					tenant = {
						tenantId: realmName,
						id: process.env.REACT_APP_DEFAULTTENANTID || 'none',
						url: realmName,
						isTenant: true,
						rootDomain: hostDomain,
						landingPage: '/',
						apiUrl: globalTenantServiceUrl,
					}
					sessionStorage.setItem('apiUrl', tenant.apiUrl)
					dispatch(getTenantSuccess(tenant))
				} else {
					let error =
						(t && t('Current domain does not seem to match the expected domain , please contact your administrator')) ||
						'Current domain does not seem to match the expected domain , please contact your administrator'
					dispatch(getTenantFailure(error))
					dispatch(alertActions.error(error))
				}
			} else if ((data.whitelisted && data.whitelisted.includes(hostname)) || data.domain === hostname) {
				tenant = {
					tenantId: data.tenantId,
					whitelisted: data.whitelisted,
					domain: data.domain,
					idpUrl: data.federationServerUrl,
					cdnUri: data.cdnUri,
					...data,
					apiUrl: data.apiUrl + data.basePath,
					isTenant: true,
				}
				identityServices.identityApi.interceptors.request.use(config => {
					config.headers.common['x-tenantId'] = tenant.id
					return config
				})
				localStorage.setItem('apiUrl', data.apiUrl + (data.basePath || ''))
				sessionStorage.setItem('apiUrl', tenant.apiUrl)
				localStorage.setItem('cdnUri', data.cdnUri)
				sessionStorage.setItem('cdnUri', tenant.cdnUri)
				session && session.set('apiUrl', tenant.apiUrl)
				session && session.set('cdnUri', tenant.cdnUri)
				dispatch(getTenantSuccess(tenant))
			} else {
				// should not come here
				console.log(`Error: not matching domain ${data && data.domain}, ${hostname}`)
			}
		} catch (error) {
			dispatch(getTenantFailure(error))
			dispatch(alertActions.error(error))
		}
	}
}
