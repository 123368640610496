export const RECORD_LIMIT = '4';

export const ERROR_MESSAGE = 'Oops! Something went wrong. Please try again later.';

export const GETALL_REQUEST_REVIEWCHAT = 'GETALL_REQUEST_REVIEWCHAT';
export const GETALL_SUCCESS_REVIEWCHAT= 'GETALL_SUCCESS_REVIEWCHAT';
export const GETALL_FAILURE_REVIEWCHAT= 'GETALL_FAILURE_REVIEWCHAT';

export const GET_REQUEST_REVIEWCHAT =  'GET_REQUEST_REVIEWCHAT';
export const GET_SUCCESS_REVIEWCHAT =  'GET_SUCCESS_REVIEWCHAT';
export const GET_FAILURE_REVIEWCHAT =  'GET_FAILURE_REVIEWCHAT';   

export const DOWNLOAD_REQUEST_REVIEWCHAT = 'DOWNLOAD_REQUEST_REVIEWCHAT';
export const DOWNLOAD_SUCCESS_REVIEWCHAT = 'DOWNLOAD_SUCCESS_REVIEWCHAT';
export const DOWNLOAD_FAILURE_REVIEWCHAT =  'DOWNLOAD_FAILURE_REVIEWCHAT';

export const FLAG_REQUEST_REVIEWCHAT =  'FLAG_REQUEST_REVIEWCHAT';
export const FLAG_SUCCESS_REVIEWCHAT = 'FLAG_SUCCESS_REVIEWCHAT';
export const FLAG_FAILURE_REVIEWCHAT =  'FLAG_FAILURE_REVIEWCHAT';
