import { Icon, Select } from '@chakra-ui/react'
import * as AdaptiveCards from 'adaptivecards'
import $ from 'jquery'
import moment from 'moment'
import React, { Component } from 'react'
import Toggle from 'react-bootstrap-toggle'
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css'
// import Switch from "react-switch";
// Locale
import { withTranslation } from 'react-i18next'
import { FaBell, FaBellSlash, FaVolumeMute, FaVolumeUp, faCau } from 'react-icons/fa'
import { FiAlertTriangle} from 'react-icons/fi' 
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { Alert, Button, Label, Modal, ModalBody } from 'reactstrap'
import io from 'socket.io-client'
import { activeAgentAction, agentChatAction, agentConstants, incomingUserAction } from '..'
// import 'botframework-webchat/botchat.css'
import { session } from '../../../config'
// import ChatTranscript from './ChatTranscript';
import WebChat from '../../agent-webchat/WebChat'
// import UserInformation from './userInformation'
import { botActions } from '../../base-ui'
import { alertActions, identityServices } from '../../common'
import PushNotification from '../../push-notification/pushNotification'
import ActiveAgents from './ActiveAgents'
import IncomingUsers from './IncomingUsers'


//Locale
var adaptiveCard = new AdaptiveCards.AdaptiveCard()
let  pushNotificationInst
// import Accordion from 'react-responsive-accordion';
// import Collapsible from 'react-collapsible';
let isBroadcastSupport = window.BroadcastChannel
class AgentChat extends Component {
	constructor(props) {
		super(props)
		var { user, tenantId, profile, t, tenant } = props
		props.dispatch(botActions.getBotConfigsAgentChat(tenantId, t, tenant && tenant.apiUrl))
		props.dispatch(activeAgentAction.getAgentAvailability(profile.userId, tenantId))
		props.dispatch(activeAgentAction.registerAllSocket(profile.preferred_username))
		this.changeStatus = this.changeStatus.bind(this)
		this.handleDisconnectAgentData = this.handleDisconnectAgentData.bind(this)
		this.success = this.success.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.toggleAlertModal = this.toggleAlertModal.bind(this)
		this.handleTranscript = this.handleTranscript.bind(this)
		this.handleAgentData = this.handleAgentData.bind(this)
		this.handleReassignReq = this.handleReassignReq.bind(this)
		this.handleLoadTranscript = this.handleLoadTranscript.bind(this)
		//this.handlerParent = identityServices.handlerParent.bind(this)
		//window.addEventListener("message", identityServices.handlerParent)

		// this.chatTranscriptsHistory=this.chatTranscriptsHistory.bind(this);
		// let agentData = JSON.parse(localStorage.getItem('userData'))

		// if (agentData.is_available == undefined) {
		//   agentData.is_available = 0
		//   localStorage.setItem('userData', JSON.stringify(agentData))
		// }
		let agentData = profile
		this.state = {
			dropdownOpen: false,
			// agentAvailability: agentData && agentData.is_available ? agentData.is_available : 0,
			agentAvailability: 0,
			agentData: agentData,
			toggleActive: agentData.is_available == 1 ? true : false,
			transcripts: {},
			userid: 0,
			isChatNow: false,
			showHistory: true,
			chatUserConversationId: '',
			agentConversationId: '',
			chatAgentId: 0,
			conversation: {},
			user: {},
			botConnection: {},
			modal: false,
			nonAvailabilityModal: false,
			nonAvailabilityReason: null,
			informationAlert: '',
			alertModal: false,
			incrementer: 0,
			userFullName: null,
			//agentName: 'sample'
			agentName: agentData.preferred_username != '' ? agentData.preferred_username : 'Agent - ' + agentData.userId,
			agentFullName: agentData.name ? agentData.name : agentData.preferred_username ? agentData.preferred_username : 'Agent - ' + agentData.userId,
			directlineSecret: '',
			botName: 'Emma',
			currentActiveChatUser: null,
			allowDisconnect: true,
			cannedResponses: [],
			customerProfile: [],
			tempResponses: [],
			isTeams: false,
			wsInit: false,
			isAudio: true,
			isNotification: true,
			loadTranscript: false,
			sessionid: null,
			startTime: null,
			currentAgentChatData: null,
			hideDisconnect: true,
			nonAvailabilityReasonCustom: null,
			agentchat: null,
		}
		//@todo currenly getactiveagnets api fetching all records
		//@todo we need to add remove user to reducer of active agents
		//if(!tenantId) tenantId = this.state.tenantId
	}

	handleReassignReq(reassign) {
		console.log('~~~~~~~~~++REASSIGN_TO_AGENT++~~~~~~~~~:: ', JSON.stringify(reassign))
		try {
			const { t } = this.props
			const { agentchat, profile } = this.props
			let prevAgent = null

			console.log('~~~~~~~++ supervisor::handleReassignReq ++ ~~~~~~~~~~~~', reassign)
			if (!reassign) return true

			if (
				reassign &&
				reassign.data &&
				reassign.data.assignedFrom &&
				agentchat.agentData &&
				agentchat.agentData[reassign.data.assignedFrom] &&
				profile.userId !== reassign.data.activeAgentId
			) {
				var index = agentchat.agentData[reassign.data.assignedFrom].findIndex(e => e.userIncomingChatRequestId == reassign.data.id)
				if (index != -1) {
					agentchat.agentData[reassign.data.assignedFrom].splice(index, 1)
				}
				this.setState({
					showHistory: true,
					isChatNow: false,
					transcripts: {},
					userid: 0,
					userFullName: null,
				})
				this.props.dispatch(alertActions.success(t('Reassigned a chat to another agent.')))
			}

			if (
				reassign &&
				reassign.data &&
				reassign.data.assignedFrom &&
				agentchat.agentData &&
				agentchat.agentData[reassign.data.activeAgentId] &&
				profile.userId !== reassign.data.assignedFrom
			) {
				let curAgntIndex = agentchat.agentData[reassign.data.activeAgentId].findIndex(e => e.userIncomingChatRequestId == reassign.data.id)
				if (curAgntIndex == -1) {
					reassign.data.id = reassign.data.activeAgentId
					reassign.data.agentId = reassign.data.activeAgentId
					agentchat.agentData[reassign.data.activeAgentId].push(reassign.data)
				}
				this.props.dispatch(alertActions.success(t('You have been assigned a chat by the supervisor.')))
			}

			this.props.dispatch(this.success(agentchat))
		} catch (err) {
			console.error('handleReassignReq::error:: ', err)
		}
	}
	// componentWillReceiveProps(props) {
	// 	if (!this.state.agentchat && Object.keys(props.agentchat?.agentData).length) this.setState({ ...this.state, agentchat: props.agentchat })
	// }

	handleAgentData(data) {
		try {
			console.log('~+~+~+~+~connecting agent to User+~+~+~+~+~', data)
			if (!data) return true
			const { agentchat } = this.props
			// @ts-ignore
			if (agentchat?.agentData && agentchat?.agentData[data.activeAgentId]) {
				var currentAgent =
					agentchat?.agentData &&
					agentchat.agentData[data.activeAgentId].map(c => {
						if (c.userIncomingChatRequestId == data.userIncomingChatRequestId) {
							c.agentBotConversationId = data.agentBotConversationId
							c.queueName = data.queueName
							// c.userFullName = data.userFullName ? data.userFullName : c.userFullName
							// this.setState({ userFullName: data.userFullName  })
						}
						return c
					})
				// @ts-ignore
				agentchat.agentData[data.activeAgentId] = currentAgent
				this.props.dispatch(this.success(agentchat))
			}
		} catch (err) {
			console.error('handleAgentData::', err)
		}
	}

	// componentWillUnmount() {
	// 	// console.log("\n\n\n\n\n::: Unmount calles", this.socket)
	// 	// this.socket.disconnect()
	// 	// this.socket = null
	// 	// @todo search for how to disconnect on unmount
	// }

	initSocket(agentQueueList) {
		let { identity, tenantId, t, globalSetting } = this.props
		let maxUserPerAgent =
			(globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat && globalSetting.uiFeature.liveChat.maxUserPerAgent) || 5
		let userId =
			(identity && identity.profile && identity.profile.userId) ||
			(identity && identity.profile && identity.profile.sub) ||
			(identity && identity.profile && identity.profile.email) ||
			null
		const that = this
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let socketUrl = apiUrl && apiUrl.includes('://') ? 'https://' + apiUrl.split('/')[2] : 'https:// ' + apiUrl.split('/')[0]
		if (!sessionStorage.getItem('tenantId')) sessionStorage.setItem('tenantId', tenantId)
		if (!this.socket) {
			this.socket = []
			if (agentQueueList && agentQueueList.length > 0) {
				for (let i = 0; i < agentQueueList.length; i++) {
					this.socket[agentQueueList[i].queueName] = io(tenantId ? socketUrl + '/' + tenantId + '/' + agentQueueList[i].queueName : socketUrl, {
						path: '/api/v1/ws/socket.io',
					})
					this.socket[agentQueueList[i].queueName].on('REMOVE_INCOMING_USER', function (data) {
						that.props.dispatch(incomingUserAction.removeIncommingUser(data))
						that.props.dispatch(activeAgentAction.getActiveAgents(userId))
						that.handleTranscript(data)
					})
					this.socket[agentQueueList[i].queueName].on('DISCONNECT_USER', this.handleDisconnectAgentData)
					this.socket[agentQueueList[i].queueName].on('INCOMING_USER', function (data) {
						console.log('~+~+~+~+~incomming queue User+~+~+~+~+~')
						let {
							agentchat: { agentData },
						} = that.props
						if (userId && agentData && agentData[userId] && agentData[userId].length > maxUserPerAgent) {
							console.log('there are users waiting please hurry up an close chats....')
						} else {
							that.props.dispatch(incomingUserAction.setIncomingUserSocket(data))
							that._browserNotification()
							toast.warn(`${t('Incoming chat request')}`)
						}
					})
					this.socket[agentQueueList[i].queueName].on('CONNECT_AGENT_TO_USER', that.handleAgentData)
					let sock = this.socket[agentQueueList[i].queueName]
					this.socket[agentQueueList[i].queueName].on('disconnect', function (reason) {
						console.log(`Socket disconnect event: ${reason}`)
						if (reason === 'io server disconnect') {
							// the disconnection was initiated by the server, you need to reconnect manually
							sock && sock.connect()
							if (!sock) console.log(`socket not available in disconnect event.`)
						}
						window.location.reload()
						// console.log(`queue disconnect event `, args)
						// this.socket[agentQueueList[i].queueName] = io(tenantId ? socketUrl + '/' + tenantId + '/' + agentQueueList[i].queueName : socketUrl, {
						// 	path: '/api/v1/ws/socket.io',
						// })
					})

					sock.on('connect_error', err => {
						console.log(`connection error ${err}`)
						// setTimeout(() => {
						// 	sock.connect()
						// }, 3000)
					})
				}
			}
			this.socket['Main'] = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
			this.socket['Main'].on('REMOVE_INCOMING_USER', function (data) {
				that.props.dispatch(incomingUserAction.removeIncommingUser(data))
				that.props.dispatch(activeAgentAction.getActiveAgents(userId))
				that.handleTranscript(data)
			})
			this.socket['Main'].on('INCOMING_USER', function (data) {
				console.log('~+~+~+~+~incomming User+~+~+~+~+~')

				let {
					agentchat: { agentData },
				} = that.props
				if (userId && agentData && agentData[userId] && agentData[userId].length > maxUserPerAgent) {
					console.log('there are users waiting please hurry up an close chats....')
				} else {
					that.props.dispatch(incomingUserAction.setIncomingUserSocket(data))
					that._browserNotification()
					toast.warn(`${t('Incoming chat request')}`)
				}
			})
			this.socket['Main'].on('CONNECT_AGENT_TO_USER', that.handleAgentData)
			this.socket['Main'].on('DISCONNECT_USER', this.handleDisconnectAgentData)
			this.socket['Main'].on('REASSIGN_TO_AGENT', this.handleReassignReq)
			let sock = this.socket['Main']
			this.socket['Main'].on('disconnect', function (reason) {
				// console.log(`main disconnect even`, args)
				that.props.dispatch(alertActions.error(t('Lost connection to the server, reloading the page')))

				console.log(`Socket disconnect event: ${reason}`)
				if (reason === 'io server disconnect') {
					// the disconnection was initiated by the server, you need to reconnect manually
					sock && sock.connect()
					if (!sock) console.log(`socket not available in disconnect event.`)
				}
				window.location.reload()
				// if (!tenantId) tenantId = sessionStorage.getItem('tenantId')
				// let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
				// if (!socketUrl) socketUrl = apiUrl && apiUrl.includes('://') ? 'https://' + apiUrl.split('/')[2] : 'https:// ' + apiUrl
				// if (socketUrl) that.socket['Main'] = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
			})

			sock.on('connect_error', err => {
				console.log(`connection error ${err}`)
				// setTimeout(() => {
				// 	sock.connect()
				// }, 3000)
			})
		}
	}

	handleTranscript(data) {
		try {
			// console.log("This is data and transcript",this.state.transcripts,data);
			let customerSessionId = data && data.customerSessionId ? data.customerSessionId : data && data.customer_session_id ? data.customer_session_id : null
			if (
				this.state.transcripts &&
				this.state.transcripts.data &&
				this.state.transcripts.data.length > 0 &&
				customerSessionId &&
				customerSessionId === this.state.transcripts.data[0].sessionId
			) {
				let { t } = this.props
				this.setState({
					transcripts: {},
					userid: null,
				})
				$('.chat-head h4').html(t('Chat Session'))
			}
		} catch (err) {
			console.error('handleTranscript:: ', err)
		}
	}

	confirmRecord() {
		const { agentchat, globalSetting, profile, t } = this.props
		let liveChat = globalSetting?.uiFeature?.liveChat
		if (agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && agentchat.agentData[profile.userId].length > 1) {
			this.props.dispatch(
				alertActions.error(
					`${t('You have')} ${agentchat.agentData[profile.userId].length - 1} ${t('active chats going on please disconnect em and try again')}.`
				)
			)
			return false
		}
		if (!agentchat?.agentQueueList.length && liveChat.agent_queue === 1) {
			this.props.dispatch(
				alertActions.error('You are currently not part any queue please ask your supervisor to add you to any queue.')
			)
			return false
		}
		this.setState({
			modal: !this.state.modal,
		})
		if (this.state.modal === false) {
			this.availabilityReason()
		}
	}

	toggleModal() {
		this.setState({
			modal: !this.state.modal,
			nonAvailabilityModal: false,
			nonAvailabilityReason: null,
		})
	}

	async availabilityReason() {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/UnavailabilityReason/findAndCountWithAgent`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status == 200 && response.data) {
				this.setState(() => ({ nonAvailabilityReasonCustom: response.data.rows || response.data }))
			}
		})
	}
	toggleAlertModal() {
		this.setState({
			alertModal: !this.state.alertModal,
			informationAlert: '',
		})
	}
	handlerParent(event) {
		let data = event.data

		console.log('Hello World?', data, event)
	}

	componentDidMount() {
		const { t } = this.props
		if(!pushNotificationInst?.checkPermission() && t){
			toast.warn(`${t?t('Please allow browser notification to get the notification'):"Please allow browser notification to get the notification."}`)
		}
		let { identity, tenantId, globalSetting, 	agentchat: { agentData }, } = this.props
		let maxUserPerAgent =
		(globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat && globalSetting.uiFeature.liveChat.maxUserPerAgent) || 5
	let userId =
		(identity && identity.profile && identity.profile.userId) ||
		(identity && identity.profile && identity.profile.sub) ||
		(identity && identity.profile && identity.profile.email) ||
		null
			const channel = new BroadcastChannel('sw-messages');
			channel.addEventListener('message', event => {
				let pushData = event.data
				console.log("Push Data availabel:::", pushData)
				switch (pushData.pushType) {
					case "INCOMING_USER":
						if (userId && agentData && agentData[userId] && agentData[userId].length > maxUserPerAgent) {
						} else {
							that.props.dispatch(incomingUserAction.setIncomingUserSocket(pushData.data))
							toast.warn(`${t('Incoming chat request')}`)
						}
						break;
					case "DISCONNECT_USER":
						that.handleDisconnectAgentData(pushData.data)
						break;
					case "REMOVE_INCOMING_USER"	:
						that.props.dispatch(incomingUserAction.removeIncommingUser(pushData.data))
						that.props.dispatch(activeAgentAction.getActiveAgents(userId))
						that.handleTranscript(pushData.data)
						break;
					case "CONNECT_AGENT_TO_USER":
						that.handleAgentData(pushData.data)
						// that.props.dispatch(incomingUserAction.removeIncommingUser(pushData.data))
						// that.props.dispatch(activeAgentAction.getActiveAgents(userId))
						break;
					case "REASSIGN_TO_AGENT":
						that.handleReassignReq(pushData?.data)
						break;
					default:
						break;
				}
			});
		let {
			identity: { profile },
			agentchat,
		} = this.props
		let queryParams = this.getQueryParameters()
		console.log('queryParams', queryParams, this.props, localStorage)
		if (queryParams.isTeams == true || queryParams.isTeams == 'true') this.setState({ isTeams: true })
		if (
			(queryParams.isTeams == true || queryParams.isTeams == 'true') &&
			queryParams.sessionId &&
			queryParams.sessionId !== 'null' &&
			agentchat &&
			agentchat.incomingUserData &&
			agentchat.incomingUserData.data &&
			agentchat.incomingUserData.data.length > 0
		) {
			this.chatTranscriptsHistory(null, queryParams.sessionId)
		}
		// var socket = io(socketUrl, { path: '/api/v1/ws/socket.io' });
		let that = this
		// socket.on('disconnect', function () {
		//   // that.setState({
		//   //   alertModal: !that.state.alertModal,
		//   //   informationAlert: 'Lost connection to the server, Please reload the page'
		//   // })
		//   // alert("Server socket is disconnected please reload the page.")
		//   that.props.dispatch(alertActions.error(t('Lost connection to the server, Please reload the page')))
		// });

		this.props.dispatch(agentChatAction.getAgentQueueList(profile.userId, profile.preferred_username))
		let lang = window && window.localStorage && window.localStorage.getItem('i18nextLng')
		this.getCanned(lang && lang.slice(0, 2))
	}

	componentDidUpdate(prevProps) {
		let {
			identity: { profile },
			userRoles,
			tenantConfig,
			workspace,
		} = this.props
		let { wsInit } = this.state

		if (tenantConfig && !tenantConfig.loading && tenantConfig.data && workspace && !workspace.loading && !wsInit) {
			console.log('prevProps::: ', prevProps)
			console.log('Updated Props:: ', this.props)
			this.props.dispatch(agentChatAction.getAgentQueueList(profile.userId, profile.preferred_username))
			this.setState({ wsInit: true })

			//   if(workspace.selectedWs != null && prevProps.workspace.selectedWs !=  workspace.selectedWs) {
			// 	this.props.dispatch(agentChatAction.getAgentQueueList(profile.userId, profile.preferred_username))
			//   }
		}
	}

	getCanned(lang) {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let apiUrlCustom = apiUrl

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/CannedResponses?$filter=lang eq '${lang}' and status eq 'Published'`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			//console.log(response.data[0].properties['text'],"response.data")
			if (response.status == 200 && response.data) {
				let responses = response.data.map(res => {
					return res.properties['text']
				})
				this.setState(() => ({ cannedResponses: responses, tempResponses: responses }))
			}
		})
	}

	getCustomerProfile(customerPrimary) {
		const { tempResponses } = this.state
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let apiUrlCustom = apiUrl

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/customerprofile/findcustomer/${customerPrimary}`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status == 200 && response.data) {
				this.setState(() => ({ customerProfile: response.data }))
				this.updateCannedResponse(tempResponses, this.state.customerProfile)
			}
		})
	}
	updateCannedResponse(response, profile) {
		const updatedResponse = response.map(template => {
			var output = this.templateMe(template, (profile && profile.properties) || profile)
			console.log(output, 'output')
			return output
		})
		this.setState(() => ({ cannedResponses: updatedResponse }))
	}

	templateMe(template, obj) {
		var regex = /{{(.*?)}}/g
		return template.replace(regex, function (match, capture) {
			return obj[capture] || match
		})
	}

	async componentWillMount() {

		// For Push Notification:
		const { profile, identity:{isAuthenticated} } = this.props
		if(!pushNotificationInst)
		 pushNotificationInst = new PushNotification({userInfo:{tenantUid:profile?.tenantUid , userId: profile?.sub }})
		if(pushNotificationInst && isAuthenticated)
		 pushNotificationInst.allowPermission()
		let that = this
		this.props.dispatch(incomingUserAction.getIncomingUsers())
		if (!('Notification' in window)) {
			that.setState({
				alertModal: !that.state.alertModal,
				informationAlert: 'This browser does not support agent notification.',
			})
			// alert("This browser does not support agent notification");
		} else if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
			//console.log("Notification.permission",Notification.permission)
			Notification.requestPermission().then(function (result) {
				// If the user accepts, let's create a notification
				// if (permission === "granted") {
				//   //var notification = new Notification("Hi there!");
				// }
			})
		}
		let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
		//@ts-ignore
		link.type = 'image/x-icon'
		//@ts-ignore
		link.rel = 'shortcut icon'
		//@ts-ignore
		link.href = process.env.PUBLIC_URL + '/favicon.ico'
		document.getElementsByTagName('head')[0].appendChild(link)
	}
	handleDisconnectAgentData(data) {
		try {
			console.log('~+~+~+~+~disconnect user+~+~+~+~+~', data)
			const { t } = this.props
			if (!data) return true
			const { agentchat, profile } = this.props
			let agentId = data.activeAgentId || data.active_agent_id //data.agent_id? data.agent_id.split('-')[1]:0
			var index = agentchat.agentData[agentId].findIndex(e => e.userIncomingChatRequestId == data.id)
			if (data.userTimeout) {
				index = agentchat.agentData[agentId].findIndex(e => e.userIncomingChatRequestId == data.userIncomingChatRequestId)
			}
			agentchat.agentData[agentId].splice(index, 1)
			this.props.dispatch(this.success(agentchat))
			if (data.activeAgentId == profile.userId || data.active_agent_id == profile.userId) {
				let smsg =
					(data.userFullName && data.userFullName != 'Anonymous user' ? data.userFullName : t('Chat Session') + '#' + data.incrementer) +
					t(' has been disconnected')
				if (data.userTimeout) {
					smsg = t('User has been disconnected')
				}
				toast.success(smsg)
			}
			// const activeAgentData = JSON.parse(localStorage.getItem('activeAgentData'))
			if (agentchat && agentchat.agentStatus && agentchat.agentStatus.data && agentchat.agentStatus.data.agentId == agentId) {
				this.setState({
					showHistory: true,
					isChatNow: false,
					transcripts: {},
					userid: 0,
					userFullName: null,
				})
			}
		} catch (err) {
			console.error('handleDisconnectAgentData:: ', err)
		}
	}

	setReason(availability, postBody) {
		const { nonAvailabilityReason } = this.state
		let time = moment().format('YYYY-MM-DDTHH:mm:ss')
		if (!availability || availability == 0) {
			return { metrics: { agentName: postBody.userName, isAvailable: false, reason: nonAvailabilityReason, time } }
		} else {
			this.setState({ nonAvailabilityModal: false })
			return { metrics: { agentName: postBody.userName, isAvailable: true, reason: false, time } }
		}
	}
	addReason(evt) {
		let reason = evt && evt.target && evt.target.value ? evt.target.value : null
		this.setState({ nonAvailabilityReason: reason })
	}
	/**
	 *
	 * @param {*} state
	 * @param {*} node
	 * @param {*} evt
	 * @todo from on to off status check for active chats if user in conversation terminate action with message
	 */
	changeStatus(state, node, evt) {
		// console.log("::::::::", this.state.agentData)
		//if (confirm('Are you sure you want to change Availability?')) {
		const { agentchat, token, profile, tenantId, t } = this.props
		const { nonAvailabilityReason } = this.state

		if (agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && agentchat.agentData[profile.userId].length > 1) {
			this.props.dispatch(
				alertActions.error(
					`${t('You have')} ${agentchat.agentData[profile.userId].length - 1} ${t('active chats going on please disconnect em and try again')}.`
				)
			)
			this.setState({ nonAvailabilityModal: true })
			return false
		}

		// if(agentchat && agentchat.agentStatus && )
		// console.log("agentchat****************", agentchat.isAgentAvailable)
		// return false
		let is_available_now = 0
		// let agentData = JSON.parse(localStorage.getItem('userData'))
		this.setState({ toggleActive: !this.state.toggleActive })
		if (agentchat && agentchat.isAgentAvailable === 'Unavailable') {
			this.setState({ agentAvailability: 1 })
			is_available_now = 1
		} else {
			this.setState({ agentAvailability: 0 })
			is_available_now = 0
		}
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let ep = apiUrl
		let postBody = { agentId: profile.userId, isActive: is_available_now, userName: profile.preferred_username }
		let dim = this.setReason(is_available_now, postBody)
		postBody = Object.assign({}, dim, postBody)
		if (is_available_now == 0 && (!nonAvailabilityReason || nonAvailabilityReason == '')) {
			this.setState({ nonAvailabilityModal: true })
			return false
		} else {
			this.setState({ nonAvailabilityModal: false, nonAvailabilityReason: null, modal: !this.state.modal })
		}
		let headers = {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			'x-tenantid': tenantId,
			usecase: localStorage.getItem('product'),
		}
		if (token.includes('Bearer')) {
			delete headers.Authorization
			headers.msTokenSignedForUser = token
		}
		// console.log('****postBody',{postBody},{is_available_now},{dim})
		fetch(ep + '/active_agents/setAgentAvailability', {
			method: 'POST',
			headers,
			body: JSON.stringify(postBody),
		})
			.then(res => res.json())
			.then(res => {
				if (res && res.error) {
					this.props.dispatch(alertActions.error(t('You have active chats going on please disconnect em and try again')))
					return false
				} else {
					this.props.dispatch(this.success(res))
					PushNotification.unsubscribePush(is_available_now)
				}

				// if (is_available_now == 1) {
				//   var activeAgentData = {};
				//   activeAgentData.id = res.data.id;
				//   activeAgentData.agent_id = res.data.agent_id;
				//   localStorage.setItem('activeAgentData', JSON.stringify(activeAgentData))
				// }
				// else {
				//   localStorage.removeItem('activeAgentData')
				// }
			})

		// this.props.dispatch(agentChatAction.setAgentAvailability(this.state.agentData.userid, is_available_now))
		// agentData.is_available = is_available_now;
		// localStorage.setItem('userData', JSON.stringify(agentData))
		// this.setState({
		//   modal: !this.state.modal
		// });
		//}
		//evt.preventDefault();
	}
	success(agentchat) {
		// console.log("I am calling well",agentchat);
		return { type: agentConstants.AGENT_STATUS_CHANGE_SUCCESS, agentchat }
	}
	successagent(agentchat) {
		return { type: agentConstants.INCOMING_USER_SUCCESS, agentchat }
	}

	chatTranscriptsHistory(event, sessionidFromTeams) {
		let lang = window && window.localStorage && window.localStorage.getItem('i18nextLng')
		this.getCanned(lang && lang.slice(0, 2))
		const { agentchat, profile } = this.props
		console.log('this.props', this.props, sessionidFromTeams)
		let sessionid, startTime, incrementer, user
		if (event) {
			sessionid = event.target.dataset.sessionid
			startTime = event.target.dataset.starttime
			incrementer = event.target.dataset.incrementer
			user = event.target.dataset.user
		} else if (sessionidFromTeams) {
			sessionid = sessionidFromTeams
			let selectedUesr =
				agentchat.incomingUserData &&
				agentchat.incomingUserData.data &&
				agentchat.incomingUserData.data.length > 0 &&
				agentchat.incomingUserData.data.filter(c => {
					if (c.customerSessionId == sessionid) return c
				})
			incrementer = selectedUesr[0].incrementer
			user = selectedUesr[0].id
		}
		var { token, tenantId, t } = this.props
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let ep = apiUrl
		// let HistoryElems = document.querySelectorAll(".active-incoming-history");

		// [].forEach.call(HistoryElems, function(el) {
		//     el.classList.remove("active-incoming-history");
		// });
		// setTimeout(function(){
		//   event.target.parentElement.classList.add('active-incoming-history');
		// }, 2000)

		var currentIncomingUsers = agentchat.incomingUserData.data.map(c => {
			if (c.customerSessionId == sessionid) {
				c.isChatOpen = true
			} else {
				c.isChatOpen = false
			}
			return c
		})

		if (currentIncomingUsers) agentchat.incomingUserData.data = currentIncomingUsers
		this.props.dispatch(this.success(agentchat))
		this.setState({ transcripts: [], sessionid, startTime })
		let headers = {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			'x-tenantid': tenantId,
		}
		if (token.includes('Bearer')) {
			delete headers.Authorization
			headers.msTokenSignedForUser = token
		}
		fetch(ep + '/active_agents/getUserTranscriptWithBot/' + sessionid + ((startTime && `?startTime=${startTime}`) || ''), {
			method: 'GET',
			headers,
		})
			.then(res => res.json())
			.then(res => {
				//alert(1);
				this.setState({
					transcripts: res,
					userid: user,
					isChatNow: false,
					showHistory: true,
					incrementer: incrementer,
				})
				//console.log("This is response\n\n\n\n\n\n",res)
				let userFullName = res && res.data && res.data.length > 0 ? res.data[0].userInfo.userFullName : ''
				this.setState({ userFullName: userFullName })
				$('.uic-top h4').html(userFullName && userFullName != 'Anonymous user' ? userFullName : t('Chat Session') + '#' + incrementer)
				$('.chat-head h4').html(userFullName && userFullName != 'Anonymous user' ? userFullName : t('Chat Session') + '#' + incrementer)
				// event.target.parentElement.classList.add('active-incoming-history');
				let userDetails =
					res &&
					res.data &&
					res.data.filter(message => {
						return message.userInfo && message.userInfo.role === 'user'
					})
				let userInfo = userDetails && userDetails[0] && userDetails[0].userInfo
				//console.log(res.data,userDetails,userInfo,"userInfo")
				if (userInfo && userInfo.userFullName && userInfo.userFullName.toLowerCase().includes('anonymous'))
					this.getUserPrimaryChatSession(ep, token, sessionid, tenantId)
				else {
					let splitStr = userInfo && userInfo.userFullName && userInfo.userFullName.trim().split(' ')
					let firstname = (splitStr && splitStr[0] && splitStr[0]) || '{{firstName}}'
					let lastname = (splitStr && splitStr[1] && splitStr[1]) || '{{lastName}}'
					if (splitStr) {
						userInfo['firstName'] = firstname
						userInfo['lastName'] = lastname
						this.updateCannedResponse(this.state.tempResponses, userInfo)
					} else this.setState(() => ({ cannedResponses: this.state.tempResponses }))
				}
				if (sessionidFromTeams) $('.chat-begin').trigger('click')
			})
	}

	getUserPrimaryChatSession(ep, token, sessionid, tenantId) {
		const { tempResponses } = this.state
		let headers = {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			'x-tenantid': tenantId,
		}
		if (token.includes('Bearer')) {
			delete headers.Authorization
			headers.msTokenSignedForUser = token
		}
		fetch(ep + '/active_agents/getUserPrimaryChatSession/' + sessionid, {
			method: 'GET',
			headers,
		})
			.then(res => res.json())
			.then(res => {
				let customerPrimary = res && res.data && res.data.length > 0 ? res.data[0].user_info.customerProfilePrimary : ''
				if (customerPrimary) this.getCustomerProfile(customerPrimary)
				else this.setState(() => ({ cannedResponses: tempResponses }))
			})
	}

	disconnectUser() {
		const { t } = this.props
		$('#wc-shellinput-input').val(t('Disconnect'))
		$('.wc-send').click()
		$('#wc-shellinput-input').val('')
	}

	chatInitiate(userid) {
		const { agentchat, profile, t, identity, globalSetting } = this.props
		let that = this
		let maxUserPerAgent =
			(globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat && globalSetting.uiFeature.liveChat.maxUserPerAgent) || 5
		let userId =
			(identity && identity.profile && identity.profile.userId) ||
			(identity && identity.profile && identity.profile.sub) ||
			(identity && identity.profile && identity.profile.email) ||
			null

		let {
			agentchat: { agentData },
		} = this.props
		if (userId && agentData && agentData[userId] && agentData[userId].length > maxUserPerAgent) {
			this.setState({
				informationAlert: 'Max user limit reached, Please contact administrator',
				alertModal: !this.state.alertModal,
			})
			return false
		}

		if (!userid) {
			this.setState({
				informationAlert: 'Please select the valid user',
				alertModal: !this.state.alertModal,
			})
			return false
			//return alert('Please select the valid user.');
		}
		// let agentData = JSON.parse(localStorage.getItem('userData'))
		if (agentchat && agentchat.isAgentAvailable === 'Unavailable') {
			this.setState({
				informationAlert: 'Make your self available in order to chat',
				alertModal: !this.state.alertModal,
			})
			return false
		}
		// if (agentData.is_available == undefined || agentData.is_available == 0) {
		//   this.setState({
		//     informationAlert: "Make your self available in order to chat.",
		//     alertModal: !this.state.alertModal
		//   })
		//   return true
		//   //alert('Make you self available in order to chat.');
		//   //return true;
		// }

		let tempInc = this.state.incrementer

		let botUserConversationId, botUserSessionId, agentId, activeAgentId, activeAgentDbId
		// const activeAgentData = JSON.parse(localStorage.getItem('activeAgentData'))

		// const { user } = authentication;
		var currentChatUser = agentchat.incomingUserData.data.filter(chatUser => {
			return chatUser.id == userid
		})[0]
		//var currentChatUser = agentchat.incomingUserData.data[0]

		if (currentChatUser) {
			botUserConversationId = currentChatUser.botUserConversationId
			botUserSessionId = currentChatUser.customerSessionId
			activeAgentId = agentchat.agentStatus.data.agentId
			activeAgentDbId = agentchat.agentStatus.data.id
			agentId = profile.userId
			let userFullName = currentChatUser.name || currentChatUser.userFullName || currentChatUser.customerProfilePrimary || ''
			this.setState({ userFullName: userFullName })

			// this.setState({chatUserConversationId:botUserConversationId,
			//   chatAgentId:agentId
			// })
			var messages = this.state.transcripts?.data.map(c => {
				return {
					type: 'message',
					id: c.userInfo && c.userInfo.id ? c.userInfo.id : botUserConversationId,
					text: c.text,
					from: {
						id: c.userInfo && c.userInfo.id ? c.userInfo.id : botUserConversationId,
						name: c.sentBy === c.bot ? c.bot : c.userInfo && c.userInfo.name ? c.userInfo.name : c.sentBy,
						role: c.sentBy === 'user' || (c.sentBy == userFullName && !c.userInfo.agentCheck) ? 'bot' : 'user', //(c.userInfo && c.userInfo.agentId)
						sent: c.userInfo.role === 'user' && c.userInfo.agentCheck ? 'agent' : c.userInfo.role,
					},
					attachments:
						c.attachments && c.attachments.length > 0
							? c.attachments.map(x => {
									if (x.contentType === 'application/vnd.microsoft.keyboard') x.contentType = 'application/vnd.microsoft.card.hero'
									return x
							  })
							: [],
					timestamp: c.startTimestamp,
				}
			})
			var conversation = {
				messages: messages,
				customerAddress: {
					conversation: { id: botUserConversationId },
					user: { name: 'User' },
				},
			}
			var userinfo = {
				id: 'Agent-' + agentId,
			}

			var { token, tenantId } = this.props
			let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			let ep = apiUrl
			let headers = {
				Pragma: 'no-cache',
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				'x-tenantid': tenantId,
			}
			if (token.includes('Bearer')) {
				delete headers.Authorization
				headers.msTokenSignedForUser = token
			}
			fetch(
				ep +
					'/active_agents/handOverFromBot/' +
					activeAgentDbId +
					'/' +
					activeAgentId +
					'/' +
					userid +
					'/' +
					botUserSessionId +
					'/' +
					botUserConversationId +
					'/' +
					this.state.agentName,
				// fetch(ep + '/active_agents/handOverFromBot/35/efaa26fc-7d66-4af1-8677-4b043258bd21/40/sample/sample',
				{
					method: 'GET',
					headers,
				}
			)
				.then(res => res.json())
				.then(res => {
					if (res.code == 410 || (res.error == true && res.name == 'resource_unavailable')) {
						that.props.dispatch(alertActions.error(`The chat is no more available.`))
						return false
					} else if (res.code > 399 || res.name == 'SequelizeUniqueConstraintError') {
						that.props.dispatch(alertActions.error(`User's request already picked-up by another agent, please select another user.`))
						return false
						// @todo apply check to hide the chat pannel
					} else {
						// this.setState({ showHistory: false, isChatNow: true });
						that.setState({
							chatUserConversationId: botUserConversationId,
							chatAgentId: agentId,
							conversation: conversation,
							user: userinfo,
							showHistory: false,
							isChatNow: true,
							agentConversationId: null,
						})
						$(document).ready(function () {
							setTimeout(function () {
								$('#wc-shellinput-input').val(t('connect'))
								$('.wc-send').click()
								$('#wc-shellinput-input').val('')
								$('.chat-header span.bot-name').html(userFullName && userFullName != 'Anonymous user' ? userFullName : t('Chat Session') + '#' + tempInc)
								$('#wc-shellinput-input').hide()
								$('.wc-send').hide()
							}, 1000)
							$('.wc-message-group-content').on('DOMNodeInserted', function () {
								if (
									$('.format-markdown p').text().search(t('You are connected to')) != -1 ||
									$('.format-markdown p').text().search('Merci de fournir les détails.')
								) {
									$('#wc-shellinput-input').show()
									$('.wc-send').show()
								}
							})
						})
						let headers = {
							Pragma: 'no-cache',
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: `Bearer ${token}`,
							'x-tenantid': tenantId,
						}
						if (token.includes('Bearer')) {
							delete headers.Authorization
							headers.msTokenSignedForUser = token
						}

						fetch(ep + '/active_agents/removeIncommingUser/' + userid, {
							method: 'GET',
							headers,
						})
							.then(res => res.json())
							.then(res => {
								let updateReqId = res.data.id
								agentchat.incomingUserData.data = agentchat.incomingUserData.data.filter(c => c.id != updateReqId)
								// console.log("restincommingrestincomming",restincomming)
								// agentchat.incomingUserData.data = restincomming;
								this.props.dispatch(this.successagent(agentchat.incomingUserData))
								//  this.props.dispatch(activeAgentAction.getActiveAgents());
								//res.data.agentId=activeAgentId;
								// this.props.dispatch(incomingUserAction.addUserToAgent(res))
							})
					}
				})
		} else {
		}
	}

	chatWithActiveAgent(event) {
		// console.log("\n\n\nthis is event value",event)
		let lang = window && window.localStorage && window.localStorage.getItem('i18nextLng')
		this.getCanned(lang && lang.slice(0, 2))
		$('.active-chat').addClass('disable-chat')
		const { agentchat, user, tenantId, token, profile, globalSetting, t } = this.props
		const { tempResponses } = this.state
		let sessionid = event.target.dataset.sessionid
		let startTime = event.target.dataset.starttime
		let userid = event.target.dataset.user
		let incrementer = event.target.dataset.incrementer
		let userFullName = event.target.dataset.userFullName || event.target.dataset.userFullName || ''
		this.setState({ userFullName: userFullName, incrementer, startTime, sessionid })
		let botUserConversationId, botUserSessionId, agentId, activeAgentId, agentConversationId
		$('.id-top').removeClass('active-user-chat')
		event.target.classList.add('active-user-chat')
		let liveChat = globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat
		if (liveChat && liveChat.saveMessages == 0 && this.state.currentActiveChatUser && this.state.currentActiveChatUser == incrementer) {
			return false
		}

		// const activeAgentData = JSON.parse(localStorage.getItem('activeAgentData'))

		if (agentchat && agentchat.isAgentAvailable !== 'Available') {
			this.setState({
				informationAlert: 'You are not authorized to access other agent conversation',
				alertModal: !this.state.alertModal,
			})
			return false
		}

		// var currentChatUser = agentchat.agentData[activeAgentData.agent_id].filter((chatUser) => {
		//   return chatUser.user_incoming_chat_request_id == userid;
		// })[0];
		var currentChatUser = agentchat.agentData[profile.userId].filter(chatUser => {
			return chatUser.userIncomingChatRequestId == userid
		})[0]

		if (currentChatUser && profile.userId != currentChatUser.agentId) {
			this.setState({
				informationAlert: 'You are not authorized to access other agent conversation',
				alertModal: !this.state.alertModal,
			})
			return true
		}
		var currentAgentUsers = agentchat.agentData[profile.userId].map(c => {
			if (c.userIncomingChatRequestId == userid) {
				c.isNewMessage = false
				c.isChatOpen = true
			} else {
				c.isChatOpen = false
			}
			return c
		})
		// console.log("currentAgentUserscurrentAgentUserscurrentAgentUsers",currentAgentUsers)
		if (currentAgentUsers) agentchat.agentData[profile.userId] = currentAgentUsers
		this.props.dispatch(this.success(agentchat))
		this.setState({
			isChatNow: false,
			showHistory: false,
			currentActiveChatUser: incrementer,
		})
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let ep = apiUrl
		let headers = {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			'x-tenantid': tenantId,
		}
		if (token.includes('Bearer')) {
			delete headers.Authorization
			headers.msTokenSignedForUser = token
		}
		fetch(ep + '/active_agents/getUserTranscriptWithBot/' + sessionid + ((startTime && `?startTime=${startTime}`) || ''), {
			method: 'GET',
			headers,
		})
			.then(res => res.json())
			.then(res => {
				try {
					if (currentChatUser) {
						botUserConversationId = currentChatUser.userBotConversationId
						botUserSessionId = currentChatUser.userChatSessionId
						activeAgentId = agentchat.agentStatus.data.id
						agentId = profile.userId
						agentConversationId = currentChatUser.agentBotConversationId
						console.log(currentChatUser, 'currentChatUser')
						// this.setState({chatUserConversationId:botUserConversationId,
						//   chatAgentId:agentId
						// })
						userFullName = res && res.data && res.data.length > 0 ? res.data[0].userInfo.userFullName : ''
						let userDetails =
							res &&
							res.data &&
							res.data.filter(message => {
								return message.userInfo && message.userInfo.role === 'user'
							})
						let userInfo = userDetails && userDetails[0] && userDetails[0].userInfo
						console.log(res.data, userDetails, userInfo, this.state.transcripts, 'userInfo')
						if (userInfo && userInfo.userFullName && userInfo.userFullName.toLowerCase().includes('anonymous'))
							this.getUserPrimaryChatSession(ep, token, sessionid, tenantId)
						else {
							let splitStr = userInfo && userInfo.userFullName && userInfo.userFullName.trim().split(' ')
							let firstname = (splitStr && splitStr[0] && splitStr[0]) || '{{firstName}}'
							let lastname = (splitStr && splitStr[1] && splitStr[1]) || '{{lastName}}'
							if (splitStr) {
								userInfo['firstName'] = firstname
								userInfo['lastName'] = lastname
								this.updateCannedResponse(tempResponses, userInfo)
							} else this.setState(() => ({ cannedResponses: tempResponses }))
						}
						this.setState({ userFullName: userFullName })
						var messages = res.data.map(c => {
							return {
								type: 'message',
								id: c.userInfo && c.userInfo.id ? c.userInfo.id : botUserConversationId,
								text: c.text,
								from: {
									id: c.userInfo && c.userInfo.id ? c.userInfo.id : botUserConversationId,
									name: c.sentBy === c.bot ? c.bot : c.userInfo && c.userInfo.name ? c.userInfo.name : c.sentBy,
									role: c.sentBy === 'user' || (c.sentBy == userFullName && !c.userInfo.agentCheck) ? 'bot' : 'user', //(c.userInfo && c.userInfo.agentId)
									sent: c.userInfo.role === 'user' && c.userInfo.agentCheck ? 'agent' : c.userInfo.role,
								},
								attachments:
									c.attachments && c.attachments.length > 0
										? c.attachments.map(x => {
												if (x.contentType === 'application/vnd.microsoft.keyboard') x.contentType = 'application/vnd.microsoft.card.hero'
												return x
										  })
										: [],
								timestamp: c.startTimestamp,
							}
						})
						var conversation = {
							messages: messages,
							customerAddress: {
								conversation: { id: botUserConversationId },
								user: { name: 'User' },
							},
						}
						var userinfo = {
							id: 'Agent-' + agentId,
						}
						this.setState({
							chatUserConversationId: botUserConversationId,
							chatAgentId: agentId,
							conversation: conversation,
							agentConversationId: agentConversationId,
							user: userinfo,
							isChatNow: true,
							showHistory: false,
						})
					}
				} catch (err) {
					console.warn('Error::', err)
				}
			})
		$(document).ready(function () {
			$('.uic-top h4').html(userFullName && userFullName != 'Anonymous user' ? userFullName : t('Chat Session') + '#' + incrementer)
			setTimeout(function () {
				$('.wc-header span').html(userFullName && userFullName != 'Anonymous user' ? userFullName : t('Chat Session') + '#' + incrementer)
			}, 1500)
		})
		setTimeout(function () {
			$('.active-chat').removeClass('disable-chat')
		}, 5000)
		event.preventDefault()
	}

	_browserNotification() {
		let { t } = this.props
		let { isAudio, isNotification } = this.state
		if (isAudio) {
			if (!document.getElementById('myAudio')) {
				console.warn('_browserNotification::myAudio::is not found somehow::')
				console.log('myAudio::is not found somehow')
				return false
			}
			document
				.getElementById('myAudio')
				// @ts-ignore
				.play()
				.then(() => {})
				.catch(err => {
					if (err) {
						// if (navigator.userAgent.indexOf('Safari') != -1) {
						// 	toast.error(t('Notification sounds are disabled.'))
						// } else {
						// 	toast.error(t('Notification sounds are disabled.'))
						// }
						toast.error(t('Notification sounds are disabled.'))
					}
				})
		}
		//document.querySelector('audio').play();
		if (isNotification) {
			if (!('Notification' in window)) {
				toast.error('Your browser does not support desktop notifications')
			}

			// Let's check whether notification permissions have already been granted
			else if (Notification.permission === 'granted') {
				// If it's okay let's create a notification
				var options = {
					body: 'Incoming Live chat request. Please review.',
					icon: process.env.PUBLIC_URL + '/ms-icon.png',
				}
				let title = 'Incoming Request'
				var notification = new Notification(title, options)
				setTimeout(notification.close.bind(notification), 7000)
			}

			// Otherwise, we need to ask the user for permission
			else if (Notification.permission !== 'denied') {
				Notification.requestPermission().then(function (permission) {
					// If the user accepts, let's create a notification
					if (permission === 'granted') {
						var options = {
							body: 'Incoming Live chat request. Please review.',
							icon: process.env.PUBLIC_URL + '/ms-icon.png',
						}
						let title = 'Incoming Request'
						var notification = new Notification(title, options)
						setTimeout(notification.close.bind(notification), 7000)
					}
				})
			}
		}
		if (window.location.pathname !== '/agent') {
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
			// @ts-ignore
			link.type = 'image/png'
			// @ts-ignore
			link.rel = 'shortcut icon'
			// @ts-ignore
			link.href = process.env.PUBLIC_URL + '/ms-icon.png'
			document.getElementsByTagName('head')[0].appendChild(link)
		}
	}
	getQueryParameters() {
		let queryParams = {}
		window.location &&
			window.location.search &&
			window.location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					let s = item.split('='),
						k = s[0],
						v = s[1] && decodeURIComponent(s[1])
					queryParams[k] = v
				})
		return queryParams
	}

	handleAudio() {
		this.setState(prevState => {
			return { isAudio: !prevState.isAudio }
		})
	}

	handleNotification() {
		this.setState(prevState => {
			return { isNotification: !prevState.isNotification }
		})
	}

	async handleLoadTranscript(event) {
		this.setState({
			isChatNow: false,
		})
		let { agentchat, bot, t, i18n, tenantId, token, profile } = this.props
		const { sessionid, startTime, chatUserConversationId, userFullName, userid } = this.state
		let conversationResponse = null
		let transitionResponse = []
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let ep = apiUrl

		const requestOptions = {
			url: ep + '/active_agents/getUserTranscriptWithBot/' + sessionid + ((startTime && `?startTime=${startTime}`) || ''),
			method: 'GET',
		}

		let res = await identityServices.identityApi.request(requestOptions)
		if (res && res.data && res.data.data) {
			transitionResponse = res.data.data
			var messages = res.data.data.map(c => {
				return {
					type: 'message',
					id: c.userInfo && c.userInfo.id ? c.userInfo.id : chatUserConversationId,
					text: c.text,
					from: {
						id: c.userInfo && c.userInfo.id ? c.userInfo.id : chatUserConversationId,
						name: c.sentBy === c.bot ? c.bot : c.userInfo && c.userInfo.name ? c.userInfo.name : c.sentBy,
						role: c.sentBy === 'user' || (c.sentBy == userFullName && !c.userInfo.agentCheck) ? 'bot' : 'user', //(c.userInfo && c.userInfo.agentId)
						sent: c.userInfo.role === 'user' && c.userInfo.agentCheck ? 'agent' : c.userInfo.role,
					},
					attachments:
						c.attachments && c.attachments.length > 0
							? c.attachments.map(x => {
									if (x.contentType === 'application/vnd.microsoft.keyboard') x.contentType = 'application/vnd.microsoft.card.hero'
									return x
							  })
							: [],
					timestamp: c.startTimestamp,
				}
			})
			conversationResponse = {
				messages: messages,
				customerAddress: {
					conversation: { id: chatUserConversationId },
					user: { name: 'User' },
				},
			}
		}
		setTimeout(() => {
			this.setState({
				loadTranscript: !this.state.loadTranscript,
				isChatNow: true,
				conversation: conversationResponse,
				transcripts: transitionResponse,
				hideDisconnect: false,
			})
		}, 1000)
	}
	render() {
		let { agentchat, bot, t, i18n, tenantId, namespace } = this.props
		const { isAudio, isNotification } = this.state
		if (bot && bot.loading == false && bot.botData && bot.botData.botSecret) {
			this.state.directlineSecret = bot.botData.token || bot.botData.botSecret
			this.state.botName = bot.botData.botName
		}

		if (agentchat && agentchat.agentQueueList && agentchat.loading == false && agentchat.registerAllSocket && agentchat.registerAllSocket.data && (!pushNotificationInst?.checkPermission() || !isBroadcastSupport)) {
			console.log('socket allow')
			this.initSocket(agentchat.agentQueueList)
		}

		function AnswerHtml(data) {
			let html = ''
			if (data.ans) {
				let splitAns = data.ans.split('~')
				html = splitAns[0].replace(/\\n/g, ' \n')
			}
			//return html;
			return <ReactMarkdown escapeHtml={false} source={html} renderers={{ link: LinkRenderer }} />
		}

		function UserChatTranscript(transcript) {
			if (
				transcript.data.attachments &&
				transcript.data.attachments.length > 0 &&
				transcript.data.attachments[0] &&
				transcript.data.attachments[0].content &&
				transcript.data.attachments[0].contentType == 'application/vnd.microsoft.card.hero'
			) {
				return (
					<div className="convo-wrapper clearfix ">
						<p>
							<strong>{transcript.data.attachments[0].content.title}</strong>
						</p>
						{transcript.data.attachments[0].content.text && <AnswerHtml ans={transcript.data.attachments[0].content.text} />}

						<div>
							{transcript.data.attachments[0].content.buttons.map((chat, index) => (
								<p key={index}>
									<button className="ac-pushButton">{chat.title}</button>
								</p>
							))}
						</div>
					</div>
				)
			}

			if (transcript.data.text == null || transcript.data.text == '') {
				if (transcript.data && transcript.data.attachments && transcript.data.attachments[0].content) {
					adaptiveCard.parse(transcript.data.attachments[0].content)
					var renderedCard = adaptiveCard.render()
					return (
						<div
							ref={n => {
								n && n.appendChild(renderedCard)
							}}
						/>
					)
				}
				return <div></div>
			} else {
				return <p>{transcript.data.text}</p>
			}
		}

		function LinkRenderer(props) {
			return (
				<a href={props.href} target="_blank">
					{props.children}
				</a>
			)
		}
		//console.log("this.statethis.statethis.statethis.statethis.state",this.state)
		var ChatComp = this.state.conversation && this.state.conversation.customerAddress && (
			<WebChat
				t={t}
				chatTitle={t('Live Chat')}
				conversation={this.state.conversation}
				conversationMessages={
					this.state.conversation && !this.state.loadTranscript
						? this.state.conversation.messages.slice(this.state.conversation.messages.length - 10)
						: this.state.conversation.messages
				}
				agentConversationId={this.state.agentConversationId}
				token={this.state.directlineSecret}
				userId={this.state.chatAgentId}
				username={this.state.agentName}
				userFullName={this.state.userFullName}
				user={{
					id: this.state.chatAgentId,
					name: this.state.agentFullName || this.state.agentName,
					userName: this.state.agentName,
					agentFullName: this.state.agentFullName,
					agentConversationId: this.state.agentConversationId,
					incommingChatUserId: this.state.userid,
					agentId: this.state.chatAgentId,
					agentCheck: 'Agent-' + this.state.chatAgentId,
					chatUserConversationId: this.state.chatUserConversationId,
					tenantId: tenantId,
					loadTranscript: this.state.loadTranscript,
				}}
				bot={{ name: this.state.botName }}
				allowDisconnect={this.state.allowDisconnect}
				incrementer={this.state.incrementer}
				cannedResponses={this.state.cannedResponses}
				handleLoadTranscript={this.handleLoadTranscript.bind(this)}
				loadTranscript={this.state.loadTranscript}
				globalSetting={this.props.globalSetting}
				hideDisconnect={this.state.hideDisconnect}
			/>
		)

		var CustomHistory = (
			<div className="pre-bot">
				<div className="chat-head">
					<h4>{this.state.userFullName && this.state.userFullName != 'Anonymous user' ? this.state.userFullName : t('Chat Session')}</h4>
				</div>
				<div className="chat-body">
					<div className="chat-frame">
						<div className="ch-convo clearfix">
							{this.state.transcripts.data &&
								this.state.transcripts.data.map((item, index) => {
									const sentBy = item.sentBy && item.bot && item.sentBy.toLowerCase() === item.bot.toLowerCase()
									return (
										<div className="clearfix" key={index}>
											<div className={sentBy ? 'convo-wrap from-me col-blue ' : ' convo-wrap from-bot col-white'}>
												<div id={'temp-id-' + index}>
													<UserChatTranscript key={index} data={item} index={index} botName={this.state.botName} />
												</div>
												<span className={!sentBy ? 'user-name' : 'sender-name'}>
													{item.sentBy && item.sentBy.toLowerCase() == 'you' ? t('User') : item.sentBy}
												</span>
											</div>
										</div>
									)
								})}
						</div>
					</div>
				</div>
				<div className="chat-bottom">
					<div className="input-wrap">
						<input type="text" className="chat-input" placeholder={t('Enter Your Message')} />
						<a href="#">
							<span className="chat-begin" onClick={this.chatInitiate.bind(this, this.state.userid)}>
								{t('Click To Initiate Chat')}
							</span>
						</a>
					</div>
				</div>
			</div>
		)

		var ChatHistory = (
			<>
				<div className="chat-window card">{this.state.showHistory && !this.state.isChatNow && CustomHistory}</div>
				<div>{!this.state.showHistory && this.state.isChatNow && ChatComp}</div>
			</>
		)
   let isNotificationAllowed = pushNotificationInst?.checkPermission()
		return (
			<div className="animated fadeIn">
				{agentchat && agentchat.loading == true && (
					<div className="outer-overlay">
						<div className="ov-content">
							<div className="row qnamaker-spinner">
								<div className="spinner-circle-mod">
									<img src="assets/img/main-loader.gif" alt="loading" />
								</div>
							</div>
							<div className="row spinner">
								<div className="loading-text">{t(`Loading Resource`)}</div>
								<div className="loading-text"></div>
							</div>
						</div>
					</div>
				)}

				{this.state.isTeams && <ToastContainer autoClose={4000} />}
				<div className="common-heading">
					{/* <audio src={soundfile} id="myAudio">
            <source src={soundfile} type="audio/mpeg" />
          </audio> */}
					<div className="common-heading row">
						<div className="col-sm-12">
							<div className="heading">
								<h5>{t('Live Chat')}</h5>
								<div className="availability">
								{ (Notification?.permission === "granted" ||  Notification?.permission === "default") && (!(localStorage.getItem('myPushConfig')) || localStorage.getItem('myPushConfig')==='null') &&  <span className="title">
										{(
											<>
											<Icon fontSize="18px" mr="10px" color="red" as={FiAlertTriangle}  />
											<span onClick={()=>window.location.reload() } style={{color:'blue', cursor:'pointer'}}>Please allow notification permission if not allowed and/or reload page for push notification </span>
											</>
										)}
									</span> 
	                                }{" "}
									<span className="title">
										{isNotification ? (
											<Icon fontSize="18px" mr="10px" color="#f1c40f" as={FaBell} onClick={this.handleNotification.bind(this)} />
										) : (
											<Icon fontSize="18px" mr="10px" as={FaBellSlash} color="#e74c3c" onClick={this.handleNotification.bind(this)} />
										)}
									</span>
									<span className="title">
										{isAudio ? (
											<Icon fontSize="18px" mr="10px" color="#1abc9c" as={FaVolumeUp} onClick={this.handleAudio.bind(this)} />
										) : (
											<Icon fontSize="18px" mr="10px" color="#e74c3c" as={FaVolumeMute} onClick={this.handleAudio.bind(this)} />
										)}
									</span>
									<span className="title">{t('Make yourself available')}</span>
									<span className="tog-switch">
										{/* <Label className="switch switch-icon switch-primary switch-pill">
                <Switch onChange={this.confirmRecord.bind(this)} checked={this.state.toggleActive} />
                </Label> */}
										{agentchat && agentchat.isAgentAvailable && (
											<Label className="switch switch-text switch-success">
												<Toggle
													// onClick={this.changeStatus}
													onClick={this.confirmRecord.bind(this)}
													on={<h4>{t('ON')}</h4>}
													off={<h4>{t('OFF')}</h4>}
													size="xs"
													width="58px"
													height="28px"
													offstyle="warning"
													active={agentchat.isAgentAvailable === 'Unavailable' ? false : true}
												/>
											</Label>
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="main-section">
					<div className="main-inner mychat">
						<div className="row">
							<div className="col-sm-3">
								<div className="profile-edit-option views card agent-chat-list asc-accord-1 ">
									<h4 className="view-head">{t('Incoming chats')}</h4>
									{(isNotificationAllowed || this.socket) && (
										<IncomingUsers
											{...this.props}
											socket={this.socket}
											action={this.chatTranscriptsHistory.bind(this)}
											userFullName={this.state.userFullName}
											isNotificationAllowed={isNotificationAllowed}
										/>
									)}
								</div>
							</div>
							{/* <ChatTranscript chatdata={this.state.transcripts} /> */}
							<div className="col-sm-3">
								<div className="profile-edit-option views card agent-chat-list ar-position-1 asc-accord-1">
									{(isNotificationAllowed || this.socket) && (
										<ActiveAgents
											isAudio={isAudio}
											isNotification={isNotification}
											{...this.props}
											socket={this.socket}
											action={this.chatWithActiveAgent.bind(this)}
											userFullName={this.state.userFullName}
											isNotificationAllowed={isNotificationAllowed}
										/>
									)}
								</div>
							</div>
							<div className="col-sm-6">
								<div className="chat-user clearfix">{ChatHistory}</div>
							</div>
						</div>
					</div>
				</div>

				<Modal autoFocus={false} isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className + ' delete-card'}>
					<ModalBody>
						<p>{t('Are you sure you want to change your availability')} ?</p>

						{agentchat && agentchat.isAgentAvailable != 'Unavailable' && (
							<>
								<hr />
								{this.state.nonAvailabilityModal && <Alert color="danger">{t(`Please Select the reason for your Non Availability`)}!</Alert>}

								<Label for="exampleSelect">{t(`Reason for Non Availability`)}</Label>
								{/* <Input type="select" onChange={this.addReason.bind(this)} name="select" id="exampleSelect">
									<option value="">--- {t(`Select`)} ---</option>
									<option>{t(`Tea Break`)}</option>
									<option>{t(`Lunch Break`)}</option>
									<option>{t(`Off for Today`)}</option>
								</Input> */}
								<Select color="black" borderColor="blue" onChange={this.addReason.bind(this)} id="nonAvailability">
									<option value="" disabled selected hidden>
										Select Your Reason For Non Availability
									</option>
									{this.state.nonAvailabilityReasonCustom && this.state.nonAvailabilityReasonCustom.length > 0 ? (
										this.state.nonAvailabilityReasonCustom
											// @ts-ignore

											.map(item => (
												<option key={item.id} value={t(item.reason)}>
													{t(item.reason)}
												</option>
											))
									) : (
										<></>
									)}
								</Select>
							</>
						)}
					</ModalBody>

					<div className="button-wrap">
						<Button color="primary" onClick={this.changeStatus}>
							<img src="assets/img/yes.png" alt={t('Yes')} />
							{t('Yes')}
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleModal}>
							<img src="assets/img/no.png" alt={t('No')} />
							{t('No')}
						</Button>
					</div>
				</Modal>

				<Modal autoFocus={false} isOpen={this.state.alertModal} toggle={this.toggleAlertModal} className={this.props.className + ' delete-card'}>
					<ModalBody>
						<p>{t(this.state.informationAlert)}</p>
					</ModalBody>
					<div className="button-wrap">
						<Button color="primary" onClick={this.toggleAlertModal}>
							<img src="assets/img/yes.png" alt={t('Ok')} />
							{t('Ok')}
						</Button>{' '}
					</div>
				</Modal>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { agentchat, identity, bot, globalSetting, tenantConfig, workspace } = state
	// const { user } = authentication;
	const { profile, token } = identity
	return {
		identity,
		profile,
		token,
		agentchat,
		tenantId: profile.tenantUid,
		bot,
		globalSetting,
		tenantConfig,
		workspace,
	}
}
export default withTranslation()(connect(mapStateToProps)(AgentChat))
