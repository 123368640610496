'use strict'

import { EntityActions } from '../base/entity';
// import {EntityComponent} from '../entity'

export class EmailTemplateAction extends EntityActions {
    constructor(options) {
        super(options);
    }


    preActionsHooks({actionName, item, tenantId}){
        if (!item || Object.keys(item).length === 0) return
        if (item && (actionName == 'addNewItem') || actionName == 'editItem' || actionName == 'publishItem' || actionName == 'copyItem') {
                let payload={...item};
                if(!payload.status && actionName==='publishItem') payload.status='Published';
                else if(actionName==='editItem') payload.status='UnPublished';
                
                if(actionName==='publishItem'){
                    payload.createdAt = new Date()
                }
                let removeObjectProperties = function(obj, props) {
                    for(var i = 0; i < props.length; i++) {
                        if(obj.hasOwnProperty(props[i])) {
                            delete obj[props[i]];
                        }
                    }
                };                                 
                let properties = {
                    whenObj: item.whenObj || '',
                    type: item.type || 'private',
                    notifyExternalSystem: item.notifyExternalSystem || '',
                    notifyExternalSystemCond: item.notifyExternalSystemCond || ''
                }
                if (item.redirectAction) properties.redirectAction = item.redirectAction
                if (item.notificationPayload) properties.notificationPayload = item.notificationPayload
                
                payload.properties = properties;
                payload.lookupProps = item.lookupProps ? item.lookupProps : { usecase: localStorage.getItem('product') };
                removeObjectProperties(payload,["notificationPayload", "redirectAction"])
                item = payload;
                removeObjectProperties(item,["whenObj","type", "subject1", "notifyExternalSystem", "notifyExternalSystemCond"])
                return item;
        } else {
            return item
        }
    }

    postActionsHooks({actionName, item, tenantId}){
        switch (actionName) {
            case 'getAll':
                let items = item && item.map((i)=>{
                    i = Object.assign({}, i, i.properties);
                    i.type = i.properties.type ;
                    i.subject1=i.subject;
                    return i;
                })             
                return items
            case 'getOne':
                if(!item.properties) return item
                item = Object.assign({}, item, item.properties);       
                return item
            default:
                return item
        }
    }
}