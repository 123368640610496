export const RECORD_LIMIT = '4';

export const ERROR_MESSAGE = 'Oops! Something went wrong. Please try again later';
export const SUCCESS_MESSAGE = 'Report generated successfully';
export const WARNING_MESSAGE = 'No data available for download';


export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS= 'REPORT_SUCCESS';
export const REPORT_FAILURE= 'REPORT_FAILURE';
