import { apiBaseUrlPath } from '../../config'
import { Entity } from '../common/base/entity/entity'
import { collectionSchemas, entitySchema, methodSchemas } from './agentNotification.schema'
import { EmailSignatureAction } from './agentNotificationAction'
import AgentNotificationDetail from './components/agentNotificationDetail'

class AgentNotificationEntity extends Entity {
	constructor(options) {
		super(options)
	}
}

let apiPath = ``
if (apiBaseUrlPath) {
	apiPath = `${apiBaseUrlPath}/TenantConfig`
} else {
	apiPath = `TenantConfig`
}

const fieldsMeta = {
	name: {
		label: 'Name',
		required: true,
		type: 'text',
		name: 'name',
		readOnlyOnEdit: true,
	},
	configType: {
		label: 'Config Type',
		required: true,
		type: 'text',
		name: 'configType',
		readOnlyOnEdit: true,
	},
	appModule: {
		label: 'App Module',
		required: true,
		type: 'text',
		name: 'appModule',
		readOnlyOnEdit: true,
	},
	signature: {
		label: 'Email Signature',
		required: true,
		type: 'component',
		component: 'MonacoEditor',
		name: 'signature',
		language: 'html',
		height: '60px',
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'Published', value: 'Published' },
			{ label: 'Deleted', value: 'Deleted' },
		],
		type: 'select',
		required: true,
	},
}

const actionsMeta = {
	editItem: {
		type: 'button',
		label: 'Update',
		action: 'editItem',
		name: 'editItem',
	},
	cancel: {
		type: 'button',
		label: 'Cancel',
		action: 'cancelEdit',
		name: 'cancel',
	},
	upsert: {
		label: 'Update',
		name: 'update',
		action: 'publishItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	update: {
		label: 'Edit',
		name: 'update',
		action: 'updateItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
}
const sectionsMeta = {
	basic: {
		title: 'EmailTemplates Details',
		items: [
			{ name: 'name', type: 'field' },
			{ name: 'configType', type: 'field' },
			{ name: 'appModule', type: 'field' },
			{ name: 'signature', type: 'field' },
		],
		classes: 'ticket-form',
	},

	headerComponent: {
		component: 'DetailHeader',
	},
	// emailTemplatesForm: {
	//     cols: [7,5],
	//     items:[{name: 'basic', type: 'section', col: 1},
	//     {name: 'createItem', type: 'action', col: 1}
	//     ],
	//     classes: 'composite-inner-form'
	// },

	// emailTemplatesCreateForm: {
	//     cols: [7,5],
	//     items:[{name: 'basic', type: 'section', col: 1},
	//     {name: 'createItem', type: 'action', col: 1}
	//     ],
	//     classes: 'composite-inner-form'
	// },

	emailTemplatesEditForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'upsert', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
}

const screens = {
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'emailTemplatesEditForm', type: 'section' },
		],
		title: 'EmailSignature Details',
	},
	// create:{
	//     items: [{name:'headerComponent', type: 'section'}, {name: 'emailTemplatesCreateForm', type: 'section'}]
	// },
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'emailTemplatesEditForm', type: 'section' },
		],
	},
	list: {
		items: [
			{ name: 'name', type: 'link' },
			{ name: 'status', type: 'field' },
		],
		filter: [
			{
				name: 'status',
				operator: 'ne',
				value: 'Deleted',
			},
			{
				name: 'name',
				operator: 'eq',
				value: 'agentNotification',
			},
		],
		orderBy: ['createdAt desc'],
		multiselect: false,
	},
}
const emailSignature = new AgentNotificationEntity({
	ActionClass: EmailSignatureAction,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	propFields: actionsMeta,
	tabsMeta: {},
	screens,
	name: 'EmailConfiguration',
	title: 'Email Configuration',
	idKey: 'id',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	entityUrlPaths: {
		update: `${apiPath}/update`,
		default: apiPath,
		publish: `${apiPath}/publish`,
		getOne: `${apiPath}/findWithDefaultTenant`,
		getAll: `${apiPath}/findWithDefaultTenant`,
	},
	fetchDetailByApi: false,
	components: { Detail: AgentNotificationDetail },
})

const AgentNotificationListSearchContainer = emailSignature.getContainer('ListSearch')
//const EmailSignatureCreateContainer = emailSignature.getContainer('Create')
const AgentNotificationDetailContainer = emailSignature.getContainer('Detail')

export { AgentNotificationListSearchContainer, AgentNotificationDetailContainer }
