import { apiBaseUrlPath } from '../../../config'
import { Entity } from '../base/entity/entity'
import { EmailTemplateAction } from './emailTemplateAction'
import { collectionSchemas, entitySchema, methodSchemas } from './emailTemplates.schema'

class EmailTemplatesEntity extends Entity {
	constructor(options) {
		super(options)
	}
}

let apiPath = ``
if (apiBaseUrlPath) {
	apiPath = `${apiBaseUrlPath}/EmailTemplate`
} else {
	apiPath = `EmailTemplate`
}

const fieldsMeta = {
	templateName: {
		label: 'Template Name',
		required: true,
		type: 'text',
		name: 'templateName',
	},
	queue: {
		label: 'Queue',
		required: true,
		type: 'text',
		name: 'queue',
		default: 'Default',
		readOnlyOnEdit: true,
	},
	action: {
		label: 'Email Action',
		required: true,
		type: 'text',
		name: 'action',
		default: 'action',
	},
	emailTemplate: {
		label: 'Template',
		required: true,
		type: 'component',
		component: 'MonacoEditor',
		name: 'emailTemplate',
		language: 'html',
		height: '100%',
		dependsOn: ['eventType'],
		labelClass: 'col-sm-2 email-label',
		classes: 'col-sm-10 email-name',
		autoCompletionApiProps: {
			api: {
				path: apiBaseUrlPath ? `${apiBaseUrlPath}/attributes` : `attributes`,
				response: {
					ref: 'data',
					value: 'properties.value',
				},
				filters: [
					{
						field: 'status',
						operator: 'is',
						value: 'Published',
					},
				],
				params: {
					filter: ['eventType'],
				},
			},
		},
	},
	fromConfig: {
		label: 'From Config',
		required: true,
		type: 'select',
		name: 'fromConfig',
		create: true,
		isMulti: true,
		promptMessage: 'Create Form',
		modalMeta: [
			{
				name: 'fromConfigValue',
				label: 'From Config',
				type: 'monaco-editor',
				required: true,
				lang: 'html',
				dependsOn: ['eventType'],
			},
		],
	},
	toConfig: {
		label: 'To Config',
		required: true,
		type: 'select',
		name: 'toConfig',
		create: true,
		isMulti: true,
		promptMessage: 'Create Form',
		modalMeta: [
			{
				name: 'toConfigValue',
				label: 'To Config',
				type: 'monaco-editor',
				required: true,
				lang: 'html',
				dependsOn: ['eventType'],
			},
		],
	},
	ccConfig: {
		label: 'CC Config',
		required: true,
		type: 'select',
		name: 'ccConfig',
		create: true,
		isMulti: true,
		promptMessage: 'Create Form',
		modalMeta: [
			{
				name: 'ccConfigValue',
				label: 'CC Config',
				type: 'monaco-editor',
				required: true,
				lang: 'html',
				dependsOn: ['eventType'],
			},
		],
	},
	additionalTemplateConfig: {
		label: 'AdditionalTemplate Config',
		required: true,
		type: 'jsonObj',
		name: 'additionalTemplateConfig',
		create: true,
		promptMessage: 'Create Form',
		labelClass: 'col-sm-4',
		classes: 'col-sm-8',
		modalMeta: [
			{
				name: 'additionalTemplateConfigValue',
				label: 'AdditionalTemplate Config',
				type: 'monaco-editor',
				required: true,
				lang: 'html',
				dependsOn: ['eventType'],
			},
		],
	},
	conditionName: {
		label: 'Condition Name',
		required: true,
		type: 'select',
		name: 'conditionName',
		allowCreate: 'onFilter',
		filterDropDown: true,
		dependsOn: ['eventType'],
		storeOptions: true,
		api: {
			path: apiBaseUrlPath ? `${apiBaseUrlPath}/EmailAction/findWithDefaultTenant` : `EmailAction/findWithDefaultTenant`,
			params: { filter: ['eventType'] },
			filters: [
				{
					field: 'status',
					operator: 'is',
					value: 'Published',
				},
			],
			response: {
				ref: 'data',
				labelKey: 'conditionName',
				valueKey: 'conditionName',
			},
		},
	},
	whenObj: {
		label: 'Condition',
		required: true,
		type: 'textarea',
		name: 'whenObj',
		dependsOn: ['conditionName'],
		api: {
			path: apiBaseUrlPath ? `${apiBaseUrlPath}/EmailAction/findWithDefaultTenant` : `EmailAction/findWithDefaultTenant`,
			params: { filter: ['conditionName'] },
			filters: [
				{
					field: 'status',
					operator: 'is',
					value: 'Published',
				},
			],
			response: {
				ref: 'data',
				labelKey: 'whenObj',
				valueKey: 'whenObj',
			},
		},
	},
	eventType: {
		label: 'Event Type',
		required: true,
		type: 'select',
		name: 'eventType',
		api: {
			path: apiBaseUrlPath ? `${apiBaseUrlPath}/Event/findWithDefaultTenant` : `Event/findWithDefaultTenant`,
			method: 'GET',
			filters: [
				{
					field: 'status',
					operator: 'is',
					value: 'Published',
				},
			],
			response: {
				ref: 'data',
				customKey: ['parentObject', 'eventType'],
				delimiter: '::',
			},
		},
		readOnlyOnEdit: true,
	},
	subject: {
		label: 'Subject',
		required: true,
		type: 'component',
		component: 'MonacoEditor',
		name: 'subject',
		height: '60px',
	},
	subject1: {
		label: 'Subject',
		required: true,
		type: 'text',
		name: 'subject1',
		height: '60px',
	},
	type: {
		label: 'Type',
		name: 'type',
		options: [
			{ label: 'public', value: 'public' },
			{ label: 'private', value: 'private' },
		],
		type: 'select',
		required: true,
	},
	notifyExternalSystem: {
		label: 'Notify External System',
		name: 'notifyExternalSystem',
		options: [
			{ label: 'True', value: 'true' },
			{ label: 'false', value: 'false' },
		],
		type: 'select',
		required: false,
	},
	notifyExternalSystemCond: {
		label: 'Notify External System Cond',
		name: 'notifyExternalSystemCond',
		type: 'textarea',
		required: false,
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'Published', value: 'Published' },
			{ label: 'Suppressed', value: 'UnPublished' },
		],
		type: 'select',
		required: true,
	},
}

const actionsMeta = {
	editItem: {
		type: 'button',
		label: 'Update',
		action: 'editItem',
		name: 'editItem',
	},
	cancel: {
		type: 'button',
		label: 'Cancel',
		action: 'cancelEdit',
		name: 'cancel',
	},
	upsert: {
		label: 'Update',
		name: 'update',
		action: 'upsertItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	update: {
		label: 'Edit',
		name: 'update',
		action: 'updateItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
	create: {
		label: 'Create',
		name: 'create',
		type: 'button',
		action: 'addNewItem',
	},
	createItem: {
		label: 'Publish',
		action: 'publishItem',
		name: 'create',
		type: 'button',
	},
	deleteConfig: {
		label: 'Suppress',
		action: 'editItem',
		name: 'deleteConfig',
		color: 'danger',
		type: 'button',
		disabledEval: 'profile.tenantUid !== item.tenantId',
	},
}
const sectionsMeta = {
	section1: {
		title: 'EmailTemplates Details',
		items: [
			{ name: 'templateName', type: 'field' },
			{ name: 'subject', type: 'field' },
			{ name: 'eventType', type: 'field' },
		],
		classes: 'ticket-form',
	},
	basic1: {
		title: 'EmailTemplates Details',
		items: [
			{ name: 'conditionName', type: 'field' },
			{ name: 'queue', type: 'field' },
			{ name: 'whenObj', type: 'field' },
		],
		classes: 'ticket-form',
	},
	finalSection1: {
		title: 'EmailTemplates Details',
		items: [
			{ name: 'fromConfig', type: 'field' },
			{ name: 'toConfig', type: 'field' },
			{ name: 'ccConfig', type: 'field' },
			{ name: 'notifyExternalSystem', type: 'field' },
			{ name: 'notifyExternalSystemCond', type: 'field' },
		],
		classes: 'ticket-form',
	},
	finalSection2: {
		title: 'EmailTemplates Details',
		items: [
			{ name: 'additionalTemplateConfig', type: 'field' },
			{ name: 'type', type: 'field' },
		],
		classes: 'ticket-form',
	},
	template: {
		title: 'EmailTemplates Details',
		items: [{ name: 'emailTemplate', type: 'field' }],
		classes: 'ticket-form',
	},
	headerComponent: {
		component: 'DetailHeader',
	},
	emailTemplatesSection1: {
		cols: [6, 6],
		items: [
			{ name: 'section1', type: 'section', col: 1 },
			{ name: 'deleteConfig', type: 'action', col: 2 },
			{ name: 'createItem', type: 'action', col: 2 },
			{ name: 'basic1', type: 'section', col: 2 },
		],
		classes: 'composite-inner-form',
	},
	emailTemplatesFinalSection: {
		cols: [6, 6],
		items: [
			{ name: 'finalSection1', type: 'section', col: 1 },
			{ name: 'finalSection2', type: 'section', col: 2 },
		],
		classes: 'composite-inner-form',
	},
	emailTemplatesForm: {
		cols: [6, 6],
		items: [
			{ name: 'basic1', type: 'section', col: 2 },
			{ name: 'createItem', type: 'action', col: 2 },
		],
		classes: 'composite-inner-form',
	},
	templateSection: {
		cols: [12],
		title: 'EmailTemplates Details',
		items: [{ name: 'template', type: 'section', col: 1 }],
		classes: 'composite-inner-form',
	},
	emailTemplatesCreateForm: {
		cols: [6, 6],
		items: [
			{ name: 'section1', type: 'section', col: 1 },
			{ name: 'createItem', type: 'action', col: 2 },
			{ name: 'basic1', type: 'section', col: 2 },
		],
		classes: 'composite-inner-form',
	},
	emailTemplatesEditForm: {
		cols: [6, 6],
		items: [
			{ name: 'basic1', type: 'section', col: 2 },
			{ name: 'deleteConfig', type: 'action', col: 2 },
			{ name: 'createItem', type: 'action', col: 2 },
		],
		classes: 'composite-inner-form',
	},
}

const screens = {
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'emailTemplatesSection1', type: 'section' },
			{ name: 'templateSection', type: 'section' },
			{ name: 'emailTemplatesFinalSection', type: 'section' },
		],
		title: 'EmailTemplate Details',
	},
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'emailTemplatesCreateForm', type: 'section' },
			{ name: 'templateSection', type: 'section' },
			{ name: 'emailTemplatesFinalSection', type: 'section' },
		],
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'emailTemplatesEditForm', type: 'section' },
			{ name: 'templateSection', type: 'section' },
		],
	},
	list: {
		items: [
			{ name: 'templateName', type: 'link' },
			{ name: 'status', type: 'field' },
			{ name: 'subject1', type: 'field' },
			{ name: 'toConfig', type: 'field' },
			{ name: 'ccConfig', type: 'field' },
			{ name: 'type', type: 'field' },
			{ name: 'queue', type: 'field' },
			{ name: 'action', type: 'actionButton' }
		],
		filter: [
			{
				name: 'status',
				operator: 'ne',
				value: 'Deleted',
			},
			{
				name: 'status',
				operator: 'ne',
				value: '_Published',
			},
		],
		orderBy: ['createdAt desc'],
		multiselect: false,
		storeList: true,
	},
	search: {
		name: 'templateName',
	},
}
const emailTemplate = new EmailTemplatesEntity({
	ActionClass: EmailTemplateAction,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	propFields: actionsMeta,
	tabsMeta: {},
	screens,
	name: 'EmailTemplate',
	title: 'Email Template',
	idKey: 'id',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	entityUrlPaths: { update: `${apiPath}/update`, default: apiPath, publish: `${apiPath}/publish`, getAll: `${apiPath}/findAndCountAllWithDefaultTenant`, copyItem: `${apiPath}/copyItem` },
	fetchDetailByApi: false,
})

const EmailTemplateListSearchContainer = emailTemplate.getContainer('ListSearch')
const EmailTemplateCreateContainer = emailTemplate.getContainer('Create')
const EmailTemplateDetailContainer = emailTemplate.getContainer('Detail')

export { EmailTemplateListSearchContainer, EmailTemplateCreateContainer, EmailTemplateDetailContainer }
