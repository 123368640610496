import { reviewchatConstants } from '..'
import { BaseActions, baseConstants } from '../../base-ui'
import { alertActions } from '../../common'
import { reviewchatServices } from '../services/reviewchat.services'

let options = {}
options.service = reviewchatServices
options.resourceName = 'ReviewChat'

class ReviewChatActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
	getReviewChats(tenantId, page, pageSize, showme, performanceFilter, sortName, sortOrder, agent, date) {
		return dispatch => {
			dispatch(this.getReviewChatRequest())
			reviewchatServices.getReviewChats(tenantId, page, pageSize, showme, performanceFilter, sortName, sortOrder, agent, date).then(
				records => dispatch(this.getReviewChatSuccess(records)),
				error => dispatch(this.getReviewChatFailure(error))
			)
		}
	}

	reviewChatFlagIt(tenantId, id, flag, t) {
		return dispatch => {
			dispatch(this.reviewChatFlagRequest())
			reviewchatServices.reviewChatFlagIt(tenantId, id, flag).then(
				flagData => {
					if (flagData.name === 'error') {
						dispatch(this.reviewChatFlagFailure(flagData.detail))
						dispatch(alertActions.error((t && t(reviewchatConstants.ERROR_MESSAGE)) || reviewchatConstants.ERROR_MESSAGE))
					} else {
						dispatch(this.reviewChatFlagSuccess(flagData))
						dispatch(alertActions.success((t && t('Flag marked successfully')) || 'Flag marked successfully'))
						setTimeout(() => {
							dispatch(alertActions.clear())
						}, 6000)
					}
				},
				error => dispatch(this.reviewChatFlagFailure((t && t(reviewchatConstants.ERROR_MESSAGE)) || reviewchatConstants.ERROR_MESSAGE))
			)
		}
	}

	getReviewChatRequest() {
		return { type: `${baseConstants.GETALL_REQUEST}_${options.resourceName}` }
	}

	getReviewChatSuccess(records) {
		return { type: `${baseConstants.GETALL_SUCCESS}_${options.resourceName}`, item: records }
	}

	getReviewChatFailure(error) {
		return { type: `${baseConstants.GETALL_FAILURE}_${options.resourceName}`, error: error }
	}

	reviewChatFlagRequest = () => ({
		type: reviewchatConstants.FLAG_REQUEST_REVIEWCHAT,
	})

	reviewChatFlagSuccess = flagData => ({
		type: reviewchatConstants.FLAG_SUCCESS_REVIEWCHAT,
		flagData,
	})

	reviewChatFlagFailure = error => ({
		type: reviewchatConstants.FLAG_FAILURE_REVIEWCHAT,
		error,
	})
}

let ReviewChatActions = new ReviewChatActionModel(options)
export const getReviewChats = (tenantId, page, pageSize, showme, performanceFilter, sortName, sortOrder, agent, date) =>
	ReviewChatActions.getReviewChats(tenantId, page, pageSize, showme, performanceFilter, sortName, sortOrder, agent, date) // ReviewChatActions;
export const reviewChatFlagIt = (tenantId, id, flag) => ReviewChatActions.reviewChatFlagIt(tenantId, id, flag)
