import { WorkspaceConstants } from '..'

export const workspace = (state = {}, action) => {
	switch (action.type) {
		case WorkspaceConstants.GETALL_REQ_WS:
			return {
				...state,
				loading: true,
			}
		case WorkspaceConstants.GETALL_SUCCESS_WS:
			let selectedWsData = localStorage.getItem('workspace')
			selectedWsData = JSON.parse(selectedWsData)
			// let selectedWs = 'Default'
			// let selectedWsKey = 'Default'
			// let selectedWsMeta = null

			let selectedWs = {}
			let selectedWsKey = {}
			let selectedWsMeta = {}
			let selectedWsMetaProps = {}
			let wsType = action.records.wsType
			// if(selectedWsData && selectedWsData[wsType] && selectedWsData[wsType].data && selectedWsData[wsType].data.rulesApplied && selectedWsData[wsType].data.rulesApplied.value) {
			//   selectedWs = selectedWsData[wsType].data.rulesApplied.value
			//   selectedWsKey = selectedWsData[wsType].data.rulesApplied.key
			//   selectedWsMeta = selectedWsData[wsType].data.rulesApplied
			// } else
			if (action.records && action.records.rules && action.records.rules.length > 0) {
				// localstorage update
				let wsSelectedall = {}
				let curProd
				if (localStorage.getItem('product') || action.records.defaultTeam) {
					curProd = localStorage.getItem('product') || action.records.defaultTeam
				}
				action.records.rules.forEach(elem => {
					if (curProd && elem.props && elem.props['team']) {
						if (elem.props['team'].toLowerCase() === curProd.toLowerCase()) {
							if (!wsSelectedall[elem.workspaceType]) {
								wsSelectedall[elem.workspaceType] = elem
								selectedWs[elem.workspaceType] = elem.name
								selectedWsKey[elem.workspaceType] = elem.label
								selectedWsMeta[elem.workspaceType] = elem
								selectedWsMetaProps[elem.workspaceType] = elem.properties
							}
						}
					} else {
						if (!wsSelectedall[elem.workspaceType]) {
							wsSelectedall[elem.workspaceType] = elem

							selectedWs[elem.workspaceType] = elem.name
							selectedWsKey[elem.workspaceType] = elem.label
							selectedWsMeta[elem.workspaceType] = elem
							selectedWsMetaProps[elem.workspaceType] = elem.properties
						}
					}
				})
				// select first available ws
				// selectedWs = action.records.rules[0].rulesApplied.value
				// selectedWsKey = action.records.rules[0].rulesApplied.key
				// selectedWsMeta = action.records.rules[0].rulesApplied
				// localStorage.setItem('workspace', JSON.stringify( {[wsType]: { data: action.records.rules[0]}} ));

				if (!selectedWsData) {
					localStorage.setItem('workspace', JSON.stringify(wsSelectedall))
					let tmIdx = wsSelectedall && wsSelectedall.qna && wsSelectedall.qna.properties && wsSelectedall.qna.properties.findIndex(obj => obj.userAttrKey === 'team')
					if (tmIdx >= 0 && wsSelectedall && wsSelectedall.qna) {
						let usecase = wsSelectedall.qna.properties[tmIdx].values[0].value
						localStorage.setItem('product', usecase)
					}
				}
			}

			return {
				...state,
				loading: false,
				items: action.records,
				selectedWs: selectedWs,
				selectedWsKey,
				selectedWsMeta,
			}
		case WorkspaceConstants.GETALL_FAILURE_WS:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		case WorkspaceConstants.SET_WORKING_WS:
			return {
				...state,
				loading: false,
				selectedWs: action.records,
			}

		case WorkspaceConstants.SET_SELECTED_WS_REQ:
			return {
				...state,
				loading: true,
			}

		case WorkspaceConstants.SET_SELECTED_WS_SUCCESS:
			if (action.data && action.data.selectedWs && action.data.selectedWs && action.data.selectedWs.name) {
				let selectedWsData = localStorage.getItem('workspace')
				selectedWsData = JSON.parse(selectedWsData)

				let selectedWs = state.selectedWs
				let selectedWsKey = state.selectedWsKey
				let selectedWsMeta = state.selectedWsMeta

				selectedWs[action.data.wsType] = action.data.selectedWs.label
				selectedWsMeta[action.data.wsType] = action.data.selectedWs
				selectedWsKey[action.data.wsType] = action.data.selectedWs.name
				localStorage.setItem('workspace', JSON.stringify(selectedWsMeta))
				let tmIdx = action.data && action.data.selectedWs && action.data.selectedWs.properties && action.data.selectedWs.properties.findIndex(obj => obj.userAttrKey === 'team')
				if (tmIdx >= 0) {
					let usecase = action.data.selectedWs.properties[tmIdx].values[0].value
					localStorage.setItem('product', usecase)
				}
				return {
					...state,
					loading: false,
					selectedWs,
					selectedWsMeta,
					selectedWsKey,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}

		default:
			return state
	}
}
