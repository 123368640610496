import { session } from '../../../config'
import { identityServices, wsHelper } from '../../common'
const wsType = 'livechat'

export const getActiveAgents = data => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	let requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/activeAgentUser`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const setAgentAvailability = (agentId, status, tenantId) => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	let requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/setAgentAvailability`,
		data: { tenantId, agentId, isActive: status },
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getAgentAvailability = agentId => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	let requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/getAgentAvailability`,
		data: { agentId },
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const registerAllSocket = username => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	let requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/registerAllSocket`,
		data: { username },
	}
	requestOptions = wsHelper.getWsQuery({ wsType, req: requestOptions })
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getAgentQueueList = (agentId, userName) => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	let requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/getAgentQueueList`,
		data: { agentId, userName },
	}
	requestOptions = wsHelper.getWsQuery({ wsType, req: requestOptions })
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}
