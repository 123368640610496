import { BaseService } from '../../base-ui'

let options = {}
options.urlItemName = 'user_livechat_queues'
options.wsType = 'livechat'
options.workspaceEnabled = true

class userqueueServices extends BaseService {
	constructor(options) {
		super(options)
	}
}
const userqueueService = new userqueueServices(options)
export default userqueueService
