import { ticketCategoryConstants, ticketCategoryServices } from '../'
import { alertActions } from '../../common'

const getAllCategoryRequest = () => ({
	type: ticketCategoryConstants.GETALL_REQUEST_ticketcategory,
})

const getAllCategorySuccess = data => ({
	type: ticketCategoryConstants.GETALL_SUCCESS_ticketcategory,
	data,
})

const getAllCategoryFailure = error => ({
	type: ticketCategoryConstants.GETALL_FAILURE_ticketcategory,
	error,
})

export const getAllTicketCategory = (filter, t) => {
	return dispatch => {
		dispatch(getAllCategoryRequest())
		let errorMsg = (t && t('Cannot fetch the categories at this moment, please try again later')) || 'Cannot fetch the categories at this moment, please try again later'
		ticketCategoryServices.getAllTicketCategory(filter).then(
			data => {
				if (data.name === 'error') {
					dispatch(getAllCategoryFailure(data.detail))
					dispatch(alertActions.error(errorMsg))
				} else {
					dispatch(getAllCategorySuccess(data))
					// dispatch(alertActions.success('Q&A added successfully.'));
				}
			},
			error => {
				dispatch(getAllCategoryFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}
