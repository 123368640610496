// import { apiUrl, apiHostUrl } from '../../../config';
import { identityServices } from '../../common'
import { BaseService } from '../services/base.services'
let options = {}
options.urlItemName = 'botdef'

class botServices extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}

	getBotData(item, customerTenantID, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${apiUrlCustom}/${customerTenantID}` : apiUrl
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.urlItemName}/getConfigs`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			// if (response.status !== 200) {
			//     return Promise.reject(response);
			// }

			return response.data
		})
	}

	getBotConfigsAgentChat(tenantId, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		// apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${apiUrlCustom}/${tenantId}` : apiUrlCustom
		let urlLiveChat = `${apiUrlCustom}/${this.urlItemName}/getBotConfigsAgentChat`
		let urlLiveChatBotAutomation = `${apiUrlCustom}/bottest/${this.urlItemName}/getBotConfigsAgentChat`
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: process.env.REACT_APP_APPROOTPATH === 'studiolivechat' ? urlLiveChatBotAutomation : urlLiveChat,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			return response.data
		})
	}
}
export const botService = new botServices(options)
