import { session } from '../../../config'
import { BaseService } from '../../base-ui'
import { identityServices } from '../../common'

let options = {}
options.urlItemName = 'reviewchat'
class ReviewChatServiceModel extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}

	getReviewChats = (tenantId, page, pageSize, showme, performanceFilter, sortName, sortOrder, agent, date) => {
		let ep = this.apiHostUrl

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${ep}/reviewchat/getagentchatsession`,
			data: { page: page, pageSize, showme: showme, performanceFilter, sortName, sortOrder, agent, date },
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}
			return response.data
		})
	}

	reviewChatFlagIt = (tenantId, id, flag) => {
		let apiUrl = this.apiHostUrl
		let ep = apiUrl
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${ep}/reviewchat/updateChatSessionBySessionId`,
			data: { id, flag },
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
}
export const reviewchatServices = new ReviewChatServiceModel(options)
