import Joi from 'joi-browser'

const job = Joi.object().keys({
	id: Joi.number().integer().positive(),
	tenantId: Joi.string().uuid().required(),
	successChunk: Joi.object().allow(null),
	failedChunk: Joi.object().allow(null),
	status: Joi.string(),
	createdBy: Joi.string().max(100).allow(null),
	createdAt: Joi.date().allow(null),
	type: Joi.string().allow(null),
	requestId: Joi.string().allow(null),
	lookupProps: Joi.object().keys({
		usecase: Joi.string().required(),
		clientApp: Joi.string().required().valid('tickets', 'approvals', 'botapi', 'dashboard', 'botautomation'),
		itemType: Joi.string().optional().allow('', null),
	}),
})

// These are the schemas of the return object from the api server
export const methodSchemas = {
	getAll: Joi.array().items(job),
	getOne: job,
	editItem: job,
}
export const collectionSchemas = {
	//  notes: note
}
export const entitySchema = job
