import { agentConstants } from '..';
import { agentService } from '..';
import { alertActions } from '.';
//import { history } from '../_helpers';
// import { history } from '../../../../_helpers';
// import { apiUrl } from '../config'

export const agentChatAction = {
  setAgentAvailability,
  getAgentQueueList
};

function setAgentAvailability(agentId, status, tenantId) {
  return dispatch => {
    dispatch(request());
    agentService.setAgentAvailability(agentId, status, tenantId)
      .then(
        agentchat => dispatch(success(agentchat)),
        error => dispatch(failure(error))
      );
  };
  function request() { return { type: agentConstants.AGENT_STATUS_CHANGE } }
  function success(agentchat) { return { type: agentConstants.AGENT_STATUS_CHANGE_SUCCESS, agentchat } }
  function failure(error) { return { type: agentConstants.AGENT_STATUS_CHANGE_FAILURE, error } }
}

function getAgentQueueList(agentId, userName) {
  return dispatch => {
    dispatch(request());
    agentService.getAgentQueueList(agentId, userName)
      .then(
        agentQueueData => dispatch(success(agentQueueData)),
        error => dispatch(failure(error))
      );
  };
  function request() { return { type: agentConstants.AGENT_QUEUE_LIST_SET_REQUEST } }
  function success(agentQueueData) { return { type: agentConstants.AGENT_QUEUE_LIST_SET_SUCCESS, agentQueueData } }
  function failure(error) { return { type: agentConstants.AGENT_QUEUE_LIST_SET_FAILURE, error } }
}