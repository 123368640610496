import { session } from '../../../config'
import { BaseService } from '../../base-ui'
import { identityServices } from '../../common'

let options = {}
options.urlItemName = 'agentlist'
class AgentListServiceModel extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}

	getAllAgents = tenantId => {
		let apiUrl = this.apiHostUrl
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/active_agents/getAllAgents`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}
			return response.data
		})
	}
}
export const agentListServices = new AgentListServiceModel(options)
