import { Entity } from "../common/base/entity/entity";
import {
  collectionSchemas,
  entitySchema,
  methodSchemas,
} from "./unavailability.schema";
import { UnavailabilityAction } from "./unavailabilityAction";

class unavailabilityEntity extends Entity {
 
}

const fieldsMeta = {
  reason: {
    label: "Unavailability Reason",
    required: true,
    type: "text",
    name: "reason",
    placeholder: "Please enter the reason",
    readOnlyOnEdit: true,
  },
  teams: {
    label: "Teams",
    required: true,
    type: "select",
    name: "teams",
    placeholder: "Please choose your Teams",
    isMulti: true,
    dynamicOptions: true,
    api: {
      path: "UnavailabilityReason/getTeams",
      method: "GET",
      response: {
        ref: 'data',
        labelKey: `TeamName`,
        valueKey: `TeamName`,
      },
    },
  },
  statusEdit: {
    label: "Status",
    name: "status",
    options: [{label: 'Published', value: "Published"}, {label: 'Draft', value: "Draft"}],
    type: "select",
    required: true,
    default: "Published",
  },
 
  status: {
    label: "Status",
    name: "status",
    options: [{label: 'Published', value: "Published"}, {label: 'Draft', value: "Draft"}],
    type: "select",
    required: true,
    default: "Published",
  },
  createdBy: {
    label: "Created By",
    name: "createdBy",
    required: true,
    type: "text",
  },
  createdAt: {
    label: "Created At",
    name: "createdAt",
    required: true,
    type: "text",
  },
  updatedBy: {
    label: "Last Edited By",
    name: "updatedBy",
    required: true,
    type: "text",
  },
  updatedAt: {
    label: "Last Edited At",
    name: "updatedAt",
    required: true,
    type: "datetime-local",
  },
};

const actionsMeta = {
  create: {
    label: "Add Reason",
    action: "addNewItem",
    name: "create",
    type: "button",
  },
  upsert: {
    type: "button",
    label: "Update",
    name: "Update",
    action: "upsertItem",
  },
  edit: {
    type: "button",
    label: "Update",
    action: "editItem",
    name: "edit",
  },
  save: {
    label: "Save",
    name: "save",
    action: "addNewItem",
    type: "button",
  },
  cancel: {
    label: "Cancel",
    name: "cancel",
    action: "cancelEdit",
  },
  update: {
    label: "Save",
    name: "update",
    action: "editItem",
    classes: "fa fa-pencil fa-lg ",
    type: "span",
  },
  list: {
    label: "List All",
    name: "list",
    action: "load",
  },
  search: {
    label: "Enter the Reason to search",
    name: "search",
    action: "search",
  },
};
const sectionsMeta = {
  general: {
    items: [
      { name: "reason", type: "field" },
      { name: "teams", type: "field" },
      { name: "statusEdit", type: "field" },

    ],
    title: "Unavailability List",
  },
  basic: {
    title: "Unavailability Details",
    items: [
      { name: "reason", type: "field" },
      { name: "teams", type: "field" },
      { name: "status", type: "field" },
    ],
    classes: "unavailability-form user-queue",
  },
  headerComponent: {
    component: "DetailHeader",
  },
  unavailabilityCreateForm: {
    cols: [7, 5],
    items: [
      { name: "basic", type: "section", col: 1 },
      { name: "save", type: "action", col: 1 },
    ],
    classes: "composite-inner-form",
  },
  unavailabilityEditForm: {
    cols: [7, 5],
    items: [
      { name: "general", type: "section", col: 1 },
      { name: "update", type: "action", col: 1 },
    ],
    title : "Create Unavailability",
    classes: "composite-inner-form",
  },
};

const screens = {
  view: {
    items: [
      { name: "headerComponent", type: "section" },
      { name: "unavailabilityEditForm", type: "section" },
    ],
    title: "Update Unavailability Reason",
  },
  create: {
    items: [
      { name: "headerComponent", type: "section" },
      { name: "unavailabilityCreateForm", type: "section" },
    ],
    title: "Create Unavailability Reason",
  },
  list: {
    items: [
      { name: "reason", type: "link" },
      { name: "teams", type: "field" },
      { name: "status", type: "field" },
      { name: "createdBy", type: "field" },
      { name: "updatedAt", type: "field" },
    ],
    multiselect: false,
    title: "Unavailability List",
  },
  filter: [
    {
        name:'status',
        operator: 'ne',
        value: 'Deleted',
    }
  ],
  search: {
    items: [
      { name: "reason", type: "link" },
    ],
    title: "Unavailability List",
  },
};

 
// @ts-ignore
const unavailability = new unavailabilityEntity({
  ActionClass: UnavailabilityAction,
  fieldsMeta,
  actionsMeta,
  sectionsMeta,
  screens,
  name: "Unavailability Reason",
  title: "Reason For Agent Unavailability",
  methodSchemas,
  collectionSchemas,
  entitySchema,
  idKey: "id",
  entityUrlPaths: {
    getAll: "UnavailabilityReason/findAndCountAll",
    update: "UnavailabilityReason/update",
    default: "UnavailabilityReason",
  },
  fetchDetailByApi: false, 
});

const unavailabilityListSearchContainer = unavailability.getContainer("ListSearch");
const unavailabilityCreateContainer = unavailability.getContainer("Create");
const unavailabilityDetailContainer = unavailability.getContainer("Detail");
export {
  unavailabilityCreateContainer, unavailabilityDetailContainer, unavailabilityListSearchContainer
};
