export const agentConstants = {
  GET_ACTIVE_AGENTS: 'GET_ACTIVE_AGENTS',
  GET_ACTIVE_AGENTS_SUCCESS: 'GET_ACTIVE_AGENTS_SUCCESS',
  GET_ACTIVE_AGENTS_FAILURE: 'GET_ACTIVE_AGENTS_FAILURE',

  AGENT_STATUS_CHANGE: 'AGENT_STATUS_CHANGE',
  AGENT_STATUS_CHANGE_SUCCESS: 'AGENT_STATUS_CHANGE_SUCCESS',
  AGENT_STATUS_CHANGE_FAILURE: 'AGENT_STATUS_CHANGE_FAILURE',

  SET_SOCKRT_CONNECTION:'SET_SOCKRT_CONNECTION',
  SET_SOCKRT_CONNECTION_SUCCESS:'SET_SOCKRT_CONNECTION_SUCCESS',
  SET_SOCKRT_CONNECTION_FAILURE:'SET_SOCKRT_CONNECTION_FAILURE',

  INCOMING_USER: 'INCOMING_USER',
  INCOMING_USER_SUCCESS: 'INCOMING_USER_SUCCESS',
  INCOMING_USER_FAILURE: 'INCOMING_USER_FAILURE',    

  INCOMING_SOCKET_USER: 'INCOMING_SOCKET_USER',
  REMOVE_INCOMING_USER: 'REMOVE_INCOMING_USER',
  ADD_USER_TO_AGENT: 'ADD_USER_TO_AGENT',

  AGENT_AVAILABILITY_SET_REQUEST: 'AGENT_AVAILABILITY_SET_REQUEST',
  AGENT_AVAILABILITY_SET_SUCCESS: 'AGENT_AVAILABILITY_SET_SUCCESS',
  AGENT_AVAILABILITY_SET_FAILURE: 'AGENT_AVAILABILITY_SET_FAILURE',

  AGENT_QUEUE_LIST_SET_REQUEST: 'AGENT_QUEUE_LIST_SET_REQUEST',
  AGENT_QUEUE_LIST_SET_SUCCESS: 'AGENT_QUEUE_LIST_SET_SUCCESS',
  AGENT_QUEUE_LIST_SET_FAILURE: 'AGENT_QUEUE_LIST_SET_FAILURE',

  REASSIGN_AGENT_SUCCESS: 'REASSIGN_AGENT_SUCCESS',
}
