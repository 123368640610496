import moment from 'moment'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import 'react-virtualized-select/styles.css'
import { Button, Col, Collapse, Row } from 'reactstrap'
import styled from 'styled-components'
import { history } from '../../../../../redux/history'
import CustomView from './grid/CustomView'
// import { JsonDictComponent } from "./jsonInput/jsonDict";
const format = require('string-format')

const CardBackground = styled.section`
	background-color: ${props => props.backgroundColor};
	color: ${props => props.color};
`
// const RowBackground = styled.section`
//         background-color:${props => props.backgroundColor};
//         color:${props => props.color}
//         `;
// const BadgeStyle = styled.section`
//         background-color:${props => props.backgroundColor};
//         color:${props => props.color}
//         `;

class CardLayout extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cardData: this.props.data ? this.props.data : [],
			meta: this.props.meta,
			columns: this.props.columns,
			itemDef: this.props.itemDef,
		}
		this.columnRender = this.columnRender.bind(this)
	}
	componentWillReceiveProps(props) {
		this.setState({
			cardData: props.data,
			columns: this.props.columns,
		})
	}

	getFormatedOutput({ fields, item, field }) {
		const {t} = this.props
		let data = ''
		if (item && item[fields[field.name].name] && Array.isArray(item[fields[field.name].name])) {
			if (item[fields[field.name].name].length > 0) {
			  item[fields[field.name].name].forEach(elem => {
				if (!Array.isArray(elem) && typeof elem === 'object') {
				  Object.keys(elem).map(k => {
					if (Array.isArray(elem[k])) {
					  data += JSON.stringify(elem)
					} else {
					  data += `${k}: ${elem[k]}`
					}
				  })
				  data += '|'
				} else {
				  data += elem + '|'
				}
			  })
			  // data = item[fields[field.name].name].join(' | ')
			}
		} else if (item && ['date', 'datetime-local'].indexOf(fields[field.name].type) !== -1 && item[fields[field.name].name]) {
			data = moment(item[fields[field.name].name]).tz(moment.tz.guess()).format('MMM Do YY')
		} else if (item && item[fields[field.name].name] && typeof item[fields[field.name].name] === 'object' && item[fields[field.name].name].constructor === Object) {
			data = JSON.stringify(item[fields[field.name].name])
		} else if (item && typeof item[fields[field.name].name] == 'boolean') {
			data = item[fields[field.name].name].toString()
		} else if (item && item[fields[field.name].name]) {
			data = item[fields[field.name].name]
		} else {
			data = 'N/A'
		}

		if (data && data.length > 50) {
			return <span title={data}>{data.substring(0, 50)} ...</span>
		} else {
			return t(data)
		}

		// return (item[fields[field.name].name] &&  Array.isArray(item[fields[field.name].name]) && (item[fields[field.name].name].length>0 ? item[fields[field.name].name].join(' | ')
		//                                 : 'N/A'))
		//                                 ||
		//                                 (['date', 'datetime-local'].indexOf(fields[field.name].type) !== -1 ? item[fields[field.name].name] && moment(item[fields[field.name].name]).tz(moment.tz.guess()).format("MMM Do YY") : item[fields[field.name].name] && typeof item[fields[field.name].name] === 'object' && item[fields[field.name].name].constructor === Object && JSON.stringify(item[fields[field.name].name]))
		//                                 ||
		//                                 ((typeof item[fields[field.name].name] == 'boolean' && item[fields[field.name].name].toString()))
		//                                 ||
		//                                 (item[fields[field.name].name] || item[field.name] || 'N/A')
	}

	columnRender(item, fields, field) {
		const { t } = this.props
		if (!field.name) return true
		let fieldLabel = fields[field.name].label
		switch (field.htmlTag) {
			case 'small':
				return (
					fields[field.name] && (
						<small className={fields[field.name].backgroundColor ? `badge badge-${fields[field.name].backgroundColor}` : ''}>
							<strong className="label-text">{fields[field.name].label}</strong>: {item[fields[field.name].name] || item[field.name]}
						</small>
					)
				)
			case 'h2':
				return (
					fields[field.name] && (
						<h6>
							<strong className="label-text">{fields[field.name].label}</strong>:{' '}
							{['date', 'datetime-local'].indexOf(fields[field.name].type) !== -1
								? item[fields[field.name].name] && moment(item[fields[field.name].name]).format('MMM Do YY')
								: item[fields[field.name].name] || item[field.name]}
						</h6>
					)
				)
			case 'ul':
				return (
					fields[field.name] && (
						<div className="qa-toggle">
							<strong className="label-text">{fields[field.name].label}</strong>{' '}
							<h2 color="primary" onClick={this.toggleCollapse} style={{ fontSize: '14px', marginBottom: '2px' }}>
								{typeof item[fields[field.name].name] === 'string' && item[fields[field.name].name]}
							</h2>
							<Collapse isOpen={true}>
								{typeof item[fields[field.name].name] === 'object' && <CustomView data={item[fields[field.name].name]} />}
								{item[fields[field.name].name] && item[fields[field.name].name].pop && (
									<ul style={{ marginBottom: '5px', fontSize: '14px' }} className="pl-2">
										{item[fields[field.name].name] && item[fields[field.name].name].map(item => <li>- {item}</li>)}
									</ul>
								)}
							</Collapse>
						</div>
					)
				)
			default:
				return (
					field.name && (
						<>
							{fields[field.name] && fields[field.name].type === 'image' && item[fields[field.name].name] && (
								<div className="img-card mb-2">
									<img alt="Icon" title="logo" src={item[fields[field.name].name]} />
								</div>
							)}
							{/* { field.type==="action" &&
                      <Button color="primary" size="sm">{actions[field.name].label}</Button>
                    } */}
							{fields[field.name] && fields[field.name].type !== 'image' && (
								// <span className={fields[field.name].backgroundColor ? `badge badge-${fields[field.name].backgroundColor}` : ''}>
								// <strong className="label-text" >{fields[field.name].label}</strong>:{' '}
								// {(item[fields[field.name].name] && item[fields[field.name].name].pop && item[fields[field.name].name].join('|')) || (['date','datetime-local'].indexOf(fields[field.name].type))!=-1? item[fields[field.name].name] && moment(item[fields[field.name].name]).format("MMM Do YY") :item[fields[field.name].name] || item[field.name]}
								// </span>
								<span className={fields[field.name].backgroundColor ? `badge badge-${fields[field.name].backgroundColor}` : ''}>
									{field && fields && field.name && fields[field.name] && !fields[field.name].hideLabel && (
										<strong className="label-text">{t(fieldLabel)}:</strong>
									)}{' '}
									{this.getFormatedOutput({ fields, item, field })}
								</span>
							)}
						</>
					)
				)
		}
	}
	groupBy = key => array =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = obj[key]
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
			return objectsByKeyValue
		}, {})
	itemCellClick(row) {
		const {
			meta: { idKey, bKeys },
			itemDef,
		} = this.state
		if (itemDef && itemDef.customAction && itemDef.customAction.collectFields) {
			let propsValues = {}
			itemDef.customAction.collectFields.forEach(f => {
				propsValues[f.stateName] = row[f.fieldName]
			})
			propsValues['otherProps'] = []
			itemDef.customAction.otherProps &&
				itemDef.customAction.otherProps.forEach(f => {
					propsValues['otherProps'].push(row[f])
				})
			this.props.handleCardAction(propsValues)
		} else {
			let idObj
			if (idKey) {
				idObj = row[idKey]
			} else if (bKeys) {
				idObj = {}
				bKeys.pop &&
					bKeys.forEach(k => {
						if (k === 'tenantId') {
							//TODO encode as own | default here...
							idObj[k] = row[k]
						} else idObj[k] = row[k]
					})
			}
			this.props.itemClick(idObj)
		}
	}
	handlClick(actionMeta, item) {
		this.props.handleListAction(actionMeta, item, this.props.t)
	}

	handleCustomAction(action, item) {
		const {
			meta: { actions, bKeys },
			itemDef,
		} = this.state
		const {
			entity: { refEntityList },
			params: { itemId },
		} = this.props
		let idObj = {}
		let paramsSplit = itemId && itemId.split('__')
		paramsSplit &&
			bKeys.pop &&
			bKeys.forEach((k, i) => {
				idObj[k] = paramsSplit[i]
			})
		let actionDef = actions[action.name]
		const entityName = itemDef.dataSource && itemDef.dataSource.api && itemDef.dataSource.api.entityName
		if (entityName) {
			let data = refEntityList[entityName]
			data = Object.assign({}, data, item)
			let formBody = {}
			let urlPath = format(actionDef.path, idObj)
			const body = actionDef.body
			body &&
				Object.keys(body).map(key => {
					let responseDef = body[key].split('.')
					let res = responseDef.reduce((p, c) => {
						return p[c]
					}, data)
					if (!res) res = body[key]
					formBody = Object.assign({}, formBody, { [key]: res })
					return key
				})
			this.props.handleCustomAction({ actionMeta: actionDef, entityValues: formBody, urlPath: urlPath, t: this.props.t })
			setTimeout(() => this.props.getRequest(this.props.t), 700)
		}
	}

	render() {
		const {
			cardData,
			meta,
			meta: { actions },
			columns,
			itemDef,
		} = this.state
		const {t} = this.props.t
		const fields = meta.fields
		// this.props.column && fields && Object.keys(meta.fields)
		//     .filter(key => meta.fields[key].indexOf(this.props.column.map(x=>x.key))!=-1)
		//     .reduce((obj, key) => {
		//         obj[key] = meta.fields[key];
		//         return obj;
		//     }, {});
		let groupByFieldsRow = this.groupBy('row')
		const rowFields = groupByFieldsRow(columns)
		const rowSeprators = columns && columns.filter(r => r.type === 'seprator')
		const metaActions = columns && columns.filter(r => r.type === 'action')
		let customActions = itemDef && itemDef.customAction && itemDef.customAction && itemDef.customAction.actions
		let defaultCards = this.props.defaultCards
		return (
			<Row className="mt-4 mb-5">
				{cardData &&
					cardData.length > 0 &&
					cardData.map((item, index) => (
						<React.Fragment key={index}>
							<Col sm={this.props.cardPerRow ? 12 / this.props.cardPerRow : '4'} key={index}>
								<div
									className="for-card-component"
									style={{ width: `${customActions && customActions.length > 0 ? `${100 - 6 * customActions.length}%` : '100%'}` }}>
									<div className="my-card card border-light shadow-sm">
										<CardBackground backgroundColor={this.props.backgroundColor} color={this.props.color ? this.props.color : '#000'}>
											{rowFields &&
												Object.keys(rowFields).map((row, index) => {
													return (
														row &&
														row !== 'undefined' && (
															<span key={index}>
																{rowSeprators &&
																	rowSeprators
																		.filter((seprator, i) => seprator.afterRow === Number(row) - 1)
																		.map(i => <div className="line-seprator" key={i}></div>)}
																<Row className="bg-transparent p-2 m-0" style={{ cursor: 'pointer' }} key={index} onClick={this.itemCellClick.bind(this, item)}>
																	{rowFields[row] &&
																		rowFields[row]
																			.sort((a, b) => a.col - b.col)
																			.map((f, i) => {
																				let curColumn = this.state.columns.find(c => c.key === f.name)
																				let curField = curColumn && fields[curColumn.name]
																				let colSize = rowFields[row].length
																				rowFields[row].forEach(c => {
																					if (fields && fields[c.name] && fields[c.name].type === 'hidden') colSize = -1
																				})
																				if (curField && curField.type === 'hidden') return <span key={i}></span>

																				return (
																					<Col sm={colSize > 1 ? 12 / colSize : '12'} key={i}>
																						{this.state.columns && curColumn.shown && this.columnRender(item, fields, f)}
																					</Col>
																				)
																			})}
																</Row>
															</span>
														)
													)
												})}
											{actions && metaActions && metaActions.length > 0 && (
												<Row className="bg-transparent p-2 m-0" key={index}>
													{
														<Col sm={'12'} className={`text-right`}>
															{actions &&
																metaActions &&
																metaActions.map((action, index) => {
																	if (action.name === 'publish' && item.status === 'Published') return <span key={action.name + index}></span>
																	else
																		return (
																			<span key={action.name + index}>
																				{actions[action.name] && actions[action.name].action && (
																					<Button
																						key={action.name + index}
																						onClick={this.handlClick.bind(this, actions[action.name], item)}
																						color={`${
																							actions[action.name] && actions[action.name].backgroundColor ? actions[action.name].backgroundColor : 'primary'
																						}`}
																						size="sm">
																						{t(actions[action.name].label)}
																					</Button>
																				)}{' '}
																			</span>
																		)
																})}
														</Col>
													}
												</Row>
											)}
										</CardBackground>
									</div>
								</div>
								{customActions &&
									customActions.map(a => {
										let actionDef = actions[a.name]
										return (
											<span
												title={t(actionDef.label)}
												alt={actionDef.label}
												onClick={this.handleCustomAction.bind(this, a, item)}
												key={a.name}
												style={{ width: '6%', color: 'lightgray' }}
												className="btn-card">
												<i className="fa fa-check-circle fa-2x"></i>
											</span>
										)
									})}
							</Col>
							{cardData.length - 1 === index &&
								defaultCards &&
								defaultCards.length > 0 &&
								defaultCards.map((card, indx) => {
									if (card.type !== 'action') return <></>
									let actionDef = actions[card.name]
									return (
										<Col sm={this.props.cardPerRow ? 12 / this.props.cardPerRow : '4'} key={index}>
											<div
												className="for-card-component"
												style={{ width: `${customActions && customActions.length > 0 ? `${100 - 6 * customActions.length}%` : '100%'}` }}>
												<div className="my-card card border-light shadow-sm default-card">
													<CardBackground backgroundColor={this.props.backgroundColor} color={this.props.color ? this.props.color : '#000'}>
														<h4 className="add">
															{' '}
															<span className="add-ico" onClick={() => history.push(`/${actionDef.redirectPath}`)}>
																<i className={`${actionDef.classes}`}></i>
																{t && t(actionDef.label)}
															</span>
														</h4>
													</CardBackground>
												</div>
											</div>
										</Col>
									)
								})}
						</React.Fragment>
					))}
			</Row>
		)
	}
}
export default withTranslation()(CardLayout)
