import { BaseActions } from '..'
import { defineClassificationServices } from '../services/defineClassification.services'

let options = {}
options.service = defineClassificationServices
options.resourceName = 'dimension'

class DefineClassificationActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
}
let DefineClassificationActions = new DefineClassificationActionModel(options)
export const getData = (queryStringArr, t, apiUrl) => DefineClassificationActions.getAll(queryStringArr, t, apiUrl)
export const addData = (data, t, apiUrl) => DefineClassificationActions.addNewItem(data, t, apiUrl)
export const deleteData = (id, t, apiUrl) => DefineClassificationActions.deleteItem(id, t, apiUrl)
export const updateData = (data, id, t, apiUrl) => DefineClassificationActions.editItem(data, id, t, apiUrl)
