import React, { Component } from "react";
import FilterReport from './FilterReports';
import '../style.css'
import { withTranslation } from 'react-i18next';
class Reports extends Component {
  render() {
    const newProps = { ...this.props, ...this.state }
    //const { reports: { loading }, t } = this.props;
    return (
      <div className="animated fadeIn">
        {/* {loading && loading == true &&
          <div className="outer-overlay loaderpos">
            <div className="ov-content">
              <div className="row qnamaker-spinner">
                <div className="spinner-circle-mod">
                  <img src="assets/img/main-loader.gif" alt="loading" />
                </div>
              </div>
              <div className="row spinner">
                <div className="loading-text">
                  {t('Please wait while we prepare the Report(s)')}
                </div>
                <div className="loading-text">

                </div>
              </div>
            </div>
          </div>
        } */}
        <div className="common-heading">
          {alert.message &&
            <div className={`alert ${alert.type}`}>
              {alert.message}
            </div>
          }

        </div>
        <div className="main-section">
          <div className="main-inner">
            <FilterReport {...newProps} />
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslation()(Reports);


