import { identityServices } from '../../'
// import { apiUrl } from '../../../../config'

export class BaseService {
	constructor(options) {
		//super(options)
		this.urlItemName = options.urlItemName
		this.apiUrl = options.apiUrl || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
		this.wsType = options.wsType || null
		this.wsEnabled = options.wsEnabled || false
		this.modulePath = options.apiModuleUrl
	}
	async getAll(tenantId, queryStringArr, targetTenantDomain, isAdavanceSearch, apiUrl, headerData) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		let url
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				isAdavanceSearch: isAdavanceSearch ? true : false,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}

		if (headerData) {
			axiosConfig.headers = { ...axiosConfig.headers, ...headerData }
		}

		url = `${this.apiHostUrl}/${this.urlItemName}?${queryStringArr}`

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})
		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})
		// }

		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}

	async addNew(item, customerTenantID, targetTenantDomain, apiUrl, headerData) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let url = `${this.apiHostUrl}/${this.urlItemName}`
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		if (headerData) {
			axiosConfig.headers = { ...axiosConfig.headers, ...headerData }
		}
		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})
		//     item = Object.assign({}, item, {workspace: {key: wsData[this.wsType].key, value: wsData[this.wsType].value}})
		// }
		let response = await identityServices.identityApi.post(url, item, axiosConfig)
		return response.data
	}

	async editRecord(item, customerTenantID, editId, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let url = `${this.apiHostUrl}/${this.urlItemName}/update/${editId}`

		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})
		// }
		let response = await identityServices.identityApi.put(url, JSON.stringify(item), axiosConfig)
		return response.data
	}

	async updateTicketStatus(item, customerTenantID, editId, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let url = `${this.apiHostUrl}/${this.urlItemName}/updateTicket/${editId}`
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})
		// }
		let response = await identityServices.identityApi.put(url, JSON.stringify(item), axiosConfig)
		return response.data
	}

	deleteRecord(id, customerTenantID, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (customerTenantID !== undefined && customerTenantID !== null) ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl;
		let requestOptions = {
			method: 'DELETE',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}/${id}`,
		}

		let wsData = localStorage.getItem('workspace')
		let wsData1 = wsData && wsData != undefined ? JSON.parse(wsData) : null
		if (this.wsType && wsData1 && wsData1[this.wsType] && wsData1[this.wsType].key && wsData1[this.wsType].value && wsData1[this.wsType]) {
			// requestOptions.headers = Object.assign({}, requestOptions.headers, {"usecase": wsData1[this.wsType].value})
		}
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			return response.data
		})
	}

	deleteRecordWithParams(id, paramId, customerTenantID, apiUrl) {
		//let apiUrlCustom = (customerTenantID !== undefined && customerTenantID !== null) ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl;
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let requestOptions = {
			method: 'DELETE',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}/${paramId}/${id}`,
		}

		let wsData = localStorage.getItem('workspace')
		let wsData1 = wsData && wsData != undefined ? JSON.parse(wsData) : null
		if (this.wsType && wsData1 && wsData1[this.wsType] && wsData1[this.wsType].key && wsData1[this.wsType].value && wsData1[this.wsType]) {
			// requestOptions.headers = Object.assign({}, requestOptions.headers, {"usecase": wsData1[this.wsType].value})
		}
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response && response.status && response.status >= 200 && response.status < 300) return response
		})
	}

	async editRecordWithParams(item, paramId, customerTenantID, editId, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let url = `${this.apiHostUrl}/${this.urlItemName}/${paramId}/${editId}`

		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		return identityServices.identityApi.put(url, JSON.stringify(item), axiosConfig).then(response => {
			if (response && response.status && response.status >= 200 && response.status < 300) return response
		})
	}

	async getAllWithParams(tenantId, id, queryStringArr, targetTenantDomain, apiUrl) {
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		let url = `${this.apiHostUrl}/${this.urlItemName}/${id}?${queryStringArr}`
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})
		// }
		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}

	async addNewWithParams(item, id, customerTenantID, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let url = `${this.apiHostUrl}/${this.urlItemName}/${id}`

		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		let wsData = localStorage.getItem('workspace')
		let wsData1 = wsData && wsData != undefined ? JSON.parse(wsData) : null
		if (this.wsType && wsData1 && wsData1[this.wsType] && wsData1[this.wsType].key && wsData1[this.wsType].value && wsData1[this.wsType]) {
			// axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData1[this.wsType].value})
			item = Object.assign({}, item, { workspace: { key: wsData1[this.wsType].key, value: wsData1[this.wsType].value } })
		}
		let response = await identityServices.identityApi.post(url, item, axiosConfig)
		return response.data
	}

	notifyEmail(id, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (customerTenantID !== undefined && customerTenantID !== null) ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl;
		let requestOptions = {
			method: 'POST',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: {},
			url: `${this.apiHostUrl}/CaseValue/sendMail/${id}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     requestOptions.headers = Object.assign({}, requestOptions.headers, {"usecase": wsData[this.wsType].value})
		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}
			return response
		})
	}

	async addAttachmentWithParams(item, id, customerTenantID, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (customerTenantID !== undefined && customerTenantID !== null) ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl;
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}
		let url = `${this.apiHostUrl}/${this.urlItemName}/${id}`

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})
		// }
		if (Array.isArray(item)) {
			let attachments = item.map(attachment => {
				return identityServices.identityApi.post(url, attachment, axiosConfig)
			})
			let response = await Promise.all(attachments)
			return response && Array.isArray(response) && response.map(x => x.data)
		}
		let response = await identityServices.identityApi.post(url, item, axiosConfig)
		return response.data
	}

	//get all assigned tickets
	getAssignedTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let headerSearch = null
		if (searchParams && searchParams.length > 0) {
			headerSearch = JSON.stringify(searchParams)
		} else {
			headerSearch = null
		}

		let requestOptions = {
			method: 'GET',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', headerSearch, clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}/assignedToMe?${queryStringArr}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // requestOptions.headers = Object.assign({}, requestOptions.headers, {"usecase": wsData[this.wsType].value})
		//     requestOptions.url = getWsQueryGetTicketing({url: requestOptions.url, wsType: this.wsType})
		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	async getTicketsByFilter(tenantId, queryStringArr, sortOrder, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`

		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}

		let url = `${this.apiHostUrl}/${this.urlItemName}/getTicketsByFilter?${queryStringArr}`

		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}
	//get My All Tickets
	async getSubmittedByTickets(tenantId, queryStringArr, sortOrder, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`

		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
			},
		}

		let url = `${this.apiHostUrl}/${this.urlItemName}/submittedByMe?${queryStringArr}`

		let wsData = localStorage.getItem('workspace')
		let wsData1 = wsData && wsData != undefined ? JSON.parse(wsData) : null
		if (this.wsType && wsData1 && wsData1[this.wsType] && wsData1[this.wsType].key && wsData1[this.wsType].value && wsData1[this.wsType]) {
		}
		// axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})

		// }
		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}

	//

	async getAllPendingWithMeTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let headerSearch = null
		if (searchParams && searchParams.length > 0) {
			headerSearch = JSON.stringify(searchParams)
		} else {
			headerSearch = null
		}
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
				headerSearch,
			},
		}

		let url
		if (targetTenantDomain) {
			url = `${this.apiHostUrl}/${this.urlItemName}`
		} else {
			url = `${this.apiHostUrl}/${this.urlItemName}/pendingWithMe`
		}
		url += `?${queryStringArr}`

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})

		// }

		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}

	async getAllWatchingTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let headerSearch = null
		if (searchParams && searchParams.length > 0) {
			headerSearch = JSON.stringify(searchParams)
		} else {
			headerSearch = null
		}
		let axiosConfig = {
			headers: {
				Pragma: 'no-cache',
				'Content-Type': 'application/json',
				targettenantdomain: targetTenantDomain,
				clientApp: targetTenantDomain ? 'tickets' : window.location.pathname.split('/')[1],
				headerSearch,
			},
		}

		let url
		if (targetTenantDomain) {
			url = `${this.apiHostUrl}/${this.urlItemName}`
		} else {
			url = `${this.apiHostUrl}/${this.urlItemName}/AllWatching`
		}
		url += `?${queryStringArr}`

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     url = getWsQueryGetTicketing({url: url, wsType: this.wsType})

		// }

		let response = await identityServices.identityApi.get(url, axiosConfig)
		return response.data
	}

	//get My All Tickets
	getMyAllTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let headerSearch = null
		if (searchParams && searchParams.length > 0) {
			headerSearch = JSON.stringify(searchParams)
		} else {
			headerSearch = null
		}
		let requestOptions = {
			method: 'GET',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', headerSearch, clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}/myAllTickets?${queryStringArr}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     requestOptions.headers = Object.assign({}, requestOptions.headers, {"usecase": wsData[this.wsType].value})
		// }

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     requestOptions.url = getWsQueryGetTicketing({url: requestOptions.url, wsType: this.wsType})

		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	getSearchItems(tenantId, queryStringArr, sortOrder, apiUrl) {
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		let requestOptions = {
			method: 'GET',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}?${queryStringArr}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     requestOptions.url = getWsQueryGetTicketing({url: requestOptions.url, wsType: this.wsType})

		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	//get all assigned tickets
	getMyQueueTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl) {
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (queryStringArr !== '') {
			queryStringArr += '&'
		}
		queryStringArr += `$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let headerSearch = null
		if (searchParams && searchParams.length > 0) {
			headerSearch = JSON.stringify(searchParams)
		} else {
			headerSearch = null
		}
		let requestOptions = {
			method: 'GET',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', headerSearch, clientApp: window.location.pathname.split('/')[1] },
			// timeout: 15000,
			url: `${this.apiHostUrl}/${this.urlItemName}/assignedToMyQueue?${queryStringArr}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     requestOptions.url = getWsQueryGetTicketing({url: requestOptions.url, wsType: this.wsType})

		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	//get  All Tickets
	getAllTickets(tenantId, queryStringArr, sortOrder, apiUrl) {
		//let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${this.apiHostUrl}/${tenantId}` : apiUrl;
		if ((apiUrl && !this.apiHostUrl) || (apiUrl && !this.apiHostUrl.includes(apiUrl))) this.apiHostUrl = `${apiUrl}/${this.modulePath}`
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		if (sortOrder && sortOrder.orderBy) queryStringArr += `&$orderby=${sortOrder.orderBy} ${sortOrder.order}`
		let requestOptions = {
			method: 'GET',
			headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${this.apiHostUrl}/${this.urlItemName}/getAllTickets?${queryStringArr}`,
		}

		// let wsData = localStorage.getItem('workspace')
		// wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null
		// if(this.wsType && wsData && wsData[this.wsType] && wsData[this.wsType].key && wsData[this.wsType].value && wsData[this.wsType]) {
		//     // axiosConfig.headers = Object.assign({}, axiosConfig.headers, {"usecase": wsData[this.wsType].value})

		//     requestOptions.url = getWsQueryGetTicketing({url: requestOptions.url, wsType: this.wsType})

		// }
		// @ts-ignore
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
}

// class Qnaservice extends BaseService {
//     publish(){

//     }
// }
