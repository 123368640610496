import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { CustomFieldView, CustomView, DetailPage, WorkflowButton } from '..'
import { utils } from '../../../..'
import CopyAction from '../CopyAction'
import './style.scss'
import Table from './Table'
//Locale

function ExpendableDataGrid(propsMeta) {
	const [listData, setListData] = useState([])
	const [selectedIndex] = useState(-1)
	const [setCheckboxStates] = useState()
	const [preSelect, setPreSelect] = useState(null)
	const [copyMode, setCopyMode] = useState(false)
	const [rowData, setRowData] = useState()
	const [fieldMeta, setFieldMeta] = useState({})
	// const tableRef = useRef();
	const {
		list,
		meta,
		columns,
		handleListAction,
		entity,
		itemDef,
		itemClick,
		itemCellLookUpClick,
		handleSelectItems,
		setGridParentData,
		gridParentData,
		preSelectedIndexes,
		handleUpdateEntity,
		t,
		selectedIndexes,
		editDisable,
		tenant
	} = propsMeta
	const { fields } = meta
	useEffect(() => {
		const { preSelectedIndexes } = propsMeta
		if (!preSelect && preSelectedIndexes && preSelectedIndexes != undefined && preSelectedIndexes.length > 0) {
			setPreSelect(preSelectedIndexes)
			// setCheckboxStates(preSelectedIndexes)
		}
	}, [propsMeta.preSelectedIndexes])

	// useEffect(() => {
	//   preSelect && setCheckboxStates(preSelect)
	// }, [preSelect])

	const mapExpendedRowIndex = (index, isClearParent) => {
		// setSelectedIndex(index)
		if (index === -1) return true
		let currentRow = list && list.find((d, idx) => idx === index)
		setGridParentData(currentRow, index, itemDef, isClearParent)
	}

	// const mapChecked = (selectedRowIds, selectedFlatRows) => {
	//   //let indexes = Object.keys(selectedRowIds);
	//   // let currentSelectedRow = list && list[rowIndex];
	//   // let collectFields = itemDef && itemDef.fieldToCollect;
	//   // if (currentSelectedRow && collectFields && collectFields.length > 0) {
	//   //   var pickedFieldEntity = {};
	//   //   collectFields.forEach(function (prop) {
	//   //     pickedFieldEntity[prop] = currentSelectedRow[prop];
	//   //   });
	//   //if (!pickedFieldEntity) return true;

	//   //}
	//   // let newChecked = [...indexes || []]
	//   // if(newChecked && newChecked.length>0){
	//   //   handleCurrentSelectIndexes(newChecked)
	//   //   setPreSelect(newChecked)
	//   // }
	// }
	// React.useEffect(()=>{
	//   handleSelectItems(preSelect)
	// }, [preSelect])

	const onHandleCheckbox = selectedRowIds => {
		handleSelectItems(selectedRowIds)
	}

	const itemCellClick = (obj, rowData) => {
		itemClick(obj, rowData)
	}
	const itemCellLookUpHandle = itemDef => {
		itemCellLookUpClick(itemDef)
	}

	const handleItemClickLink = (path, id) => {
		let idKey
		if (typeof id === 'object') {
			idKey = ''
			idKey = Object.values(id).join('__')
		} else idKey = id
		window.location = `${window.location.protocol}//${window.location.host}/${path}/update/${idKey}`
	}

	const handleAction = (actionDef, rowData, rowIndex) => {
		// let idObj;
		const { lookUpMeta } = meta
		let entityValues = entity.entityValues
		let lookupDef = lookUpMeta && lookUpMeta[itemDef.name]
		if (lookupDef && itemDef && lookupDef.fieldToCollect) {
			let filterData = list && list.filter((item, index) => index !== rowIndex)
			if (lookupDef.parentOf && gridParentData) {
				if (gridParentData && gridParentData.length > 0) {
					let updatedItems
					updatedItems = gridParentData.reduce((p, c) => {
						let childItem = c.value
						if (childItem) childItem[itemDef.name] = filterData
						let parentItem = p.value
						if (parentItem) {
							parentItem[c.key] =
								parentItem[c.key] &&
								parentItem[c.key].map((r, i) => {
									if (i === c.rowIndex) return childItem
									else return r
								})
						}
						return parentItem
					})

					if (updatedItems) {
						let entityValues = { ...entity.entityValues }
						let parentNode = gridParentData[0]
						entityValues[parentNode.key] =
							entityValues[parentNode.key] &&
							entityValues[parentNode.key].map((p, index) => {
								if (index === parentNode.rowIndex) return updatedItems
								else return p
							})
						handleUpdateEntity(entityValues)
					}
				} else if (lookupDef.actions && actionDef && actionDef.name && actionDef.name === 'delete') {
					let removeList = list.splice(rowIndex, 1)
					setListData(removeList)
				}
			} else {
				entityValues[itemDef.name] = filterData
			}
		} else {
			if (actionDef && actionDef.name && actionDef.name === 'delete') {
				rowData.status = 'Deleted'
				handleListAction(actionDef, rowData)
			} else if (actionDef && actionDef.name && actionDef.name === 'publish') {
				rowData.status = 'Published'
				handleListAction(actionDef, rowData)
			} else {
				handleListAction(actionDef, rowData)
			}
		}
	}



	const actionButton = (rowData, field) => {
		setRowData(rowData)
		setCopyMode(true)
		setFieldMeta(field)
	}

	const handleLinkAction = rowData => {
		let idObj
		const { idKey, bKeys, lookUpMeta } = meta
		let lookupDef = itemDef && lookUpMeta && lookUpMeta[itemDef.name]
		if (itemDef && lookupDef && lookupDef.fieldToCollect) {
			let bk = lookupDef.bKeys || lookupDef.fieldToCollect
			idObj = {}
			bk.pop &&
				bk.forEach(k => {
					if (k === 'tenantId') {
						//TODO encode as own | default here...
						idObj[k] = rowData[k]
					} else idObj[k] = rowData[k]
				})

			handleItemClickLink(itemDef.pathName, idObj)
		} else {
			if (idKey) {
				idObj = rowData[idKey]
			} else if (bKeys) {
				idObj = {}
				bKeys.pop &&
					bKeys.forEach(k => {
						if (k === 'tenantId') {
							idObj[k] = rowData[k]
						} else idObj[k] = rowData[k]
					})
			}
			itemCellClick(idObj, rowData)
		}
	}

	const cellFormator = (data, accessor) => {
		let rowData = data && data.row && data.row.original
		let cellData = data.value
		let rowIndex = data && data.row && data.row.index
		let columnType = columns && columns.find(c => c.name === accessor)
		const { fields } = meta
		// let idObj, value
		if (columnType && columnType.type === 'actions') {
			return (
				<div className="action-buttons">
					{columnType.actions.map((a, i, arr) => {
						let aDef = meta.actions[a]
						if (aDef && aDef.type === 'span') {
							return (
								<span key={i} title={t(aDef.label) || aDef.label} className="al-icon-col" onClick={handleAction.bind(this, aDef, rowData)}>
									{aDef.classes && <i className={aDef.classes}></i>}
								</span>
							)
						} else if (aDef && aDef.type === 'button') {
							return (
								<button
									key={a}
									title={t(aDef.label) || t(aDef.label)}
									className="min-btn btn btn-primary"
									disabled={columns[i].disabled}
									onClick={handleAction.bind(this, aDef, rowData, rowIndex)}>
									{t(aDef.label) || t(a)}
									<i className={aDef.classes}></i>
								</button>
							)
						} else if (aDef && aDef.type === 'link') {
							return (
								<button key={a} title={t(aDef.label) || aDef.label} className="btn btn-link" onClick={handleLinkAction.bind(this, rowData)}>
									{t(aDef.label) || t(a)}
									<i className={aDef.classes}></i>
								</button>
							)
						} else return <span key={a}></span>
					})}{' '}
				</div>
			)
		}
		if (!data.value || data.value == '') return <>{t('N/A')}</>
		let field = columnType && fields[columnType.name]
		if (columnType && columnType.type === 'thumbnail') {
			let thumbnailSource = field && field.source && rowData && rowData[field.source] ? rowData[field.source] : null
			if (thumbnailSource) {
				if (rowData && rowData.contentType && rowData.contentType.includes('video'))
					return (
						<a href={thumbnailSource} target="_blank" rel="noopener noreferrer">
							<span className="fa fa-file-video-o fa-2x"></span>
						</a>
					)
				else
					return (
						<a href={thumbnailSource} target="_blank" rel="noopener noreferrer">
							<img style={{ width: '40px', height: '35px' }} src={thumbnailSource} alt="" />
						</a>
					)
			}
		}
		if (field && field.type && ['date', 'datetime-local'].indexOf(field.type) !== -1) {
			return moment(cellData).format('MM/DD/YYYY')
		}
		if (typeof cellData === 'string' && columnType && columnType.type === 'link') {
			return (
				<button className="btn btn-link" onClick={handleLinkAction.bind(this, rowData)}>
					{' '}
					{(field && !field.ingoreT && t(cellData)) || cellData}{' '}
				</button>
			)
		} else if (typeof cellData === 'string' && columnType && columnType.type === 'actionButton') {
			return(
				<button className="btn btn-link" onClick={actionButton.bind(this, rowData, field)}>
					{' '}<i className="fa fa-clone" aria-hidden="true"></i>
					{/* {(field && !field.ingoreT && t(cellData)) || cellData}{' '} */}
				</button>
			)
		} else if (field && field.type === 'image') {
			return <img alt="Icon" title="Icon" src={cellData} />
		} else if (typeof cellData === 'string' && field && field.type === 'textEditor') {
			return <span dangerouslySetInnerHTML={{ __html: cellData }}></span>
		} else if (cellData && field && field.type === 'component') {
			return <CustomFieldView cellData={cellData} field={field} t />
		} else if (typeof cellData === 'string' && columnType && columnType.type === 'lookUp') {
			return (
				<button className="btn btn-link" onClick={itemCellLookUpHandle.bind(this, itemDef)}>
					{' '}
					{t(cellData)}{' '}
				</button>
			)
		} else if (columnType && columnType.type === 'workflowAction') {
			let itemDef = meta.workflowActions[columnType.name]
			let possibleStates = rowData[itemDef.ref]
			if (itemDef)
				return (
					<div key={itemDef.name}>
						<WorkflowButton workflowActionMeta={itemDef} {...propsMeta} possibleStates={possibleStates} />
					</div>
				)
		} else if (field && field.type === 'downloadLink' && cellData.indexOf('https://') != -1) {
			return (
				<Link color="teal.500" href={cellData} isExternal>
					{t('Click here to download')} <ExternalLinkIcon mx="2px" />
				</Link>
			)
		} else if (typeof cellData === 'string') {
			return cellData
		}
		else if (typeof cellData === 'object') return <CustomView data={cellData} />
		else if (cellData && cellData.pop) return cellData.join('|')
		else return cellData
	}

	const rowExpendDetailPage = props => {
		const { rowData, isExpanded } = props
		let detailPage = itemDef && itemDef.detailPage
		if (!(detailPage && isExpanded)) return <></>
		// let entityValues = rowData //list.find((d, index) => index === selectedIndex);
		let newProps = { ...propsMeta }
		if (newProps.subEntity) delete newProps['subEntity']
		if (newProps.subMeta) delete newProps['subMeta']
		if (newProps.selectedIndex) delete newProps['selectedIndex']
		if (newProps.entityValues) delete newProps['entityValues']
		return (
			<div className="child-table">
				<DetailPage
					itemCellLookUpClick={itemCellLookUpClick}
					meta={meta}
					subEntity={{ entityValues: rowData }}
					subMeta={detailPage}
					selectedIndex={selectedIndex}
					setGridParentData={setGridParentData}
					handleSelectItems={handleSelectItems}
					handleUpdateEntity={handleUpdateEntity}
					{...newProps}
				/>
			</div>
		)
	}

	const addNewHandle = props => {
		const { itemDef, addItemDef } = props
		if (itemDef && addItemDef && addItemDef.item && addItemDef.incrementField) {
			let entityVal = entity.entityValues
			let item = utils.cloneObject(addItemDef.item)
			if (entityVal && entityVal[itemDef.name] && entityVal[itemDef.name].length > 0) {
				item[addItemDef.incrementField] = item[addItemDef.incrementField] + Number(Number(entityVal[itemDef.name].length) + 1)
				if (entityVal[itemDef.name] && entityVal[itemDef.name].pop) entityVal[itemDef.name].push(item)
			} else if (entityVal) {
				item[addItemDef.incrementField] = item[addItemDef.incrementField] + 1
				entityVal[itemDef.name] = []
				if (entityVal[itemDef.name] && entityVal[itemDef.name].pop) entityVal[itemDef.name].push(item)
			}
			handleUpdateEntity(entityVal)
		}
	}

	let shownColumns = columns.map(c => Object.assign({}, c))
	shownColumns =
		shownColumns &&
		shownColumns
			.filter(c => c.shown || c.shown == undefined)
			.map(c => {
				return {
					accessor: c.name || c.key,
					Header:
						(fields && fields[c.name] && fields[c.name].label && t(fields[c.name].label) ? t(fields[c.name].label) : fields[c.name] && fields[c.name].label) ||
						t(c.name),
					Cell: row => cellFormator(row, c.name || c.key),
				}
			})
	let lookUpButton = itemDef && itemDef.lookUp
	let addItemDef = itemDef && itemDef.addMore
	let multiselect = false

	if (meta && meta.screens && meta.screens.list && meta.screens.list.multiselect) multiselect = true
	if (!multiselect && itemDef && itemDef.multiselect) multiselect = true

	let initialIndexes = {}
	preSelectedIndexes &&
		preSelectedIndexes.length > 0 &&
		preSelectedIndexes.forEach(i => {
			initialIndexes = Object.assign({}, initialIndexes, { [i]: true })
		})

	return (
		<>
			<div className="row">
				<div className="col-md-8">
					<h3>{t(itemDef && itemDef.title)}</h3>
				</div>
				<div className="col-md-4 pull-right">
					{addItemDef && (
						<Button className="icon-btn-link pull-right" color="link" onClick={addNewHandle.bind(this, { itemDef, addItemDef })}>
							<i className="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
						</Button>
					)}
				</div>
			</div>
			{lookUpButton && (
         		<Button className="dwnld pull-right" color="link" onClick={itemCellLookUpHandle.bind(this, itemDef)} disabled={editDisable}>
					{t('Add New')}
				</Button>
			)}
			{copyMode && (<CopyAction 
				{...propsMeta}
				fieldMeta={fieldMeta}
				mode={copyMode}
				rowData={rowData}
				tenant={tenant}
			/>)}
			<Table
				columns={shownColumns}
				data={list || listData || []}
				rowExpendDetailPage={rowExpendDetailPage}
				isExpandedRow={itemDef && itemDef.detailPage ? true : false}
				onHandleCheckbox={onHandleCheckbox}
				selectedIndexes={selectedIndexes}
				multiselect={multiselect}
				mapExpendedRowIndex={mapExpendedRowIndex}
				preSelectedIndexes={initialIndexes}
				i18n={propsMeta && propsMeta.i18n}
			/>
		</>
	)
}

export default withTranslation()(ExpendableDataGrid)
