import { usersListConstants, usersListServices } from '../'
import { alertActions } from '../../common'

const getAllUsersListRequest = () => ({
	type: usersListConstants.GETALL_REQUEST_userslist,
})

const getAllUsersListSuccess = data => ({
	type: usersListConstants.GETALL_SUCCESS_userslist,
	data,
})

const getAllUsersListFailure = error => ({
	type: usersListConstants.GETALL_FAILURE_userslist,
	error,
})

export const getAllUsersList = (filter, t) => {
	return dispatch => {
		dispatch(getAllUsersListRequest())
		let errorMsg = (t && t('Cannot fetch the users list at this moment, please try again later')) || 'Cannot fetch the users list at this moment, please try again later'
		usersListServices.getAllUsersList(filter).then(
			data => {
				if (data.name === 'error') {
					dispatch(getAllUsersListFailure(data.detail))
					dispatch(alertActions.error(errorMsg))
				} else {
					dispatch(getAllUsersListSuccess(data))
					// dispatch(alertActions.success('Q&A added successfully.'));
				}
			},
			error => {
				dispatch(getAllUsersListFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}
