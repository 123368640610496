import { alertActions, identityConstants, identityServices, settingsActions, settingsConstants, whiteLabelActions, WorkspaceAction } from '../'
import { appName, singleTenant } from '../../../config'
import { history } from '../../../redux/history'
import { reloadResources } from '../infra/i18n'
export const initiateLogin = () => {
	// sessionStorage.clear();
	if (localStorage.globalSetting) delete localStorage.globalSetting
	return {
		type: identityConstants.LOGIN_INITIATE,
		payload: {
			isInitiated: true,
			isAuthenticated: false,
		},
	}
}

export const loginCheckRequest = () => {
	//sessionStorage.clear();
	// localStorage.clear();
	return {
		type: identityConstants.LOGIN_CHECK,
		payload: {
			isInitiated: true,
		},
	}
}

export const loginCheckSuccess = () => {
	//sessionStorage.clear();
	return {
		type: identityConstants.LOGIN_CHECK,
		payload: {
			isInitiated: false,
		},
	}
}

export const loginSuccess = (tenantId, token) => {
	sessionStorage.setItem('keycloak_token', token)
	reloadResources(tenantId, token)
	return {
		type: identityConstants.LOGIN_SUCCESS,
		payload: {
			token: token,
		},
	}
}
export const loginSuccessMs = token => {
	sessionStorage.setItem('ms_access_token', token)

	return {
		type: identityConstants.LOGIN_SUCCESS,
		payload: {
			token: token,
		},
	}
}

export const loginFailure = () => {
	sessionStorage.clear()
	return {
		type: identityConstants.LOGIN_FAILURE,
		payload: {
			isAuthenticated: false,
			isInitiated: false,
			token: null,
			userInfo: {},
		},
	}
}

export const clearIdentity = () => {
	//sessionStorage.clear();
	return {
		type: identityConstants.LOGOUT_SUCCESS,
	}
}

export const clearGlobalSetting = () => {
	return {
		type: settingsConstants.CLEAR_GLOBAL_SETTING,
	}
}

export const logout = t => {
	return async (dispatch, getState) => {
		const { tenant, agentchat } = getState()
		if (agentchat && agentchat.isAgentAvailable === 'Available') {
			dispatch(alertActions.error(t('Cannot logout while you are available for chat as an agent, please turn off your availability status')))
		} else {
			dispatch(alertActions.info(t('Logging out...')))
			dispatch(clearIdentity())
			dispatch(clearGlobalSetting())
			await identityServices.logoutService(tenant)
		}
	}
}

export const setIdentitySuccess = (userInfo, tenant) => {
	return function (dispatch) {
		let keycloak = identityServices.keycloakInstances[tenant.tenantId]
		if (!singleTenant) {
			if (appName && appName.toLowerCase() === 'botmanagement') {
				dispatch(WorkspaceAction.GetWSForUser({ wsType: 'qna', attributes: userInfo, apiUrl: tenant && tenant.apiUrl }))
			}
			dispatch(settingsActions.getAllGlobalSettings(tenant.id, userInfo.preferred_username, null, tenant && tenant.apiUrl))
			dispatch(settingsActions.getAllTenantConfig((tenant && tenant.id) || null, null, tenant && tenant.apiUrl, userInfo))
			dispatch(settingsActions.getSsoData(tenant.tenantId, null, tenant && tenant.apiUrl))
			dispatch(whiteLabelActions.getDataWithDefaultTenant(tenant.id, null, tenant && tenant.apiUrl, userInfo))
		}

		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: {
				userInfo: userInfo,
				isAuthenticated: true,
				isInitiated: false,
				profile: {
					...userInfo,
					tenantId: tenant.tenantId,
					tenantUid: tenant.id,
					userId: userInfo.sub,
					roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					tenantRoles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
					// created_at: userInfo.profile.attributes.created_at[0],
				},
				roles: keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
				exp: keycloak.tokenParsed.exp,
				iat: keycloak.tokenParsed.iat,
				timeSkew: keycloak.timeSkew,
				expiryDate: new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString(),
			},
		})
	}
}
export const setIdentitySuccessMs = (userInfo, tenant) => {
	return function (dispatch) {
		//let keycloak = identityServices.keycloakInstances[tenant.tenantId]
		if (!singleTenant) {
			dispatch(settingsActions.getAllGlobalSettings(tenant.id, userInfo.profile.preferred_username, null, tenant && tenant.apiUrl))
			dispatch(settingsActions.getAllTenantConfig((tenant && tenant.id) || null), null, tenant && tenant.apiUrl)
			//dispatch(settingsActions.getSsoData(tenant.tenantId))
			dispatch(whiteLabelActions.getDataWithDefaultTenant(tenant.id, null, tenant && tenant.apiUrl))
		}

		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: {
				userInfo: userInfo.userInfo,
				isAuthenticated: true,
				isInitiated: false,
				profile: {
					...userInfo.userInfo,
					...userInfo.profile,
				},
				roles: userInfo.profile.roles, //keycloak.realmAccess && keycloak.realmAccess.roles ? keycloak.realmAccess.roles : null,
				exp: userInfo.exp,
				iat: userInfo.iat,
				timeSkew: userInfo.timeSkew || 0,
				expiryDate: userInfo.expiryDate,
			},
		})
	}
}

export const setIdentity = () => {
	return (dispatch, getState) => {
		const { tenant } = getState()
		//console.log(identityServices.keycloakInstances[tenant.tenantId])
		identityServices.keycloakInstances[tenant.tenantId]
			.loadUserInfo()
			.success(userInfo => dispatch(setIdentitySuccess(userInfo, tenant)))
			.error(err => dispatch(loginFailure()))
	}
}

export const checkIdentity = t => {
	return async (dispatch, getState) => {
		const { tenant, identity } = getState()
		if (tenant.isTenant) {
			dispatch(loginCheckRequest())
			identityServices.initiateIdentityService(tenant, dispatch)
			await identityServices.ensureAuthentication({ dispatch, identity, tenant, t })
		} else if (!singleTenant) {
			history.push('/error')
		}
	}
}

export const support = () => {
	if (window.location.pathname.split('/')[1] === 'tickets') {
		return async () => {
			history.push(`/${window.location.pathname.split('/')[1]}/Support`)
		}
	} else {
		return async () => {
			history.push('/Support')
		}
	}
}

// const getUserRole = async (tenantId, userId)=>{
//     let roles = await identityServices.getUserRoleById(tenantId, userId)
//     return roles;
// }

// const getRolesByTenant = async (tenantId)=>{
//     let roles = await identityServices.getRolesByTenant(tenantId)
//      roles = await Promise.all(roles.map(async role=>{
//         let _role = await identityServices.getTenantRoleById(tenantId, role.id)
//         return _role;
//      }))
//     return roles;
// }

export const getTenantRoleById = async (tenantId, roleId) => {
	let roles = await identityServices.getRolesByTenant(tenantId)
	return roles
}
