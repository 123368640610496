import moment from 'moment'
import React from 'react'
import Collapsible from 'react-collapsible'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import io from 'socket.io-client'
import { incomingUserAction } from '..'
import { session } from '../../../config'
class IncomingUsers extends React.Component {
	constructor(props) {
		super(props)
		this.socket = null
		this.state = {
			dropdownOpen: false,
			incomingUserData: [],
		}
		let { tenantId } = props

		setInterval(function () {
			props.dispatch(incomingUserAction.setIncomingUserSocket())
		}, 60000)
		// socket.on('REMOVE_INCOMING_USER_QUEUE', function(data) {
		//   props.dispatch(incomingUserAction.setIncomingUserSocket(data))
		// })
		console.log('\n\n:::socket={this.socket}:: ', props)
		this.socketCreated = false
	}

	componentWillMount() {
		var { tenantId } = this.props
		this.props.dispatch(incomingUserAction.getIncomingUsers())
	}

	// componentWillUnmount() {
	//   this.socket.disconnect()
	// }

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		})
	}
	initSocket(agentQueueList) {
		let { user, tenantId, agentchat, t } = this.props
		const that = this
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let socketUrl = apiUrl && apiUrl.includes('://') ? 'https://' + apiUrl.split('/')[2] : 'https:// ' + apiUrl.split('/')[0]
		if (!this.socket) {
			console.log('Socket function calling', agentQueueList)
			if (agentQueueList && agentQueueList.length > 0) {
				for (let i = 0; i < agentQueueList.length; i++) {
					that.socket = io(tenantId ? socketUrl + '/' + tenantId + '/' + agentQueueList[i].queueName : socketUrl, { path: '/api/v1/ws/socket.io' })
					let sock = that.socket
					that.socket.on('INCOMING_USER', function (data) {
						that.props.dispatch(incomingUserAction.setIncomingUserSocket(data))
						toast.warn(`${t('Incoming chat request')}`)
					})
					sock.on('disconnect', function (reason) {
						console.log(`Socket disconnect event: ${reason}`)
						if (reason === 'io server disconnect') {
							// the disconnection was initiated by the server, you need to reconnect manually
							sock && sock.connect()
							if (!sock) console.log(`socket not available in disconnect event.`)
						}
						window.location.reload()
					})

					sock.on('connect_error', err => {
						console.log(`connection error ${err}`)
						// setTimeout(() => {
						// 	sock.connect()
						// }, 3000)
					})
				}
			} else {
				that.socket = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
				that.socket.on('INCOMING_USER', function (data) {
					that.props.dispatch(incomingUserAction.setIncomingUserSocket(data))
					toast.warn(`${t('Incoming chat request')}`)
				})
				let sock = that.socket
				sock.on('disconnect', function (reason) {
					console.log(`Socket disconnect event: ${reason}`)
					if (reason === 'io server disconnect') {
						// the disconnection was initiated by the server, you need to reconnect manually
						sock && sock.connect()
						if (!sock) console.log(`socket not available in disconnect event.`)
					}
					window.location.reload()
				})

				sock.on('connect_error', err => {
					// setTimeout(() => {
					// 	sock.connect()
					// }, 3000)
				})
			}
		}
	}

	render() {
		const { alert, agentchat, globalSetting, t, userFullName } = this.props
		// if(agentchat && agentchat.agentQueueList && agentchat.loading == false ) {
		//   // this.initSocket(agentchat.agentQueueList)
		// }
		// console.log("agentchatagentchat incomming user",agentchat)

		function IncommingQueue(props) {
			let { record, index, recordQueue, action } = props
			if (recordQueue.queueName == record.queueName) {
				return (
					<li
						key={index}
						className={
							moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes() >= 1 ? 'alert-agent blink' : ''
						}>
						<a href="#" className={record.isChatOpen ? 'g-select active-incoming-history' : 'g-select'}>
							<img src="assets/img/chat-avt.png" />
							<span
								className="LinkFix"
								onClick={action}
								data-starttime={record.startTime}
								data-sessionid={record.customerSessionId}
								data-user={record.id}
								data-incrementer={record.incrementer}>
								{record.userFullName && record.userFullName != 'Anonymous user' ? record.userFullName : t('Chat Session') + `#${record.incrementer}`}
							</span>
							<span className="time-stamp">
								{moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes()} {t('min ago')}
								<span
									className={
										moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes() >= 1
											? 'badge-count waiting-h blink'
											: 'badge-count waiting-h'
									}></span>
							</span>
						</a>
					</li>
				)
			} else {
				return <></>
			}
		}

		function QueueUserCount(props) {
			let agentCount = 0
			if (agentchat && agentchat.incomingUserData && agentchat.incomingUserData.data && agentchat.incomingUserData.data.length > 0) {
				agentchat.incomingUserData.data.forEach(elem => {
					if (elem.queueName == props.recordQueue.queueName) {
						agentCount++
					}
				})
			}
			return <p>{agentCount}</p>
		}
		let liveChat = globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat
		return (
			// <ul className="pe-list incomming-user">
			//   {agentchat && agentchat.agentData && Array.isArray(agentchat.incomingUserData && agentchat.incomingUserData.data) && agentchat.incomingUserData.data.map((record, index) =>
			//     <li className={((moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format()))).minutes()) >= 1 ? "alert-agent blink" : ""} key={index}>
			//       <a onClick={this.props.action} data-sessionid={record.customer_session_id} data-user={record.id} data-incrementer={record.incrementer} href="#" className="g-select">
			//         <img src="assets/img/chat-avt.png" />
			//         Chat Session #{record.incrementer}
			//         <span className="time-stamp">
			//           {(moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format()))).minutes()} min ago
			//             <span className="badge-count waiting-h"></span>
			//         </span>
			//       </a>
			//     </li>
			//   )}
			// </ul>

			<ul className="accord-row">
				{liveChat &&
					liveChat.agent_queue == 1 &&
					agentchat &&
					agentchat.agentData &&
					Array.isArray(agentchat.incomingUserData && agentchat.incomingUserData.data) &&
					agentchat.agentQueueList &&
					agentchat.agentQueueList.map((recordQueue, index) => (
						<li key={index}>
							<div className="accord-wrap">
								<Collapsible
									trigger={
										<div className="row">
											<p className="col-10">{recordQueue.queueLabel}</p>
											<div className="col-2">
												<Badge color="success" className="pull-right">
													<QueueUserCount recordQueue={recordQueue} />
												</Badge>
											</div>
										</div>
									}
									open={true}
									className="collap-list">
									<ul className="pe-list incomming-user">
										{agentchat && agentchat.agentData && Array.isArray(agentchat.incomingUserData && agentchat.incomingUserData.data) ? (
											agentchat.incomingUserData.data.map((record, index) => (
												<IncommingQueue record={record} index={index} recordQueue={recordQueue} action={this.props.action} key={index} />
											))
										) : (
											<li>
												<p>No Chat</p>
											</li>
										)}
									</ul>
								</Collapsible>
							</div>
						</li>
					))}

				{liveChat &&
					!liveChat.agent_queue &&
					agentchat &&
					agentchat.agentData &&
					Array.isArray(agentchat.incomingUserData && agentchat.incomingUserData.data) &&
					agentchat.incomingUserData.data.map((record, index) => (
						<li
							className={
								moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes() >= 1
									? 'pe-list incomming-user alert-agent blink'
									: 'pe-list incomming-user'
							}
							key={index}>
							<a href="#" className={record.isChatOpen ? 'g-select active-incoming-history' : 'g-select'}>
								<img src="assets/img/chat-avt.png" />
								<span
									className="LinkFix"
									onClick={this.props.action}
									data-starttime={record.startTime}
									data-sessionid={record.customerSessionId}
									data-user={record.id}
									data-incrementer={record.incrementer}>
									{record.userFullName && record.userFullName != 'Anonymous user' ? record.userFullName : t('Chat Session') + `#${record.incrementer}`}
								</span>
								<span className="time-stamp">
									{moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes()} {t('min ago')}
									<span
										className={
											moment.duration(moment(moment(new Date()).utc().format()).diff(moment(record.created).utc().format())).minutes() >= 1
												? 'badge-count waiting-h alert-agent blink'
												: 'badge-count waiting-h'
										}></span>
								</span>
							</a>
						</li>
					))}
			</ul>
		)
	}
}

function mapStateToProps(state) {
	const { agentchat, identity, globalSetting } = state
	// const { user } = authentication;
	const { profile, token } = identity
	return {
		identity,
		profile,
		token,
		agentchat,
		tenantId: profile.tenantUid,
		globalSetting,
	}
}

export default connect(mapStateToProps)(IncomingUsers)
