import Joi from 'joi-browser'

const emailSignature = Joi.object().keys({
    id: Joi.number().integer().optional(),
    tenantId: Joi.string().uuid().required(), // used as namespace in i18n
    configType: Joi.string().valid('defaultSetting'), // both ui appName.featureName or appModule.featureName allowed in feature
    properties: Joi.any(),
    name: Joi.string().required(),
    appModule: Joi.string().required(),
    status: Joi.string()
        .valid('Published', 'Draft', 'Deleted', 'Staging')
        .default('Draft'),
    createdBy: Joi.string().allow('', null),
    updatedBy: Joi.string().allow('', null),
    createdAt: Joi.date(),
    updatedAt: Joi.date(),
    deletedSeq: Joi.number().integer(),
    lookupProps : Joi.object().keys({
      usecase: Joi.string().required().valid('james'),
      clientApp: Joi.string().required().valid('livechat'),
      itemType: Joi.string().optional().allow('', null),
    })
})

// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(emailSignature),
    getOne: emailSignature,
    addItem: emailSignature,
    editItem: emailSignature,
    upsert: emailSignature
  }
  export const collectionSchemas = {
    //  notes: note
  }
  export const entitySchema = emailSignature