import { Entity } from '../common/base/entity/entity'
import { ContentActions } from './cannedResponses.action'
import { collectionSchemas, entitySchema, methodSchemas } from './cannedResponses.schema'



class CannedResponsesEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods

}


const fieldsMeta = {
    status: { "label": "Status", "name": "status", "type": "select",  "placeholder": "Please choose status", "options": [{"label": "Published", value:"Published"}, {"label":"Draft", value: "Draft"}] },
    createdAt: { "label": "Created At", "name": "createdAt", "type": "date","readOnly": "true" },
    createdBy: { "label": "Created By", "name": "createdBy", "type": "text", "readOnly": "true" },
    updatedAt: { "label": "Updated At", "name": "updatedAt", "type": "date",  "readOnly": "true" },
    updatedBy: { "label": "Updated By", "name": "updatedBy", "type": "text", "readOnly": "true" },
    text: { "label": "Text", "name": "text", "type": "text", "placeholder": "Please enter the text", required: true },
    key: {"label": "Key", "name": "key", "type": "text", "placeholder": "Please enter the key", required: true, readOnlyOnEdit: true},
    lang: {
        label: 'Language',
        min: 3,
        max: 20,
        type: 'select',
        required: true,
        placeholder: 'Please specify the language',
        api: {
            path: 'Locale/availableLanguages',
            method: 'GET',
            filters: [{
                field: 'status',
                operator: 'is',
                value : 'Published'
            }],
            response: {
                ref: 'data',
                labelKey: 'lang',
                valueKey: 'lang'
            }
        },
        readOnly: false,
        name: 'lang',
        isMulti:false
    }
}

const actionsMeta = {
    editItem: {
        type: 'button',
        label: 'Update',
        action: 'editItem',
        name: 'editItem'
    },
    cancel: {
        type: 'button',
        label: 'Cancel',
        action: 'cancelEdit',
        name: 'cancel'
    },
    upsert: {
        label: 'Update',
        name: 'upsert',
        action: 'upsertItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },

    update: {
        label: 'Edit',
        name: 'update',
        action: 'editItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },
    list: {
        label: 'List All',
        name: 'list',
        action: 'load'
    },
    search: {
        label: 'Search',
        name: 'search',
        action: 'search'
    },
    view: {
        label: 'View',
        name: 'view',
        action: 'getOne',
        classes: 'fa fa-eye fa-lg',
        type: 'link'
    },
    create: {
        label: 'Create',
        action: 'addNewItem',
        name: 'create',
        type: 'button'
    },
    createItem: {
        label: 'Create',
        action: 'upsertItem',
        name: 'create',
        type: 'button'
    }

}
const sectionsMeta = {
    basic: {
        title: 'Canned Responses Details',
        items: [{"name": "text", type: "field" },{"name": "key", type: "field" },{"name": "lang", type: "field" }, {"name": "status", type: "field" }],
        classes: "ticket-form"
    },
    detail: {
        title: 'Canned Responses Details',
        items: [{ "name": "text", type: "field" },{"name": "key", type: "field" },{"name": "lang", type: "field" }, { "name": "status", type: "field" }],
        classes: "ticket-form"
    },
    audit: {
        title: 'Basic Audit Details',
        items: [{ "name": "createdAt", "type": "field", }, { "name": "createdBy", "type": "field" }, { "name": "updatedAt", "type": "field" }, { "name": "updatedBy", "type": "field" }]
    },

    headerComponent: {
        component: 'DetailHeader',
    },
    cannedResponseForm: {
        cols: [7, 5],
        items: [{ name: 'detail', type: 'section', col: 1 },
        { name: 'upsert', type: 'action', col: 2 },
        { name: 'audit', type: 'section', col: 2 }],
        classes: 'composite-inner-form'
    },


    cannedResponseCreateForm: {
        cols: [7, 5],
        items: [{ name: 'basic', type: 'section', col: 1 },
        { name: 'createItem', type: 'action', col: 2 }],
        classes: 'composite-inner-form'
    }
}


const tabsMeta = {

}

const screens = {
    view: {
        items: [
            { name: 'headerComponent', type: 'section' },
            { name: 'cannedResponseForm', type: 'section' },
        ],
        classes: 'composite-form',
        title: 'Canned Responses Details'
    },
    create: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'cannedResponseCreateForm', type: 'section' }]
    },
    edit: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'cannedResponseForm', type: 'section' }]
    },
    list: {
        items: [{ "name": "text", type: "link",  row:1, col:1 }, {"name": "lang", type: "field" }, { "name": "createdBy", type: "field" },{ "name": "status", type: "field" }],
        layoutType:{type:['Grid'],backgroundColor:'#8E8CD8', cardInRow:3, color:'#fff' },
        orderBy:['createdAt desc'],
        name:'cannedResponses',
        multiselect:false,
        filter : [{
            name: "status",
            operator: "ne",
            value: "Deleted"
        }]
    }
    
}


const cannedResponses = new CannedResponsesEntity({ fieldsMeta, actionsMeta, sectionsMeta, tabsMeta, screens, ActionClass: ContentActions, name: 'cannedResponses', title: 'Canned Responses', methodSchemas, collectionSchemas, entitySchema, idKey: 'id', entityUrlPaths: { default: "CannedResponses", upsert:"CannedResponses/upsert" }, fetchDetailByApi: true, propFields: ['text'] })

const CannedResponsesListSearchContainer = cannedResponses.getContainer('ListSearch')
const CannedResponsesCreateContainer = cannedResponses.getContainer('Create')
const CannedResponsesDetailContainer = cannedResponses.getContainer('Detail')

export { CannedResponsesListSearchContainer, CannedResponsesCreateContainer, CannedResponsesDetailContainer }

