import { agentqueueConstants } from '../';

export const agentqueue = (state = {}, action) => {
    switch (action.type) {
        case agentqueueConstants.GETALL_REQUEST_agentqueue:
            return {
                ...state,
                loading: true
            }
        case agentqueueConstants.GETALL_SUCCESS_agentqueue:
            return {
                ...state,
                loading: false,
                queueData: action.item,

            }

        case agentqueueConstants.ADDNEW_REQUEST_agentqueue:
            return {
                ...state,
                loading: true
            }

        case agentqueueConstants.ADDNEW_SUCCESS_agentqueue:
            let agentQueueData = state.queueData.slice();
            agentQueueData.unshift(action.item)
            return {
                ...state,
                loading: false,
                queueData: agentQueueData,
            }

        case agentqueueConstants.ADDNEW_FAILURE_agentqueue:
            return {
                ...state,
                loading: false
            }

        
        // ###########------##############
        case agentqueueConstants.EDIT_REQUEST_agentqueue:
            return {
                ...state,
                loading: true
            }

        case agentqueueConstants.EDIT_SUCCESS_agentqueue:
            let newQueueData = state.queueData.slice();
            if(action && action.item && action.item.newQueueData && action.item.newQueueData.id) {
                newQueueData.unshift(action.item.newQueueData)
            }
            return {
                ...state,
                loading: false,
                queueData: newQueueData,
            }

        case agentqueueConstants.EDIT_FAILURE_agentqueue:
            return {
                ...state,
                loading: false
            }

        // ###########------##############
        case agentqueueConstants.DELETE_REQUEST_agentqueue:
            return {
                ...state,
                loading: true
            }

        case agentqueueConstants.DELETE_SUCCESS_agentqueue:
            return {
                ...state,
                loading: false,
                queueData: state.queueData.filter(item => {
                    return item.id != action.item[1][0].id
                }),
            }

        case agentqueueConstants.DELETE_FAILURE_agentqueue:
            return {
                ...state,
                loading: false
            }
        

        // ###############-----
        case agentqueueConstants.UPDATEINFO_REQUEST_agentqueue:
            return {
                ...state,
                loading: true
            }
        case agentqueueConstants.UPDATEINFO_SUCCESS_agentqueue:
            if(action && action.item && action.item[1]) {
                let tempQueueData = state.queueData.filter(elem => {
                    return elem.queueName != action.item[1][0].queueName
                })
                return {
                    ...state,
                    loading: false,
                    queueData: [ ...action.item[1], ...tempQueueData ]
                }
            } else {
                return {
                    ...state,
                    loading: false
                }
            }
            
        case agentqueueConstants.UPDATEINFO_FAILURE_agentqueue:
            return {
                ...state,
                loading: false
            }

            
        default:
            return state
    }
}