import Joi from 'joi-browser'

 const cannedResponses = Joi.object().keys({
    id: Joi.number().integer().positive(),
    tenantId: Joi.string().uuid(),
    properties : Joi.object().keys({ text: Joi.string()}),
    key: Joi.string().required(),
    lang: Joi.string().required(),
    deletedSeq: Joi.number(),
    status: Joi.string().valid('Deleted', 'Published', 'Draft').required().default('Draft'),
    createdAt: Joi.date().optional().allow(null),
    updatedAt: Joi.date().optional().allow(null),
    createdBy: Joi.string().optional().allow(null),
    updatedBy: Joi.string().optional().allow(null)
})

// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(cannedResponses),
    getOne: cannedResponses,
    addItem: cannedResponses,
    editItem: cannedResponses,
    upsert: cannedResponses
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = cannedResponses