import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reportsActions } from '..'
import ReportsComponent from '../components'
const mapStateToProps = state => {
	const { identity, alert, reports, tenant, globalSetting } = state
	const { profile } = identity
	return {
		identity,
		profile,
		alert,
		reports,
		tenant,
		globalSetting,
	}
}

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			...reportsActions,
		},
		dispatch
	),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportsComponent)
