export const RECORD_LIMIT = '4';
export const RECORD_RANGE = '5';
export const RECORD_TRAIN = '10';
export const RECORD_TRAIN_IN = '20';
export const ERROR_MESSAGE = 'Oops! Something went wrong. Please try again later.';


export const GETALL_REQUEST_QNA = 'GETALL_REQUEST_QNA';
export const GETALL_SUCCESS_QNA = 'GETALL_SUCCESS_QNA';
export const GETALL_FAILURE_QNA = 'GETALL_FAILURE_QNA';

export const GET_REQUEST_QNA = 'GET_REQUEST_QNA';
export const GET_SUCCESS_QNA = 'GET_SUCCESS_QNA';
export const GET_FAILURE_QNA = 'GET_FAILURE_QNA';

export const ADD_REQUEST_QNA = 'ADD_REQUEST_QNA';
export const ADD_SUCCESS_QNA = 'ADD_SUCCESS_QNA';
export const ADD_FAILURE_QNA = 'ADD_FAILURE_QNA';

export const UPDATE_REQUEST_QNA = 'UPDATE_ADD_REQUEST_QNA';
export const UPDATE_SUCCESS_QNA = 'UPDATE_ADD_SUCCESS_QNA';
export const UPDATE_FAILURE_QNA = 'UPDATE_ADD_FAILURE_QNA';

export const UPDATE_REQUEST_TRAIN_STATUS = 'UPDATE_REQUEST_TRAIN_STATUS';
export const UPDATE_SUCCESS_TRAIN_STATUS = 'UPDATE_SUCCESS_TRAIN_STATUS';
export const UPDATE_FAILURE_TRAIN_STATUS = 'UPDATE_FAILURE_TRAIN_STATUS';

export const DELETE_REQUEST_QNA = 'DELETE_REQUEST_QNA';
export const DELETE_SUCCESS_QNA = 'DELETE_SUCCESS_QNA';
export const DELETE_FAILURE_QNA = 'DELETE_FAILURE_QNA';
export const DOWNLOAD_REQUEST_QNA = 'DOWNLOAD_REQUEST_QNA';
export const DOWNLOAD_SUCCESS_QNA = 'DOWNLOAD_SUCCESS_QNA';
export const DOWNLOAD_FAILURE_QNA = 'DOWNLOAD_FAILURE_QNA';


export const FILTER_QNA_REQUEST = 'FILTER_QNA_REQUEST';
export const FILTER_QNA_SUCCESS = 'FILTER_QNA_SUCCESS';
export const FILTER_QNA_FAILURE = 'FILTER_QNA_FAILURE';

export const DELETE_MANAGE_QNA_REQUEST = 'DELETE_MANAGE_QNA_REQUEST';
export const DELETE_MANAGE_QNA_SUCCESS = 'DELETE_MANAGE_QNA_SUCCESS';
export const DELETE_MANAGE_QNA_FAILURE = 'DELETE_MANAGE_QNA_FAILURE';


export const UPDATE_LUIS_TRAIN_REQUEST = 'UPDATE_LUIS_TRAIN_REQUEST';
export const UPDATE_LUIS_TRAIN_SUCCESS = 'UPDATE_LUIS_TRAIN_SUCCESS';
export const UPDATE_LUIS_TRAIN_FAILURE = 'UPDATE_LUIS_TRAIN_FAILURE';

export const ADD_TRAIN_QNA_REQUEST = 'ADD_TRAIN_QNA_REQUEST';
export const ADD_TRAIN_QNA_SUCCESS = 'ADD_TRAIN_QNA_SUCCESS';
export const ADD_TRAIN_QNA_FAILURE = 'ADD_TRAIN_QNA_FAILURE';


export const GETALL_MULTIPLE_TRAIN_SA_REQUEST = 'GETALL_MULTIPLE_TRAIN_SA_REQUEST'
export const GETALL_MULTIPLE_TRAIN_SA_SUCCESS = 'GETALL_MULTIPLE_TRAIN_SA_SUCCESS'
export const GETALL_MULTIPLE_TRAIN_SA_FAILURE = 'GETALL_MULTIPLE_TRAIN_SA_FAILURE'

export const GETALL_UNIQUE_SA_REQUEST = 'GETALL_UNIQUE_SA_REQUEST'
export const GETALL_UNIQUE_SA_SUCCESS = 'GETALL_UNIQUE_SA_SUCCESS'
export const GETALL_UNIQUE_SA_FAILURE = 'GETALL_UNIQUE_SA_FAILURE'


export const GET_UNPUBLISHED_COUNT_REQUEST = 'GET_UNPUBLISHED_COUNT_REQUEST'
export const GET_UNPUBLISHED_COUNT_SUCCESS = 'GET_UNPUBLISHED_COUNT_SUCCESS'
export const GET_UNPUBLISHED_COUNT_FAILURE = 'GET_UNPUBLISHED_COUNT_FAILURE'

export const QUESTION_EXISTS_REQUEST = 'QUESTION_EXISTS_REQUEST'
export const QUESTION_EXISTS_SUCCESS = 'QUESTION_EXISTS_SUCCESS'
export const QUESTION_EXISTS_FAILURE = 'QUESTION_EXISTS_FAILURE'

export const QUESTION_EXISTS_REMOVE_SUCCESS = 'QUESTION_EXISTS_REMOVE_SUCCESS'
export const QUESTION_EXISTS_ADD_SUCCESS = 'QUESTION_EXISTS_ADD_SUCCESS'
export const QUESTION_EXISTS_CLEAR_SUCCESS = 'QUESTION_EXISTS_CLEAR_SUCCESS'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'

export const PUBLISH_QNA_REQUEST = 'PUBLISH_QNA_REQUEST'
export const PUBLISH_QNA_SUCCESS = 'PUBLISH_QNA_SUCCESS'
export const PUBLISH_QNA_FAILURE = 'PUBLISH_QNA_FAILURE'


export const UPLOAD_QNAKB_REQUEST = 'UPLOAD_QNAKB_REQUEST'
export const UPLOAD_QNAKB_SUCCESS = 'UPLOAD_QNAKB_SUCCESS'
export const UPLOAD_QNAKB_FAILURE = 'UPLOAD_QNAKB_FAILURE'

export const SYNC_EXTERNAL_KB_REQUEST = "SYNC_EXTERNAL_KB_REQUEST"
export const SYNC_EXTERNAL_KB_FAILURE = "SYNC_EXTERNAL_KB_FAILURE"
export const SYNC_EXTERNAL_KB_SUCCESS = "SYNC_EXTERNAL_KB_SUCCESS"

export const CLEAR_JOB_DATA = "CLEAR_JOB_DATA"
