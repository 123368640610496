import { BaseService } from './base.services'

let options = {}
options.urlItemName = 'categories'
class CategoryServiceModel extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}
}
export const categoryServices = new CategoryServiceModel(options)
