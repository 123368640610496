// import { apiUrl, apiHostUrl } from '../../../config';
import { session } from '../../../config'
import { identityServices, wsHelper } from '../../common'

/**
 *  @todo Custom dynamic function with dynamic urlItemName
 * @todo remove tenanatId from url as ticketing
 */
export class BaseService {
	constructor(options) {
		//super(options)
		this.urlItemName = options.urlItemName
		this.apiUrl = options.apiUrl || session.get('apiUrl') || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
		this.workspaceEnabled = options.workspaceEnabled || false
		this.wsType = options.wsType || null
		this.oData = options.oData || false
	}
	getAll(queryStringArr, apiUrl) {
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		let apiUrlCustom = apiUrl || this.apiHostUrl
		if (!apiUrlCustom) {
			apiUrlCustom = session.get('apiUrl') || sessionStorage.getItem('apiUrl')
			this.apiHostUrl = apiUrlCustom
		}
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
			url: `${apiUrlCustom}/${this.urlItemName}?${queryStringArr}`,
		}
		if (this.workspaceEnabled && this.wsType) {
			requestOptions = wsHelper.getWsQuery({ wsType: this.wsType, req: requestOptions, oData: this.oData })
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	addNew(item, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		if (!apiUrlCustom) {
			apiUrlCustom = session.get('apiUrl') || sessionStorage.getItem('apiUrl')
			this.apiHostUrl = apiUrlCustom
		}
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
			data: item,
			url: `${apiUrlCustom}/${this.urlItemName}`,
		}
		if (this.workspaceEnabled && this.wsType) {
			requestOptions = wsHelper.getWsQuery({ wsType: this.wsType, req: requestOptions, oData: null })
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 201) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	editRecord(item, editId, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		if (!apiUrlCustom) {
			apiUrlCustom = session.get('apiUrl') || sessionStorage.getItem('apiUrl')
			this.apiHostUrl = apiUrlCustom
		}
		let requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.urlItemName}/update/${editId}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 202) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	deleteRecord(id, apiUrl) {
		let apiUrlCustom = apiUrl || this.apiHostUrl
		if (!apiUrlCustom) {
			apiUrlCustom = session.get('apiUrl') || sessionStorage.getItem('apiUrl')
			this.apiHostUrl = apiUrlCustom
		}
		let requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
			url: `${apiUrlCustom}/${this.urlItemName}/${id}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			return response.data
		})
	}
}

// class Qnaservice extends BaseService {
//     publish(){

//     }
// }
