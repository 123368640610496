import { agentConstants } from '..';

export function agentchat(state = {}, action) {
  switch (action.type) {
    case agentConstants.GET_ACTIVE_AGENTS:
      return {
        ...state,
        loading: true
      }
    case agentConstants.GET_ACTIVE_AGENTS_SUCCESS:
      console.log("GET_ACTIVE_AGENTS_SUCCESS::", action.agentchat)
      console.log("GET_ACTIVE_AGENTS_SUCCESS::", state)

      let tempStatKey

      try {
        action.agentchat && Object.keys(action.agentchat).forEach(function (key) {
          tempStatKey = key
        });

        if (tempStatKey && action.agentchat && action.agentchat[tempStatKey] && action.agentchat[tempStatKey].length > 0) {
          action.agentchat[tempStatKey].map((elem, i) => {
            if (elem.agentId) {
              let tempVal = state.agentData[tempStatKey].find(o => o.userIncomingChatRequestId == elem.userIncomingChatRequestId)

              if (tempVal && tempVal.isNewMessage) {
                action.agentchat[tempStatKey][i].isNewMessage = true
              }
            }
          })
        }

        return {
          ...state,
          agentData: action.agentchat,
          loading: false
        }
      } catch (err) {
        console.log("GET_ACTIVE_AGENTS_SUCCESS::", err)
        return {
          ...state,
          agentData: action.agentchat,
          loading: false
        }
      }
    case agentConstants.GET_ACTIVE_AGENTS_FAILURE:
      return {
        ...state,
        agentDataError: action.error,
        loading: false
      }
    case agentConstants.AGENT_STATUS_CHANGE:
      return {
        ...state,
        loading: true
      }
    case agentConstants.AGENT_STATUS_CHANGE_SUCCESS:
      console.log("\n\n::AGENT_STATUS_CHANGE_SUCCESS:::Action: ", action)
      if (action.agentchat && action.agentchat !== undefined && action.agentchat.data ) {
        return {
          ...state,
          agentStatus: (action.agentchat.data && action.agentchat.data.isActive && action.agentchat.data.isActive == 1) ? action.agentchat : {},
          isAgentAvailable: (action.agentchat.data && action.agentchat.data.isActive && action.agentchat.data.isActive == 1) ? 'Available' : 'Unavailable',
          loading: false
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case agentConstants.AGENT_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        agentStatusError: action.error
      }
    case agentConstants.INCOMING_USER:
      return {
        ...state,
        loading: true
      }
    case agentConstants.INCOMING_USER_SUCCESS:
      console.log(action.agentchat)
      console.log(state)
      return {
        ...state,
        incomingUserData: action.agentchat,
        loading: false
      }
    case agentConstants.INCOMING_USER_FAILURE:
      return {
        ...state,
        incomingUserError: action.error,
        loading: false
      }
    case agentConstants.INCOMING_SOCKET_USER:
      if (action.agentchat == undefined || action.agentchat == '') {
        return {
          ...state,
          incomingUserData:
          {
            data: (state.incomingUserData && state.incomingUserData.data) || []
          }
        }
      } else {
        if (state.incomingUserData && state.incomingUserData.data && action.agentchat && action.agentchat.id && state.incomingUserData.data.find(o => o.id == action.agentchat.id)) {
          return {
            ...state,
            incomingUserData:
            {
              data: state.incomingUserData.data || []
            }
          }
        } else {
          return {
            ...state,
            incomingUserData:
            {
              data: (state.incomingUserData.data && state.incomingUserData.data) ? state.incomingUserData.data.concat(action.agentchat) : [action.agentchat]
            }
          }
        }
      }

    case agentConstants.REMOVE_INCOMING_USER:
      return {
        ...state,
        incomingUserData:
        {
          data: state.incomingUserData.data.filter((incomingId) => { return incomingId.id != action.agentchat.id })
        }
      }
    case agentConstants.ADD_USER_TO_AGENT:
      var arrAgent = [];
      var agent = state.agentData.data.map((c) => {

        if (c.agentId == action.agentchat.data.agentId) {
          arrAgent.push(action.agentchat);
          c.activeChatUser = arrAgent;
        }
        return c;
      })
      return {
        ...state,
        agentData:
        {
          data: agent
        }
      }

    case agentConstants.AGENT_AVAILABILITY_SET_REQUEST:
      return {
        ...state,
        loading: true
      }
    case agentConstants.AGENT_AVAILABILITY_SET_SUCCESS:
      return {
        ...state,
        agentStatus: action.agentchat,
        isAgentAvailable: (action.agentchat.data && action.agentchat.data.isActive && action.agentchat.data.isActive == 1) ? 'Available' : 'Unavailable',
        loading: false
      }
    case agentConstants.AGENT_AVAILABILITY_SET_FAILURE:
      return {
        ...state,
        agentDataError: action.error
      }

    // $$$$$$$$$$$$$$$$$$$
    case agentConstants.AGENT_QUEUE_LIST_SET_REQUEST:
      return {
        ...state,
        loading: true
      }
    case agentConstants.AGENT_QUEUE_LIST_SET_SUCCESS:
      return {
        ...state,
        agentQueueList: action.agentQueueData,
        loading: false
      }
    case agentConstants.AGENT_QUEUE_LIST_SET_FAILURE:
      return {
        ...state,
        agentDataError: action.error,
        loading: false
      }

    // socket connection
    case agentConstants.SET_SOCKRT_CONNECTION:
      return {
        ...state,
        loading: true
      }
    case agentConstants.SET_SOCKRT_CONNECTION_SUCCESS:
      return {
        ...state,
        registerAllSocket: action.socketConnection,
        loading: false
      }
    case agentConstants.SET_SOCKRT_CONNECTION_FAILURE:
      return {
        ...state,
        registerAllSocket: action.error,
        loading: false
      }

    case agentConstants.REASSIGN_AGENT_SUCCESS:
      console.log(action.agentchat)
      console.log(state)
      return {
        ...state,
        agentData: action.agentchat,
        loading: false
      }

    default:
      return state
  }
}