import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, Table } from 'reactstrap'
// import { globalSetting } from '../../common/reducers/settings.reducers'

/**
 * TODO: get the item fields as part of constructor. remove hardcoding of
 */
class BaseComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			newItem: {
				name: '',
				label: '',
				status: 1,
				description: '',
			},
			editItem: {
				id: '',
				name: '',
				label: '',
				status: 1,
				description: '',
			},
			modalad1: false,
			editModal: false,
			submitted: false,
			deleteModal: false,
			itemId: '',
		}
	}

	componentWillMount() {
		let { identity, t, tenant } = this.props
		let { profile } = identity
		this.props.getAll(profile.tenantUid, t, tenant.apiUrl)
		this.props.getAllCategories(profile.tenantUid, t, tenant.apiUrl)
	}

	toggleModaladdItem = () => {
		this.setState({
			modalad1: !this.state.modalad1,
		})
	}

	addItem = event => {
		event.preventDefault()
		let { identity, t, tenant } = this.props
		let { profile } = identity
		this.setState({ submitted: true })
		this.props.addNewItem(this.state.newItem, profile.tenantUid, t, tenant.apiUrl)
		this.setState({ modalad1: false })
	}

	updateItem = catid => {
		const {
			item: { itemData },
		} = this.props
		this.setState({
			editModal: !this.state.editModal,
		})

		let editItemData = itemData.data.filter(catData => {
			return catData.id === catid
		})

		editItemData = editItemData[0]

		this.setState({
			editItem: {
				id: editItemData.id,
				name: editItemData.name,
				label: editItemData.label,
				status: editItemData.status,
				description: editItemData.description,
			},
		})
	}

	updateItemSave = event => {
		event.preventDefault()
		let { identity, t, tenant } = this.props
		let { profile } = identity
		this.setState({ submitted: true })
		this.props.editRecord(this.state.editItem, profile.tenantUid, t, tenant.apiUrl)
		this.setState({ editModal: false })
	}

	handleChange = event => {
		const { name, value } = event.target
		const { newItem } = this.state
		this.setState({
			newItem: {
				...newItem,
				[name]: value,
			},
			clear: false,
		})
	}

	handleChangeEdit = event => {
		const { name, value } = event.target
		const { editItem } = this.state
		this.setState({
			editItem: {
				...editItem,
				[name]: value,
			},
			clear: false,
		})
	}

	confirmDelete = () => {
		let { identity, t, tenant } = this.props
		let { profile } = identity
		this.props.deleteItem(this.state.itemId, profile.tenantUid, t, tenant.apiUrl)
		this.setState({
			deleteModal: !this.state.deleteModal,
		})
	}

	toggleDeleteModal = () => {
		this.setState({
			deleteModal: !this.state.deleteModal,
		})
	}

	toggleModaleditItem = () => {
		this.setState({
			editModal: !this.state.editModal,
		})
	}

	setItemId = id => {
		this.setState({
			itemId: id,
			deleteModal: !this.state.deleteModal,
		})
	}

	render() {
		const { alert } = this.props
		const { newItem, submitted, editItem } = this.state
		const {
			item: { itemData },
			t
		} = this.props
		const {
			globalSetting: { globalSettingData, data },
		} = this.props
		let settingData = data || (globalSettingData && globalSettingData.data && (globalSettingData.data.data || (globalSettingData && globalSettingData.data)))
		let tagsMgmt = settingData.tagsManagement || (settingData.setting_json && settingData.setting_json.tags_management)
		/*{globalSettingData && globalSettingData.data && globalSettingData.data.length > 0 && globalSettingData.data[0].status == 1 &&*/
		if (tagsMgmt && tagsMgmt.status == 0)
			return (
				<div className="animated fadeIn">
					<div className="common-heading">
						<div className="row">
							<div className="col-sm-12">
								<div className="heading">
									<div className="alert alert-info">
										<strong>Note :- </strong>
										Categories/Tags - Q&A is turned off in
										<Link to="settings"> Global Settings</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		else
			return (
				<div className="animated fadeIn">
					<div className="common-heading">
						{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
						<div className="row">
							<div className="col-sm-6">
								<div className="heading">
									<h5>Q&A Categories Management</h5>
								</div>
							</div>
							<div className="col-sm-6">
								{/* <div className="search-frame">
                                <i className="fa fa-search"></i>
                                <input type="search" className="custom-search" id="search-user" placeholder="Search" />
                            </div> */}
							</div>
						</div>
					</div>
					<div className="main-section">
						<div className="row">
							<div className="col-sm-6">
								<div className="show-section">
									<span className="add-ico" onClick={this.toggleModaladdItem.bind(this)}>
										<img src={'assets/img/add-q.png'} alt="add" />
									</span>
									<h4 className="ad-faq">ADD NEW CATEGORY</h4>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<Table className="custom-table table-responsive">
									<thead>
										<tr>
											<th width="7%">{t('Sr no')}</th>
											<th width="18%">Name</th>
											<th width="17%">Label</th>
											<th width="20%">Description</th>
											<th width="20%">Status</th>
											<th width="20%">Action</th>
										</tr>
									</thead>
									<tbody>
										{itemData &&
											itemData.data &&
											itemData.data.map((item, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{item.name}</td>
													<td>{item.label ? item.label : '--'}</td>
													<td>{item.description ? item.description.substring(0, 50) + '...' : '--'}</td>
													<td>{item.status == 1 ? 'Active' : 'Inactive'}</td>
													<td>
														<span className="al-icon-col" onClick={this.updateItem.bind(this, item.id)}>
															<i className="fa fa-pencil fa-lg "></i>
														</span>
														<span className="al-icon-col" onClick={this.setItemId.bind(this, item.id)}>
															|&nbsp;&nbsp;&nbsp;<i className="fa fa-trash fa-lg"></i>
														</span>
													</td>
												</tr>
											))}
									</tbody>
								</Table>

								<div className="row">
									<div className="col-sm-12">
										<div className="pagination-control pull-right clearfix"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Modal autoFocus={false} isOpen={this.state.modalad1} toggle={this.toggleModaladdItem} className={this.props.className || 'delete-card editor-frame  add-faq'}>
						<h4 className="modal-min-heading">Add New Item</h4>
						<Form className="custom-from" onSubmit={this.addItem}>
							<ModalBody>
								<FormGroup>
									{/*TODO: the fields are rendered from metadata and not hardcoded */}
									<Label for="ItemName">Name</Label>
									<Input type="text" name="name" id="ItemName" value={newItem.name} onChange={this.handleChange} placeholder="Enter item name" />
								</FormGroup>
								<FormGroup>
									<Label for="ItemLabel">Label</Label>
									<Input type="text" name="label" id="ItemLabel" value={newItem.label} onChange={this.handleChange} placeholder="Enter item label" />
								</FormGroup>

								<FormGroup>
									<Label>Status :</Label>
									<Label check>
										<Input type="radio" value="1" onChange={this.handleChange} name="status" defaultChecked /> Active
									</Label>
									<Label check>
										<Input type="radio" value="0" onChange={this.handleChange} name="status" /> Inactive
									</Label>
								</FormGroup>

								<FormGroup>
									<Label for="ItemDescription">Description</Label>
									<Input type="textarea" onChange={this.handleChange} name="description" id="ItemDescription" value={newItem.description} />
								</FormGroup>
							</ModalBody>
							<div className="tools custom">
								<Button color="secondary" onClick={this.toggleModaladdItem}>
									<img src="assets/img/no.png" />
									Close
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" />
									Save
								</Button>{' '}
							</div>
						</Form>
					</Modal>

					<Modal autoFocus={false} isOpen={this.state.editModal} toggle={this.toggleModaleditItem} className={this.props.className} className="delete-card editor-frame  add-faq">
						<h4 className="modal-min-heading">Update Item</h4>
						<Form className="custom-from" onSubmit={this.updateItemSave}>
							<ModalBody>
								<FormGroup>
									<Label for="ItemNameEdit">Name</Label>
									<Input type="text" name="name" id="ItemNameEdit" value={editItem.name} onChange={this.handleChangeEdit} placeholder="Enter item name" />
								</FormGroup>
								<FormGroup>
									<Label for="ItemLabelEdit">Label</Label>
									<Input type="text" name="label" id="ItemLabelEdit" value={editItem.label} onChange={this.handleChangeEdit} placeholder="Enter item label" />
								</FormGroup>

								<FormGroup>
									<Label>Status :</Label>
									<Label check>
										<Input type="radio" onChange={this.handleChangeEdit} name="status" value="1" checked={parseInt(editItem.status) == 1 ? true : false} /> Active
									</Label>
									<Label check>
										<Input type="radio" value="0" onChange={this.handleChangeEdit} name="status" checked={parseInt(editItem.status) == 0 ? true : false} /> Inactive
									</Label>
								</FormGroup>

								<FormGroup>
									<Label for="ItemDescriptionEdit">Description</Label>
									<Input type="textarea" onChange={this.handleChangeEdit} name="description" id="ItemDescriptionEdit" value={editItem.description} />
								</FormGroup>
							</ModalBody>
							<div className="tools custom">
								<Button color="secondary" onClick={this.toggleModaleditItem}>
									<img src="assets/img/no.png" alt="no" />
									Close
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" alt="yes" />
									Save
								</Button>{' '}
							</div>
						</Form>
					</Modal>

					{/* delete modal */}
					<Modal autoFocus={false} isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className={this.props.className || 'delete-card'}>
						<ModalBody>
							<p>Are you sure you want to delete item ?</p>
						</ModalBody>
						<div className="button-wrap">
							<Button color="primary" onClick={this.confirmDelete}>
								<img src="assets/img/yes.png" alt="yes" />
								Yes
							</Button>{' '}
							<Button color="secondary" onClick={this.toggleDeleteModal}>
								<img src="assets/img/no.png" alt="no" />
								No
							</Button>
						</div>
					</Modal>
				</div>
			)
	}
}

export default withTranslation()(BaseComponent)
