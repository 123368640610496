// import { apiUrl } from '../../../config';
import { BaseService } from './base.services'

let options = {}
options.urlItemName = 'dimension'
options.wsType = 'qna'
options.workspaceEnabled = true
options.oData = true
class DefineClassificationServiceModel extends BaseService {
	constructor(options) {
		super(options)
		this.apiUrl = options.apiUrl || sessionStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}
}
export const defineClassificationServices = new DefineClassificationServiceModel(options)
