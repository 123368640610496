import { session } from '../../../config'
import { identityServices } from '../../common'

let options = {}
options.urlItemName = 'reportsMeta'
class ReportsServiceModel {
	constructor(options) {
		this.urlItemName = options.urlItemName
		this.apiUrl = options.apiUrl || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl
	}

	getStdReport = (tenantId, payload) => {
		let apiUrl = this.apiHostUrl || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let ep = apiUrl
		if (!payload.workingHour) payload.workingHour = 9

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${ep}/reportsMeta/downloadReport`,
			data: payload,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data || response
		})
	}
}
export const reportsServices = new ReportsServiceModel(options)
