import { BaseActions } from '..'
import { alertActions } from '../../common'
import { categoryServices } from '../services/qnacategory.services'

let options = {}
options.service = categoryServices
options.resourceName = 'customMetadata'

class CustomMetadataActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}

	getAll(queryStringArr, t, apiUrl) {
		let that = this
		let errorMsg =
			(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
			'Cannot fetch the ' + this.resourceName + ' at this moment, please try again later'
		return dispatch => {
			// that.resourceName = 'customMetadata'
			dispatch(that.request('GETALL'))
			that.service.getAll(queryStringArr, apiUrl).then(
				data => {
					if (data) {
						dispatch(that.success('GETALL', data))
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure('GETALL', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
}
let customMetadataActions = new CustomMetadataActionModel(options)
export const getCustomMetadata = (queryStringArr, t, apiUrl) => customMetadataActions.getAll(queryStringArr, t, apiUrl)
