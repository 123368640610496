import { session } from '../../../config'
import { identityServices } from '../../common'

export const getIncomingUsers = () => {
	let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/active_agents/getIncomingUsers`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}
