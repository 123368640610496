import Joi from 'joi-browser'
import { localTest, session } from '../../config'
import { identityServices } from '../common'
import { EntityService } from '../common/base/entity'

// import {EntityComponent} from '../entity'

export class jobService extends EntityService {
	constructor(options) {
		super(options)
	}

	getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select }) {
		console.log('keycloakInstances:: ', identityServices.keycloakInstances['careerlauncher'])
		// let apiUrlCustom = (tenantId !== undefined && tenantId !== null) ? `${apiUrl}/${tenantId}` : apiUrl;
		// queryStringArr = (queryStringArr && queryStringArr != undefined && queryStringArr.length > 0) ? queryStringArr.join("&") : ''
		let that = this
		let reqPath = this.entityUrlPaths.getAll || this.entityUrlPaths.default + '/findAndCountAll'
		// let querySymb;
		// if(reqPath.indexOf('?')!=-1)
		//     querySymb='&'
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top, select })
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let url = `${apiUrl}`
		if (baseUrl) {
			url += `${baseUrl}`
		}
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			//url: `${url}/${this.entityUrlPaths.getAll || this.entityUrlPaths.default || this.entityUrlPaths}${query}`
			url: `${url}/${reqPath}${query}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200 && response.status !== 304) {
					return Promise.reject(response)
					// return res
				}
				if (response && response.data == null) return []
				if (that.methodSchemas && that.methodSchemas.getAll) {
					val = Joi.validate(response.data.rows, that.methodSchemas.getAll)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
					}
				}
				// return res
				// console.log("user queue", response)
				return response.data
			})
			.catch(err => {
				// for testing
				if (localTest) {
					let now = new Date()
					let res = {
						data: [
							{
								key: 'Some',
								value: { a: 1, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Some note', createdAt: now, createdBy: 'me' },
									{ note: 'second note', createdAt: now, createdBy: 'me' },
								],
							},
							{
								key: 'Any',
								value: { a: 2, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Any note', createdAt: now, createdBy: 'me' },
									{ note: 'Any second note', createdAt: now, createdBy: 'me' },
								],
							},
							{
								key: 'Cool',
								value: { a: 3, b: 2 },
								status: 'Published',
								updatedBy: 'me',
								updatedAt: now,
								notes: [
									{ note: 'Cool note', createdAt: now, createdBy: 'me' },
									{ note: 'Cool second note', createdAt: now, createdBy: 'me' },
								],
							},
						],
					}
					if (filter) {
						res.data = res.data.filter(r => Object.keys(filter).every(k => r[k] === filter[k]))
					}
					if (that.methodSchemas && that.methodSchemas.getAll) {
						val = Joi.validate(res.data, that.methodSchemas.getAll)
						if (!val.error) return res.data
						else {
							let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
							return Promise.reject(`Api response did not pass validation for Get All '${errMsg}'`)
						}
					}
				} else return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
}
