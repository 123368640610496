import { session, PUSH_PUBLIC_KEY } from '../../../src/config';
import { identityServices } from '../../app/common';
// import {serviceVar} from '../../index' 
let sw;
const publicKey= PUSH_PUBLIC_KEY || 'BNDrByJXmY7ISZTcjo-SG5XKxORKhP_0Y4MhCj3gNuLowNBBJLyZdupIvdD8p5j6cP6BaMp_02LknzTd6YrUSdw';

class PushNotification{
  constructor(props){
    this.props=props;
  }
  checkPermission(){
    // Boolean
    // Notification.permission has 2 states : granted || denied
    if(!('Notification' in window)){
      // Check if the Browser Support Notification!
      return false
    }
    if(Notification.permission === "granted"){
      return true
    }
    return false
  }
  async allowPermission(){
    // Check & Allow for Permission:
    if(!this.checkPermission()){
      Notification.requestPermission(permission=>{
        if(permission==='granted'){
          // When Permission is Granted GOTO: Next use Subscribe Method
          return true
        }else {
          return false
          // When User Denied the Permission || Notifications are blocked by the browser
        }
      }).then(async ()=> await this.subscribe())
    }else{
      // As permission is already Granted
      await this.subscribe()
    }
  }
  async subscribe(){

    // At this moment SW registered!(FOR Local)
    (async()=>{
      if ('serviceWorker' in navigator) {
        // Service Worker Getting Registered:
            navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`).then(registration=>{
              registration.onupdatefound=()=> {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      // Execute callback
                      // if (config.onUpdate) {
                      //   config.onUpdate(registration);
                      // }
                      registration.update()
                    }else{
                      // if (config.onSuccess) {
                      //   config.onSuccess(registration);
                      // }
                    }
                  }
                }
              }
              
            }).catch(err=>{
              console.log('ERROR:', err)
            })
      }
    })();
      let sw = await navigator?.serviceWorker?.ready;
      let push = await sw?.pushManager?.subscribe({
          userVisibleOnly:true,
          applicationServerKey:publicKey
      })
      
      if(!localStorage.getItem('myPushConfig') || localStorage.getItem('myPushConfig')=='null'){
        // Api request to send to Backend || DB:
        let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
        let requestOptions = {
        method: 'POST',
        headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
        url: `${apiUrl}/PushSubscription`,
        data: {
          tenantId: `${this.props.userInfo.tenantUid}`,
          userId:`${this.props.userInfo.userId}`,
          userAgent:`${navigator.userAgent}`,
          pushConfig:push,
        }
      }
      identityServices.identityApi.request(requestOptions).then(response => {
        if(response.status===201) {
          // TODO: Save Push config in local storage
          localStorage.setItem('myPushConfig', JSON.stringify(push))
        }
    })
    }
  }
  static isAllowedPermission(){
    // Boolean
    // Notification.permission has 2 states : granted || denied
    if(!('Notification' in window)){
      // Check if the Browser Support Notification!
      return false
    }
    if(Notification.permission === "granted"){
      return true
    }
    return false
  }

  static unsubscribePush(isOn){
    if(!isOn && localStorage.getItem('myPushConfig')){
      // API Call to delete directly to DB:
      // We are sending pushConfig (which is stored locally) to HARD delete it from DB 
    let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
    let requestOptions = {
      method: 'POST',
      headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
      url: `${apiUrl}/PushSubscription/unsubscribeUser`,
      data: {
        endPoint: `${JSON.parse(localStorage.myPushConfig).endpoint}`,
      }
    }
    identityServices.identityApi.request(requestOptions).then(response => {
      if(response.status===203 || response.status===200) {
        // Save Push config in local storage
        localStorage.removeItem('myPushConfig')
      }
    })
   }
  }
}
export default PushNotification;