import React from 'react'
// import { apiUrl } from '../config';
import Collapsible from 'react-collapsible'
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import io from 'socket.io-client'
import { activeAgentAction, agentConstants } from '..'
import { session } from '../../../config'
// import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';
// import { userInfo } from 'os';

class ActiveAgents extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dropdownOpen: false,
			newAgent: {},
			agentchat: null,
		}
		this.socketopen = null
		this.handleOnData = this.handleOnData.bind(this)
		this.handleOffData = this.handleOffData.bind(this)
		this.handleAgentData = this.handleAgentData.bind(this)
		this.handleUserMessages = this.handleUserMessages.bind(this)
		this._browserNotification = this._browserNotification.bind(this)
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let socketUrl = apiUrl && apiUrl.includes('://') ? 'https://' + apiUrl.split('/')[2] : 'https:// ' + apiUrl.split('/')[0]
		let { tenantId } = props
		// console.log("****************dasdasdasd**********************", tenantId ? socketUrl + '/'+ tenantId: socketUrl)
		if (!props.socket && !this.props.isNotificationAllowed) {
			props.socket['Main'] = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
		}
		let isBroadcastSupport = window.BroadcastChannel
		if(!this.props.isNotificationAllowed || !isBroadcastSupport){
		props.socket['Main'].on('AGENT_AVAILABLE', this.handleOnData)
		props.socket['Main'].on('AGENT_UNAVAILABLE', this.handleOffData)
		props.socket['Main'].on('USER_CHAT_UPDATE', this.handleUserMessages)
		props.socket['Main'].on('IDLE_AGENT_UNAVIALABILITY', this.handleAgentIdleTimeout)
		}
		let that = this
		if(isBroadcastSupport){
			const channel = new BroadcastChannel('sw-messages');
			channel.addEventListener('message', event => {
				let pushData = event.data
				switch (pushData.pushType) {
					case "AGENT_AVAILABLE":
						that.handleOnData(pushData.data)
						break;
					case "AGENT_UNAVAILABLE":
						that.handleOffData(pushData.data)
						break;
					case "USER_CHAT_UPDATE"	:
						that.handleUserMessages(pushData.data)
						break;
					case "IDLE_AGENT_UNAVIALABILITY":
						that.handleAgentIdleTimeout(pushData.data)
						break;
					default:
						break;
				}
			});
		}
	}

	componentWillMount() {
		let { profile } = this.props
		this.props.dispatch(activeAgentAction.getActiveAgents(profile))
		// this.props.dispatch(activeAgentAction.registerAllSocket(profile.preferred_username))
	}
	// componentWillReceiveProps(props) {
	// 	if (!this.state.agentchat && Object.keys(props.agentchat?.agentData).length) this.setState({ ...this.state, agentchat: props.agentchat })
	// }
	handleAgentIdleTimeout(data) {
		// console.log("!+_@!+@_!+@_!+@_!+@_!+@_!+_@!+@_!+_@+!_@+!_@+!_@+!_@::::", data)
		const { agentchat } = this.props
		if (data != null && data != undefined) {
			// @ts-ignore
			if (agentchat && agentchat.agentData) delete agentchat.agentData[data.agent_id]
			this.props.dispatch(this.success(agentchat))
		}
	}

	_browserNotification() {
		let { t } = this.props
		let { isAudio, isNotification } = this.props
		if (isAudio) {
			if (!document.getElementById('myAudio')) {
				console.warn('_browserNotification::myAudio::is not found somehow::')
				return false
			}
			document
				.getElementById('myAudio')
				// @ts-ignore
				.play()
				.then(() => {})
				.catch(err => { 
					if (err) {
						// if (navigator.userAgent.indexOf('Safari') != -1) {
						// 	toast.error(t('Notification sounds are disabled.'))
						// } else {
						// 	toast.error(t('Notification sounds are disabled.'))
						// }
						toast.error(t('Notification sounds are disabled.'))
					}
				})
		}
		//document.querySelector('audio').play();
		if (isNotification) {
			if (!('Notification' in window)) {
				toast.error('Your browser does not support desktop notifications')
			}

			// Let's check whether notification permissions have already been granted
			else if (Notification.permission === 'granted') {
				// If it's okay let's create a notification
				var options = {
					body: 'New message from User.',
					icon: process.env.PUBLIC_URL + '/ms-icon.png',
				}
				let title = 'Incoming Message'
				var notification = new Notification(title, options)
				setTimeout(notification.close.bind(notification), 7000)
			}

			// Otherwise, we need to ask the user for permission
			else if (Notification.permission !== 'denied') {
				Notification.requestPermission().then(function (permission) {
					// If the user accepts, let's create a notification
					if (permission === 'granted') {
						var options = {
							body: 'New message from User.',
							icon: process.env.PUBLIC_URL + '/ms-icon.png',
						}
						let title = 'Incoming Message'
						var notification = new Notification(title, options)
						setTimeout(notification.close.bind(notification), 7000)
					}
				})
			}
		}
		if (window.location.pathname !== '/agent') {
			// @ts-ignore
			let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
			// @ts-ignore
			link.type = 'image/png'
			// @ts-ignore
			link.rel = 'shortcut icon'
			// @ts-ignore
			link.href = process.env.PUBLIC_URL + '/ms-icon.png'
			document.getElementsByTagName('head')[0].appendChild(link)
		}
	}

	handleUserMessages(data) {
		try {
			console.log('~+~+~+~+~new user msg+~+~+~+~+~')
			const { agentchat, t } = this.props
			//var agent_id = data.name.split('-')[1];
			var agent_id = data.agentId
			// @ts-ignore
			if (agentchat && agentchat.agentData && agentchat.agentData[agent_id]) {
				// @ts-ignore
				var currentAgentUsers = agentchat.agentData[agent_id].map(c => {
					if (c.userIncomingChatRequestId == data.incommingChatUserId && !c.isChatOpen) {
						console.log(c.userIncomingChatRequestId, data.incommingChatUserId, c.isChatOpen, 'NotificationInit')
						c.isNewMessage = true
						if (c.isChatOpen === false && c.userIncomingChatRequestId && data.incommingChatUserId) this._browserNotification()
						//c.isChatOpen=false;
					}
					return c
				})
				// @ts-ignore
				if (currentAgentUsers) agentchat.agentData[agent_id] = currentAgentUsers

				this.props.dispatch(this.success(agentchat))
			}
		} catch (err) {
			console.error('handleUserMessages:: ', err)
		}
	}

	handleOnData(data) {
		try {
			console.log('~+~+~+~+~available on+~+~+~+~+~')
			let { t } = this.props
			let { agentchat } = this.props
			let newAgent = { [data.agentId]: [{ agentName: data.agentName, activeAgentId: data.id }] }
			let newAgentChat = Object.assign({}, agentchat)
			Object.assign(newAgentChat.agentData, newAgent)

			// agentchat.agentData = agentData
			// this.setState({ ...this.state, agentData })
			this.props.dispatch(this.success(newAgentChat))
		} catch (err) {
			console.error('handleOnData:: ', err)
		}
	}

	handleOffData(data) {
		try {
			console.log('~+~+~+~+available off~+~+~+~+~+~')
			const { agentchat } = this.props
			let newAgentChat = Object.assign({}, agentchat)
			// @ts-ignore
			if (data != null && data != undefined && data.agentId && agentchat && agentchat.agentData && agentchat.agentData[data.agentId]) {
				// @ts-ignore
				delete newAgentChat.agentData[data.agentId]
				this.props.dispatch(this.success(newAgentChat))
			}
		} catch (err) {
			console.error('handleOffData:: ', JSON.stringify(err))
		}
	}
	handleAgentData(data) {
		try {
			if (!data) return true
			const { agentchat } = this.props
			// @ts-ignore
			if (agentchat && agentchat.agentData && agentchat.agentData[data.activeAgentId]) {
				// @ts-ignore
				var currentAgent = agentchat.agentData[data.activeAgentId].map(c => {
					if (c.userIncomingChatRequestId == data.userIncomingChatRequestId) {
						c.agentBotConversationId = data.agentBotConversationId
						c.queueName = data.queueName
						c.userFullName = data.userFullName || c.userFullName || ''
					}
					return c
				})
				// @ts-ignore
				agentchat.agentData[data.activeAgentId] = currentAgent
				this.props.dispatch(this.success(agentchat))
			}
		} catch (err) {
			console.error('handleAgentData:: ', err)
		}
	}

	success(agentData) {
		return { type: agentConstants.AGENT_STATUS_CHANGE_SUCCESS, agentData, agentchat: agentData }
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		})
	}
	initSocket(agentQueueList) {
		let { user, tenantId } = this.props
		const that = this
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let socketUrl = apiUrl && apiUrl.split('/api')[0]
		if (!this.socketQue) {
			if (agentQueueList && agentQueueList.length > 0) {
				for (let i = 0; i < agentQueueList.length; i++) {
					this.socketQue = io(tenantId ? socketUrl + '/' + tenantId + '/' + agentQueueList[i].queueName : socketUrl, { path: '/api/v1/ws/socket.io' })
					this.socketQue.on('CONNECT_AGENT_TO_USER', that.handleAgentData)
					let sock = that.socketQue
					// @ts-ignore
					that.socketQue.on('disconnect', function (reason) {
						console.log(`Socket disconnect event: ${reason}`)
						if (reason === 'io server disconnect') {
							// the disconnection was initiated by the server, you need to reconnect manually
							sock && sock.connect()
							if (!sock) console.log(`socket not available in disconnect event.`)
						}
						window.location.reload()
					})

					sock &&
						sock.on('connect_error', err => {
							console.log(`connection error ${err}`)
							// setTimeout(() => {
							// 	sock && sock.connect()
							// }, 3000)
						})
				}
			} else {
				if (!this.socketopen) {
					this.socketopen = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
				}
				this.socketopen.on('CONNECT_AGENT_TO_USER', that.handleAgentData)
				let sock = this.socketopen
				this.socketopen.on('disconnect', function (reason) {
					console.log(`Socket disconnect event: ${reason}`)
					if (reason === 'io server disconnect') {
						// the disconnection was initiated by the server, you need to reconnect manually
						sock && sock.connect()
						if (!sock) console.log(`socket not available in disconnect event.`)
					}
					window.location.reload()
					// that.socketopen = io(tenantId ? socketUrl + '/' + tenantId : socketUrl, { path: '/api/v1/ws/socket.io' })
				})
				sock.on('connect_error', err => {
					console.log(`connection error ${err}`)
					// setTimeout(() => {
					// 	sock.connect()
					// }, 3000)
				})
			}
		}
	}

	render() {
		const { profile, agentchat, globalSetting, t, userFullName } = this.props
		// if(agentchat && agentchat.agentQueueList && agentchat.loading == false ) {
		//   // this.initSocket(agentchat.agentQueueList)
		// }
		let liveChat = globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.liveChat
		function IncommingActiveUser(props) {
			let { userRecord, index, recordQueue, action } = props
			if (!userRecord.queueName || recordQueue.queueName == userRecord.queueName) {
				return (
					<li className={userRecord.isChatOpen ? 'active-chat-selected' : ''}>
						<a href="#" className="g-select clearfix">
							<span
								onClick={props.action}
								data-starttime={userRecord.startTime}
								data-sessionid={userRecord.userChatSessionId}
								data-user={userRecord.userIncomingChatRequestId}
								data-incrementer={userRecord.incrementer}
								className={userRecord.isChatOpen ? 'id-top active-user-chat' : 'id-top'}>
								<img
									data-sessionid={userRecord.userChatSessionId}
									data-starttime={userRecord.startTime}
									data-user={userRecord.userIncomingChatRequestId}
									data-incrementer={userRecord.incrementer}
									src="assets/img/chat-avt.png"
								/>
								{userRecord.userFullName && userRecord.userFullName != 'Anonymous user'
									? userRecord.userFullName
									: t('Chat Session') + `#${userRecord.incrementer}`}
								{/* #{userRecord.incrementer} */}
							</span>
							<span className="new-chat">{userRecord.isNewMessage && <img src="assets/img/new-chat.png" />}</span>
						</a>
					</li>
				)
			} else {
				return <></>
			}
		}
		var AgentUi = (
			<ul className="accord-row">
				{globalSetting && liveChat && !liveChat.agent_queue && agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && (
					<li className={profile && agentchat.agentData.hasOwnProperty(profile.userId) ? 'active' : ''}>
						<div className="accord-wrap">
							{agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && agentchat.agentData[profile.userId].length > 0 && (
								<Collapsible
									trigger={
										<div className="row">
											<p className="col-10">
												{
													agentchat.agentData[profile.userId].filter(agentName => {
														return agentName.hasOwnProperty('agentName')
													})[0].agentName
												}
											</p>
											<p className="col-2">
												<Badge color="success" className="pull-right">
													{agentchat.agentData[profile.userId] &&
														agentchat.agentData[profile.userId].filter(x => {
															return x.userIncomingChatRequestId != null
														}).length}
												</Badge>
											</p>
										</div>
									}
									className="collap-list">
									<ul className="pe-list active-chat">
										{agentchat.agentData.hasOwnProperty(profile.userId) &&
											agentchat.agentData[profile.userId].length > 0 &&
											agentchat.agentData[profile.userId].map((userRecord, index) =>
												userRecord.agentId ? (
													<li key={index}>
														<a href="#" className="g-select">
															<span
																className={userRecord.isChatOpen ? 'id-top active-user-chat' : 'id-top'}
																onClick={this.props.action}
																data-starttime={userRecord.startTime}
																data-sessionid={userRecord.userChatSessionId}
																data-user={userRecord.userIncomingChatRequestId}
																data-incrementer={userRecord.incrementer}>
																<img
																	data-starttime={userRecord.startTime}
																	data-sessionid={userRecord.userChatSessionId}
																	data-user={userRecord.userIncomingChatRequestId}
																	data-incrementer={userRecord.incrementer}
																	src="assets/img/chat-avt.png"
																/>
																{userRecord.userFullName && userRecord.userFullName != 'Anonymous user'
																	? userRecord.userFullName
																	: t('Chat Session') + `#${userRecord.incrementer}`}
																{/* #{userRecord.incrementer} */}
															</span>

															<span className="new-chat">{userRecord.isNewMessage && <img src="assets/img/new-chat.png" />}</span>
														</a>
													</li>
												) : (
													<li>
														<p>No Chat</p>
													</li>
												)
											)}
									</ul>
								</Collapsible>
							)}
						</div>
					</li>
				)}

				{globalSetting && liveChat && liveChat.agent_queue == 1 && agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && (
					<li className={profile && agentchat.agentData.hasOwnProperty(profile.userId) ? 'active' : ''}>
						<div className="accord-wrap">
							{/* {agentchat && agentchat.agentData && agentchat.agentData[profile.userId] && agentchat.agentData[profile.userId].length>0 &&
                <Collapsible trigger={<p>{agentchat.agentData[profile.userId].filter(agentName => { return agentName.hasOwnProperty('agent_name') })[0].agent_name}</p>} open={true}> */}

							{agentchat &&
								agentchat.agentData &&
								Array.isArray(agentchat.incomingUserData && agentchat.incomingUserData.data) &&
								agentchat.agentQueueList &&
								agentchat.agentQueueList.map((recordQueue, index) => (
									<Collapsible
										key={index}
										trigger={
											<p>
												{recordQueue.queueLabel}{' '}
												<Badge color="success" className="pull-right">
													{agentchat.agentData[profile.userId] &&
														agentchat.agentData[profile.userId].filter(x => {
															return x.userIncomingChatRequestId != null && (!x.queueName || recordQueue.queueName == x.queueName)
														}).length}
												</Badge>
											</p>
										}
										className="collap-list">
										<ul className="pe-list active-chat">
											{agentchat.agentData.hasOwnProperty(profile.userId) &&
												agentchat.agentData[profile.userId].length > 0 &&
												agentchat.agentData[profile.userId].map(
													(userRecord, index) =>
														userRecord.agentId ? (
															//  <If condition={recordQueue.queueName == userRecord.queue_name} key={index}>
															// <Then>
															<IncommingActiveUser
																userRecord={userRecord}
																index={index}
																recordQueue={recordQueue}
																action={this.props.action}
																key={index}></IncommingActiveUser>
														) : (
															''
														)
													// </Then>
													// </If>
												)}
										</ul>
									</Collapsible>
								))}

							{/* </Collapsible>
              } */}
						</div>
					</li>
				)}

				{/* {agentchat && agentchat.agentData && Object.keys(agentchat.agentData).map((record, index) =>

          profile && profile.userId != record &&
          <li className={profile && profile.userId == record ? "active" : ""} key={index}>
            <div className="accord-wrap">
              <Collapsible trigger={<p>{agentchat.agentData[record].filter(agentName => { return agentName.hasOwnProperty('agent_name') })[0].agent_name}
                <Badge color="success" className="pull-right">{agentchat.agentData[record] && agentchat.agentData[record].filter(x => { return (x.user_incoming_chat_request_id != null) }).length}</Badge></p>} className="collap-list">
                <ul className="pe-list">
                  {agentchat.agentData.hasOwnProperty(record) && agentchat.agentData[record].length > 0 && agentchat.agentData[record].map((userRecord, index) =>
                    (
                      !userRecord.hasOwnProperty('agent_name') && <li key={index}>
                        <a onClick={this.props.action} data-sessionid={userRecord.user_chat_session_id} data-user={userRecord.user_incoming_chat_request_id} data-incrementer={userRecord.incrementer} href="#" className="g-select">
                          <img src="assets/img/chat-avt.png" />Chat Session #{userRecord.incrementer}
                          <span className="new-chat">
                            {userRecord.isNewMessage && <img src="assets/img/new-chat.png" />}
                          </span>
                        </a>
                      </li>
                    ))}
                </ul>
              </Collapsible>
            </div>
          </li>

        )} */}
				{/* <li>
        <div className="accord-wrap">
            <Collapsible trigger="Agent 2"  className="collap-list">
              <ul className="pe-list">
                <li><a href="#" className="g-select"><img src="assets/img/chat-avt.png" />Anonymous #1<span className="new-chat"><img src="assets/img/new-chat.png" /></span></a></li>
                <li><a href="#" className="s-select"><img src="assets/img/chat-avt.png" />Anonymous #2<span className="new-chat"><img src="assets/img/new-chat.png" /></span></a></li>
                                                
              </ul>
            </Collapsible>
        </div>

        </li> */}
			</ul>
		)

		return (
			<div className="custom-accord">
				<h4 className="view-head">{t('Active Chat')}</h4>
				{AgentUi}
			</div>
		)
	}
}
function mapStateToProps(state) {
	const { agentchat, identity, globalSetting } = state
	// const { user } = authentication;
	const { profile, token } = identity
	return {
		identity,
		profile,
		token,
		agentchat,
		tenantId: profile.tenantUid,
		globalSetting,
	}
}

export default connect(mapStateToProps)(ActiveAgents)
