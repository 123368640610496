import React, { Component, useEffect, useState } from 'react'
// import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { Col, FormGroup, FormText, Input, Jumbotron, Label, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import './jsonInput/component.css'
class ImageComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tabSwitch: false,
			upload: true,
			refUrl: '',
			activeTab: '1',
			errorMsg: { validUrl: true },
			imgUrl: false,
			disabled: { upload: true, url: true },
			image: false,
			imgList:
				this.props.value ||
				[
					// { url: 'https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', type: 'image' },
					// { url: 'https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500', type: 'image' },
					// { url: 'https://venture-lab.org/wp-content/uploads/2019/03/pexels-photo-1068523.jpeg', type: 'image' }
				],
		}
		this.toggle = this.toggle.bind(this)
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
				upload: true,
				refUrl: '',
				errorMsg: { validUrl: true },
				imgUrl: false,
				image: false,
			})
		}
	}
	componentWillMount() { }

	componentDidMount() {
		if (this.props && this.props.disableTab) {
			this.setState({ disabled: { upload: this.props.disableTab.upload, url: this.props.disableTab.url } })
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.value) {
			this.mapValues(nextProps.value)
		}
	}

	mapValues = value => {
		if (value && value[0].contentRef) {
			let refName = value[0].contentRef
			if (this.validURL(refName)) {
				this.toggle('2')
				this.setState({ image: true, imgUrl: refName, refUrl: refName })
			} else {
				this.toggle('1')
				this.setState({ imgList: value })
			}
		}
	}
	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	validURL(str) {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
			'i'
		) // fragment locator
		return !!pattern.test(str)
	}

	handleInputChange = event => {
		const { errorMsg } = this.state
		if (event.target.value) {
			if (this.validURL(event.target.value)) {
				this.saveUrl(event.target.value)
				this.setState({ image: true, imgUrl: event.target.value, refUrl: event.target.value })
			} else {
				this.setState({
					refUrl: event.target.value,
					image: false,
					imgUrl: null,
					errorMsg: {
						...errorMsg,
						validUrl: true,
						inValidMsg: '',
					},
				})
			}
		} else {
			this.setState({ image: false, refUrl: '', imgUrl: false })
		}
	}
	switchTab = tabName => {
		if (tabName && tabName === 'upload') this.setState({ tabSwitch: false, upload: true })
		else this.setState({ tabSwitch: true, upload: false })
	}

	readFileAsync(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader()

			reader.onload = () => {
				resolve(reader.result)
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	}

	onDrop = async files => {
		const { errorMsg } = this.state
		let fileList = []
		if (files && files.length > 0) {
			const checkFileType = (fType, fName) => {
				if (fType.includes('application')) {
					const fExt = fName.split('.');
					return `application/${fExt[fExt.length - 1]}`
				}
				return fType
			}
			let filesData = Object.keys(files)
			await this.asyncForEach(filesData, async key => {
				// // let docName = files[key].name;
				// let docType = files[key].type;
				let contentBuffer = await this.readFileAsync(files[key])
				let timestamp = new Date().getTime().toString()
				let fileObj = {
					contentRef: '',
					name: files[key].name || 'File',
					type: checkFileType(files[key].type, files[key].name),
					size: files[key].size,
					data: contentBuffer,
				}
				fileList.push(fileObj)
			})
			this.props.onComponentChange(this.props.name, fileList)
			this.resetAll()
		} else {
			this.setState({
				errorMsg: {
					...errorMsg,
					upload: false,
					inValidMsg: 'Please Select a File to Upload',
				},
			})
		}
	}
	cancelUpload = () => {
		this.resetAll()
	}

	saveUrl = refUrl => {
		const { errorMsg } = this.state
		let fileList = []
		if (this.validURL(refUrl)) {
			let fileObj = {
				contentRef: refUrl,
			}
			fileList.push(fileObj)
			this.props.onComponentChange(this.props.name, fileList)
			this.resetAll()
		} else
			this.setState({
				errorMsg: {
					...errorMsg,
					validUrl: false,
					inValidMsg: 'Url is Invalid',
				},
			})
	}

	resetAll() {
		this.setState({
			refUrl: '',
			//image:false,
			//imgUrl:false,
			errorMsg: {
				validUrl: true,
				inValidMsg: '',
			},
		})
	}

	cancelUrl = () => {
		this.resetAll()
	}

	render() {
		const { activeTab, imgUrl, image, imgList, errorMsg, refUrl, disabled } = this.state
		return (
			<div className="upload">
				{image && imgUrl && <Image imgSrc={imgUrl} preview={true} className={'img img-thumbnail'} />}

				<Nav tabs>
					<NavItem>
						{disabled && disabled.upload && (
							<NavLink
								className={`tablink ${activeTab === '1' ? `active` : ``}`}
								onClick={() => {
									this.toggle('1')
								}}>
								UPLOAD
							</NavLink>
						)}
					</NavItem>
					<NavItem>
						{disabled && disabled.url && (
							<NavLink
								className={`tablink ${activeTab === '2' ? `active` : ``}`}
								onClick={() => {
									this.toggle('2')
								}}>
								URL
							</NavLink>
						)}
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<Row>
							<Col sm="12">
								<FormGroup>
									<DropZoneComponent
										upload={this.onDrop.bind(this)}
										cancel={this.cancelUpload.bind(this)}
										maxUpload={this.props.maxUpload || 1}
										maxUploadSize={this.props.maxUploadSize || 5}
										dimensions={this.props.dimensions}
										inputAccept={this.props.inputAccept}
										uploadPrompt={this.props.uploadPrompt}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						<Row>
							<Col sm="12">
								{errorMsg && !errorMsg.validUrl && <FormText color="danger">{this.props.t(errorMsg.inValidMsg) || ''}</FormText>}
								<FormGroup>
									<Label for="exampleCustomFileBrowser">Enter Url</Label>
									<Input
										type="textarea"
										placeholder="Enter Refrence URl"
										value={refUrl}
										onChange={this.handleInputChange.bind(this)}
										id="url"
										name="url"
										label="Enter Url !"
									/>
								</FormGroup>
							</Col>
						</Row>
						{/* <Row><Col sm={6}></Col>
                <Col sm={6}>
                    <Row className="pull-right">
                        <div className="col-md-5"><Button color="primary" onClick={this.saveUrl.bind(this)} size="sm">Save</Button></div>
                        <div className="col-md-5"><Button color="danger" onClick={this.cancelUrl.bind(this)} size="sm">Cancel</Button></div>
                    </Row>
                </Col>
            </Row> */}
					</TabPane>
					{imgList && imgList.length > 0 && <ImageList list={this.state.imgList} preview={true} />}
				</TabContent>
			</div>
		)
	}
}

ImageComponent.propTypes = {}

export default withTranslation()(ImageComponent)

// React DropZone Component
const docUploadConfig = {
	fileTypes: [
		'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain', '.ppt', 'video/*', 'image/*'
	]
};

const DropZoneComponent = props => {
	const [files, setFiles] = useState([])
	const [errorMsg, setErrorMsg] = useState(false)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: props.inputAccept && props.inputAccept.join(',') || docUploadConfig.fileTypes.join(','),
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
		},
	})
	const [selectedFile, setSelectedFile] = useState(acceptedFiles || null)

	useEffect(() => {
		if (validateFile(acceptedFiles) && validateFileSize(acceptedFiles)) {
			setSelectedFile(acceptedFiles)
			acceptedFiles && acceptedFiles.length > 0 && upload(acceptedFiles)
		}
	}, [acceptedFiles])

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => URL.revokeObjectURL(file.preview))
		},
		[files]
	)

	const validateFile = validate => {
		const { maxUpload } = props
		if (validate.length <= maxUpload) {
			setErrorMsg(false)
			return true
		} else {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`Maximum ${maxUpload} can be uploaded!`)
			return false
		}
	}
	const validateFileSize = validate => {
		const { maxUploadSize } = props
		let mbToByte = maxUploadSize * 1024 * 1024
		let file = validate[0]
		if (file && mbToByte && file.size > mbToByte) {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`Maximum ${maxUploadSize} MB file can be uploaded!`)
			return false
		} else {
			setErrorMsg(false)
			return true
		}
	}

	// const removeFile = (fileInfo, event) => {
	//     let filterFile = selectedFile && selectedFile.length > 0 && selectedFile.filter(x => x.name != fileInfo.name)
	//     setSelectedFile(filterFile)
	// }

	// const cancel = () => {
	//     setFiles([])
	//     setSelectedFile(null)
	//     setErrorMsg(false)
	//     props.cancel()
	// }

	const upload = uploadFiles => {
		if (uploadFiles.length > 0) {
			props.upload(uploadFiles)
			setErrorMsg(false)
		} else {
			setErrorMsg(`Please Select a file to upload!`)
		}
	}

	return (
		<section className="container">
			{errorMsg && (
				<p>
					<FormText color="danger">{errorMsg}</FormText>
				</p>
			)}
			<div {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<Jumbotron>{props.uploadPrompt || `Drag 'n' drop supporting images,video,xls,ppt,doc,pdf files here, or click to select files`}</Jumbotron>
			</div>
			{/* <Row><Col sm={6}></Col>
                <Col sm={6}>
                    <Row className="pull-right">
                        <div className="col-md-5"><Button color="primary" onClick={upload.bind(this)} size="sm">Upload</Button></div>
                        <div className="col-md-5"><Button color="danger" onClick={cancel.bind(this)} size="sm">Cancel</Button></div>
                        <div className="clearfix"></div>
                    </Row>
                </Col>
            </Row> */}
			<aside>
				{selectedFile && selectedFile.length > 0 && (
					<Jumbotron>
						<ListGroup>
							{selectedFile.map(file => (
								<ListGroupItem key={file.path} className="justify-content-between listPad">
									<Image imgSrc={file.preview} />
									<span className="spantitle">
										{file.path} - {file.size} bytes{' '}
									</span>
								</ListGroupItem>
							))}
						</ListGroup>
					</Jumbotron>
				)}
			</aside>
			{props.dimensions && <div>{`Note: Recommended Dimensions (${props.dimensions})`}</div>}
		</section>
	)
}

// Image List Component
const ImageList = props => {
	const [list, setList] = useState(props.list || [])
	const [imageUrl, setImageUrl] = useState(null)

	useEffect(() => {
		setList(props.list)
	}, [props])

	const onClick = url => {
		setImageUrl(url)
	}

	return (
		<div className="videoList">
			<Jumbotron>
				<Row>
					<Col sm="12" className="vcol">
						<Image imgSrc={imageUrl || list[0].url || undefined} />
					</Col>
				</Row>
				<Row>
					{list &&
						list.length > 0 &&
						list.map((file, index) => (
							<Col sm={3} key={index} className="vcol image-wrapper">
								<div className={file.url === imageUrl ? 'tablink imageactive' : 'tablink img img-thumbnail'}>
									<Image imgSrc={file.url} onClick={onClick} />
								</div>
							</Col>
						))}

					{list && list.length > 0 && (
						<Jumbotron>
							<ListGroup>
								{list.map((file, index) => (
									<ListGroupItem key={index} className="justify-content-between listPad spantitle">
										File Name : {file.name} <br /> Type : {file.type}
									</ListGroupItem>
								))}
							</ListGroup>
						</Jumbotron>
					)}
				</Row>
			</Jumbotron>
		</div>
	)
}

// React Image Component
const Image = props => {
	const { imgSrc, height, width, onClick, className } = props
	const onImageClick = imageUrl => {
		onClick && onClick(imageUrl)
	}
	return (
		<div className="image">
			{imgSrc && (
				<img
					className={className || ''}
					src={imgSrc}
					height={height || undefined}
					width={width || undefined}
					onClick={onImageClick.bind(this, imgSrc)}
					alt=""
				/>
			)}
		</div>
	)
}
