import { agentConstants } from '..';
// var groupArray = require('group-array');
import { agentService } from '..';
// import { alertActions } from '.';
//import { history } from '../_helpers';
// import { history } from '../../../../_helpers';
// import { apiUrl } from '../config'

export const activeAgentAction = {
  getActiveAgents,
  getAgentAvailability,
  registerAllSocket
};

function getActiveAgents(userId) {
    return dispatch => {
        dispatch(request());
        agentService.getActiveAgents(userId)
            .then(
              agentchat => dispatch(success(agentchat.data)),
              // agentchat => dispatch(success(groupArray(agentchat.data, 'agent_id'))),
            error => dispatch(failure(error))
            );
    };
    function request() { return { type: agentConstants.GET_ACTIVE_AGENTS } }
    function success(agentchat) { return { type: agentConstants.GET_ACTIVE_AGENTS_SUCCESS, agentchat } }
    function failure(error) { return { type: agentConstants.GET_ACTIVE_AGENTS_FAILURE, error } }
}

function getAgentAvailability(agentId, tenantId) {
  return dispatch => {
    dispatch(request());

    agentService.getAgentAvailability(agentId, tenantId)
      .then(
        agentchat => dispatch(success(agentchat)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: agentConstants.AGENT_AVAILABILITY_SET_REQUEST } }
  function success(agentchat) { return { type: agentConstants.AGENT_AVAILABILITY_SET_SUCCESS, agentchat } }
  function failure(error) { return { type: agentConstants.AGENT_AVAILABILITY_SET_FAILURE, error } }
}
function registerAllSocket(username) {
  return dispatch => {
    dispatch(request());
    agentService.registerAllSocket(username)
        .then(
          socketConnection => dispatch(success(socketConnection)),
          // agentchat => dispatch(success(groupArray(agentchat.data, 'agent_id'))),
        error => dispatch(failure(error))
        );
};
function request() { return { type: agentConstants.SET_SOCKRT_CONNECTION } }
function success(socketConnection) { return { type: agentConstants.SET_SOCKRT_CONNECTION_SUCCESS, socketConnection} }
function failure(error) { return { type: agentConstants.SET_SOCKRT_CONNECTION_FAILURE, error } }
}
