/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { DashboardT } from '../common/analytics'
// import {EntityComponent} from '../entity'
import { methodSchemas} from './schemas'
import moment from 'moment'


class SupervisorDashboardT extends DashboardT {
     constructor(options){
         super(options);
     }
    
    getPropsFilterValues({profile, globalSetting}){
        if(profile.roles.includes('chat_agent_supervisor')){
            // Need to get all agent data, skip the filter
            return ;
        }
        let agentFilter = 
        {
            field: 'agent',
            operator: 'is',
            value: profile.preferred_username
        }
        if(!this.apisMeta.liveChatMetrics.filters.some(f=> f.field === 'agent')){
            this.apisMeta.liveChatMetrics.filters.push(agentFilter);
        }
        this.cardsMeta.liveChatCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.cardsMeta.liveChatOpenCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.cardsMeta.liveChatClosedCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.cardsMeta.volCount.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.cardsMeta.avgFeedback.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.chartsMeta.avgResponseTime.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.chartsMeta.nonAvailableByReason.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] }
        }
        this.chartsMeta.tagging.dynFilters = {}
        this.chartsMeta.avgResolutionTime.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.chartsMeta.missedEscalations.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }
        this.chartsMeta.avgConversationTime.dynFilters = {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
        }

        delete this.chartsMeta['percentageEscalated']

        this.sectionsMeta.filters.items = this.sectionsMeta.filters.items.filter(f =>{
            return f.name != 'agent'
        })

        delete this.fieldsMeta['agent']
        return ;
    }
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 * 
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 * 
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *   
 *  cards: livechatCount, avgFeedback
 *  line: avg Response, avgResolution
 *  bar: missedChat
 *  % escalations: pie.
 * 
 */




let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let dayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate())
let dayBeginF =
    moment(dayBegin).tz(moment.tz.guess()).format().slice(0, -6)

let weekBegin = new Date(now.getFullYear(),now.getMonth(), now.getDate() - now.getDay())
let weekBeginF =
    moment(weekBegin).tz(moment.tz.guess()).format().slice(0, -6)

let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF =
    moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
    liveChatMetrics: {
        name: 'liveChatMetrics',
        dependsOnFields: [ 'agent', 'queue'], // page filters
        filters: [{
            field: 'createdAt',
            operator: 'is within',
            values: [monthBeginF, nowF]
        },
        ],
        select: ['metricName', 'createdAt', 'duration', 'reason'],
        groupByAgg: [{ field: 'count', operation: 'sum', as: 'metric' }, { field: 'sum', operation: 'sum', as: 'summetric' }, { field: "avg", operation: "average", as: "avg"}], 
        path: 'analytics',
        method: 'POST',
        groupBy: ['agent', 'queue'],
        
        response: {
            valueKey: 'data',
        },
        orderBy: [{ field: 'createdAt', order: 'asc' }]
    },
    onGoingRag: {
        name: 'onGoingRag', 
        path: 'chatsessions/getRAG',
        method: 'GET',        
        response: {
            valueKey: 'data',
        }
    },
    slaMetrics: {
        name: 'slaMetrics',
        dependsOnFields: [ 'agent', 'queue'],
        filters: [{
            field: 'createdAt',
            operator: 'is within',
            values: [monthBeginF, nowF]
        },
        ],
        select: ['metricName', 'createdAt', 'duration', 'reason'],
        groupByAgg: [{ field: 'count', operation: 'sum', as: 'metric' }, { field: 'sum', operation: 'sum', as: 'summetric' }, { field: "avg", operation: "average", as: "avg"}], 
        path: 'sla/overallsla',
        method: 'POST',
        groupBy: ['agent', 'queue'],
        
        response: {
            valueKey: 'data',
        },
        orderBy: [{ field: 'createdAt', order: 'asc' }]
    }
    
}

const cardsMeta = {
    liveChatOpenCount: {
        title: 'OpenUnassigned',
        metrics: [{ name: 'LiveChat', fields: ['summetric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent',
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    liveChatCount: {
        title: 'CurrentOpenAssigned',
        metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'live_chat_count' }, ],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent',
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    avgFeedback: {
        title: 'CSAT Average',
        metrics: [{ name: 'avgFeedback', fields: ['avg'], operations: ['mean'] }], // need summetric / metric as value
        filters: [{ field: 'metricName', value: 'feedback_live' }, ],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent'
        },
        size: 'xl',
        dependsOnFields: ['fromDate', 'toDate', 'agent'],
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'bg-gradient-mb',
    },
    volCount: {
        name: 'volCount',
        title: 'Vol Count',
        metrics: [{ name: 'live_chat_open_count', value: 'live_chat_open_count', fields: ['summetric'], operations: ['sum']}, { name: 'live_chat_closed_count', value:'live_chat_closed_count' ,fields: ['metric'], operations: ['sum']}, { name: 'live_chat_count', value:'live_chat_count', fields: ['metric'], operations: ['sum']}],
        metricsOperations: ['sum'],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent',
            queue: 'queue'
        },
        size: 'xl',
        dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'bg-gradient-mb',
    },
    respondedCount:{
        name: 'respondedCount',
        title: 'Responded Count',
        metrics: [{ name: 'live_chat_count', value: 'live_chat_count', fields: ['metric'], operations: ['sum']}, { name: 'missed_chat_count', value:'missed_chat_count' ,fields: ['metric'], operations: ['sum']}],
        metricsOperations: ['diff'], // need summetric / metric as value
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            agent: 'agent',
            queue: 'queue'
        },
        size: 'xl',
        dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'bg-gradient-mb',
    },
    missedChatCount: {
        name: 'missedChatCount',
        title: 'Missed Chat Count',
        metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
        filters: [{ field: 'metricName', value: 'missed_chat_count' }, ],
        dynFilters: {
            createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        size: 'xl',
        classes: 'bg-gradient-ly',
        dependsOnFields: [ 'period', 'fromDate', 'toDate', 'agent'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: '',
        icon: 'fa fa-search',
        classes: 'sm'

    }
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {
    /**
     * dualAxis chart eg.
     
     */
}
const fieldsMeta = {
    fromDate: {
        label: 'From Date',
        name: 'fromDate',
        type: 'text',
        value : dayBeginF,
        valueFn : ( period) => {
            if(!period) return
            switch( period) {
                case 'Day':
                    return dayBeginF
                case 'Week': 
                    return weekBeginF
                case 'Month': 
                    return monthBeginF
                default:
                    return monthBeginF
            }
        },
        dependsOnFields: ['period'],
        readOnly : true 
    },
    toDate: {
        label: 'To Date',
        name: 'toDate',
        type: 'calculated',
        value: nowF,
        readOnly : true 
    },
    // need to map to appropriate api to get all live chat agents by name/email
    agent: { // list of queues associated with the user..
        label: 'Agent',
        type: 'select',
        required: true,
        name: 'agent',
        api: {
            path: 'active_agents/getAllAgents',
           // params: { filter: ['agent'] },
            response: {
                ref: 'data',
                labelKey: 'agentName',
                valueKey: 'username'
            }
        },
        allowedRole : 'chat_agent_supervisor'
    },
    queue: { // list of queues associated with the user..
        label: 'Queue',
        type: 'select',
        required: true,
        name: 'queue',
        api: {
            path: 'queue_livechat',
            response: {
                ref: 'data',
                labelKey: 'queueLabel',
                valueKey: 'queueName'
            }
        },
        allowedRole : 'chat_agent_supervisor'
    },
    period: {
        label: 'Period',
        type: 'select',
        name: 'period',
        options: [{label: 'Day', value: 'Day'}, {label: 'Week', value : 'Week'}, {label: 'Month', value: 'Month'}],
        default: 'Month'
    }

}


const sectionsMeta = {
    headerComponent: {
        component: 'DetailHeader',

        // props: [] by default pass all parent props as props to the component.
    },

    basic: {
        title: 'Query Details',
        items: [{ name: 'period', type: 'field' },  { name: 'agent', type: 'field' },  { name: 'queue', type: 'field' }]
    },
    filters: {
        items: [{ name: 'period', type: 'field', col: 1 } , { name: 'agent', type: 'field', col: 3 }, { name: 'fromDate', type: 'field', col: 3}, { name: 'queue', type: 'field' , col: 2}],
        title: 'Filters',
        cols: [4, 4, 4]
    },
    cards: {
        cols: [4, 4, 4],
        items: [{name: 'volCount', type: 'card', col: 1}, { name: 'avgFeedback', type: 'card', col: 2 }, { name: 'liveChatCount', type: 'card', col: 1 }, {name: 'liveChatOpenCount', type: 'card', col: 2},{name:'respondedCount', type:'card', col:3}, {name: 'missedChatCount', type:'card', col:3}]
    }
}

const dashboardMeta = {
    items: [{ name: 'filters', type: 'section' }, {name: 'cards', type: 'section'}],
    classes: 'composite-form',
    title: 'Supervisor Dashboard',
    initialValues: {
        filterValues: {
            fromDate: monthBeginF,
            toDate: nowF,
            period: 'Month'
        }
    },
    apis: ['liveChatMetrics']
}

const SupervisorDashboard = new SupervisorDashboardT({ fieldsMeta, sectionsMeta, dashboardMeta, apisMeta, cardsMeta, chartsMeta, name: 'liveChatDashboard', title: 'LiveChat Dashboard', methodSchemas })

const SupervisorDashboardContainer = SupervisorDashboard.getContainer('Dashboard')
export { SupervisorDashboardContainer }