/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment'
import { DashboardT } from '../common/analytics'
import { methodSchemas } from './schemas'

class LiveChatKPI extends DashboardT {
	constructor(options) {
		super(options)
	}
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 *
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 *
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *
 *  cards: TotalLiveChatsRequested, Responded, Missed, avgResponseTime, avgChatDuration, avgCSAT
 *
 */

let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let dayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate())
let dayBeginF = moment(dayBegin).tz(moment.tz.guess()).format().slice(0, -6)

let weekBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay())
let weekBeginF = moment(weekBegin).tz(moment.tz.guess()).format().slice(0, -6)

let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF = moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
	liveChatMetrics: {
		name: 'liveChatMetrics',
		/**
		 * get most metrics if not all associated with the page.
		 */
		dependsOnFields: ['queue', 'fromDate', 'toDate'], // page filters
		filters: [
			{
				field: 'createdAt',
				operator: 'is within',
				values: [monthBeginF, nowF],
				valuesRef: ['fromDate', 'toDate'],
			},
		],
		select: ['metricName', 'createdAt', 'afterHours'],
		groupByAgg: [
			{ field: 'count', operation: 'sum', as: 'metric' },
			{ field: 'sum', operation: 'sum', as: 'summetric' },
			{ field: 'avg', operation: 'average', as: 'avg' },
		],
		path: 'analytics',
		method: 'POST',
		groupBy: ['queue'],
		response: {
			valueKey: 'data', //  data attribute of the reducer... <reducer>.data.metrics
		},
		orderBy: [{ field: 'createdAt', order: 'asc' }],
	},
}

const cardsMeta = {
	avgFeedback: {
		name: 'avgFeedback',
		title: 'CSAT Average',
		metrics: [{ name: 'avgFeedback', fields: ['avg'], operations: ['mean'] }], // need summetric / metric as value
		filters: [{ field: 'metricName', value: 'feedback_live' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	volCount: {
		title: 'Total Chats',
		metrics: [
			{ name: 'live_chat_open_count', value: 'live_chat_open_count', fields: ['summetric'], operations: ['sum'] },
			{ name: 'live_chat_closed_count', value: 'live_chat_closed_count', fields: ['summetric'], operations: ['sum'] },
			{ name: 'live_chat_count', value: 'live_chat_count', fields: ['summetric'], operations: ['sum'] },
			{ name: 'missed_chat_count', value: 'missed_chat_count', fields: ['summetric'], operations: ['sum'] },
		],
		metricsOperations: ['sum'], // need summetric / metric as value
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	respondedCount: {
		name: 'respondedCount',
		title: 'Responded',
		metrics: [
			{ name: 'live_chat_count', value: 'live_chat_count', fields: ['summetric'], operations: ['sum'] },
			{ name: 'live_chat_closed_count', value: 'live_chat_closed_count', fields: ['summetric'], operations: ['sum'] },
		],
		metricsOperations: ['sum'], // need summetric / metric as value
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	missedChatCount: {
		title: 'Missed - Business Hours',
		metrics: [{ name: 'missedChatCount', fields: ['metric'], operations: ['sum'] }],
		filters: [
			{ field: 'metricName', value: 'missed_chat_count' },
			{ field: 'afterHours', operator: 'is', value: 'No' },
		],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		// classes: 'sm'
	},
	missedChatCountAfterHours: {
		title: 'Missed - After Hours',
		metrics: [{ name: 'missedChatCountAfterHours', fields: ['metric'], operations: ['sum'] }],
		filters: [
			{ field: 'metricName', value: 'missed_chat_count' },
			{ field: 'afterHours', operator: 'is', value: 'Yes' },
		],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		// classes: 'sm'
	},
	avgResponseTime: {
		title: 'Average Response Time (sec)',
		metrics: [{ name: 'avgResponseTime', fields: ['avg'], operations: ['mean'] }],
		filters: [{ field: 'metricName', value: 'response_time' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		// classes: 'sm'
	},
	avgConversationTime: {
		title: 'Average Chat Duration (mins)',
		metrics: [{ name: 'avgConversationTime', fields: ['avg'], operations: ['mean'] }],
		filters: [{ field: 'metricName', value: 'conversation_time' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		// classes: 'sm'
	},
	awaitingChats: {
		title: 'Open',
		metrics: [{ name: 'awaitingChats', fields: ['summetric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['fromDate', 'toDate'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		// classes: 'sm'
	},
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {}

const fieldsMeta = {
	fromDate: {
		label: 'From',
		name: 'fromDate',
		type: 'calender',
		//value : monthBeginF,
		readOnly: true,
	},
	toDate: {
		label: 'To',
		name: 'toDate',
		type: 'calender',
		//value: nowF,
		readOnly: true,
	},
	agent: {
		label: 'Agent',
		type: 'select',
		required: true,
		name: 'agent',
		api: {
			path: 'active_agents/getAllAgents',
			response: {
				ref: 'data',
				labelKey: 'agentName',
				valueKey: 'username',
			},
		},
		allowedRole: 'chat_agent_supervisor',
	},
	queue: {
		label: 'Queue',
		type: 'select',
		required: true,
		name: 'queue',
		lengthCheck: 1,
		api: {
			path: 'queue_livechat/getqueuesdistinct',
			response: {
				ref: 'data',
				labelKey: 'queuelabel',
				valueKey: 'queuename',
			},
		},
		allowedRole: 'chat_agent_supervisor',
	},
}

const sectionsMeta = {
	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},

	basic: {
		title: 'Query Details',
		items: [
			{ name: 'fromDate', type: 'field', col: 1 },
			{ name: 'toDate', type: 'field', col: 2 },
		],
	},
	filters: {
		items: [
			{ name: 'fromDate', type: 'field', col: 1 },
			{ name: 'toDate', type: 'field', col: 2 },
			{ name: 'queue', type: 'field', col: 3 },
		],
		title: 'Filters',
		cols: [4, 4, 4],
	},
	cards: {
		cols: [4, 4, 4],
		items: [
			{ name: 'volCount', type: 'card', col: 1 },
			{ name: 'awaitingChats', type: 'card', col: 2 },
			{ name: 'respondedCount', type: 'card', col: 3 },
			{ name: 'missedChatCount', type: 'card', col: 1 },
			{ name: 'missedChatCountAfterHours', type: 'card', col: 2 },
			{ name: 'avgResponseTime', type: 'card', col: 3 },
			{ name: 'avgConversationTime', type: 'card', col: 1 },
			{ name: 'avgFeedback', type: 'card', col: 2 },
		],
	},
}

const dashboardMeta = {
	items: [
		{ name: 'filters', type: 'section' },
		{ name: 'cards', type: 'section' },
	],
	classes: 'composite-form',
	title: 'Live Chat KPI',
	initialValues: {
		filterValues: {
			fromDate: monthBeginF,
			toDate: nowF,
		},
	},
	apis: ['liveChatMetrics'],
}

const LiveChatKPIDashboard = new LiveChatKPI({ fieldsMeta, sectionsMeta, dashboardMeta, apisMeta, cardsMeta, name: 'liveChatKPI', title: 'Live Chat KPI', methodSchemas })

const LiveChatKPIDashboardContainer = LiveChatKPIDashboard.getContainer('Dashboard')
export { LiveChatKPIDashboardContainer }
