import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Badge, Button, Row } from 'reactstrap'
import { ExpendableDataGrid, LookUpComponent } from '..'
import { i18n } from '../../../..'
import CardLayout from '../Card'
import CustomView from './CustomView'
import FieldFilter from './Filter'
// import DataGrid from './DataGrid';
import FieldHide from './HideField'
import FieldSort from './Sort'
// import ReactDataGrid from "react-data-grid";
import './style.scss'

// Locale
//Locale
export class MainGrid extends Component {
	constructor(props) {
		super(props)
		const cloneColumns = [...this.props.columns]
		this.state = {
			data: this.props.data,
			columns:
				cloneColumns &&
				cloneColumns.map(column =>
					Object.assign(
						{},
						{
							shown: true,
							key: column.key || column.name,
							type: column.type,
							label: (this.props.fields && this.props.fields[column.name] && this.props.fields[column.name].label) || column.name,
							row: column.row,
							col: column.col,
							name: column.name,
							actions: column.actions,
							ignoreT: this.props.fields && this.props.fields[column.name] && this.props.fields[column.name].ignoreT,
						}
					)
				), //column.formatter= this.rowFormatter;
			totalCount: this.props.totalCount || (this.props.data && this.props.data.length),
			fields: this.props.fields || this.state.fields,
			meta: this.props.meta,
			layoutView: (this.props.meta.screens.list.layoutType &&
				this.props.meta.screens.list.items &&
				this.props.meta.screens.list.layoutType.type &&
				this.props.meta.screens.list.layoutType.type) || ['Grid'],
			selectedIndexes: [],
			multiSelect: false,
			isLookUp: false,
			lookUpInfo: null,
			itemDef: this.props.itemDef,
		}
	}
	itemCellClick(idObj) {
		this.props.itemClick(idObj)
	}
	itemCellLookUpClick(column, obj) {
		this.setState({
			isLookUp: !this.state.isLookUp,
			lookUpInfo: { column: column, colObj: obj },
		})
	}
	toggleLookUp() {
		this.setState({ isLookUp: !this.state.isLookUp })
	}
	rowFormatter(column, obj) {
		const { value, row } = obj
		const {
			meta: { idKey, bKeys },
		} = this.state
		let idObj
		if (typeof value === 'string' && column.type === 'link') {
			if (idKey) {
				idObj = row[idKey]
			} else if (bKeys) {
				idObj = {}
				bKeys.pop &&
					bKeys.forEach(k => {
						if (k === 'tenantId') {
							//TODO encode as own | default here...
							idObj[k] = row[k]
						} else idObj[k] = row[k]
					})
			}
			return (
				<button className="btn btn-link" onClick={this.itemCellClick.bind(this, idObj || 0)}>
					{' '}
					{value}{' '}
				</button>
			)
		}
		if (typeof value === 'string' && column.type === 'isLookUp') {
			return (
				<button className="btn btn-link" onClick={this.itemCellLookUpClick.bind(this, column, obj)}>
					{' '}
					{value}{' '}
				</button>
			)
		} else if (typeof value === 'string') return value
		else if (typeof value === 'object' || (value && Array.isArray(value))) {
			return <CustomView data={value} />
		} else if (typeof value == 'boolean') {
			return value ? 'True' : 'False'
		} else return value
	}

	handleHideField(name, checked) {
		const { columns } = this.state
		this.setState({
			columns: columns.map(item => {
				if (item.name === name) {
					item.shown = checked ? true : false
				}
				return item
			}),
		})
	}

	handleHideShowAllField(showAll) {
		const { columns } = this.state
		this.setState({
			columns: columns.map(item => {
				item.shown = showAll ? true : false
				return item
			}),
		})
	}
	componentWillReceiveProps(props) {
		this.setState({
			data: props.data,
			totalCount: props.totalCount || (props.data && props.data.length),
		})
	}
	handleLayoutChange(layout) {
		this.setState({
			layoutView: [layout],
		})
	}

	handleBulkPublish() {
		const { meta, data, selectedIndexes } = this.state
		// if (selectedIndexes && selectedIndexes.length > 0) {
		let entities = data && data.filter((item, index) => selectedIndexes && selectedIndexes.indexOf(index) !== -1)
		this.props.handleListAction(meta.actions.bulkPublish, entities)
		this.setState({ selectedIndexes: [] })
		// }
		// else
		//   this.setState({ multiSelect: false })
	}

	handleSelectItems(selectedIndexes) {
		this.setState({
			selectedIndexes: selectedIndexes,
		})
	}

	handleExportData() {
		const { data } = this.state
		this.props.handleExportData(data)
	}

	render() {
		const { data, columns, fields, meta, layoutView, isLookUp, lookUpInfo, itemDef } = this.state
		const {
			meta: {
				entitySchema,
				screens: { list },
			},
			t,
			globalSetting,
			profile,
		} = this.props
		const {
			screens: { lookUp },
		} = meta
		let importAction,
			auditLogAction,
			bulkPublishAction,
			exportAction,
			allowedActions = []
		let commonUiOptions = globalSetting && globalSetting.uiFeature && globalSetting.uiFeature.commonUiOptions
		if (meta && meta.actions) {
			if (commonUiOptions && commonUiOptions.roles && profile.roles) {
				let addedRoles = Object.keys(commonUiOptions.roles)
				addedRoles.every(r => {
					if (profile.roles.indexOf(r) != -1) {
						allowedActions = commonUiOptions.roles[r].allowedActions
						if (allowedActions) return false
						return true
					} else return true
				})
			}
			// createAction = meta.actions.create
			importAction = meta.actions.import && allowedActions && allowedActions.indexOf(meta.actions.import.name) != -1 ? meta.actions.import : null
			auditLogAction = this.props.auditLogAction && allowedActions && allowedActions.indexOf(this.props.auditLogAction.name) != -1 ? this.props.auditLogAction : null
			bulkPublishAction = meta.actions.bulkPublish && allowedActions && allowedActions.indexOf(meta.actions.bulkPublish.name) != -1 ? meta.actions.bulkPublish : null
			exportAction = meta.actions.export && allowedActions && allowedActions.indexOf(meta.actions.export.name) != -1 ? meta.actions.export : null
		}
		let newProps = Object.assign({}, { ...this.props })
		delete newProps.columns
		let filterColumns = columns.filter(f => !(f.type === 'seprator' || f.type === 'action'))
		let filterFieldColumns = filterColumns.filter(f => !(f.type === 'actions'))
		return (
			<div>
				{
					<Row className="asc-white">
						<div className="col-sm-6">
							<ul className="filter-block p-1 m-0">
								{columns && fields && (!list.layoutType || !list.layoutType.excluded || !list.layoutType.excluded.hideField) && (
									<li>
										<FieldHide
											data={data}
											columns={filterColumns}
											fields={fields}
											handleHideField={this.handleHideField.bind(this)}
											handleHideShowAllField={this.handleHideShowAllField.bind(this)}
										/>
									</li>
								)}
								{(!list.layoutType || !list.layoutType.excluded || !list.layoutType.excluded.filter) && (
									<li>
										<FieldFilter
											entitySchema={entitySchema}
											columns={filterFieldColumns}
											fields={fields}
											handleSearch={this.props.handleSearch}
											preFilters={this.props.preFilters}
											apiUrl={this.props.tenant && this.props.tenant.apiUrl}
										/>
									</li>
								)}
								{(!list.layoutType || !list.layoutType.excluded || !list.layoutType.excluded.sort) && (
									<li>
										<FieldSort entitySchema={entitySchema} columns={filterFieldColumns} fields={fields} handleSort={this.props.handleSort} />
									</li>
								)}
								<li>
									<Button color="secondary" outline>
										{t('Record Count')} <Badge color="primary">{this.state.totalCount || 0}</Badge>
									</Button>
								</li>
							</ul>
						</div>
						<div className="col-sm-6">
							<ul className="filter-block p-1 m-0 filter-split">
								<li>
									{layoutView.indexOf('Grid') !== -1 && this.props.meta.screens.list.layoutType && this.props.meta.screens.list.layoutType.type.indexOf('Card') !== -1 && (
										<Button className="icon-btn-link" color="link" onClick={this.handleLayoutChange.bind(this, 'Card')}>
											<i className="fa fa-th-large" aria-hidden="true"></i>
											{t(`Card View`)}
										</Button>
									)}
									{(!list.layoutType || !list.layoutType.excluded || !list.layoutType.excluded.grid) && layoutView.indexOf('Card') !== -1 && (
										<Button className="icon-btn-link" color="link" onClick={this.handleLayoutChange.bind(this, 'Grid')}>
											<i className="fa fa-table" aria-hidden="true"></i>
											{t(`Grid View`)}
										</Button>
									)}
								</li>

								<li>
									{importAction && (
										<Button className="icon-btn-link" color="link" onClick={this.props.importData}>
											<i className="fa fa-download" aria-hidden="true"></i>
											{t('Import')}
										</Button>
									)}
								</li>
								<li>
									{exportAction && (
										<Button className="dwnld" color="link" onClick={this.handleExportData.bind(this)}>
											<i className="fa fa-upload" aria-hidden="true"></i>
											{t('Export')}
										</Button>
									)}
								</li>

								<li>
									{bulkPublishAction && (
										<Button className="dwnld" color="link" onClick={this.handleBulkPublish.bind(this)}>
											<i className="fa fa-bullhorn" aria-hidden="true"></i>
											{t('Bulk Publish')}
										</Button>
									)}
								</li>
								<li>
									{auditLogAction && (
										<Button className="dwnld" color="link" onClick={this.props.viewAuditLog}>
											<i className="fa fa-list-ul" aria-hidden="true"></i>
											{t('Audit Log')}
										</Button>
									)}
								</li>
							</ul>
						</div>
					</Row>
				}
				<Row>
					{layoutView && columns && meta && layoutView.indexOf('Grid') !== -1 && data && (
						<ExpendableDataGrid
							list={data}
							columns={filterColumns}
							fields={fields}
							meta={meta}
							handleSelectItems={this.handleSelectItems.bind(this)}
							selectedIndexes={this.state.selectedIndexes}
							multiSelect={this.state.multiSelect}
							itemCellLookUpClick={this.itemCellLookUpClick.bind(this)}
							itemDef={itemDef}
							i18n={i18n}
							{...newProps}
						/>
					)}
				</Row>
				{layoutView && meta.screens && meta.screens.list && meta.screens.list.layoutType && layoutView.indexOf('Card') !== -1 && data && (
					<CardLayout
						meta={meta}
						data={data}
						columns={columns}
						itemClick={this.props.itemClick}
						handleListAction={this.props.handleListAction}
						cardPerRow={meta.screens.list.layoutType.cardPerRow}
						backgroundColor={meta.screens.list.layoutType.backgroundColor}
						color={meta.screens.list.layoutType.color}
						defaultCards={meta.screens.list.layoutType.defaultCards}
					/>
				)}

				{lookUp && isLookUp && <LookUpComponent toggle={isLookUp} toggleLookUp={this.toggleLookUp.bind(this)} lookupvalue={lookUpInfo} fields={fields} {...this.props} />}
			</div>
		)
	}
}
export default withTranslation()(MainGrid)
