import React, { Component } from 'react';
// Locale
import {withTranslation} from 'react-i18next';
//Locale

class Footer extends Component {
  render() {
    const {t}=this.props;
    return (
      <footer className="app-footer clearfix">
      <p className="lead">{t('For best experience, use one of these fully compatible browsers - (Chrome or Edge on Chromium)')}</p>
        <div className="ap-f-left pull-left">
          <a target="_blank" href="https://www.rezolve.ai" rel="noopener noreferrer">{t('Rezolve.ai')}</a> &copy; {(new Date()).getFullYear()}
          <span className=" apf-right pull-right">&nbsp;{t('Powered by')} <a target="_blank" href="https://www.rezolve.ai" rel="noopener noreferrer">{t('Rezolve.ai')}</a>
        </span>
        </div>

      </footer>
    )
  }
}

export default withTranslation()(Footer);
