"use strict";

import { EntityActions } from "../common/base/entity";
// import {EntityComponent} from '../entity'

export class UnavailabilityAction extends EntityActions {
  constructor(options) {
    super(options);
  }

  getFns() {
    let that = this;
    let fns = super.getFns();
    Object.values(fns).forEach((f) => f.bind(that));
    return fns;
  }
}
