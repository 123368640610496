import * as expr from 'expression-eval'
// import { request } from 'https';
import Joi from 'joi-browser'
import React from 'react'
import { EntityConstants } from '..'
import { alertActions, i18n } from '../../..'
import { appRootPath } from '../../../../../config'
import { history } from '../../../../../redux/history'
import { executeApi } from '../../../helpers/apiHelper'
import * as utils from '../../../helpers/utils'
import ToastMessage from '../components/Toast'
import { getExactError } from '../joiErrorWrapper'

export class EntityActions {
	constructor(options) {
		this.service = options.service
		this.resourceName = options.resourceName
		this.methodSchemas = options.methodSchemas
		this.entitySchema = options.entitySchema
		this.collectionSchemas = options.collectionSchemas
		this.propFields = options.propFields
		this.apiUrl = options.apiUrl
	}
	filterData({ filteredData, searchText }) {
		let that = this
		return dispatch => {
			// @ts-ignore
			dispatch(that.success({ method: 'FILTEREDDATA', data: { filteredData, searchText } }))
		}
	}
	executeApi({ tenantId, values, apiMeta, apiUrl, meta }) {
		let that = this
		if (!apiMeta) return
		return dispatch => {
			dispatch(that.request({ method: 'EXECUTEAPI' }))
			// @ts-ignore
			if (that.preActionsHooks) values = that.preActionsHooks({ actionName: 'executeApi', item: values, propFields: that.propFields })
			let errorMsg =
				i18n.t('Cannot execute api {{apiPath}} on {{resourceName}} at this moment, please try again later', {
					apiPath: apiMeta && apiMeta.path,
					resourceName: that.resourceName,
				}) || `Cannot execute api ${apiMeta && apiMeta.path} the  ${that.resourceName} at this moment, please try again later`
			// @ts-ignore
			executeApi({ tenantId, values, apiMeta, apiUrl: this.apiUrl || apiUrl })
				.then(result => {
					if (result) {
						// @ts-ignore
						if (that.postActionsHooks) result = that.postActionsHooks({ actionName: 'getOne', item: result })

						dispatch(
							// @ts-ignore
							that.success({ method: 'EXECUTEAPI', item: result, targetProp: apiMeta.response && apiMeta.response.targetProp, data: { inputs: values } })
						)
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'EXECUTEAPI', error: errorMsg }))
						// dispatch(alertActions.error(errorMsg));
					}
				})
				.catch(error => {
					dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
					// dispatch(alertActions.error(errorMsg));
				})
		}
	}
	executeApiAction({ tenantId, values, apiMeta, t, apiUrl }) {
		let that = this
		if (!apiMeta) return
		return async dispatch => {
			dispatch(that.request({ method: 'EXECUTEAPI' }))

			// @ts-ignore
			if (that.preActionsHooks) values = that.preActionsHooks({ actionName: 'executeApi', item: values, propFields: that.propFields })
			let errorMsg =
				(t &&
					t('Cannot execute api {{apiPath}} on {{resourceName}} at this moment, please try again later', {
						apiPath: apiMeta && apiMeta.path,
						resourceName: that.resourceName,
					})) ||
				`Cannot execute api ${apiMeta && apiMeta.path} the  ${that.resourceName} at this moment, please try again later`
			if (apiMeta.pollingEval) {
				try {
					let success = false
					let counter = 0
					while (!success) {
						// @ts-ignore
						let result = await executeApi({ tenantId, values, apiMeta, apiUrl: this.apiUrl || apiUrl })
						if (result) {
							const ast = expr.parse(apiMeta.pollingEval)
							let evaluated = expr.eval(ast, result)
							//dispatch(that.success({ method: 'EXECUTEAPI', item: result, targetProp: apiMeta.response && apiMeta.response.targetProp, data: { inputs: values } }))
							// @ts-ignore
							if (that.postActionsHooks) result = that.postActionsHooks({ actionName: 'getOne', item: result })
							if (evaluated) {
								success = true
								result = result || values
								setTimeout(function () {
									that.goBackUrl(result.menuPath || result.configType)
								}, 1000)
							} else {
								counter++
								if (counter > 100)
									throw new Error((t && t('Timeout Error: Taking too long, our systems are slow.')) || 'Timeout Error: Taking too long, our systems are slow.')
								await new Promise(resolve => setTimeout(resolve, 4000))
							}
						} else {
							// @ts-ignore
							dispatch(that.failure({ method: 'EXECUTEAPI', error: errorMsg }))
							// dispatch(alertActions.error(errorMsg));
						}
						// }).catch(
						//     error => {
						//         let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path} on ' + ${that.resourceName} + ' at this moment please try again later`
						//         dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }));
						//         // dispatch(alertActions.error(errorMsg));
						//     }
						// )
					}
				} catch (error) {
					dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
					// dispatch(alertActions.error(errorMsg));
				}
			}
		}
	}
	getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText, searchName, meta, t = null, id }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALL' }))
			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText, searchName, id })
				.then(items => {
					if (items.rows || items) {
						filter &&
							!filter.pop &&
							Object.keys(filter).forEach(k => {
								if (filter[k].slice(0, 1) === filter[k].slice(-1) && filter[k].slice(-1) === '%') filter[k] = filter[k].slice(1, -1)
							})
						// @ts-ignore
						if (that.postActionsHooks) items.rows = that.postActionsHooks({ actionName: 'getAll', item: items.rows || items })

						// @ts-ignore
						dispatch(that.success({ method: 'GETALL', item: items, data: { filter } }))
						// @ts-ignore
						dispatch(that.success({ method: 'GETONE', item: {} }))
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'GETALL', error: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETALL', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	getAllCount({ tenantId, filter, orderBy, skip, limit, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALLCOUNT' }))
			let errorMsg =
				i18n.t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getAllCount({ tenantId, filter, orderBy, skip, limit })
				.then(count => {
					if (count > -1) {
						// @ts-ignore
						dispatch(that.success({ method: 'GETALLCOUNT', item: count }))
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETALLCOUNT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Cannot fetch from server') || 'Cannot fetch from server'}. ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETALLCOUNT', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	getOne({ tenantId, filter, index, baseUrl, select, meta, id }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETONE' }))
			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getOne({ tenantId, filter, baseUrl, select, id })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'getOne', item: (item && item[0]) || item, meta })
						if (item && item.pop) item = item[index || 0]
						// @ts-ignore
						dispatch(that.success({ method: 'GETONE', item }))
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETONE', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETONE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	load({ tenantId, filter, orderBy, skip, limit, top, queryStr, baseUrl, select, searchText, searchName, t, id, meta }) {
		//queryStr = queryStr || { status: 'Published' }
		if (filter && !filter.pop) filter = Object.assign({}, filter, queryStr)
		else if (queryStr) filter = queryStr
		return this.getAll({ tenantId, filter: filter, orderBy, skip: skip, limit, top, baseUrl, select: select, searchText, searchName, id, meta })
	}
	addNewItem({ entityValues, tenantId, apiMeta, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'ADDNEW' }))
			// @ts-ignore
			if (that.preActionsHooks) entityValues = that.preActionsHooks({ actionName: 'addNewItem', item: entityValues, propFields: that.propFields })
			if (entityValues.error) {
				dispatch(that.failure({ method: 'EDIT', errorMsg: entityValues.error.message }))
				dispatch(alertActions.error(<ToastMessage messages={entityValues.error.message} />))
				return
			}
			let errorMsg =
				i18n.t('Cannot add the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot add the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.addNew({ item: entityValues, tenantId })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'addNew', item: (item && item[0]) || item })
						// @ts-ignore
						if (that.executeFollowUp) {
							// @ts-ignore
							apiMeta = that.executeFollowUp({ actionName: 'followUp', apiMeta, tenantId: item.tenantId })
							dispatch(that.executeApiAction({ tenantId: item.tenantId, values: entityValues, apiMeta, t: i18n.t, apiUrl: this.apiUrl }))
							// @ts-ignore
							dispatch(that.success({ method: 'ADDNEW', item }))
							dispatch(
								alertActions.success(
									i18n.t('{{resourceName}} added successfully', { resourceName: that.resourceName }) || that.resourceName + ' added successfully'
								)
							)
						} else {
							// @ts-ignore
							dispatch(that.success({ method: 'ADDNEW', item }))
							dispatch(alertActions.success(that.resourceName + ' added successfully'))
							setTimeout(function () {
								that.goBackUrl(entityValues.menuPath || entityValues.configType)
							}, 1000)
						}
					} else {
						let errorMsg = 'Cannot insert new ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'ADDNEW', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: i18n.t(`${'Error from server'} ${(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later'}`)
					dispatch(that.failure({ method: 'ADDNEW', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}

	editItem({ entityValues, tenantId, id, meta, actionMeta, profileEntity }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({
					actionName: 'editItem',
					item: entityValues,
					tenantId: tenantId,
					propFields: that.propFields,
					actionMeta,
					profileEntity,
				})
			}
			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			let item = entityValues
			if (meta && meta.updateOnlyWritable) {
				let wK = Object.keys(meta.fields).filter(k => {
					return meta.fields[k] && (meta.fields[k].readOnly === 'false' || meta.fields[k].readOnly === false)
				})
				item = {}
				wK.forEach(k => {
					item[k] = entityValues[k]
				})
			}
			that.service
				.editRecord({ item: item, tenantId, editId: id })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'editItem', item: (item && item[0]) || item })

						if (item.code > 202 && item.message && item.message.length > 0) {
							// @ts-ignore
							dispatch(that.success({ method: 'EDIT', item: item.data }))
							dispatch(alertActions.info(item.message))
						} else if (item.code === 202 && item.message && item.message.length > 0) {
							// @ts-ignore
							dispatch(that.success({ method: 'EDIT', item: item.data }))
							dispatch(
								alertActions.success(
									i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName } || that.resourceName + ' updated successfully')
								)
							)
						} else {
							// @ts-ignore
							dispatch(that.success({ method: 'EDIT', item: item }))
							dispatch(
								alertActions.success(
									i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName } || that.resourceName + ' updated successfully')
								)
							)
						}
					} else {
						let errorMsg = i18n.t('Cannot update the ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
					setTimeout(function () {
						that.goBackUrl()
					}, 1000)
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}
	customEditItem({ entityValues, tenantId, path, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'CUSTOM_EDIT_REQUEST' }))
			// if (that.preActionsHooks) {
			//     entityValues = that.preActionsHooks({ actionName: 'editItem', item: entityValues, tenantId: tenantId, propFields: that.propFields });
			// }
			let item = entityValues
			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.customUpdate({ item: item, tenantId, path: path })
				.then(item => {
					if (item) {
						// if (that.postActionsHooks)
						//     item = that.postActionsHooks({ actionName: 'editItem', item: ( item && item[0] ) || item });

						// @ts-ignore
						dispatch(that.success({ method: 'CUSTOM_EDIT_SUCCESS', item }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName } || that.resourceName + ' updated successfully')
							)
						)
					} else {
						let errorMsg = i18n.t('Cannot update the ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure({ method: 'CUSTOM_EDIT_FAILURE', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'CUSTOM_EDIT_FAILURE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	// @ts-ignore
	goBackUrl = configType => {
		let that = this
		let pathName = window.location.pathname.split('/')
		let protocol = window.location.protocol + '//' || 'http://'
		let goBackUrl = null
		let path = '/'
		if (window.location.pathname.includes('tickets') || window.location.pathname.includes('approvals')) {
			if (pathName && pathName.length > 0) {
				if (pathName[0] != '') path = `/${window.location.pathname.split('/')[1]}/${pathName[0]}`
				else if (pathName.length > 5) path = `/${window.location.pathname.split('/')[1]}/${pathName[1]}/${pathName[2]}`
				else if (appRootPath === '/' && pathName[1]) path = `/${window.location.pathname.split('/')[1]}/${pathName[1]}`
				else if (appRootPath && pathName[2]) path = `/${window.location.pathname.split('/')[1]}/${pathName[2]}`
				else path = `/${window.location.pathname.split('/')[1]}/${pathName[1]}`
			} else {
				path = `/${window.location.pathname.split('/')[1]}/${that.resourceName}`
			}
		} else {
			if (pathName && pathName.length > 0) {
				if (pathName[0] != '') path = `/${pathName[0]}`
				// else if (pathName.length > 5) path = `/${pathName[1]}/${pathName[2]}`  // commented by Udaya as botmanagement which is in history appRootPath is getting added back.
				else if (appRootPath === '/' && pathName[1]) path = `/${pathName[1]}`
				else if (appRootPath && pathName[2]) path = `${path}${pathName[2]}`
				else path = `/${pathName[1]}`
			} else {
				path = `/${that.resourceName}`
			}
		}
		if (configType && pathName.length > 3) {
			let t = 0
			path = path.replace(/\/tickets/g, match => (++t === 2 ? '' : match))
			// uncommented by Udaya as this is needed for going back to the botConfig submenu item.
			path += `/${configType}`
		}
		if (window.location.origin) {
			goBackUrl = window.location.origin
		} else {
			goBackUrl = protocol + (window.location.host || window.location.hostname)
		}
		if (goBackUrl) history.replace(`${path}`, goBackUrl)
		else history.goBack()
	}

	upsertItem({ entityValues, tenantId, appendUrl, itemType, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({ actionName: 'editItem', item: entityValues, tenantId: tenantId, propFields: that.propFields })
			}

			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			if (entityValues.error) {
				dispatch(that.failure({ method: 'EDIT', errorMsg: entityValues.error.message }))
				dispatch(alertActions.error(<ToastMessage messages={entityValues.error.message} />))
				return
			} else {
				if (that.methodSchemas && that.methodSchemas.upsert) {
					let val = Joi.validate(entityValues, that.methodSchemas.upsert, { abortEarly: false })
					if (val.error) {
						errorMsg = getExactError(val.error, meta.fields) //`Request did not pass the joi schema validation, ${val.error.message}`;
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
						return
					}
				}
			}

			that.service
				.upsertRecord({ item: entityValues, tenantId, appendUrl, itemType })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'getOne', item: (item && item[0]) || item })
						item = item || entityValues
						// @ts-ignore
						dispatch(that.success({ method: 'EDIT', item }))
						console.log(
							i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName } || that.resourceName + ' updated successfully'),
							'>>>>>>>DB>>>>>'
						)
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName } || that.resourceName + ' updated successfully')
							)
						)

						setTimeout(function () {
							if (item.overrideUrl || entityValues.overrideUrl) {
								history.push(item.overrideUrl || entityValues.overrideUrl)
							} else {
								that.goBackUrl(item.menuPath || entityValues.configType)
							}
						}, 1000)
					} else {
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={i18n.t(errorMsg)} />))
				})
		}
	}
	publishItem({ entityValues, tenantId, meta }) {
		let that = this

		return dispatch => {
			if (entityValues.status && entityValues.status !== 'Published') {
				entityValues.status = 'Published'
			}
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({ actionName: 'publishItem', item: entityValues, tenantId: tenantId, propFields: that.propFields })
			}
			let errorMsg =
				i18n.t('Cannot publish the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot publish the ' + that.resourceName + ' at this moment, please try again later'
			if (entityValues.error) {
				dispatch(that.failure({ method: 'EDIT', errorMsg: entityValues.error.message }))
				dispatch(alertActions.error(<ToastMessage messages={entityValues.error.message} />))
				return
			} else if (that.methodSchemas && that.methodSchemas.publish) {
				let val = Joi.validate(entityValues, that.methodSchemas.publish, { abortEarly: false })
				if (val.error) {
					let errorMsg = getExactError(val.error, meta?.fields) //`Request did not pass the joi schema validation, ${val.error.message}`;
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
					return
				}
			}
			that.service
				.publishRecord({ item: entityValues, tenantId })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'publishItem', item: (item && item[0]) || item })
						item = item || entityValues
						// @ts-ignore
						dispatch(that.success({ method: 'EDIT', item }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} published successfully', { resourceName: that.resourceName }) || that.resourceName + ' published successfully'
							)
						)
						setTimeout(function () {
							that.goBackUrl(item.menuPath || item.configType)
						}, 1000)
					} else {
						let errorMsg = 'Cannot publish the ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}

	testConnection({ entityValues, tenantId, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({ actionName: 'testConnection', item: entityValues, tenantId: tenantId, propFields: that.propFields })
			}
			let errorMsg =
				i18n.t('Cannot publish the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot publish the ' + that.resourceName + ' at this moment, please try again later'
			if (entityValues.error) {
				dispatch(that.failure({ method: 'EDIT', errorMsg: entityValues.error.message }))
				dispatch(alertActions.error(<ToastMessage messages={entityValues.error.message} />))
				return
			} else if (that.methodSchemas && that.methodSchemas.publish) {
				let val = Joi.validate(entityValues, that.methodSchemas.publish, { abortEarly: false })
				if (val.error) {
					let errorMsg = getExactError(val.error, meta?.fields) //`Request did not pass the joi schema validation, ${val.error.message}`;
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
					return
				}
			}
			that.service
				.testConnection({ item: entityValues, tenantId })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'publishItem', item: (item && item[0]) || item })
						item = item || entityValues
						// @ts-ignore
						dispatch(that.success({ method: 'EDIT', item }))
						dispatch(
							alertActions.success(
								i18n.t('Mail Connected Successfully!')
							)
						)
					} else {
						let errorMsg = 'Cannot publish the ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}

	copyItem({ entityValues, tenantId, meta }) {
		let that = this

		return dispatch => {
			if (entityValues.status && entityValues.status !== 'Published') {
				entityValues.status = 'Published'
			}
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({ actionName: 'copyItem', item: entityValues, tenantId: tenantId, propFields: that.propFields })
			}
			let errorMsg =
				i18n.t('Cannot publish the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot publish the ' + that.resourceName + ' at this moment, please try again later'
			if (entityValues.error) {
				dispatch(that.failure({ method: 'EDIT', errorMsg: entityValues.error.message }))
				dispatch(alertActions.error(<ToastMessage messages={entityValues.error.message} />))
				return
			} else if (that.methodSchemas && that.methodSchemas.publish) {
				let val = Joi.validate(entityValues, that.methodSchemas.publish, { abortEarly: false })
				if (val.error) {
					let errorMsg = getExactError(val.error, meta?.fields) //`Request did not pass the joi schema validation, ${val.error.message}`;
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
					return
				}
			}
			that.service
				.publishCopyRecord({ item: entityValues, tenantId })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'copyItem', item: (item && item[0]) || item })
						item = item || entityValues
						// @ts-ignore
						dispatch(that.success({ method: 'EDIT', item }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} published successfully', { resourceName: that.resourceName }) || that.resourceName + ' published successfully'
							)
						)
						setTimeout(function () {
							that.goBackUrl(item.menuPath || item.configType)
						}, 1000)
					} else {
						let errorMsg = 'Cannot publish the ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'EDIT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}
	publishItems({ entityValues, tenantId, meta }) {
		let that = this

		return dispatch => {
			dispatch(that.request({ method: 'EDIT' }))
			// @ts-ignore
			if (that.preActionsHooks) {
				// @ts-ignore
				entityValues = that.preActionsHooks({ actionName: 'publishItems', items: entityValues, tenantId: tenantId, propFields: that.propFields })
			}
			let errorMsg =
				i18n.t('Cannot publish the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot publish the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.publishBulkRecord({ items: entityValues, tenantId })
				.then(items => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'publishItems', items: items })
						items = items || entityValues
						// @ts-ignore
						dispatch(that.success({ method: 'APPEND', items }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} published successfully', { resourceName: that.resourceName }) || that.resourceName + ' published successfully'
							)
						)
						setTimeout(function () {
							that.goBackUrl(items.menuPath || items.configType)
						}, 1000)
					} else {
						dispatch(that.failure({ method: 'APPEND', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'EDIT', errorMsg }))
					dispatch(alertActions.error(<ToastMessage messages={errorMsg} />))
				})
		}
	}
	deleteItem({ id, tenantId, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'DELETE' }))
			let errorMsg =
				i18n.t('Cannot delete the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot delete the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.deleteRecord({ id, tenantId })
				.then(item => {
					if (item) {
						// @ts-ignore
						if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'deleteItem', item: (item && item[0]) || item })

						// @ts-ignore
						dispatch(that.success({ method: 'DELETE', item }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} deleted successfully', { resourceName: that.resourceName }) || that.resourceName + ' deleted successfully'
							)
						)
					} else {
						let errorMsg = 'Cannot delete ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'DELETE', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'DELETE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	/**
   * available as a prop action to component for validating
   * @param {*} object
   
   */
	validateItem({ item, method }) {
		let that = this
		return dispatch => {
			if (!item) return i18n.t(`Error. No item passed to validate`) || `Error. No item passed to validate`
			let val
			if (!method) {
				val = Joi.validate(item, that.entitySchema)
				if (val.error) dispatch(alertActions.error(val.error))
				return val.error
			} else {
				let schema = that.methodSchemas[method]
				if (!schema) return i18n.t(`No validation schema found for method {{method}}`, { method }) || `No validation schema found for method  ${method}`
				val = Joi.validate(item, schema)
				if (val.error) dispatch(alertActions.error(val.error))
				return val.error
			}
		}
	}
	validateCollectionItem({ collectionItem, collectionPropName }) {
		let that = this
		return dispatch => {
			if (!collectionItem) return i18n.t(`Error! No item passed to validate`) || `Error! No item passed to validate`
			if (!collectionPropName) return i18n.t(`Error! No Collection property key passed`) || `Error. No Collection property key passed.`
			let schema = that.collectionSchemas[collectionPropName]
			if (!schema) return `No validation schema found for Key ${collectionPropName}`
			let val
			val = Joi.validate(collectionItem, schema)
			if (val.error) dispatch(alertActions.error(val.error))
			return val.error
		}
	}
	validateRelation({ relItem, relationPropName }) {
		let that = this
		return dispatch => {
			if (!relItem) return i18n.t(`Error! No item passed to validate`) || `Error. No item passed to validate`
			if (!relationPropName) return i18n.t(`Error! No Collection property key passed`) || `Error. No Collection property key passed.`
			let schema = that.collectionSchemas[relationPropName]
			if (!schema) return `No validation schema found for Key ${relationPropName}`
			let val
			val = Joi.validate(relItem, schema)
			if (val.error) dispatch(alertActions.error(val.error))
			return val.error
		}
	}
	/**
	 * This assumes that collection is inside the main entity.
	 * @param {object} param0
	 * @param {*} param0.entityValues
	 * * @param {*} param0.childItem
	 * * @param {*} param0.propKey
	 * * @param {*} param0.childKey
	 * * @param {*} param0.entityIdKey
	 * * * @param {*} param0.t
	 */
	addChildItem({ entityValues, childItem, propKey, childKey, entityIdKey }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'ADDCHILD' }))
			let errorMsg
			if (!entityValues) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'ADDCHILD', errorMsg }))
			} else if (!childItem || !propKey) {
				if (!childItem) errorMsg = i18n.t(`No object passed to save`) || `No object passed to save`
				if (!propKey) errorMsg = i18n.t(`Key reference of the collection/relation missing`) || `Key reference of the collection/relation missing.`
				dispatch(that.failure({ method: 'ADDCHILD', errorMsg }))
			} else {
				let obj = entityValues[propKey]
				if (!obj)
					dispatch(
						that.failure({
							method: 'ADDCHILD',
							errorMsg: i18n.t(`{{propKey}} collection is not initialized`, { propKey }) || `${propKey} collection is not initialized`,
						})
					)
				else {
					if (obj.pop) obj.unshift(childItem)
					// collection is an array , add to the beginning
					else {
						// collection is a dictionary
						obj[childItem[childKey]] = childItem
					}
					// @ts-ignore
					this.editItem({ entityValues, tenantId: entityValues.tenantId, entityId: entityValues[entityIdKey] })(dispatch)
				}
			}
		}
	}
	removeChildItem({ entityValues, childItem, propKey, childKey, entityIdKey }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'REMOVECHILD' }))
			let errorMsg
			if (!entityValues) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'REMOVECHILD', errorMsg }))
			} else if (!childItem || !propKey) {
				if (!childItem) errorMsg = i18n.t(`No object passed to save`) || `No object passed to save`
				if (!propKey) errorMsg = i18n.t(`Key reference of the collection/relation missing`) || `Key reference of the collection/relation missing.`
				dispatch(that.failure({ method: 'REMOVECHILD', errorMsg }))
			} else {
				let obj = entityValues[propKey]
				if (!obj)
					dispatch(
						that.failure({
							method: 'REMOVECHILD',
							errorMsg: i18n.t(`{{propKey}} collection is not initialized`, { propKey }) || `${propKey} collection is not initialized`,
						})
					)
				else {
					if (obj.pop) {
						let i = obj.findIndex(c => {
							if (typeof c !== 'object') return c === childItem
							else if (typeof c === 'object') {
								if (c.pop) return utils.arraysEqual(c, childItem)
								else return Object.keys(c).every(k => c[k] === childItem[k])
							} else return false
						})
						if (i > -1) obj.splice(i, 1) // collection is an array
					} else {
						// collection is a dictionary
						obj[childItem[childKey]] = undefined
					}
					// @ts-ignore
					this.editItem({ entityValues, tenantId: entityValues.tenantId, entityId: entityValues[entityIdKey] })(dispatch)
				}
			}
		}
	}
	updateChildItem({ entityValues, childItem, propKey, childKey, entityIdKey }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'EDITCHILD' }))
			let errorMsg
			if (!entityValues) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'EDITCHILD', errorMsg }))
			} else if (!childItem || !propKey) {
				if (!childItem) errorMsg = i18n.t(`No object passed to save`) || `No object passed to save`
				if (!propKey) errorMsg = i18n.t(`Key reference of the collection/relation missing`) || `Key reference of the collection/relation missing.`
				dispatch(that.failure({ method: 'EDITCHILD', errorMsg }))
			} else {
				let obj = entityValues[propKey]
				if (!obj) dispatch(that.failure({ method: 'EDITCHILD', errorMsg: `${propKey} collection is not initialized` }))
				else {
					if (obj.pop) {
						let i = obj.findIndex(c => {
							if (typeof c !== 'object') return c === childItem
							else if (typeof c === 'object') {
								if (c.pop) return utils.arraysEqual(c, childItem)
								else return Object.keys(c).every(k => c[k] === childItem[k])
							} else return false
						})
						if (i > -1) obj.splice(i, 1, childItem) // collection is an array
					} else {
						// collection is a dictionary
						if (!obj[childItem[childKey]])
							dispatch(
								that.failure({
									method: 'EDITCHILD',
									errorMsg:
										i18n.t(`Child item {{childItem}} does not exist in collection `, { childItem: childItem[childKey] }) ||
										`Child item ${childItem[childKey]} does not exist in collection `,
								})
							)
						else obj[childItem[childKey]] = childItem
					}
					// @ts-ignore
					this.editItem({ entityValues, tenantId: entityValues.tenantId, entityId: entityValues[entityIdKey] })(dispatch)
				}
			}
		}
	}
	/**
	 * The item is linked and therefore not contained within the entity. It has therefore its own CRUD apis to be invoked....
	 * @param {*} param0
	 */
	getAllLinked({ tenantId, itemType, filter, orderBy, skip, limit, top, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALLLINKED' }))
			let errorMsg =
				i18n.t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getAllLinked({ tenantId, itemType, filter, orderBy, skip, limit, top })
				.then(items => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'getAllLinked', item: items })

						// @ts-ignore
						dispatch(that.success({ method: 'GETALLLINKED', item: items }))
					} else {
						dispatch(that.failure({ method: 'GETALLLINKED', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETALLLINKED', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	getAllLinkedCount({ tenantId, itemType, filter, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALLLINKEDCOUNT' }))
			let errorMsg =
				i18n.t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getAllLinkedCount({ tenantId, itemType, filter })
				.then(count => {
					if (count) {
						// @ts-ignore
						dispatch(that.success({ method: 'GETALLLINKEDCOUNT', item: count }))
					} else {
						dispatch(that.failure({ method: 'GETALLLINKEDCOUNT', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETALLLINKEDCOUNT', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	addLinkedItem({ linkedItem, tenantId, itemType, meta }) {
		let that = this

		return dispatch => {
			dispatch(that.request({ method: 'ADDLINKED' }))
			let errorMsg =
				i18n.t('Cannot add the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot add the ' + that.resourceName + ' at this moment, please try again later'
			if (!linkedItem) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'ADDLINKED', errorMsg }))
			} else if (!itemType) {
				errorMsg = `itemType not specified.`
				dispatch(that.failure({ method: 'ADDLINKED', errorMsg }))
			} else {
				that.service
					.addLinkedNew({ itemType, item: linkedItem, tenantId })
					.then(item => {
						if (item) {
							// @ts-ignore
							if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'addLinkedItem', item: (item && item[0]) || item })

							// @ts-ignore
							dispatch(that.success({ method: 'ADDLINKED', item }))
							dispatch(
								alertActions.success(
									i18n.t('{{resourceName}} added successfully', { resourceName: that.resourceName }) || that.resourceName + ' added successfully'
								)
							)
						} else {
							dispatch(that.failure({ method: 'ADDLINKED', errorMsg }))
							dispatch(alertActions.error(errorMsg))
						}
					})
					.catch(error => {
						errorMsg =
							error && error.details
								? getExactError(error, meta?.fields)
								: `${i18n.t('Error from server') || 'Error from server'} ${
										(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
								  }`
						dispatch(that.failure({ method: 'ADDLINKED', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					})
			}
		}
	}

	removeLinkedItem({ itemType, linkedItem, tenantId, id, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'REMOVELINKED' }))
			let errorMsg
			if (!linkedItem) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'REMOVELINKED', errorMsg }))
			} else if (!itemType) {
				errorMsg = i18n.t(`itemType not specified`) || `itemType not specified`
				dispatch(that.failure({ method: 'REMOVELINKED', errorMsg }))
			} else {
				that.service
					.deleteRecord({ item: linkedItem, itemType, id, tenantId })
					.then(item => {
						if (item) {
							// @ts-ignore
							if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'removeLinkedItem', item: (item && item[0]) || item })

							// @ts-ignore
							dispatch(that.success({ method: 'REMOVELINKED', item }))
							dispatch(
								alertActions.success(
									i18n.t('{{resoureName}} deleted successfully', { resourceName: that.resourceName }) || that.resourceName + ' deleted successfully'
								)
							)
						} else {
							dispatch(that.failure({ method: 'REMOVELINKED', errorMsg }))
							dispatch(alertActions.error(errorMsg))
						}
					})
					.catch(error => {
						errorMsg =
							error && error.details
								? getExactError(error, meta?.fields)
								: `${i18n.t('Error from server') || 'Error from server'} ${
										(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
								  }`
						dispatch(that.failure({ method: 'REMOVELINKED', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					})
			}
		}
	}
	updateLinkedItem({ itemType, linkedItem, tenantId, id, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'EDITLINKED' }))
			let errorMsg =
				i18n.t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			if (!linkedItem) {
				errorMsg = i18n.t(`No object passed to save to`) || `No object passed to save to`
				dispatch(that.failure({ method: 'EDITLINKED', errorMsg }))
			} else if (!itemType) {
				errorMsg = i18n.t(`itemType not specified`) || `itemType not specified`
				dispatch(that.failure({ method: 'EDITLINKED', errorMsg }))
			} else {
				that.service
					.editLinked({ itemType, item: linkedItem, tenantId, id })
					.then(item => {
						if (item) {
							// @ts-ignore
							if (that.postActionsHooks) item = that.postActionsHooks({ actionName: 'editLinkedItem', item: (item && item[0]) || item })

							// @ts-ignore
							dispatch(that.success({ method: 'EDITLINKED', item }))
							dispatch(
								alertActions.success(
									i18n.t('{{resourceName}} updated successfully', { resourceName: that.resourceName }) || that.resourceName + ' updated successfully'
								)
							)
						} else {
							dispatch(that.failure({ method: 'EDITLINKED', errorMsg }))
							dispatch(alertActions.error(errorMsg))
						}
					})
					.catch(error => {
						errorMsg =
							error && error.details
								? getExactError(error, meta?.fields)
								: `${i18n.t('Error from server') || 'Error from server'} ${
										(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
								  }`
						dispatch(that.failure({ method: 'EDITLINKED', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					})
			}
		}
	}
	upsertItems({ items, tenantId, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'APPEND' }))
			// @ts-ignore
			if (that.preActionsHooks) items = that.postActionsHooks({ actionName: 'upsertItems', item: items, propFields: that.propFields })
			let errorMsg =
				i18n.t('Cannot upload the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot upload the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.upsertItems({ items, tenantId })
				.then((items, errors) => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'upsertItems', item: items })

						// @ts-ignore
						dispatch(that.success({ method: 'APPEND', items, errors }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} added successfully', { resourceName: that.resourceName }) || that.resourceName + ' added successfully'
							)
						)
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'APPEND', errorMsg, errors }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'APPEND', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
	importItems({ items, tenantId, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'IMPORT' }))
			// @ts-ignore
			if (that.preActionsHooks) items = that.preActionsHooks({ actionName: 'importItems', item: items, propFields: that.propFields })
			let errorMsg =
				i18n.t('Cannot import the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot import the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.importItems({ items, tenantId })
				.then((items, errors) => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'importItems', item: items })

						// @ts-ignore
						dispatch(that.success({ method: 'IMPORT', items, errors }))
						dispatch(
							alertActions.success(
								i18n.t('{{resourceName}} added successfully', { resourceName: that.resourceName }) || that.resourceName + ' added successfully'
							)
						)
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'IMPORT', errorMsg, errors }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'IMPORT', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	assignToState({ entityValues, workflowState, id, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'ASSIGN_TO_STATE' }))
			let errorMsg =
				i18n.t('Cannot assign state the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName }) ||
				'Cannot assign state the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.assignToState({ entityValues, workflowState, id })
				.then(item => {
					if (item) {
						// @ts-ignore
						dispatch(that.success({ method: 'ASSIGN_TO_STATE', item }))
					} else {
						dispatch(that.failure({ method: 'ASSIGN_TO_STATE', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${i18n.t('Error from server') || 'Error from server'} ${
									(error && error.message) || error || i18n.t('Please try again later') || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'ASSIGN_TO_STATE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	request({ method }) {
		let type
		switch (method) {
			case 'IMPORT':
				type = EntityConstants.IMPORT_REQUEST
				break
			case 'APPEND':
				type = EntityConstants.APPEND_REQUEST
				break

			case 'EXECUTEAPI':
				type = EntityConstants.EXECUTEAPI_REQUEST
				break
			case 'GETALL':
				type = EntityConstants.GETALL_REQUEST
				break
			case 'GETONE':
				type = EntityConstants.GETONE_REQUEST
				break
			case 'ADDNEW':
				type = EntityConstants.ADDNEW_REQUEST
				break
			case 'ADDCHILD':
				type = EntityConstants.ADDCHILD_REQUEST
				break
			case 'REMOVECHILD':
				type = EntityConstants.REMOVECHILD_REQUEST
				break
			case 'EDITCHILD':
				type = EntityConstants.EDITCHILD_REQUEST
				break
			case 'GETALLCOUNT':
				type = EntityConstants.GETALLCOUNT_REQUEST
				break
			case 'GETALLLINKED':
				type = EntityConstants.GETALLLINKED_REQUEST
				break
			case 'GETALLLINKEDCOUNT':
				type = EntityConstants.GETALLLINKEDCOUNT_REQUEST
				break
			case 'ADDLINKED':
				type = EntityConstants.ADDLINKED_REQUEST
				break
			case 'REMOVELINKED':
				type = EntityConstants.REMOVELINKED_REQUEST
				break
			case 'EDITLINKED':
				type = EntityConstants.EDITLINKED_REQUEST
				break
			case 'EDIT':
				type = EntityConstants.EDIT_REQUEST
				break
			case 'DELETE':
				type = EntityConstants.DELETE_REQUEST
				break
			case 'GETAUDITCONFIG':
				type = EntityConstants.GETAUDITCONFIG_REQUEST
				break
			case 'GETREFITEMS':
				type = EntityConstants.GETREFITEMS_REQUEST
				break
			case 'ASSIGN_TO_STATE':
				type = EntityConstants.ASSIGN_TO_STATE_REQUEST
				break
			case 'CUSTOM_EDIT_REQUEST':
				type = EntityConstants.CUSTOM_EDIT_REQUEST
				break
			case 'GET_UI_FIELDS_META':
				type = EntityConstants.GET_UI_FIELDS_META_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type: type, resourceName: this.resourceName }
	}
	success({ method, item, itemType, data, targetProp }) {
		let type
		switch (method) {
			case 'IMPORT':
				type = EntityConstants.IMPORT_SUCCESS
				break
			case 'APPEND':
				type = EntityConstants.APPEND_SUCCESS
				break
			case 'EXECUTEAPI':
				type = EntityConstants.EXECUTEAPI_SUCCESS
				break
			case 'FILTEREDDATA':
				type = EntityConstants.FILTEREDDATA_SUCCESS
				break
			case 'GETALL':
				type = EntityConstants.GETALL_SUCCESS
				break
			case 'GETONE':
				type = EntityConstants.GETONE_SUCCESS
				break
			case 'ADDNEW':
				type = EntityConstants.ADDNEW_SUCCESS
				break
			case 'ADDCHILD':
				type = EntityConstants.ADDCHILD_SUCCESS
				break
			case 'REMOVECHILD':
				type = EntityConstants.REMOVECHILD_SUCCESS
				break
			case 'EDITCHILD':
				type = EntityConstants.EDITCHILD_SUCCESS
				break
			case 'ADDLINKED':
				type = EntityConstants.ADDLINKED_SUCCESS
				break
			case 'REMOVELINKED':
				type = EntityConstants.REMOVELINKED_SUCCESS
				break
			case 'EDITLINKED':
				type = EntityConstants.EDITLINKED_SUCCESS
				break
			case 'GETALLCOUNT':
				type = EntityConstants.GETALLCOUNT_SUCCESS
				break
			case 'GETALLLINKED':
				type = EntityConstants.GETALLLINKED_SUCCESS
				break
			case 'GETALLLINKEDCOUNT':
				type = EntityConstants.GETALLLINKEDCOUNT_SUCCESS
				break
			case 'EDIT':
				type = EntityConstants.EDIT_SUCCESS
				break
			case 'DELETE':
				type = EntityConstants.DELETE_SUCCESS
				break
			case 'GETAUDITCONFIG':
				type = EntityConstants.GETAUDITCONFIG_SUCCESS
				break
			case 'GETREFITEMS':
				type = EntityConstants.GETREFITEMS_SUCCESS
				break
			case 'ASSIGN_TO_STATE':
				type = EntityConstants.ASSIGN_TO_STATE_SUCCESS
				break
			case 'CUSTOM_EDIT_SUCCESS':
				type = EntityConstants.CUSTOM_EDIT_SUCCESS
				break
			case 'GET_UI_FIELDS_META':
				type = EntityConstants.GET_UI_FIELDS_META_SUCCESS
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, item, resourceName: this.resourceName, itemType, data, targetProp }
	}
	failure({ method, errorMsg }) {
		let type
		switch (method) {
			case 'IMPORT':
				type = EntityConstants.IMPORT_FAILURE
				break
			case 'APPEND':
				type = EntityConstants.APPEND_FAILURE
				break

			case 'EXECUTEAPI':
				type = EntityConstants.EXECUTEAPI_FAILURE
				break
			case 'GETALL':
				type = EntityConstants.GETALL_FAILURE
				break
			case 'GETONE':
				type = EntityConstants.GETONE_FAILURE
				break
			case 'ADDNEW':
				type = EntityConstants.ADDNEW_FAILURE
				break
			case 'ADDCHILD':
				type = EntityConstants.ADDCHILD_FAILURE
				break
			case 'REMOVECHILD':
				type = EntityConstants.REMOVECHILD_FAILURE
				break
			case 'EDITCHILD':
				type = EntityConstants.EDITCHILD_FAILURE
				break
			case 'ADDLINKED':
				type = EntityConstants.ADDLINKED_FAILURE
				break
			case 'REMOVELINKED':
				type = EntityConstants.REMOVELINKED_FAILURE
				break
			case 'EDITLINKED':
				type = EntityConstants.EDITLINKED_FAILURE
				break
			case 'GETALLCOUNT':
				type = EntityConstants.GETALLCOUNT_FAILURE
				break
			case 'GETALLLINKED':
				type = EntityConstants.GETALLLINKED_FAILURE
				break
			case 'GETALLLINKEDCOUNT':
				type = EntityConstants.GETALLLINKEDCOUNT_FAILURE
				break
			case 'EDIT':
				type = EntityConstants.EDIT_FAILURE
				break
			case 'DELETE':
				type = EntityConstants.DELETE_FAILURE
				break
			case 'GETAUDITCONFIG':
				type = EntityConstants.GETAUDITCONFIG_FAILURE
				break
			case 'GETREFITEMS':
				type = EntityConstants.GETREFITEMS_FAILURE
				break
			case 'ASSIGN_TO_STATE':
				type = EntityConstants.ASSIGN_TO_STATE_FAILURE
				break
			case 'CUSTOM_EDIT_FAILURE':
				type = EntityConstants.CUSTOM_EDIT_FAILURE
				break
			case 'GET_UI_FIELDS_META':
				type = EntityConstants.GET_UI_FIELDS_META_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		console.error(`${type}:${errorMsg}`)
		return { type, error: errorMsg, resourceName: this.resourceName }
	}
	getAllAdvanceSearch({ tenantId, filter, orderBy, skip, limit, top, t, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALL' }))
			let errorMsg =
				(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName })) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getAllAdvanceSearch({ tenantId, filter, orderBy, skip, limit, top })
				.then(items => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'getAll', item: items })

						// @ts-ignore
						dispatch(that.success({ method: 'GETALL', item: items, data: { filter } }))
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'GETALL', error: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${(t && t('Error from server')) || 'Error from server'} ${
									(error && error.message) || error || (t && t('Please try again later')) || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETALL', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	getAuditConfig(t) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETAUDITCONFIG' }))
			let errorMsg =
				(t && t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName })) ||
				'Cannot update the ' + that.resourceName + ' at this moment, please try again later'
			that.service.getAuditConfig().then(
				item => {
					if (item) {
						if (item.pop) item = item[0]
						// @ts-ignore
						dispatch(that.success({ method: 'GETAUDITCONFIG', item }))
					} else {
						// @ts-ignore
						dispatch(that.success({ method: 'GETAUDITCONFIG', item }))
					}
				},
				error => {
					errorMsg =
						error && error.details
							? getExactError(error)
							: `${(t && t('Error from server')) || 'Error from server'} ${
									(error && error.message) || error || (t && t('Please try again later')) || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETAUDITCONFIG', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	getRefItems({ apiDef, tenantId, filter, orderBy, skip, limit, top, params, t, meta }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETREFITEMS' }))
			let errorMsg =
				(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName })) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getRefItems({ apiDef, tenantId, filter, orderBy, skip, limit, top, params })
				.then(items => {
					if (items) {
						// @ts-ignore
						if (that.postActionsHooks) items = that.postActionsHooks({ actionName: 'getRefItems', item: items })
						// @ts-ignore
						dispatch(that.success({ method: 'GETREFITEMS', item: items, itemType: apiDef.entityName || apiDef.response.ref }))
					} else {
						dispatch(that.failure({ method: 'GETREFITEMS', errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error, meta?.fields)
							: `${(t && t('Error from server')) || 'Error from server'} ${
									(error && error.message) || error || (t && t('Please try again later')) || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GETREFITEMS', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	getUiFieldsMeta(t) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GET_UI_FIELDS_META' }))
			let errorMsg =
				(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: that.resourceName })) ||
				'Cannot fetch the ' + that.resourceName + ' at this moment, please try again later'
			that.service
				.getUiFieldsMeta()
				.then(items => {
					if (items) {
						items.forEach(i => {
							!that.propFields.includes(i.fieldProperties.name) && that.propFields.push(i.fieldProperties.name)
						})
						// @ts-ignore
						dispatch(that.success({ method: 'GET_UI_FIELDS_META', item: items }))
					} else {
						// @ts-ignore
						dispatch(that.failure({ method: 'GET_UI_FIELDS_META', error: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					errorMsg =
						error && error.details
							? getExactError(error)
							: `${(t && t('Error from server')) || 'Error from server'} ${
									(error && error.message) || error || (t && t('Please try again later')) || 'Please try again later.'
							  }`
					dispatch(that.failure({ method: 'GET_UI_FIELDS_META', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	getFns() {
		return {
			getAll: this.getAll.bind(this),
			addNewItem: this.addNewItem.bind(this),
			editItem: this.editItem.bind(this),
			deleteItem: this.deleteItem.bind(this),
			addChildItem: this.addChildItem.bind(this),
			removeChildItem: this.removeChildItem.bind(this),
			updateChildItem: this.updateChildItem.bind(this),
			validateItem: this.validateItem.bind(this),
			validateRelation: this.validateRelation.bind(this),
			validateCollectionItem: this.validateCollectionItem.bind(this),
			load: this.load.bind(this),
			request: this.request.bind(this),
			success: this.success.bind(this),
			failure: this.failure.bind(this),
			getOne: this.getOne.bind(this),
			getAllCount: this.getAllCount.bind(this),
			getAllLinked: this.getAllLinked.bind(this),
			getAllLinkedCount: this.getAllLinkedCount.bind(this),
			addLinkedItem: this.addLinkedItem.bind(this),
			removeLinkedItem: this.removeLinkedItem.bind(this),
			updateLinkedItem: this.updateLinkedItem.bind(this),
			filterData: this.filterData.bind(this),
			getAllAdvanceSearch: this.getAllAdvanceSearch.bind(this),
			executeApi: this.executeApi.bind(this),
			upsertItem: this.upsertItem.bind(this),
			upsertItems: this.upsertItems.bind(this),
			importItems: this.importItems.bind(this),
			publishItem: this.publishItem.bind(this),
			testConnection: this.testConnection.bind(this),
			copyItem: this.copyItem.bind(this),
			getAuditConfig: this.getAuditConfig.bind(this),
			publishItems: this.publishItems.bind(this),
			assignToState: this.assignToState.bind(this),
			getRefItems: this.getRefItems.bind(this),
			customEditItem: this.customEditItem.bind(this),
			getUiFieldsMeta: this.getUiFieldsMeta.bind(this),
		}
	}
}
