import { baseConstants } from '..'
import { alertActions } from '../../common'

export class BaseActions {
	constructor(options) {
		this.service = options.service
		this.resourceName = options.resourceName
	}
	getAll(queryStringArr, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			that.service.getAll(queryStringArr, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('GETALL', item))
					} else {
						let errorMsg =
							(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot fetch the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('GETALL', errorMsg))
						dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot fetch the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot fetch the ' + this.resourceName + ' at this moment, please try again later'
					dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	addNewItem(itemData, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('ADDNEW'))

			that.service.addNew(itemData, apiUrl).then(
				item => {
					console.log('test:::', item)
					if (item) {
						dispatch(that.success('ADDNEW', item))
						dispatch(alertActions.success(that.resourceName + ' added successfully.'))
					} else {
						let errorMsg =
							(t && t('Cannot add the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot add the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('ADDNEW', errorMsg))
						dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot add the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot add the ' + this.resourceName + ' at this moment, please try again later'
					dispatch(that.failure('ADDNEW', errorMsg))
					dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	editItem(itemData, editId, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))

			that.service.editRecord(itemData, editId, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('EDIT', item))
						dispatch(alertActions.success(that.resourceName + ' updated successfully.'))
					} else {
						let errorMsg =
							(t && t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot update the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('EDIT', errorMsg))
						dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot update the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot update the ' + this.resourceName + ' at this moment, please try again later' + (error ? error.message : '')
					dispatch(that.failure('EDIT', errorMsg))
					dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	deleteItem(id, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('DELETE'))

			that.service.deleteRecord(id, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('DELETE', item))
						dispatch(alertActions.success(that.resourceName + ' deleted successfully.'))
					} else {
						let errorMsg =
							(t && t('Cannot delete the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
							'Cannot delete the ' + this.resourceName + ' at this moment, please try again later'
						dispatch(that.failure('DELETE', errorMsg))
						dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg =
						(t && t('Cannot delete the {{resourceName}} at this moment, please try again later', { resourceName: this.resourceName })) ||
						'Cannot delete the ' + this.resourceName + ' at this moment, please try again later' + (error ? error.message : '')
					dispatch(that.failure('DELETE', errorMsg))
					dispatch({ type: `${baseConstants.LOADER_FALSE}_${this.resourceName}` })
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	request(method) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_REQUEST
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_REQUEST
				break
			case 'EDIT':
				type = baseConstants.EDIT_REQUEST
				break
			case 'DELETE':
				type = baseConstants.DELETE_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type: type }
	}
	success(method, item) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_SUCCESS
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_SUCCESS
				break
			case 'EDIT':
				type = baseConstants.EDIT_SUCCESS
				break
			case 'DELETE':
				type = baseConstants.DELETE_SUCCESS
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, item }
	}
	failure(method, error) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_FAILURE
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_FAILURE
				break
			case 'EDIT':
				type = baseConstants.EDIT_FAILURE
				break
			case 'DELETE':
				type = baseConstants.DELETE_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, error }
	}
}
