/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment'
import { DashboardT } from '../common/analytics'
// import {EntityComponent} from '../entity'
import { methodSchemas } from './schemas'
// import {    baseConstants} from '../../entity';

class QueueDashboardT extends DashboardT {
	constructor(options) {
		super(options)
	}

	getPropsFilterValues({ profile }) {
		if (profile.roles.includes('chat_agent_supervisor')) {
			// Need to get all agent data, skip the filter
			return
		}

		this.cardsMeta.liveChatCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.cardsMeta.liveChatOpenCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.cardsMeta.liveChatClosedCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.cardsMeta.respondedCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.cardsMeta.avgFeedback.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.chartsMeta.avgResponseTime.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.chartsMeta.maxWaitingTime.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		}
		this.chartsMeta.tagging.dynFilters = {}
		return
	}
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 *
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 *
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *
 *  cards: livechatCount, avgFeedback
 *  line: avg Response, avgResolution
 *  bar: missedChat
 *  % escalations: pie.
 *
 */

let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let dayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate())
let dayBeginF = moment(dayBegin).tz(moment.tz.guess()).format().slice(0, -6)

let weekBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay())
let weekBeginF = moment(weekBegin).tz(moment.tz.guess()).format().slice(0, -6)

let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF = moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
	liveChatMetrics: {
		name: 'liveChatMetrics',
		/**
		 * get most metrics if not all associated with the page.
		 */
		dependsOnFields: ['agent', 'queue'], // page filters
		filters: [
			{
				field: 'createdAt',
				operator: 'is within',
				values: [monthBeginF, nowF],
			},
		],
		select: ['metricName', 'createdAt', 'duration', 'reason'],
		// groupBy: [ 'agent'], // date, metricname are default grouped
		groupByAgg: [
			{ field: 'count', operation: 'sum', as: 'metric' },
			{ field: 'sum', operation: 'sum', as: 'summetric' },
			{ field: 'avg', operation: 'average', as: 'avg' },
			{ field: 'sum', operation: 'max', as: 'max' },
		],
		//
		path: 'analytics',
		method: 'POST',
		groupBy: ['agent', 'queue'],

		response: {
			valueKey: 'data', //  data attribute of the reducer... <reducer>.data.metrics
		},
		orderBy: [{ field: 'createdAt', order: 'asc' }],
	},
	onGoingRag: {
		name: 'onGoingRag',
		path: 'chatsessions/getRAG',
		method: 'GET',
		response: {
			valueKey: 'data', //  data attribute of the reducer... <reducer>.data.metrics
		},
	},
}

const cardsMeta = {
	liveChatOpenCount: {
		title: 'Live Chat Open Count',
		metrics: [{ name: 'LiveChat', fields: ['summetric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		//size: 'xs',
		// dependsOnFields: ['fromDate', 'toDate'],
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	liveChatClosedCount: {
		title: 'Live Chat Closed Count',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_closed_count' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		//size: 'xs',
		// dependsOnFields: ['fromDate', 'toDate'],
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	liveChatCount: {
		title: 'Live Chat Count',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_count' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		//size: 'xs',
		// dependsOnFields: ['fromDate', 'toDate'],
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	avgFeedback: {
		title: 'CSAT Average',
		metrics: [{ name: 'avgFeedback', fields: ['avg'], operations: ['mean'] }], // need summetric / metric as value
		filters: [{ field: 'metricName', value: 'feedback_live' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		dependsOnFields: ['fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	respondedCount: {
		name: 'respondedCount',
		title: 'Responded Vs Threshold',
		metrics: [
			{ name: 'live_chat_count', value: 'live_chat_count', fields: ['metric'], operations: ['sum'] },
			{ name: 'missed_chat_count', value: 'missed_chat_count', fields: ['metric'], operations: ['sum'] },
		],
		metricsOperations: ['diff'], // need summetric / metric as value
		settingValue: { name: 'threshold', operation: 'divide' },
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		size: 'xl',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {
	/**
     * dualAxis chart eg.
     
     */

	avgResponseTime: {
		padding: [20, 40, 100, 80],
		name: 'avgResponseTime',
		title: 'Avg Response Time',
		chartType: 'lines',
		xy: 'createdAt*avg',
		axes: [
			{ field: 'createdAt', alias: 'Date', type: 'cat' },
			{ field: 'avg', alias: 'Response Time (sec)', label: 'mins' },
		],
		metrics: [{ series: 'Mine', field: 'avg', operation: ['mean'], groupBy: ['createdAt'] }],
		filters: [{ field: 'metricName', value: 'response_time' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		dependsOnFields: ['agent', 'fromDate', 'toDate'],
		height: 200,
	},
	tagging: {
		padding: [20, 70, 50, 100],
		name: 'TaggingChart',
		title: 'TotalCount Vs Indicator',
		chartType: 'bars',
		api: 'onGoingRag',
		xy: 'indicator*total_count',
		series: 'series',
		height: 300,
		axes: [
			{ field: 'total_count', alias: 'Count', type: 'cat' },
			{ field: 'indicator', alias: 'Indicator' },
		],
		metrics: [{ series: 'TotalCount', field: 'total_count', groupBy: ['indicator'], operations: ['sum'] }],
		// static filters
		dynFilters: {
			// dynamic filters
			createdat: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		dependsOnFields: ['queue', 'fromDate', 'toDate'],
		// axes: [{ field: 'metricName', alias: 'Metric', type: 'cat' }, { field: 'metric', alias: 'Performance in hrs' }],
		// metrics: [
		//     { series: 'Mine', field: 'metric', filters: { agent: 'agent1' }, operations: ['mean'], groupBy: ['queue', 'metricName'] },
		//     { series: 'All', field: 'metric', operations: ['mean'], groupBy: ['queue', 'metricName'] }
		// ],
	},
	maxWaitingTime: {
		padding: [20, 40, 100, 80],
		name: 'maxWaitingTime',
		title: 'Max Waiting Time',
		chartType: 'lines',
		xy: 'createdAt*max',
		axes: [
			{ field: 'createdAt', alias: 'Date', type: 'cat' },
			{ field: 'max', alias: 'Response Time (sec)', label: 'mins' },
		],
		metrics: [{ series: 'Mine', field: 'max', operation: ['max'], groupBy: ['createdAt'] }],
		filters: [{ field: 'metricName', value: 'response_time' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			queue: 'queue',
		},
		dependsOnFields: ['agent', 'fromDate', 'toDate'],
		height: 200,
	},
}
const fieldsMeta = {
	fromDate: {
		label: 'From Date',
		name: 'fromDate',
		type: 'text',
		value: dayBeginF,
		valueFn: period => {
			if (!period) return
			switch (period) {
				case 'Day':
					return dayBeginF
				case 'Week':
					return weekBeginF
				case 'Month':
					return monthBeginF
				default:
					return monthBeginF
			}
		},
		dependsOnFields: ['period'],
		readOnly: true,
	},
	toDate: {
		label: 'To Date',
		name: 'toDate',
		type: 'calculated',
		value: nowF,
		readOnly: true,
	},
	// need to map to appropriate api to get all live chat agents by name/email
	queue: {
		// list of queues associated with the user..
		label: 'Queue',
		type: 'select',
		required: true,
		name: 'queue',
		api: {
			path: 'queue_livechat',
			response: {
				ref: 'data',
				labelKey: 'queueLabel',
				valueKey: 'queueName',
			},
		},
		allowedRole: 'chat_agent_supervisor',
	},
	period: {
		label: 'Period',
		type: 'select',
		name: 'period',
		options: [
			{ label: 'Day', value: 'Day' },
			{ label: 'Week', value: 'Week' },
			{ label: 'Month', value: 'Month' },
		],
		default: 'Month',
	},
}

const listsMeta = {
	exampleList: {
		// api:
		// dependsOnFields:
		// dataProp: '' ,
		title: 'My List of Items',
		name: 'exampleList',
		filters: [{ field: 'metricName', value: 'resolutionSLA' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			//queue: 'queue'
		},
		height: 400,
		colProps: [
			{ field: 'metricName', width: 20, route: '/ticket/settings/update/some' },
			{ field: 'metric', width: 15 },
			{ field: 'queue' },
			{ field: 'agent' },
			{ field: 'createdAt', label: 'Date' },
		],
	},
}
const sectionsMeta = {
	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},

	basic: {
		title: 'Query Details',
		items: [
			{ name: 'period', type: 'field' },
			{ name: 'agent', type: 'field' },
			{ name: 'queue', type: 'field' },
		],
	},
	filters: {
		items: [
			{ name: 'period', type: 'field', col: 1 },
			{ name: 'fromDate', type: 'field', col: 3 },
			{ name: 'queue', type: 'field', col: 2 },
		],
		title: 'Filters',
		cols: [4, 4, 4],
	},
	cards: {
		cols: [4, 4, 4],
		items: [
			{ name: 'liveChatCount', type: 'card', col: 1 },
			{ name: 'liveChatOpenCount', type: 'card', col: 1 },
			{ name: 'avgFeedback', type: 'card', col: 2 },
			{ name: 'liveChatClosedCount', type: 'card', col: 2 },
			{ name: 'respondedCount', type: 'card', col: 3 },
		],
	},

	charts: {
		cols: [6, 6],
		items: [
			{ name: 'avgResponseTime', type: 'chart', col: 1 },
			{ name: 'maxWaitingTime', type: 'chart', col: 2 },
			{ name: 'tagging', type: 'chart', col: 1 },
		],
	},
	// lists: {
	//     items: [{name: 'exampleList', type: 'list'},] //{name: 'exampleList', type: 'list'}
	// }

	// object: {
	//     object: {        ref: 'value' }
	//       // show all value object keys as grid key values. All fields are defaulted to text. If value is not of type object then this section is ignored.

	// // },
	// headerComponent: {
	//     component: 'DetailHeader',

	//     // props: [] by default pass all parent props as props to the component.
	// },
}

const dashboardMeta = {
	items: [
		{ name: 'filters', type: 'section' },
		{ name: 'cards', type: 'section' },
		{ name: 'charts', type: 'section' },
	],
	classes: 'composite-form',
	title: 'Queue Dashboard',
	initialValues: {
		filterValues: {
			fromDate: monthBeginF,
			toDate: nowF,
			period: 'Month',
		},
	},
	apis: ['liveChatMetrics'],
}

const QueueDashboard = new QueueDashboardT({
	fieldsMeta,
	sectionsMeta,
	dashboardMeta,
	apisMeta,
	cardsMeta,
	chartsMeta,
	listsMeta,
	name: 'liveChatDashboard',
	title: 'LiveChat Dashboard',
	methodSchemas,
})

const QueueDashboardContainer = QueueDashboard.getContainer('Dashboard')
export { QueueDashboardContainer }
