import { BaseActions } from '../../base-ui'
import userqueueServices from '../services/userqueue.services'

let options = {}
options.service = userqueueServices
options.resourceName = 'userqueue'

class UserQueueQueueActionModel extends BaseActions {
	constructor(options) {
		super(options)
	}
}

let UserQueuesQueueActionModel = new UserQueueQueueActionModel(options)

export const getUserQueueData = (filter, t) => UserQueuesQueueActionModel.getAll(filter, t)
export const userLivechatQueues = (itemData, t) => UserQueuesQueueActionModel.addNewItem(itemData, t)
