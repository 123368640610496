import { session } from '../../../config'
import { BaseService } from '../../base-ui'
import { identityServices, wsHelper } from '../../common'
let options = {}
options.urlItemName = 'queue_livechat'
options.wsType = 'livechat'
options.workspaceEnabled = true
class agentqueueServices extends BaseService {
	constructor(options) {
		super(options)
	}

	editQueueInfo(queueData) {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/queue_livechat/updateinfo`,
			data: JSON.stringify(queueData),
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}

	updateQueueAssignment(queueData, id) {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrl}/queue_livechat/updateQueueAssignment/${id}`,
			data: queueData,
		}
		requestOptions = wsHelper.getWsQuery({ wsType: options.wsType, req: requestOptions })
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
}
const agentqueueService = new agentqueueServices(options)
export default agentqueueService
