/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment'
import { DashboardT } from '../common/analytics'
// import {EntityComponent} from '../entity'
import { methodSchemas } from './schemas'
// import {    baseConstants} from '../../entity';

class OverallDashboardT extends DashboardT {
	constructor(options) {
		super(options)
	}

	getPropsFilterValues({ profile }) {
		if (profile.roles.includes('chat_agent_supervisor')) {
			// Need to get all agent data, skip the filter
			return
		}
		this.cardsMeta.liveChatCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.cardsMeta.liveChatOpenCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.cardsMeta.liveChatClosedCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.cardsMeta.volCount.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.cardsMeta.avgFeedback.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.chartsMeta.avgResponseTime.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.chartsMeta.nonAvailableByReason.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}
		this.chartsMeta.avgConversationTime.dynFilters = {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		}

		return
	}
}
/**
 *    one api for entire page? yes. if all metrics have common filters ( time, agent)
 *    and all metrics are obtained at one go.. without metricname in filter.
 *
 *      obtain the following( 3 apis ) on page load
 *  1. live_chat_count, resolution_time, response_time, missed_chat, feedback
 *  2. feedback where type is liveChat
 *  3. chatsession count.
 *
 *   pageFilters:  time interval derived from Period choice, agentName : default null.
 *   Day, Week, Month period should map to type in api....
 *
 *  cards: livechatCount, avgFeedback
 *  line: avg Response, avgResolution
 *  bar: missedChat
 *  % escalations: pie.
 *
 */

let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let dayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate())
let dayBeginF = moment(dayBegin).tz(moment.tz.guess()).format().slice(0, -6)

let weekBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay())
let weekBeginF = moment(weekBegin).tz(moment.tz.guess()).format().slice(0, -6)

let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF = moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
	liveChatMetrics: {
		name: 'liveChatMetrics',
		/**
		 * get most metrics if not all associated with the page.
		 */
		dependsOnFields: ['agent', 'queue'], // page filters
		filters: [
			{
				field: 'createdAt',
				operator: 'is within',
				values: [monthBeginF, nowF],
			},
		],
		select: ['metricName', 'createdAt', 'duration', 'reason'],
		// groupBy: [ 'agent'], // date, metricname are default grouped
		groupByAgg: [
			{ field: 'value_number', operation: 'sum', as: 'metric' },
			{ field: 'value_number', operation: 'average', as: 'avg' },
		],
		//
		path: 'analytics',
		method: 'POST',
		groupBy: ['agent', 'queue'],
		metricType: 'Day',
		response: {
			valueKey: 'data', //  data attribute of the reducer... <reducer>.data.metrics
		},
		orderBy: [{ field: 'createdAt', order: 'asc' }],
	},
	overallMetrics: {
		name: 'overallMetrics',
		/**
		 * get most metrics if not all associated with the page.
		 */
		dependsOnFields: ['agent', 'queue'], // page filters
		filters: [
			{
				field: 'createdAt',
				operator: 'is within',
				//values: ['fromDate', 'toDate'],
				values: [monthBeginF, nowF],
				// defaultValues: [monthBeginF, nowF] // to be injected
			},
			// {
			//     field: 'metricName',
			//     operator: 'contains',
			//     values: ['feedback_live', 'live_chat_count', 'resolution_time', 'response_time', 'missed_chat_count', 'chat_session_count'] // to be injected
			// }
		],
		select: ['occupancy', 'non_availability_per'],
		// groupBy: [ 'agent'], // date, metricname are default grouped
		groupByAgg: [
			{ field: 'count', operation: 'sum', as: 'metric' },
			{ field: 'sum', operation: 'sum', as: 'summetric' },
			{ field: 'avg', operation: 'average', as: 'avg' },
		],
		//
		path: 'chatsessions/getOverallMetrics',
		method: 'POST',
		groupBy: ['agent', 'queue'],

		response: {
			valueKey: 'data', //  data attribute of the reducer... <reducer>.data.metrics
			// computeFn : 'flatternJson',
			// colToFlat : 'dim'
		},
		orderBy: [{ field: 'createdAt', order: 'asc' }],
	},
	slaMetrics: {
		name: 'slaMetrics',
		path: 'sla/findWithDefaultTenant',
		method: 'GET',
		response: {
			valueKey: 'data',
			targetProp: 'sla',
		},
	},
}

const cardsMeta = {
	liveChatOpenCount: {
		title: 'Live Chat Open Count',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
		dynFilters: {
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		},
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	avgFeedback: {
		name: 'avgFeedback',
		title: 'CSAT Average',
		metrics: [{ name: 'avgFeedback', fields: ['avg'], operations: ['mean'] }], // need summetric / metric as value
		filters: [{ field: 'metricName', value: 'feedback_live' }],
		size: 'xl',
		dependsOnFields: ['fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	volCount: {
		title: 'Vol Count',
		metrics: [
			{ name: 'live_chat_open_count', value: 'live_chat_open_count', fields: ['metric'], operations: ['sum'] },
			{ name: 'live_chat_closed_count', value: 'live_chat_closed_count', fields: ['metric'], operations: ['sum'] },
			{ name: 'live_chat_count', value: 'live_chat_count', fields: ['metric'], operations: ['sum'] },
		],
		metricsOperations: ['sum'], // need summetric / metric as value
		size: 'xl',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	reassignedCount: {
		name: 'reassignedCount',
		title: 'Escalation Count',
		metrics: [{ name: 'reassignedCount', fields: ['avg'], operation: ['mean'] }], // need summetric / metric as value
		filters: [{ field: 'metricName', value: 'escalation_count' }],
		size: 'xl',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	liveChatClosedCount: {
		title: 'Cycle Rate',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_closed_count' }],
		settingValue: { name: 'mins', operation: 'divide' },
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	missedChatCount: {
		title: 'Abandon Rate',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'missed_chat_count' }],
		settingValue: { name: 'mins', operation: 'divide' },
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	nonAvailableTime: {
		name: 'nonAvailableTime',
		title: 'NonAvailableTime in percent',
		api: 'overallMetrics',
		metrics: [{ name: 'nonAvailableTime', fields: ['non_availability'], operations: ['sum'] }],
		filters: [{ field: 'metricname', value: 'non_availability' }],
		dynFilters: {
			createdat: { operator: 'range', values: ['fromDate', 'toDate'] },
		},
		size: 'xl',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	occupancy: {
		name: 'occupancy',
		title: 'Occupancy',
		api: 'overallMetrics',
		metrics: [{ name: 'occupancy', fields: ['occupancy'], operations: ['sum'] }],
		filters: [{ field: 'metricname', value: 'conversation_time' }],
		dynFilters: {
			createdat: { operator: 'range', values: ['fromDate', 'toDate'] },
		},
		size: 'xl',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'bg-gradient-mb',
	},
	volCountSLA: {
		name: 'volCount',
		title: 'Vol Count SLA',
		metrics: [
			{ name: 'live_chat_open_count', value: 'live_chat_open_count', fields: ['metric'], operations: ['sum'] },
			{ name: 'live_chat_closed_count', value: 'live_chat_closed_count', fields: ['metric'], operations: ['sum'] },
			{ name: 'live_chat_count', value: 'live_chat_count', fields: ['metric'], operations: ['sum'] },
		],
		metricsOperations: ['sum'], // need summetric / metric as value
		size: 'xl',
		color: 'sla',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	answeredCountSLA: {
		name: 'liveChatCount',
		title: 'Answered Count SLA',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_count' }],
		color: 'sla',
		size: 'xl',
		classes: 'bg-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	pendingCountSLA: {
		name: 'liveChatOpenCount',
		title: 'Pending SLA',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'live_chat_open_count' }],
		color: 'sla',
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
	leftUnAnsweredSLA: {
		name: 'missedChatCount',
		title: 'LeftUnAnswered SLA',
		metrics: [{ name: 'LiveChat', fields: ['metric'], operations: ['sum'] }],
		filters: [{ field: 'metricName', value: 'missed_chat_count' }],
		color: 'sla',
		size: 'xl',
		classes: 'bg-gradient-ly',
		dependsOnFields: ['period', 'fromDate', 'toDate', 'agent'],
		// dataProp: 'data', jsonpath to the rows collection.
		bodySuffix: '',
		icon: 'fa fa-search',
		classes: 'sm',
	},
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {
	/**
     * dualAxis chart eg.
     
     */

	/** need to supply overall chat count too as data point here. */
	avgConversationTime: {
		padding: [20, 40, 100, 80],
		name: 'avgConversationTime',
		title: 'Avg Conversation Time',
		chartType: 'lines',
		xy: 'createdAt*avg',
		axes: [
			{ field: 'createdAt', alias: 'Date', type: 'cat' },
			{ field: 'avg', alias: 'Conversation Time (mins)', label: 'mins' },
		],
		metrics: [{ series: 'Mine', field: 'avg', operation: ['mean'], groupBy: ['createdAt'] }],
		filters: [{ field: 'metricName', value: 'conversation_time' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		},
		dependsOnFields: ['agent', 'fromDate', 'toDate'],
		height: 200,
	},
	concurrent: {
		padding: [20, 40, 100, 80],
		name: 'concurrent',
		title: 'Concurrent Vs Working Hours',
		chartType: 'lines',
		xy: 'createdAt*avg',
		axes: [
			{ field: 'createdAt', alias: 'Date' },
			{ field: 'avg', alias: 'Conversation Time (mins)', label: 'mins' },
		],
		metrics: [{ series: 'Mine', field: 'avg', operation: ['mean'], groupBy: ['createdAt'] }],
		settingValue: { name: 'working_hours', operation: 'divide' },
		filters: [{ field: 'metricName', value: 'conversation_time' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
		},
		dependsOnFields: ['agent', 'fromDate', 'toDate'],
		height: 200,
	},
	queuesSLAChart: {
		padding: [20, 70, 50, 100],
		name: 'queuesSLAChart',
		title: 'Queue SLA',
		chartType: 'bars',
		xy: 'metricName*queue',
		series: 'series',
		height: 300,
		axes: [
			{ field: 'metricName', alias: 'Metric', type: 'cat' },
			{ field: 'queue', alias: 'Performance in hrs' },
		],
		metrics: [{ series: 'All', field: 'queue', operations: ['count'], groupBy: ['queue', 'metricName'], value: 'queue' }],
		// static filters
		filters: [{ field: 'metricName', operator: 'in', values: ['live_chat_count', 'missed_chat_count', 'live_chat_open_count'] }],
		dependsOnFields: ['queue'],
	},
}
const fieldsMeta = {
	fromDate: {
		label: 'From Date',
		name: 'fromDate',
		type: 'text',
		value: dayBeginF,
		valueFn: period => {
			if (!period) return
			switch (period) {
				case 'Day':
					return dayBeginF
				case 'Week':
					return weekBeginF
				case 'Month':
					return monthBeginF
				default:
					return monthBeginF
			}
		},
		dependsOnFields: ['period'],
		readOnly: true,
	},
	toDate: {
		label: 'To Date',
		name: 'toDate',
		type: 'calculated',
		value: nowF,
		readOnly: true,
	},
	period: {
		label: 'Period',
		type: 'select',
		name: 'period',
		options: [
			{ label: 'Day', value: 'Day' },
			{ label: 'Week', value: 'Week' },
			{ label: 'Month', value: 'Month' },
		],
		default: 'Month',
	},
}

const listsMeta = {
	exampleList: {
		// api:
		// dependsOnFields:
		// dataProp: '' ,
		title: 'My List of Items',
		name: 'exampleList',
		filters: [{ field: 'metricName', value: 'resolutionSLA' }], // static filters
		dynFilters: {
			// dynamic filters
			createdAt: { operator: 'range', values: ['fromDate', 'toDate'] },
			//queue: 'queue'
		},
		height: 400,
		colProps: [
			{ field: 'metricName', width: 20, route: '/ticket/settings/update/some' },
			{ field: 'metric', width: 15 },
			{ field: 'queue' },
			{ field: 'agent' },
			{ field: 'createdAt', label: 'Date' },
		],
	},
}
const sectionsMeta = {
	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},

	basic: {
		title: 'Query Details',
		items: [{ name: 'period', type: 'field' }],
	},
	filters: {
		items: [
			{ name: 'period', type: 'field', col: 1 },
			{ name: 'fromDate', type: 'field', col: 3 },
		],
		title: 'Filters',
		cols: [4, 4, 4],
	},
	cards: {
		cols: [4, 4, 4],
		items: [
			{ name: 'volCount', type: 'card', col: 1 },
			{ name: 'liveChatOpenCount', type: 'card', col: 1 },
			{ name: 'avgFeedback', type: 'card', col: 2 },
			{ name: 'reassignedCount', type: 'card', col: 3 },
			{ name: 'liveChatClosedCount', type: 'card', col: 2 },
			{ name: 'missedChatCount', type: 'card', col: 1 },
			{ name: 'nonAvailableTime', type: 'card', col: 3 },
			{ name: 'occupancy', type: 'card', col: 2 },
		],
	},

	charts: {
		cols: [6, 6],
		items: [
			{ name: 'avgConversationTime', type: 'chart', col: 1 },
			{ name: 'concurrent', type: 'chart', col: 2 },
			{ name: 'queuesSLAChart', type: 'chart', col: 1 },
		],
	},
	overallSla: {
		cols: [4, 4, 4],
		title: 'SLA',
		items: [
			{ name: 'volCountSLA', type: 'card', col: 1 },
			{ name: 'answeredCountSLA', type: 'card', col: 2 },
			{ name: 'pendingCountSLA', type: 'card', col: 3 },
			{ name: 'leftUnAnsweredSLA', type: 'card', col: 1 },
		],
	},
}

const dashboardMeta = {
	items: [
		{ name: 'cards', type: 'section' },
		{ name: 'overallSla', type: 'section', title: 'SLA' },
		{ name: 'charts', type: 'section' },
	],
	classes: 'composite-form',
	title: 'Overall Dashboard',
	initialValues: {
		filterValues: {
			fromDate: monthBeginF,
			toDate: nowF,
			period: 'Month',
		},
	},
	refreshFilters: [
		{
			field: 'updatedAt',
			operator: '>',
			valueRef: 'timerFilter',
		},
	],
	slaApi: 'slaMetrics',
	refresh: true,
	apis: ['liveChatMetrics'],
}

const OverallDashboard = new OverallDashboardT({
	fieldsMeta,
	sectionsMeta,
	dashboardMeta,
	apisMeta,
	cardsMeta,
	chartsMeta,
	listsMeta,
	name: 'liveChatDashboard',
	title: 'Overall Dashboard',
	methodSchemas,
})

const OverallDashboardContainer = OverallDashboard.getContainer('Dashboard')
export { OverallDashboardContainer }
