import { reportsConstants } from '../';

export const reports = (state = {}, action) => {
    switch (action.type) {
        case reportsConstants.REPORT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case reportsConstants.REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                itemData: action.records,

            }
        case reportsConstants.REPORT_FAILURE:
            return {
                ...state,
                loading: true
            }
      
        default:
            return state
    }
}