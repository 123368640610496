export const GETALL_REQUEST = 'GETALL_REQUEST';
export const GETALL_SUCCESS = 'GETALL_SUCCESS';
export const GETALL_FAILURE = 'GETALL_FAILURE';
export const GET_REQUEST = 'GET_REQUEST';
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_FAILURE = 'GET_FAILURE';
export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';
export const ADDNEW_REQUEST = 'ADDNEW_REQUEST';
export const ADDNEW_SUCCESS = 'ADDNEW_SUCCESS';
export const ADDNEW_FAILURE = 'ADDNEW_FAILURE';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAILURE = 'EDIT_FAILURE';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';
export const LOADER_FALSE = 'LOADER_FALSE'
