import { usersListConstants } from '../';

export const userslist = (state = {}, action) => {
    switch (action.type) {
        case usersListConstants.GETALL_REQUEST_userslist:
            return {
                ...state,
                loading: true
            }
        case usersListConstants.GETALL_SUCCESS_userslist:
            return {
                ...state,
                loading: false,
                userData: action.data,

            }
        case usersListConstants.GETALL_FAILURE_userslist:
            return {
                ...state,
                loading: false
            }
            
        default:
            return state
    }
}