import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { agentqueueActions, ticketCategoryActions, userqueueActions, userslistActions } from '..'
import LiveChatQueueComponent from '../components/index'

const mapStateToProps = state => {
	const { identity, alert, globalSetting, queue, agentqueue, userqueue, ticketcategory, userslist, tenantConfig, workspace, tenant } = state
	const { profile } = identity
	return {
		identity,
		tenant,
		profile,
		alert,
		globalSetting,
		queue,
		agentqueue,
		userqueue,
		ticketcategory,
		userslist,
		workspace,
		tenantConfig,
	}
}

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			...agentqueueActions,
			...userqueueActions,
			...ticketCategoryActions,
			...userslistActions,
		},
		dispatch
	),
})

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatQueueComponent)
