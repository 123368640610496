import expr from 'expression-eval'
import React, { Component } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/lib/Creatable'
import { Button, FormGroup, FormText, Input, Modal, ModalBody, Table } from 'reactstrap'
export default class ManageQueueComponent extends Component {
	constructor(props) {
		super(props)
		this.toggleModalAdd = this.toggleModalAdd.bind(this)
		this.toggleModalAssignQueue = this.toggleModalAssignQueue.bind(this)
		this.handleAssignmentChange = this.handleAssignmentChange.bind(this)
		this.confirmDelete = this.confirmDelete.bind(this)
		this.toggleModalEdit = this.toggleModalEdit.bind(this)
		this.closeModalEdit = this.closeModalEdit.bind(this)
		this.toggleAlertModal = this.toggleAlertModal.bind(this)
		this.state = {
			errorMsg: false,
			modalEdit: false,
			editData: { queueName: null, ticketCategory: null, ticketSubCategory: null, assignmentGroup: null, defaultQueueName: null },
			editId: '',
			deleteModal: false,
			deletedId: '',
			addData: {},
			modalAdd: false,
			assignQueueModal: false,
			ticketing: false,
			assignmentGroups: [{ value: 'temp', label: 'temp 1' }],
			ticketCategory: [],
			ticketSubCategory: [],
			defaultQueueArr: [],
			uniqueQueueArray: [],
			uniqueAsignmentGroupArr: [],
			uniqueTicketCatArr: [],
			uniqueTicketSubCatArr: [],
			uniqueDefaultQueueArray: [],
			UpdateQueueInfo: { queueName: null, queueLabel: null, updatedBy: null },
			alertModal: false,
			wsInit: false,
		}
	}

	componentWillMount() {
		let { identity, globalSetting } = this.props
		let { profile } = identity
		this.props.getAgentQueueData(["$filter=status ne 'Deleted'", '$orderby=queueLabel asc'])
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && globalSetting.loading == false && tickets && tickets.status == 1) {
			this.props.getAllTicketCategory(["$filter=status ne 'Deleted'"])
		}
	}

	componentDidUpdate(prevProps) {
		let {
			identity: { profile },
			userRoles,
			tenantConfig,
			workspace,
		} = this.props
		let { wsInit } = this.state

		if (tenantConfig && !tenantConfig.loading && tenantConfig.data && workspace && !workspace.loading && !wsInit) {
			console.log('prevProps::: ', prevProps)
			console.log('Updated Props:: ', this.props)

			//   if(workspace.selectedWs != null && prevProps.workspace.selectedWs !=  workspace.selectedWs) {
			this.props.getAgentQueueData(["$filter=status ne 'Deleted'", '$orderby=queueLabel asc'])
			this.setState({ wsInit: true })
			//   }
		}
	}

	toggleAlertModal() {
		this.setState({
			alertModal: !this.state.alertModal,
			informationAlert: '',
		})
	}

	toggleModalAdd(event) {
		this.setState({
			modalAdd: !this.state.modalAdd,
			errorMsg: false,
		})
	}

	toggleModalAssignQueue(event) {
		let uniqueQueueArray = [],
			uniqueAsignmentGroupArr = []
		const { agentqueue, globalSetting, ticketcategory } = this.props

		if (agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0 && !this.state.assignQueueModal) {
			agentqueue.queueData.map(function (d, i) {
				if (uniqueQueueArray.length > 0) {
					if (!uniqueQueueArray.find(o => o.value == d.queueName)) {
						uniqueQueueArray.push({ value: d.queueName, label: d.queueLabel })
					}
				} else {
					uniqueQueueArray.push({ value: d.queueName, label: d.queueLabel })
				}
			})
			// console.log("++++++++++++++++++++++++++uniqueQueueArray+++++++++++++++++++++++++++++++++++", uniqueQueueArray)
		}
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && tickets && tickets.status == 1) {
			if (ticketcategory && ticketcategory.catData && ticketcategory.catData.length > 0) {
				ticketcategory.catData.map(function (d, i) {
					if (d.level == 1) {
						uniqueAsignmentGroupArr.push({ value: d.name, label: d.label })
					}
				})
			}
		} else {
			if (agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0 && !this.state.assignQueueModal) {
				agentqueue.queueData.map(function (d, i) {
					if (uniqueAsignmentGroupArr.length > 0) {
						if (!uniqueAsignmentGroupArr.find(o => o.value == d.assignmentGroup)) {
							uniqueAsignmentGroupArr.push({ value: d.assignmentGroup, label: d.assignmentGroup })
						}
					} else {
						uniqueAsignmentGroupArr.push({ value: d.assignmentGroup, label: d.assignmentGroup })
					}
				})
				// console.log("+++++++++++++++++++++++++++uniqueAsignmentGroupArr++++++++++++++++++++++++++++++++++", uniqueAsignmentGroupArr)
			}
		}

		this.setState({
			assignQueueModal: !this.state.assignQueueModal,
			uniqueQueueArray,
			uniqueAsignmentGroupArr,
			uniqueDefaultQueueArray: [],
			editData: { queueName: null, ticketCategory: null, ticketSubCategory: null, assignmentGroup: null, defaultQueueName: null },
		})
	}

	_setUniqueTicketCategory(assignmentGroup) {
		const { agentqueue, ticketcategory, globalSetting } = this.props
		let uniqueTicketCatArr = [],
			{ editData } = this.state
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && tickets && tickets.status == 1) {
			if (ticketcategory && ticketcategory.catData && ticketcategory.catData.length > 0) {
				ticketcategory.catData.map(function (d, i) {
					if (d.level == 2 && d.parentName == editData.assignmentGroup) {
						uniqueTicketCatArr.push({ value: d.name, label: d.label })
					}
				})
			}
		} else {
			agentqueue.queueData.map(function (d, i) {
				if (uniqueTicketCatArr.length > 0) {
					if (uniqueTicketCatArr.find(o => o.value != d.ticketCategory && d.assignmentGroup == assignmentGroup)) {
						uniqueTicketCatArr.push({ value: d.ticketCategory, label: d.ticketCategory })
					}
				} else {
					uniqueTicketCatArr.push({ value: d.ticketCategory, label: d.ticketCategory })
				}
			})
		}

		// console.log("+++++++++++++++++++++++++++ticketCategoryArr++++++++++++++++++++++++++++++++++", uniqueTicketCatArr)
		return uniqueTicketCatArr
	}

	_setUniqueTicketSubCategory(ticketCategory) {
		const { agentqueue, ticketcategory, globalSetting } = this.props
		let uniqueTicketSubCatArr = [],
			{ editData } = this.state
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && tickets && tickets.status == 1) {
			if (ticketcategory && ticketcategory.catData && ticketcategory.catData.length > 0) {
				ticketcategory.catData.map(function (d, i) {
					if (d.level == 3 && d.parentName == editData.ticketCategory) {
						uniqueTicketSubCatArr.push({ value: d.name, label: d.label })
					}
				})
			}
		} else {
			agentqueue.queueData.map(function (d, i) {
				if (uniqueTicketSubCatArr.length > 0) {
					if (uniqueTicketSubCatArr.find(o => o.value != d.ticketSubCategory && d.ticketCategory == ticketCategory)) {
						uniqueTicketSubCatArr.push({ value: d.ticketSubCategory, label: d.ticketSubCategory })
					}
				} else {
					uniqueTicketSubCatArr.push({ value: d.ticketSubCategory, label: d.ticketSubCategory })
				}
			})
		}

		// console.log("+++++++++++++++++++++++++++uniqueTicketSubCatArr++++++++++++++++++++++++++++++++++", uniqueTicketSubCatArr)
		return uniqueTicketSubCatArr
	}

	_setUniqueDefaultQueue(excludeQueue) {
		const { uniqueQueueArray } = this.state
		let uniqueDefaultQueueArray = []
		uniqueQueueArray.map(function (d, i) {
			if (d.value !== excludeQueue) {
				uniqueDefaultQueueArray.push({ value: d.value, label: d.label })
			}
		})
		// console.log("+++++++++++++++++++++++++++uniqueDefaultQueueArray++++++++++++++++++++++++++++++++++", uniqueDefaultQueueArray)
		return uniqueDefaultQueueArray
	}

	_setUniqueQueArray(excludeQueue) {
		let uniqueQueueArray = [],
			uniqueDefaultQueueArray = [],
			uniqueAsignmentGroupArr = []
		const { agentqueue, globalSetting, ticketcategory } = this.props

		if (agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0) {
			agentqueue.queueData.map(function (d, i) {
				if (uniqueQueueArray.length > 0) {
					if (!uniqueQueueArray.find(o => o.value == d.queueName)) {
						uniqueQueueArray.push({ value: d.queueName, label: d.queueLabel })
					}
				} else {
					uniqueQueueArray.push({ value: d.queueName, label: d.queueLabel })
				}
			})
			// console.log("++++++++++++++++++++++++++uniqueQueueArray+++++++++++++++++++++++++++++++++++", uniqueQueueArray)
		}
		uniqueQueueArray.map(function (d, i) {
			if (d.value !== excludeQueue) {
				uniqueDefaultQueueArray.push({ value: d.value, label: d.label })
			}
		})
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && tickets && tickets.status == 1) {
			if (ticketcategory && ticketcategory.catData && ticketcategory.catData.length > 0) {
				ticketcategory.catData.map(function (d, i) {
					if (d.level == 1) {
						uniqueAsignmentGroupArr.push({ value: d.name, label: d.label })
					}
				})
			}
		} else {
			if (agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0 && !this.state.assignQueueModal) {
				agentqueue.queueData.map(function (d, i) {
					if (uniqueAsignmentGroupArr.length > 0) {
						if (!uniqueAsignmentGroupArr.find(o => o.value == d.assignmentGroup)) {
							uniqueAsignmentGroupArr.push({ value: d.assignmentGroup, label: d.assignmentGroup })
						}
					} else {
						uniqueAsignmentGroupArr.push({ value: d.assignmentGroup, label: d.assignmentGroup })
					}
				})
				// console.log("+++++++++++++++++++++++++++uniqueAsignmentGroupArr++++++++++++++++++++++++++++++++++", uniqueAsignmentGroupArr)
			}
		}
		this.setState({
			uniqueQueueArray,
			uniqueDefaultQueueArray,
			uniqueAsignmentGroupArr,
		})
	}

	toggleModalEdit(data) {
		let { editData } = this.state
		const { agentqueue } = this.props

		this._setUniqueQueArray(data.queueName)
		let UpdateQueueInfo = {
			queueName: data.queueName,
			queueLabel: data.queueLabel,
			assignmentGroup: data.assignmentGroup,
			defaultQueueName: data.defaultQueueName,
			ticketCategory: data.ticketCategory,
			ticketSubCategory: data.ticketSubCategory,
			conditionsName: data.conditionsName,
			priority: data.priority,
			ruleDescription: data.ruleDescription,
			conditions: data.conditions,
		}
		if (agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0 && agentqueue.queueData.find(element => element['queueName'] === data.defaultQueueName) !== undefined)
			this.setState({
				modalEdit: !this.state.modalEdit,
				UpdateQueueInfo,
				editData: UpdateQueueInfo,
				deletedId: data.id,
			})
		else {
			UpdateQueueInfo['defaultQueueName'] = null
			this.setState({
				modalEdit: !this.state.modalEdit,
				UpdateQueueInfo,
				editData: UpdateQueueInfo,
				deletedId: data.id,
			})
		}
	}

	closeModalEdit() {
		this.setState({
			modalEdit: !this.state.modalEdit,
			uniqueQueueArray: [],
			uniqueAsignmentGroupArr: [],
			uniqueDefaultQueueArray: [],
			editData: { queueName: null, ticketCategory: null, ticketSubCategory: null, assignmentGroup: null, defaultQueueName: null },
		})
	}

	toggleDeleteModal = data => {
		this.setState({
			deleteModal: !this.state.deleteModal,
			deletedId: data.id,
		})
	}

	handleChange(event) {
		let addData = this.state.addData
		addData[event.target.name] = event.target.value
		this.setState({ addData: addData })
	}

	handleUpdateInfoChange(event) {
		let { UpdateQueueInfo } = this.state
		UpdateQueueInfo['queueLabel'] = event.target.value
		this.setState({ UpdateQueueInfo })
	}

	handleEditChange(event) {
		let editData = this.state.editData
		editData[event.target.name] = event.target.value
		this.setState({ editData })
	}

	saveQueue(event) {
		event.preventDefault()
		let addData = this.state.addData
		let validate = this.validateData(addData)
		if (validate) {
			let { profile } = this.props
			addData.createdBy = profile.userId
			this.setState({
				modalAdd: !this.state.modalAdd,
			})
			this.props.addAgentQueue(addData)
		}
	}

	updateQueueLabel(event) {
		event.preventDefault()
		let { UpdateQueueInfo } = this.state
		let {
			profile: { tenantUid, userId },
		} = this.props
		let validate = this.validateData(UpdateQueueInfo)

		if (validate) {
			UpdateQueueInfo.updatedBy = userId
			// console.log("-=-=-=-=-=-=-=-=-:::", UpdateQueueInfo)
			this.props.updateInfoQueue(UpdateQueueInfo)
			this.setState({ modalEdit: !this.state.modalEdit })
		}
	}

	updateQueue(event) {
		event.preventDefault()
		if (this.state.deletedId != '') {
			let updateWithDelete = {
				id: this.state.deletedId,
				queueUpdate: true,
			}
			this.props.deleteQueue(JSON.stringify(updateWithDelete))
		}
		let editData = this.state.editData,
			{
				agentqueue: { queueData },
			} = this.props

		// validate edit data

		let { profile } = this.props
		editData.updatedBy = profile.userId
		queueData.forEach(elem => {
			if (editData.queueName == elem.queueName) {
				editData.queueLabel = elem.queueLabel
			}
		})

		editData.assignmentGroup = editData.assignmentGroup ? editData.assignmentGroup : '*'
		editData.ticketCategory = editData.ticketCategory ? editData.ticketCategory : '*'
		editData.ticketSubCategory = editData.ticketSubCategory ? editData.ticketSubCategory : '*'
		editData.priority = editData.priority != '' ? editData.priority : 0

		if (this.validateUpdateQueueData(editData)) {
			if (this.state.modalEdit) {
				this.setState({
					modalEdit: !this.state.modalEdit,
					deletedId: '',
				})
			} else
				this.setState({
					assignQueueModal: !this.state.assignQueueModal,
				})
			// console.log("_+_+_", editData)
			this.props.updateAgentQueue(editData, editData.queueName)
		}
	}

	validateUpdateQueueData(editData) {
		if (!editData || !editData.queueName) {
			this.setState({
				alertModal: !this.state.alertModal,
				informationAlert: 'Please select a queue name',
			})
			return false
		}
		if (editData && editData.conditions && editData.conditions.trim() != '') {
			try {
				const ast = expr.parse(editData.conditions.trim())
				return true
			} catch (err) {
				this.setState({
					alertModal: !this.state.alertModal,
					informationAlert: 'Condition should be in correct format or leave it blank',
				})
				return false
			}
		} else {
			return true
		}
	}

	validateData(input) {
		let {
			agentqueue: { queueData },
			t,
		} = this.props
		this.setState({ errorMsg: false })
		if (!input.queueLabel) {
			this.setState({ errorMsg: t('Please Enter Queue Label') })
			return false
		} else if (input.queueLabel.trim() == '') {
			this.setState({ errorMsg: t('Please Enter Queue Label') })
			return false
		} else if (queueData && queueData.length > 0 && queueData.find(o => o.queueLabel.trim().toLowerCase() == input.queueLabel.trim().toLowerCase())) {
			this.setState({ errorMsg: t('Queue with this name already exist') })
			return false
		} else {
			return true
		}
	}

	handleAssignmentChange(newValue, actionMeta) {
		// console.log("", actionMeta);
		// console.log("", newValue);
		let { editData, uniqueTicketCatArr, uniqueTicketSubCatArr, uniqueDefaultQueueArray } = this.state
		if (newValue && newValue.value && newValue.value.trim() != '' && newValue.value != null) {
			editData[actionMeta.name] = newValue.value
			if (actionMeta.name == 'assignmentGroup') {
				uniqueTicketCatArr = actionMeta.action == 'create-option' ? [] : this._setUniqueTicketCategory(newValue.value)
				editData.ticketCategory = null
				editData.ticketSubCategory = null
			}
			if (actionMeta.name == 'ticketCategory') {
				uniqueTicketSubCatArr = actionMeta.action == 'create-option' ? [] : this._setUniqueTicketSubCategory(newValue.value)
				editData.ticketSubCategory = null
			}
			if (actionMeta.name == 'queueName') {
				uniqueDefaultQueueArray = this._setUniqueDefaultQueue(newValue.value)
				editData.defaultQueueName = null
			}
		} else {
			editData[actionMeta.name] = null
		}

		this.setState({ editData, uniqueTicketCatArr, uniqueTicketSubCatArr, uniqueDefaultQueueArray })
	}
	handleAssignmentInputChange(inputValue, actionMeta) {
		// console.group('Input Changed handleAssignmentInputChange::::', actionMeta);
		// console.log('handleAssignmentInputChange::::::', inputValue);
		// console.log(`action: ${actionMeta.action}`);
		// console.groupEnd();
	}

	confirmDelete() {
		let { identity } = this.props
		let { profile } = identity
		this.setState({
			deleteModal: !this.state.deleteModal,
			loader: true,
		})
		this.props.deleteQueue(this.state.deletedId)
		this.setState({
			loader: false,
			deletedId: '',
		})
	}

	render() {
		const { alert, agentqueue, globalSetting, t } = this.props
		let errorMsg = this.state.errorMsg
		let tickets = globalSetting && ((globalSetting.data && globalSetting.data.tickets) || (globalSetting.productSetting && globalSetting.productSetting.tickets))

		if (globalSetting && globalSetting.loading == false && tickets && tickets.status == 1) {
			this.state.ticketing = true
		}

		return (
			<div className="col-sm-9 col-xl-10">
				<div className="gb-setting-bg">
					<div className="mf-common smallTalks Manage-Task">
						<h3>{t('Manage Agent Queue')}</h3>
						<div className="row">
							<div className="col-sm-12">
								<div className="show-section ag-chat clearfix">
									<div className="pull-left">
										<span className="add-ico" onClick={this.toggleModalAdd}>
											<i className="fa fa-plus-square-o" aria-hidden="true"></i>
										</span>
										<h4 className="ad-faq">{t('ADD QUEUE')}</h4>
									</div>

									{agentqueue && agentqueue.queueData && agentqueue.queueData.length > 0 && (
										<div className="pull-right">
											<span className="add-ico" onClick={this.toggleModalAssignQueue}>
												<i className="fa fa-plus-square-o" aria-hidden="true"></i>
											</span>
											<h4 className="ad-faq">{t('Assignment Rules')}</h4>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<Table className="table">
									<thead>
										<tr>
											<th>{t?t('Sr no'):"Sr. no."}</th>
											<th>{t('Queue Label')}</th>
											<th>{t('Assignment Group')}</th>
											<th>{t('Category')}</th>
											<th>{t('Sub-Category')}</th>
											<th>{t('Condition Name')}</th>
											<th>{t('Condition')}</th>
											<th>{t('Weight')}</th>
											<th>{t('Rule Description')}</th>
											<th>{t('Status')}</th>
											<th>{t('Action')}</th>
										</tr>
									</thead>
									<tbody>
										{agentqueue &&
											agentqueue.queueData &&
											agentqueue.queueData.pop &&
											agentqueue.queueData.map(
												(el, i) =>
													el &&
													el.status != 'Deleted' && (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{el.queueLabel}</td>
															<td>{el.assignmentGroup}</td>
															<td>{el.ticketCategory}</td>
															<td>{el.ticketSubCategory}</td>
															<td>{el.conditionsName}</td>
															<td>{el.conditions}</td>
															<td>{el.priority}</td>
															<td>{el.ruleDescription}</td>
															<td>{el.status}</td>
															<td>
																<span onClick={this.toggleModalEdit.bind(this, el)} className="al-icon-col">
																	<i className="fa fa-pencil fa-lg "></i>
																</span>{' '}
																|
																<span onClick={this.toggleDeleteModal.bind(this, el)} className="al-icon-col">
																	<i className="fa fa-trash fa-lg "></i>
																</span>
															</td>
														</tr>
													)
											)}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
				{/* Add Queue */}
				<Modal isOpen={this.state.modalAdd} toggle={this.toggleModalAdd} className={this.props.className || 'delete-card editor-frame add-faq white-text ag-modal-width'}>
					<ModalBody>
						{errorMsg && <div className="alert alert-danger">{t(errorMsg)}</div>}
						<h4 className="modal-min-heading">{t('Add Queue')}</h4>
						<form className="custom-from" onSubmit={this.saveQueue.bind(this)}>
							{/* <FormGroup >
                                <Input className="question" type="text" name="queueName" placeholder="Enter Queue Name" onChange={this.handleChange.bind(this)} />
                            </FormGroup> */}

							<FormGroup>
								<Input className="question" type="text" name="queueLabel" maxLength="100" placeholder={t('Enter Queue Label')} onChange={this.handleChange.bind(this)} />
							</FormGroup>

							<div className="tools">
								<Button color="secondary" onClick={this.toggleModalAdd}>
									<img src="assets/img/no.png" alt={t('Close')} />
									{t('Close')}
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" alt={t('Save')} />
									{t('Save')}
								</Button>{' '}
							</div>
						</form>
					</ModalBody>
				</Modal>

				{/* Assignment queue modal */}
				<Modal
					isOpen={this.state.assignQueueModal}
					toggle={this.toggleModalAssignQueue}
					className={this.props.className || 'delete-card editor-frame add-faq white-text ag-modal-width '}>
					<ModalBody>
						{errorMsg && <div className="alert alert-danger">{t(errorMsg)}</div>}
						<h4 className="modal-min-heading">{t('Assignment Rules')}</h4>
						<form className="custom-from" onSubmit={this.updateQueue.bind(this)}>
							{this.state.uniqueQueueArray && this.state.uniqueQueueArray.length > 0 && (
								<FormGroup>
									<Select onChange={this.handleAssignmentChange} options={this.state.uniqueQueueArray} name="queueName" placeholder={t('Select Queue')} />
								</FormGroup>
							)}

							{this.state.uniqueQueueArray && this.state.uniqueDefaultQueueArray.length > 0 && (
								<FormGroup>
									<Select onChange={this.handleAssignmentChange} options={this.state.uniqueDefaultQueueArray} name="defaultQueueName" placeholder={t('Select Default Queue')} />
								</FormGroup>
							)}

							{this.state.ticketing && (
								<div>
									<FormGroup>
										<Select
											onChange={this.handleAssignmentChange}
											options={this.state.uniqueAsignmentGroupArr}
											name="assignmentGroup"
											value={this.state.editData.assignmentGroup ? { value: this.state.editData.assignmentGroup, label: this.state.editData.assignmentGroup } : null}
											placeholder={t('Assignment Group')}
										/>
									</FormGroup>

									{this.state.editData.assignmentGroup && (
										<FormGroup>
											<Select
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketCatArr}
												name="ticketCategory"
												value={this.state.editData.ticketCategory ? { value: this.state.editData.ticketCategory, label: this.state.editData.ticketCategory } : null}
												placeholder={t('Category')}
											/>
										</FormGroup>
									)}

									{this.state.editData.ticketCategory && this.state.editData.assignmentGroup && (
										<FormGroup>
											<Select
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketSubCatArr}
												name="ticketSubCategory"
												value={this.state.editData.ticketSubCategory ? { value: this.state.editData.ticketSubCategory, label: this.state.editData.ticketSubCategory } : null}
												placeholder={t('Sub-Category')}
											/>
										</FormGroup>
									)}
								</div>
							)}

							{!this.state.ticketing && (
								<div>
									<FormGroup>
										<CreatableSelect
											isClearable
											onChange={this.handleAssignmentChange}
											options={this.state.uniqueAsignmentGroupArr}
											name="assignmentGroup"
											value={this.state.editData.assignmentGroup ? { value: this.state.editData.assignmentGroup, label: this.state.editData.assignmentGroup } : null}
											placeholder={t('Assignment Group')}
										/>
									</FormGroup>

									{this.state.editData.assignmentGroup && (
										<FormGroup>
											<CreatableSelect
												isClearable
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketCatArr}
												name="ticketCategory"
												value={this.state.editData.ticketCategory ? { value: this.state.editData.ticketCategory, label: this.state.editData.ticketCategory } : null}
												placeholder={t('Category')}
											/>
										</FormGroup>
									)}

									{this.state.editData.ticketCategory && this.state.editData.assignmentGroup && (
										<FormGroup>
											<CreatableSelect
												isClearable
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketSubCatArr}
												name="ticketSubCategory"
												value={this.state.editData.ticketSubCategory ? { value: this.state.editData.ticketSubCategory, label: this.state.editData.ticketSubCategory } : null}
												placeholder={t('Sub-Category')}
											/>
										</FormGroup>
									)}
								</div>
							)}

							<FormGroup>
								<Input name="priority" placeholder={t('Weight')} min={1} max={100} type="number" step="1" onChange={this.handleEditChange.bind(this)} />
							</FormGroup>

							<FormGroup>
								<Input name="conditionsName" placeholder={t('Condition Name')} type="text" onChange={this.handleEditChange.bind(this)} />
							</FormGroup>

							<FormGroup>
								<Input name="conditions" placeholder={t('Condition')} type="text" onChange={this.handleEditChange.bind(this)} />
								<FormText color="muted">{t('(ex userLocation == USA)')}</FormText>
							</FormGroup>

							<FormGroup>
								<Input name="ruleDescription" placeholder={t('Rule Description')} type="text" onChange={this.handleEditChange.bind(this)} />
							</FormGroup>

							<div className="tools">
								<Button color="secondary" onClick={this.toggleModalAssignQueue}>
									<img src="assets/img/no.png" alt={t('Close')} />
									{t('Close')}
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" alt={t('Save')} />
									{t('Save')}
								</Button>{' '}
							</div>
						</form>
					</ModalBody>
				</Modal>

				{/* Edit of assignment queue */}

				<Modal isOpen={this.state.modalEdit} toggle={this.toggleModalEdit} className={this.props.className || 'delete-card editor-frame add-faq ag-modal-width'}>
					<ModalBody>
						{errorMsg && <div className="alert alert-danger">{t(errorMsg)}</div>}
						<h4 className="modal-min-heading">{t('Edit Queue')}</h4>
						<form className="custom-from" onSubmit={this.updateQueue.bind(this)}>
							<FormGroup>
								<Input className="question" type="text" name="queueLabel" placeholder={t('Enter Queue Label')} value={this.state.UpdateQueueInfo.queueLabel} />
							</FormGroup>
							{this.state.uniqueDefaultQueueArray.length > 0 && (
								<FormGroup>
									<Select
										onChange={this.handleAssignmentChange}
										options={this.state.uniqueDefaultQueueArray}
										name="defaultQueueName"
										value={
											this.state.editData.defaultQueueName
												? {
														value: this.state.editData.defaultQueueName,
														label: this.state.uniqueDefaultQueueArray.find(element => element['value'] === this.state.editData.defaultQueueName)['label'],
												  }
												: null
										}
										placeholder={t('Select Default Queue')}
									/>
								</FormGroup>
							)}

							{this.state.ticketing && (
								<div>
									<FormGroup>
										<Select
											onChange={this.handleAssignmentChange}
											options={this.state.uniqueAsignmentGroupArr}
											name="assignmentGroup"
											value={this.state.editData.assignmentGroup ? { value: this.state.editData.assignmentGroup, label: this.state.editData.assignmentGroup } : null}
											placeholder={t('Assignment Group')}
										/>
									</FormGroup>

									{this.state.editData.assignmentGroup && (
										<FormGroup>
											<Select
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketCatArr}
												name="ticketCategory"
												value={this.state.editData.ticketCategory ? { value: this.state.editData.ticketCategory, label: this.state.editData.ticketCategory } : null}
												placeholder={t('Category')}
											/>
										</FormGroup>
									)}

									{this.state.editData.ticketCategory && this.state.editData.assignmentGroup && (
										<FormGroup>
											<Select
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketSubCatArr}
												name="ticketSubCategory"
												value={this.state.editData.ticketSubCategory ? { value: this.state.editData.ticketSubCategory, label: this.state.editData.ticketSubCategory } : null}
												placeholder={t('Sub-Category')}
											/>
										</FormGroup>
									)}
								</div>
							)}

							{!this.state.ticketing && (
								<div>
									<FormGroup>
										<CreatableSelect
											isClearable
											onChange={this.handleAssignmentChange}
											options={this.state.uniqueAsignmentGroupArr}
											name="assignmentGroup"
											value={this.state.editData.assignmentGroup ? { value: this.state.editData.assignmentGroup, label: this.state.editData.assignmentGroup } : null}
											placeholder={t('Assignment Group')}
										/>
									</FormGroup>

									{this.state.editData.assignmentGroup && (
										<FormGroup>
											<CreatableSelect
												isClearable
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketCatArr}
												name="ticketCategory"
												value={this.state.editData.ticketCategory ? { value: this.state.editData.ticketCategory, label: this.state.editData.ticketCategory } : null}
												placeholder={t('Category')}
											/>
										</FormGroup>
									)}

									{this.state.editData.ticketCategory && this.state.editData.assignmentGroup && (
										<FormGroup>
											<CreatableSelect
												isClearable
												onChange={this.handleAssignmentChange}
												options={this.state.uniqueTicketSubCatArr}
												name="ticketSubCategory"
												value={this.state.editData.ticketSubCategory ? { value: this.state.editData.ticketSubCategory, label: this.state.editData.ticketSubCategory } : null}
												placeholder={t('Sub-Category')}
											/>
										</FormGroup>
									)}
								</div>
							)}
							<FormGroup>
								<Input
									name="priority"
									placeholder={t('Weight')}
									min={1}
									max={100}
									type="number"
									step="1"
									onChange={this.handleEditChange.bind(this)}
									value={this.state.editData.priority > 0 ? this.state.editData.priority : ''}
								/>
							</FormGroup>

							<FormGroup>
								<Input name="conditionsName" placeholder={t('Condition Name')} type="text" onChange={this.handleEditChange.bind(this)} value={this.state.editData.conditionsName} />
							</FormGroup>

							<FormGroup>
								<Input name="conditions" placeholder={t('Condition')} type="text" onChange={this.handleEditChange.bind(this)} value={this.state.editData.conditions} />
								<FormText color="muted">{t('(ex userLocation == USA)')}</FormText>
							</FormGroup>

							<FormGroup>
								<Input
									name="ruleDescription"
									placeholder={t('Rule Description')}
									type="text"
									onChange={this.handleEditChange.bind(this)}
									value={this.state.editData.ruleDescription}
								/>
							</FormGroup>

							<div className="tools">
								<Button color="secondary" onClick={this.closeModalEdit}>
									<img src="assets/img/no.png" alt={t('Close')} />
									{t('Close')}
								</Button>
								<Button color="primary" type="submit">
									<img src="assets/img/yes.png" alt={t('Update')} />
									{t('Update')}
								</Button>{' '}
							</div>
						</form>
					</ModalBody>
				</Modal>

				{/* delete modal */}
				<Modal autoFocus={false} isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className={this.props.className || 'delete-card'}>
					<ModalBody>
						<p>{t('Are you sure you want to delete item?')}</p>
					</ModalBody>
					<div className="button-wrap">
						<Button color="primary" onClick={this.confirmDelete}>
							<img src="assets/img/yes.png" alt={t('Yes')} />
							{t('Yes')}
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleDeleteModal}>
							<img src="assets/img/no.png" alt={t('No')} />
							{t('No')}
						</Button>
					</div>
				</Modal>

				<Modal autoFocus={false} isOpen={this.state.alertModal} toggle={this.toggleAlertModal} className={this.props.className || 'delete-card'}>
					<ModalBody>
						<p>{t(this.state.informationAlert)}</p>
					</ModalBody>
					<div className="button-wrap">
						<Button color="primary" onClick={this.toggleAlertModal}>
							<img src="assets/img/yes.png" alt={t('Ok')} />
							{t('Ok')}
						</Button>{' '}
					</div>
				</Modal>
			</div>
		)
	}
}
