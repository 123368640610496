import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import ReactDOM from 'react-dom';
import "shim-selected-options";
import App from './App';
import { identityServices, Loader } from './app/common';
import { session } from './config';
import './polyfill';
// import {session} from '../../../config'
import PushNotification from './app/push-notification/pushNotification';
// import {register} from './service-worker'

ReactDOM.render(<Suspense fallback={<Loader/>}><App /></Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// register();

// When user tries to remove tab or close window:
window.addEventListener('beforeunload', (e)=>{
	// const isSubscribed = new PushNotification().checkPermission()
	// for(let i=0; i<10000; i++){
	// console.log('unloaded')
  // Only execute if i have set the permission to allow before:
    if(localStorage.getItem('myPushConfig')){
			let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			let requestOptions = {
				method: 'POST',
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
				url: `${apiUrl}/PushSubscription/unsubscribeUser`,
				data: {
					endPoint: `${JSON.parse(localStorage.myPushConfig).endpoint}`,
				}
			}
			identityServices.identityApi.request(requestOptions).then(response => {
				localStorage.removeItem('myPushConfig')
			}).catch(err=>{
				})
      return undefined  
    }
//  }
})