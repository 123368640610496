import { Button } from '@chakra-ui/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Pagination from 'react-js-pagination'
import { localTest } from '../../../config'
import MainLayout from '../../common/base/entity/components/grid/MainGrid'
import { ConfrimDialog, FullSearchComponent } from '../../common/base/entity/components/index'
import { EntityComponent } from '../../common/base/entity/components/ListSearchComponent'
import { Loader } from '../../common/index'

/**
 * extended class for expanding and collapsing the properties field on to form.
 */

class JobListsearch extends EntityComponent {
	constructor(props) {
		super(props)
		// this.state.limit = 2
	}

	/**
	 * one time checks on loading of component....
	 */
	componentWillMount() {
		let { profile, baseUrl, routeProps, meta } = this.props
		// let profile = identity ? identity.profile : null;

		let tenantId = profile && profile.tenantUid
		let data = this.checkData()
		let queryStr
		let select = null
		if (meta && meta.selectedFields) {
			select = meta.selectedFields
		} else {
			select = null
		}

		if (this.props.params && this.props.params.baseResource) {
			queryStr = { modelName: this.props.params.baseResource }
		}
		if (routeProps) {
			if (routeProps.filter) queryStr = Object.assign({}, queryStr || {}, routeProps.filter)
		}
		// @ts-ignore
		if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()

		if (profile) {
			if (profile.roles && profile.roles.includes('ASC Admin')) {
				this.props.getAuditConfig()
			}

			let createdBy = profile.preferred_username || profile.email
			const {
				meta: { screens },
			} = this.props
			let listScreen = screens.list
			if (listScreen && listScreen.hideList) return true

			if (listScreen && listScreen.filter) {
				listScreen.filter.push(createdBy)
			} else {
				listScreen = Object.assign({}, listScreen, { filter: { createdBy } })
			}
			//this.props.getAllCount({ tenantId: profile.tenantUid, filter: queryStr })
			this.props.load({
				tenantId: profile.tenantUid,
				skip: '0',
				top: this.state.limit,
				queryStr,
				baseUrl,
				filter: listScreen.filter || null,
				orderBy: listScreen.orderBy || null,
				select,
			})
		} else if (localTest && !data) {
			// if (profile && profile.roles && profile.roles.includes('ASC Admin'));
			// {
			//     this.props.getAuditConfig();
			// }
			const {
				meta: { screens },
			} = this.props
			const listScreen = screens.list
			if (listScreen && listScreen.hideList) return true
			this.props.load({ tenantId, skip: '0', top: this.state.limit, queryStr, baseUrl, select })
			//this.props.getAllCount({ tenantId: defaultTenantId, filter: queryStr })
		}
		this.setState({ tenantId })
	}

	/**
	 * Call the server to list records in table as per the specified search inputs
	 * @param {*} sortOrder
	 */
	handleSort(sortOrder) {
		let { profile } = this.props
		const { limit, filter } = this.state
		let meta = this.props.meta
		const {
			screens: { list },
			selectedFields,
		} = meta
		let createdBy = profile.preferred_username || profile.email
		let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
		this.setState({ orderBy: sortOrder })

		if ((sortOrder && sortOrder.pop && sortOrder.length > 0) || (this.props.routeProps && this.props.routeProps.filter)) {
			this.props.getAll({
				tenantId: tenantUid,
				filter: filter || (this.props.routeProps ? this.props.routeProps.filter || list.filter : null),
				orderBy: sortOrder,
				skip: '0',
				top: limit,
				select: selectedFields,
			})
		} else {
			let modifiedFilter = Object.assign({}, list.filter, { createdBy })
			this.props.getAll({
				tenantId: tenantUid,
				orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy,
				skip: '0',
				top: limit,
				select: selectedFields,
				filter: modifiedFilter,
			})
		}
		// if (profile) this.props.getAllCount({ tenantId: profile.tenantUid, filter: filter, orderBy: sortOrder })
	}

	/**
	 * Call the server to list records in table as per the specified search inputs
	 * @param {*} searchParams
	 */
	handleSearch(searchParams) {
		let { profile } = this.props
		const { limit, orderBy } = this.state
		// let keys = searchParams && Object.keys(searchParams)
		let meta = this.props.meta
		const {
			screens: { list },
			selectedFields,
		} = meta
		let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
		let createdBy = profile.preferred_username || profile.email
		this.setState({ filter: searchParams, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
		// this.setState({ loading: true })
		let filter = searchParams || {}
		searchParams &&
			!searchParams.pop &&
			Object.keys(searchParams).forEach(k => {
				if (meta.fields[k].type === 'text') filter[k] = `%${searchParams[k]}%`
				else filter[k] = searchParams[k]
			})

		if (searchParams || (this.props.routeProps && this.props.routeProps.filter)) {
			let modifiedFilter = [...filter]
			let modifiedFilterList = Object.assign({}, list.filter, { createdBy })
			modifiedFilter.push({ cond: '', label: 'Created By', name: 'createdBy', operator: 'eq', value: createdBy })
			this.props.getAll({
				tenantId: tenantUid,
				filter: modifiedFilter || modifiedFilterList,
				orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
				skip: '0',
				top: limit,
				select: selectedFields,
			})
		} else {
			let modifiedFilter = Object.assign({}, list.filter, { createdBy })
			this.props.getAll({
				tenantId: tenantUid,
				orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
				skip: '0',
				top: limit,
				filter: modifiedFilter,
				select: selectedFields,
			})
		}
		//if (profile) this.props.getAllCount({ tenantId: profile.tenantUid, filter: filter, orderBy: this.state.orderBy })
	}
	handlePageChange(pageNumber) {
		const { limit, filter, tenantId, orderBy } = this.state
		let {
			profile,
			routeProps,
			meta: {
				screens: { list },
				selectedFields,
			},
		} = this.props
		let newSkip = limit * (pageNumber - 1)
		let createdBy = profile.preferred_username || profile.email
		this.setState({ activePage: pageNumber })
		let queryStr = {}
		let modifiedFilter = {}
		if (routeProps) {
			if (routeProps.filter) queryStr = Object.assign({}, queryStr, routeProps.filter)
		}
		let currFilter
		if (filter && Object.keys(filter).length > 0) currFilter = Object.assign({}, filter, queryStr)
		else if (filter && Array.isArray(filter)) currFilter = filter
		else currFilter = queryStr

		if (Object.entries(currFilter).length === 0) {
			modifiedFilter = Object.assign({}, list.filter, { createdBy })
		} else {
			currFilter = Object.assign({}, currFilter, { createdBy })
		}

		this.props.getAll({ skip: newSkip, top: limit, filter: Object.entries(currFilter).length === 0 ? modifiedFilter : currFilter, tenantId, orderBy, select: selectedFields })
	}

	refreshList() {
		let { profile, t } = this.props
		const { limit, orderBy } = this.state
		this.setState({ loading: true })
		// let keys = searchParams && Object.keys(searchParams)
		let meta = this.props.meta
		let {
			screens: { list },
			selectedFields,
		} = meta
		let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
		let createdBy = profile.preferred_username || profile.email
		let searchParams = JSON.parse(localStorage.getItem('filter'))
		if(searchParams){
			this.setState({ filter: searchParams, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
			let filter = searchParams || {}
			searchParams &&
				!searchParams.pop &&
				Object.keys(searchParams).forEach(k => {
					if (meta.fields[k].type === 'text') filter[k] = `%${searchParams[k]}%`
					else filter[k] = searchParams[k]
				})
	
			if (searchParams || (this.props.routeProps && this.props.routeProps.filter)) {
				let modifiedFilter = [...filter]
				let modifiedFilterList = Object.assign({}, list.filter, { createdBy })
				modifiedFilter.push({ cond: '', label: 'Created By', name: 'createdBy', operator: 'eq', value: createdBy })
				this.props.getAll({
					tenantId: tenantUid,
					filter: modifiedFilter || modifiedFilterList,
					orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
					skip: '0',
					top: limit,
					select: selectedFields,
					t,
				})
			}
		} else {
			let filterModified = Object.assign({}, list.filter, { createdBy })
			this.props.getAll({
				tenantId: tenantUid,
				orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
				skip: '0',
				top: limit,
				filter: filterModified,
				select: selectedFields,
				t,
			})
		}
		//if (profile) this.props.getAllCount({ tenantId: profile.tenantUid, filter: filter, orderBy: this.state.orderBy })
	}

	render() {
		const { modelData, isDelayed } = this.state
		const sideBarMinimized = this.props.meta && this.props.meta.sideBarMinimized
		if (sideBarMinimized) document.body.classList.remove('sidebar-lg-show')
		else document.body.classList.add('sidebar-lg-show')
		const { alert } = this.props
		let entityList

		// const { entityList } = this.state;

		entityList = this.props.entityList
		const { meta, t } = this.props
		// const {  entity:{itemData }, name } = this.props;
		// const meta = this.props.entity? this.props.entity.meta : undefined
		let itemData = entityList && entityList.itemData
		const loading = entityList && entityList.loading
		const filteredData = this.state.filteredData || (this.props.entityList && this.props.entityList.filteredData)
		const searchText = this.state.searchText || (this.props.entityList && this.props.entityList.searchText)
		const searchParams = this.props.entityList && this.props.entityList.searchParams

		if (filteredData) itemData = filteredData
		const { entity } = this.props
		let auditConfig = entity && entity.auditConfig
		let enableAuditLogAction = auditConfig ? true : false
		let createAction
		// let viewAction
		// let editAction
		// let deleteAction;
		let auditLogAction
		// let importAction
		let redirectAction
		if (enableAuditLogAction) {
			auditLogAction = {
				label: 'View Audit Log',
				name: 'auditLog',
				type: 'button',
				action: 'getAuditLog',
			}
		}
		if (meta && meta.actions) {
			createAction = meta.actions.create
			// viewAction = meta.actions.View
			// editAction = meta.actions.Update
			// deleteAction = meta.actions.Delete
			// importAction = meta.actions.import
			redirectAction = meta.actions.redirect
		}
		// const { globalSetting: { globalSettingData } } = this.props;
		const listScreen = meta.screens && meta.screens.list
		let itemsByCols
		if (listScreen && listScreen.renderItems) {
			itemsByCols = {}
			listScreen.cols &&
				listScreen.renderItems.forEach(i => {
					if (i.col) {
						itemsByCols[i.col] = itemsByCols[i.col] || []
						itemsByCols[i.col].push(i)
					} else {
						itemsByCols.unassigned = itemsByCols.unassigned || []
						itemsByCols.unassigned.push(i)
					}
				})

			// @ts-ignore
			itemsByCols.unassigned = itemsByCols.unassigned || (listScreen && !listScreen.cols && listScreen.renderItems)

			// @ts-ignore
			if (listScreen.cols && itemsByCols.unassigned) return <div>{`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `}</div>
		}

		return (
			<div>
				{loading && <Loader />}

				<div className="common-heading row">
					{alert.message && <div className={`alert ${alert.type}`}>{t ? t(alert.message) : alert.message}</div>}
					<div className="col-sm-6">
						<div className="heading">
							<h5>{meta ? (t ? t(meta.title) : meta.title) : t ? t('Meta missing') : 'Meta missing'}</h5>
						</div>
					</div>
				</div>

				<div className="main-section">
					<div className="main-inner">
						<div className="row table-title">
							<div className="col-sm-8">
								<h5>{listScreen && listScreen.title && t(listScreen.title || 'Listing')}</h5>
							</div>
							<div className="col-sm-4">
								<div className="search-section">
									{meta && meta.screens && meta.screens.search && (
										<FullSearchComponent
											meta={this.props.meta}
											handleSearch={this.handleSearch.bind(this)}
											handleTextSearch={this.handleTextSearch.bind(this)}
											searchText={searchText}
											searchForm={searchParams}
											handleAction={this.handleAction.bind(this)}
											filters={this.props.routeProps && this.props.routeProps.filter}
										/>
									)}
								</div>
							</div>
							<div className="col-sm-4">
								{!loading && itemData && (
									<Button colorScheme="blue" onClick={this.refreshList.bind(this)}>
										{t('Check Status')}
									</Button>
								)}
							</div>
						</div>
						<div className="row">
							{createAction && (
								<div className="col-sm-6">
									<div className="show-section">
										<h4 className="add">
											<span className="add-ico" onClick={this.addItem.bind(this)}>
												<i className="fa fa-plus-square-o" aria-hidden="true"></i> {t ? t(createAction.label) : createAction.label}
											</span>
										</h4>
									</div>
								</div>
							)}
							{redirectAction && (
								<div className="col-sm-6">
									<div className="show-section">
										<h4 className="add">
											{' '}
											<span className="add-ico" onClick={this.redirectItem.bind(this, redirectAction.redirectPath)}>
												<img src={`assets/img/add-q.png`} alt="Add" /> {t ? t(redirectAction.label) : redirectAction.label}
											</span>
										</h4>
									</div>
								</div>
							)}
						</div>
						{itemsByCols && listScreen && (
							<div className={`row ${listScreen.classes}`}>
								{listScreen.cols &&
									Array.isArray(listScreen.cols) &&
									listScreen.cols.map((c, i) => {
										return (
											<div className="col-sm-{c}" key={i}>
												{itemsByCols[i + 1] &&
													itemsByCols[i + 1].map(item => {
														return this.renderItem(item)
													})}
											</div>
										)
									})}
								{itemsByCols &&
									// @ts-ignore
									itemsByCols.unassigned &&
									// @ts-ignore
									itemsByCols.unassigned.map(item => {
										return this.renderItem(item)
									})}
							</div>
						)}
						<div className="row">
							<div className="col-sm-12">
								{meta.screens && meta.screens.list && !meta.screens.list.hideList && meta.screens.list.items && (
									<div>
										<MainLayout
											data={itemData}
											columns={meta.screens.list.items}
											fields={meta.fields}
											meta={meta}
											totalCount={this.state.totalCount || this.props.entityList.count || (this.props.entity && this.props.entity.count)}
											itemClick={this.itemClick.bind(this)}
											importData={this.importData.bind(this)}
											viewAuditLog={this.viewAuditLog.bind(this)}
											handleSearch={this.handleSearch.bind(this)}
											preFilters={this.props.routeProps && this.props.routeProps.filter}
											handleSort={this.handleSort.bind(this)}
											handleListAction={this.handleListAction.bind(this)}
											handleExportData={this.handleExportData.bind(this)}
											auditLogAction={auditLogAction}
											{...this.props}
										/>
										{meta.screens &&
											meta.screens.list &&
											(!meta.screens.list.layoutType ||
												!meta.screens.list.layoutType.excluded ||
												(!meta.screens.list.layoutType.excluded.all && meta.screens.list.layoutType.excluded.paging)) && (
												<div className="row">
													<div className="col-sm-12">
														<div className="pagination-control pull-right clearfix">
															{itemData && !loading && itemData.length > 0 && (
																<Pagination
																	activePage={this.state.activePage}
																	totalItemsCount={
																		this.state.totalCount || this.props.entityList.count || (this.props.entity && this.props.entity.count && this.props.entity.count)
																	}
																	onChange={this.handlePageChange.bind(this)}
																	itemsCountPerPage={this.state.limit}
																/>
															)}
														</div>
													</div>
												</div>
											)}
									</div>
								)}
							</div>
						</div>
					</div>
					{modelData && (
						<ConfrimDialog
							openModal={modelData.openModal}
							message={modelData.confirmActionMessage}
							actionMeta={modelData.actionMeta}
							actionObj={modelData.actionObj}
							actionPayload={modelData.actionPayload}
							entity={this.state.entity}
							handleStateReset={this.handleStateReset.bind(this)}
							handleAction={this.handleAction.bind(this)}
							handleListAction={this.handleListAction.bind(this)}></ConfrimDialog>
					)}
				</div>
			</div>
		)
	}
}
export default withTranslation()(JobListsearch)
