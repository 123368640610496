import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { Component, useEffect, useState } from 'react'
import * as Datetime from 'react-datetime'
import { Button, FormGroup, Label, Modal, ModalBody, Table } from 'reactstrap'
import { session } from '../../../config'
import { identityServices } from '../../common'
import '../style.css'
import { FilterButton, FilterSelect } from './static'
class FilterReview extends Component {
	constructor(props) {
		super(props)
		let startDate = moment().startOf('month')
		let endDate = new Date()
		this.state = {
			queues: null,
			agents: null,
			selected: [],
			errors: null,
			report: {},
			selectAll: false,
			selectFrom: false,
			selectTo: false,
			filters: {
				startDate,
				endDate,
			},
			reportsArr: [
				{
					label: 'Chats Report',
					action: this.getReport.bind(this),
					name: 'chat_Report_Daily',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'date', value: '' },
					filterBy: props.t('Overall Chat Report'),
					rollUp: [
						{
							name: 'filterByDate',
							config: {
								type: 'btn',
								list: [
									{ label: props.t('Daily'), name: 'day', value: 'Daily' },
									{ label: props.t('Weekly'), name: 'week', value: 'Weekly' },
								],
							},
							component: FilterButton,
							col: '',
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: moment().startOf('year').format('YYYY-MMM-DD'),
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'chat_Report_Daily'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'chat_Report_Daily'),
					},
				},
				{
					label: 'Queue Report',
					action: this.getReport.bind(this),
					name: 'queue_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'queueName', name: 'queue', value: '' },
					filterBy: [
						{
							name: 'queue',
							label: props.t('Select Queue'),
							col: 'col-md-10',
							component: FilterSelect,
							type: 'select',
							options: [],
							optionConfig: { label: props.t('queueLabel'), value: 'queueName' },
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: moment().startOf('year').format('YYYY-MMM-DD'),
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'queue_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'queue_Report'),
					},
				},
				{
					label: 'Agent Report',
					action: this.getReport.bind(this),
					name: 'agent_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'activeAgentId', name: 'agent', value: '' },
					filterBy: [
						{
							name: 'agent',
							label: props.t('Select Agent'),
							col: 'col-md-10',
							component: FilterSelect,
							type: 'select',
							options: [],
							optionConfig: { label: props.t('label'), value: 'value' },
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: moment().startOf('year').format('YYYY-MMM-DD'),
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'agent_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'agent_Report'),
					},
				},
				{
					label: 'Agent Unavailability Report',
					action: this.getReport.bind(this),
					name: 'agent_Unavailability_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'activeAgentId', name: 'agent', value: '' },
					filterBy: [
						{
							name: 'agent',
							label: props.t('Select Agent'),
							col: 'col-md-10',
							component: FilterSelect,
							type: 'select',
							options: [],
							optionConfig: { label: props.t('label'), value: 'value' },
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: moment().startOf('year').format('YYYY-MMM-DD'),
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'agent_Unavailability_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'agent_Unavailability_Report'),
					},
				},
				{
					label: 'QueueWise Report',
					action: this.getReport.bind(this),
					name: 'queue_Wise_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'queueName', name: 'queue', value: '' },
					filterBy: [
						{
							name: 'queue',
							label: props.t('Select Queue'),
							col: 'col-md-10',
							component: FilterSelect,
							type: 'select',
							options: [],
							optionConfig: { label: 'queueLabel', value: 'queueName' },
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: startDate,
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'queue_Wise_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'queue_Wise_Report'),
					},
				},
				{
					label: 'AgentWise Report',
					action: this.getReport.bind(this),
					name: 'agent_Wise_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filter: { type: 'activeAgentId', name: 'agent', value: '' },
					filterBy: [
						{
							name: 'agent',
							label: props.t('Select Agent'),
							col: 'col-md-10',
							component: FilterSelect,
							type: 'select',
							options: [],
							optionConfig: { label: props.t('label'), value: 'value' },
							onChange: this.handleFilterChange.bind(this),
						},
					],
					From: {
						value: startDate,
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'agent_Wise_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'agent_Wise_Report'),
					},
				},
				{
					label: 'SLA Report',
					action: this.getReport.bind(this),
					name: 'sla_Report',
					startDate,
					endDate,
					validate: ['stardDate', 'endDate'],
					filterBy: props.t('Overall SLA'),
					From: {
						value: startDate,
						open: false,
						onClick: this.toggleCalArr.bind(this, 'From', 'sla_Report'),
					},
					To: {
						value: '',
						open: false,
						onClick: this.toggleCalArr.bind(this, 'To', 'sla_Report'),
					},
				},
			],
		}
		this.tenantId = this.props.profile && this.props.profile.tenantUid
	}
	resetField() {}
	componentWillReceiveProps(props) {
		const { loading, itemData } = props && props.reports
		this.setState({ loading: loading, reportData: itemData })
	}
	downloadAll() {
		let m = this.getReports()
		let selected = this.state.selected
		m.map(el => {
			if (selected.includes(el.name)) el.action('downloadAll', 'all')
			return null
		})
	}
	getReport(downloadType, reportName) {
		let data = this.getDateRange(downloadType, reportName)
		let filter
		// @ts-ignore
		if (data && data.filter && data.filter.type && data.filter.value) {
			// @ts-ignore
			filter = { [data.filter.type]: data.filter.value }
		} else {
			reportName = `${reportName}_All`
		}
		let fileName = `${reportName}_${moment(data.start).format('MMM_DD-YYYY')}_to_${moment(data.end).format('MMM_DD-YYYY')}`
		let username = this.props.profile && this.props.profile.preferred_username ? this.props.profile.preferred_username : null

		let payload = {
			reportName: data?.subName || reportName,
			startDate: data.start,
			endDate: data.end,
			userName: username,
			filter,
			workingHour: 8,
			fileName,
			download: true,
		}
		this.props.getStdReport(this.props.profile && this.props.profile.tenantUid, payload)
	}
	// getTraineeProgramReport(downloadType, reportName) {
	//     let date = this.getDateRange(downloadType, 'traineeProgramStatus')
	//     let username = this.props.profile && this.props.profile.preferred_username ? this.props.profile.preferred_username : null
	//     this.props.getStdReport(this.props.profile && this.props.profile.tenantUid, 'traineeProgramStatus', date.start, date.end, username)
	// }
	// getTraineeMLReport(downloadType, reportName) {
	//     let date = this.getDateRange(downloadType, 'traineeStdMLStatus')
	//     let username = this.props.profile && this.props.profile.preferred_username ? this.props.profile.preferred_username : null
	//     this.props.getStdReport(this.props.profile && this.props.profile.tenantUid, 'traineeStdMLStatus', date.start, date.end,username)
	// }

	getDateRange(downloadType, reportName) {
		let start,
			end,
			filter,
			subName,
			{ reportsArr } = this.state
		if (downloadType == 'downloadIndividual') {
			reportsArr.forEach(elem => {
				if (elem.name == reportName) {
					start = elem.startDate
					end = elem.endDate
					filter = elem.filter
					subName = elem.subName || null
				}
			})
		} else {
			start = this.state.filters.startDate
			end = this.state.filters.endDate
		}
		return {
			start,
			end,
			filter,
			subName,
		}
	}
	isAllSelected() {
		let report = this.getReports().map(el => el.name)
		delete report[0]
		let { selected } = this.state
		let isAllSelected = true

		report.map(el => {
			if (!selected.includes(el)) {
				isAllSelected = false
			}
			return null
		})

		return isAllSelected
	}

	isSelected(name) {
		if (name == 'All Reports') {
			return this.isAllSelected()
		}

		let { selected } = this.state
		return selected.includes(name)
	}
	handleSelect(event) {
		let name = event.target.value
		let selected = this.state.selected

		if (!selected.includes(name)) {
			selected.push(name)
		} else {
			selected = selected.filter(el => el != name)
		}

		this.setState({
			selected,
		})
	}
	getReports() {
		let { reportsArr } = this.state
		return reportsArr
	}
	dateTimeChange(type, event) {
		let filters = this.state.filters
		event = event.format('YYYY-MM-DD')
		let selectFrom, selectTo
		if (type == 'From') {
			selectFrom = false
			selectTo = true
			filters.startDate = event
		} else {
			selectFrom = false
			selectTo = false
			filters.endDate = event
		}
		this.setState({
			selectFrom,
			selectTo,
			filters,
		})
	}
	dateTimeChangeArrFrom(type, reportName, event) {
		let { reportsArr } = this.state

		event = event.format('YYYY-MM-DD')
		if (type == 'From') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.startDate = event
					elem.From.open = false
					elem.endDate = ''
					elem.To.open = true
				}
				return null
			})
		}

		this.setState({
			reportsArr,
			errors: false,
		})
	}

	dateTimeChangeArrTo(type, reportName, event) {
		let { reportsArr } = this.state

		event = event.format('YYYY-MM-DD')
		if (type == 'To') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.endDate = event
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		}

		this.setState({
			reportsArr,
			errors: false,
		})
	}
	toggleCal(type) {
		if (type == 'from') {
			this.setState({
				selectFrom: !this.state.selectFrom,
				selectTo: false,
			})
		} else {
			this.setState({
				selectFrom: false,
				selectTo: !this.state.selectTo,
			})
		}
	}
	toggleCalArr(type, reportName) {
		let { reportsArr } = this.state,
			reportFind
		if (type == 'From') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.From.open = !elem.From.open
					elem.To.open = false
				} else {
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		} else {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.From.open = false
					elem.To.open = !elem.To.open
				} else {
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		}

		this.setState({
			reportsArr,
		})
	}

	downloadIndividual(report) {
		let download = true
		//validate[] filter:{name:'queue'},
		let error = []
		let { errors } = this.state
		let { t } = this.props
		report.validate &&
			Array.isArray(report.validate) &&
			report.validate.map((item, idx) => {
				if (!report[item] && report[item] == '') {
					error.push({ [item]: `${t('Please Select')} ${item}` })
					download = false
				}

				if (report.filter && report.filter.name === item && report.filter.value === '') {
					error.push({ [item]: `${t('Please Select')} ${item}` })
					download = false
				}
				return null
			})
		if (download) {
			report.action('downloadIndividual', report.name)
			this.resetField() //report)
			this.setState({
				errors: [],
			})
		} else {
			this.setState({
				errors: {
					[report.name]: error,
				},
			})
		}
	}
	handleSelectAll() {
		let report = this.getReports().map(el => el.name)
		if (this.state.selected.length < report.length) {
			this.setState({
				selected: report,
			})
		} else {
			this.setState({
				selected: [],
			})
		}
	}

	configApi(config) {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${config.url}`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(response)
			}
			return response.data
		})
	}

	async componentWillMount() {
		let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		let queueConfig = { url: `${apiUrl}/queue_livechat?$filter=status ne 'Deleted'&$orderby=queueLabel asc` }
		let agentConfig = { url: `${apiUrl}/appusers` }
		let queues = await this.configApi(queueConfig)
		let userslist = await this.configApi(agentConfig)
		let agents = []
		if (userslist && userslist.length > 0) {
			userslist.map((elem, i) => {
				if (elem.roles && elem.roles) {
					if (elem.roles.indexOf('chat_agent') != -1 || elem.roles.indexOf('Chat Agent') != -1) {
						agents.push({ label: elem.username, value: elem.userId })
					}
				}
				return null
			})
		}

		if (agents) this.setState({ agents })
		if (queues) this.setState({ queues })
	}

	handleFilterChange(type, value, reportName) {
		let { reportsArr } = this.state
		if (type == 'filterByDate') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					if (value) elem.subName = `chatReport${value}All`
					elem.filter = { type: 'rollUp', value, name: elem.filter.name || '' }
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		}
		if (type === 'agent' || type === 'agentWiseReport') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.filter = { type: 'activeAgentId', value, name: elem.filter.name || '' }
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		}
		if (type == 'queue' || type === 'queueWiseReport') {
			reportsArr.map((elem, i) => {
				if (elem.name == reportName) {
					elem.filter = { type: 'queueName', value, name: elem.filter.name || '' }
					elem.From.open = false
					elem.To.open = false
				}
				return null
			})
		}

		this.setState({
			reportsArr,
		})
	}

	renderErrorMsg(field, errors) {
		let msg = errors && Array.isArray(errors) && errors.find(x => x[field])
		if (msg) {
			return <span style={{ color: 'red', fontSize: '11px' }}>{msg[field]}</span>
		}
	}
	validReportDate = (type, reportName, current) => {
		let diff = 31
		let today = moment(),
			{ reportsArr } = this.state,
			startDate
		let currentYear = moment().diff(moment().startOf('year'), 'day') > 60 ? moment().startOf('year') : moment().subtract(60, 'day').startOf('month')
		let stDate
		reportsArr.forEach(elem => {
			if (elem.name == reportName) {
				startDate = elem.startDate
				stDate = elem.startDate
			}
		})
		let createdAt = moment(currentYear).subtract(1, 'day')
		if (type == 'From') return current.isBefore(today)
		if (type == 'To')
			return (
				current.isAfter(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')) &&
				current.isBefore(moment(stDate).add(diff, 'day')) &&
				current.isBefore(today)
			)

		return current.isAfter(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')) && current.isBefore(today)
	}
	render() {
		const tbody = this.getReports()
		const { errors, reportData, loading } = this.state
		let date = {
			From: {
				value: this.state.filters.startDate,
				open: this.state.selectFrom,
				onClick: this.toggleCal.bind(this, 'from'),
			},
			To: {
				value: this.state.filters.endDate,
				open: this.state.selectTo,
				onClick: this.toggleCal.bind(this, 'to'),
			},
		}
		let valid = (type, current) => {
			let today = moment()
			let createdAt = moment(this.state.filters.startDate).subtract(1, 'day')
			createdAt = moment(createdAt).subtract(1, 'day')
			if (type == 'From') return current.isBefore(today) && current.isAfter(createdAt)

			return current.isAfter(moment(this.state.filters.startDate).subtract(1, 'days').format('YYYY-MM-DD')) && current.isBefore(today)
		}

		// let validReportDate = (type, reportName, current) => {
		//     let today = moment(), { reportsArr } = this.state, startDate
		//     let currentYear = moment().diff(moment().startOf('year'), 'days') > 60 ? moment().startOf('year') : moment().subtract(60, 'days').startOf('month')
		//     reportsArr.forEach(elem => {
		//         if (elem.name == reportName) {
		//             startDate = elem.startDate
		//         }
		//     })

		//     let createdAt = moment(currentYear).subtract(1, "day")

		//     if (type == "From")
		//         return current.isBefore(today) && current.isAfter(createdAt)

		//     return current.isAfter(moment(startDate).subtract(1, "days").format("YYYY-MM-DD")) && current.isBefore(today);

		// }
		let allowed = ['chat_Report_Daily', 'agent_Report', 'queue_Report', 'queue_Wise_Report', 'agent_Wise_Report', 'sla_Report', 'agent_Unavailability_Report']
		const {
			globalSetting: { uiFeature },
			t,
		} = this.props
		let learning = uiFeature && uiFeature.learning

		if (learning && learning.report) allowed = learning.report.allowed

		// if(!allowed || allowed.length < 1){
		//     return null;
		// }

		return (
			<div>
				<div className="common-heading">
					<div className="row table-title">
						<div className="col-sm-12">
							<div className="heading">
								<h5>{t('Reporting/Analytics')}</h5>
							</div>
						</div>
					</div>
				</div>
				<section>
					{/* <div className="row">
                        <div className='filters col-sm-12 pull-right'>
                            {Object.keys(date).map((key, i) =>
                                <FormGroup className="custom-margin-form-group" key={i}>
                                    <Label for="ex-url">{t(key)}</Label>
                                    <div className="custom-calendar">
                                        <span className="set-date-sec" name="schedule_date" onClick={date[key].onClick}>
                                            {date[key].value ? moment(date[key].value, "YYYY-MM-DD").format("DD MMM YY") : t("Select")}
                                            <span className="cal-ico">
                                                <i className="fa fa-calendar fa-lg" />
                                            </span>
                                        </span>
                                        <div className={`custom-calendar-inner ${key == "From" ? "cal-left-zero" : "cal-right-zero"}`} style={{ display: date[key].open ? "block" : "none" }}>
                                            <Datetime
                                                isValidDate={valid.bind(this, key)}
                                                defaultValue={date[key].value}
                                                input={false}
                                                onChange={this.dateTimeChange.bind(this, key)}
                                                viewDate={date[key].value}
                                                timeFormat={false}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            )}
                        </div>
                    </div> */}
					<div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<Table className="table">
									<colgroup>
										<col width="1%" />
										<col width="20%" />
										<col width="30%" />
										<col width="30%" />
										<col width="30%" />
										<col width="15%" />
									</colgroup>
									<thead>
										<tr>
											<th>
												<span>
													{/* <Input type="checkbox"
                                                        id="inline-checkbox0"
                                                        name="checkbox[]"
                                                        checked={this.isAllSelected()}
                                                        onChange={this.handleSelectAll.bind(this)}
                                                    /> */}
												</span>
											</th>
											<th>{t('Report Name')}</th>
											<th>{t('Filter By')}</th>
											<th>{t('Date Range')}</th>
											<th>{t('Roll Up')}</th>
											<th>{t('Action')}</th>
										</tr>
									</thead>
									<tbody>
										{tbody.map(
											(el, i) =>
												allowed.includes(el.name) && (
													<tr key={el.name + i}>
														<td>
															<span>
																{/* <Input type="checkbox"
                                                            id={`inline-checkbox${i + 1}`}
                                                            name="checkbox[]"
                                                            value={el.name}
                                                            checked={this.isSelected(el.name)}
                                                            onChange={el.inputAction ? el.inputAction : this.handleSelect.bind(this)}
                                                        /> */}
															</span>
														</td>
														<td>
															{' '}
															<div className="row static-comp reports-top-20">{t(el.label)}</div>
														</td>
														<td>
															<div className="row static-comp reports-top-20">
																{el.filterBy &&
																	Array.isArray(el.filterBy) &&
																	el.filterBy.map((val, idx) => {
																		let StaticComponent = val.component
																		if (val.name === 'queue' && this.state.queues) val.options = this.state.queues

																		if (val.name === 'agent' && this.state.agents) val.options = this.state.agents

																		return StaticComponent && <StaticComponent parent={el.name} key={el.name + idx} {...val} />
																	})}
																{el.filterBy && typeof el.filterBy === 'string' ? el.filterBy : ''}
																{errors && el.filter && el.filter.name && errors[el.name] && this.renderErrorMsg(el.filter.name, errors[el.name])}
															</div>
														</td>
														<td>
															<div className="filters">
																<FormGroup className="custom-margin-form-group" key={`From_${i}`}>
																	<Label for="ex-url">{t('From')}</Label>
																	<div className="custom-calendar">
																		<span className="set-date-sec" key="schedule_date" onClick={el.From.onClick}>
																			{el.startDate ? moment(el.startDate, 'YYYY-MM-DD').format('DD MMM YY') : t('Select')}
																			<span className="cal-ico">
																				<i className="fa fa-calendar fa-lg" />
																			</span>
																		</span>
																		<div className="custom-calendar-inner cal-left-zero" style={{ display: el.From.open ? 'block' : 'none' }}>
																			<Datetime
																				key={`From_Date_${i}`}
																				isValidDate={this.validReportDate.bind(this, 'From', el.name)}
																				defaultValue={el.From.value}
																				input={false}
																				onChange={this.dateTimeChangeArrFrom.bind(this, 'From', el.name)}
																				viewDate={el.startDate}
																				timeFormat={false}
																			/>
																		</div>
																	</div>
																	{errors && errors[el.name] && this.renderErrorMsg('startDate', errors[el.name])}
																</FormGroup>

																<FormGroup className="custom-margin-form-group" key={`To_${i}`}>
																	<Label for="ex-url">{t('To')}</Label>
																	<div className="custom-calendar">
																		<span className="set-date-sec" key="schedule_date" onClick={el.To.onClick}>
																			{el.endDate ? moment(el.endDate, 'YYYY-MM-DD').format('DD MMM YY') : t('Select')}
																			<span className="cal-ico">
																				<i className="fa fa-calendar fa-lg" />
																			</span>
																		</span>
																		<div className="custom-calendar-inner cal-left-zero" style={{ display: el.To.open ? 'block' : 'none' }}>
																			<Datetime
																				key={`To_Date_${i}`}
																				isValidDate={this.validReportDate.bind(this, 'To', el.name)}
																				value={el.endDate && el.endDate != '' ? new Date(el.endDate) : new Date(el.startDate)}
																				input={false}
																				onChange={this.dateTimeChangeArrTo.bind(this, 'To', el.name)}
																				timeFormat={false}
																			/>
																		</div>
																	</div>
																	{errors && errors[el.name] && this.renderErrorMsg('endDate', errors[el.name])}
																</FormGroup>
															</div>
														</td>
														<td>
															<div className="row static-comp reports-top-20">
																{el.rollUp &&
																	Array.isArray(el.rollUp) &&
																	el.rollUp.map((val, idx) => {
																		let StaticComponent = val.component
																		if (val.name === 'queue' && this.state.queues) val.options = this.state.queues

																		if (val.name === 'agent' && this.state.agents) val.options = this.state.agents

																		return StaticComponent && <StaticComponent parent={el.name} key={el.name + idx} {...val} />
																	})}
															</div>
														</td>
														<td>
															{el.action && (
																<Button type="danger" className="btn btn-danger reports-top-20" onClick={this.downloadIndividual.bind(this, el)}>
																	{t('Download')}
																</Button>
															)}
														</td>
													</tr>
												)
										)}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					{this.state.selected.length > 0 && (
						<div>
							<Button type="danger" className="btn btn-danger" onClick={this.downloadAll.bind(this)}>
								{t('Download')} {this.isAllSelected() ? t('All') : t('Selected')}
							</Button>
						</div>
					)}
				</section>
				{reportData && reportData.requestId && <AsyncJobsModal {...this.props} modal={true} jobData={reportData} />}
			</div>
		)
	}
}

export default FilterReview

const AsyncJobsModal = props => {
	const [modal, setModal] = useState(props.modal)
	const [jobData, setJobData] = useState(props.jobData)
	const [redirectUrl, setRedirectUrl] = useState('/livechat/requests')
	useEffect(() => {
		if (props && props.jobData && props.jobData.requestId && props.jobData.requestId != jobData.requestId) {
			setModal(true)
			setJobData(props.jobData)
		}
	}, [props, jobData.requestId])
	const toggleModal = () => {
		setModal(!modal)
	}

	return (
		<div className="async-jobs-modal">
			<Modal autoFocus={false} isOpen={modal} backdrop="static" toggle={toggleModal} className="delete-card">
				<ModalBody>
					<Text>
						{props.t('Your request is in progress,')}{' '}
						<Link color="teal.500" href={redirectUrl} isExternal>
							{props.t('Click here')} : {jobData.requestId} <ExternalLinkIcon mx="2px" />
						</Link>{' '}
						{props.t('to check the status')}.
					</Text>
				</ModalBody>
				<div className="button-wrap">
					{' '}
					<Button color="secondary" onClick={toggleModal}>
						<img src={`assets/img/no.png`} alt="no" />
						{props.t('Close')}
					</Button>
				</div>
			</Modal>
		</div>
	)
}
