// import { apiUrl } from  '../../../config';
import { identityServices } from '../../common'
const moment = require('moment')

export const getFaqQna = (tenant_Id, type, apiUrl) => {
	let defaultTimeZone = moment().format('z')
	let ep = apiUrl || sessionStorage.getItem('apiUrl')
	if (tenant_Id) ep = ep + '/' + tenant_Id
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		url: `${ep}/dashboard/getFaqQna/${type}?timeZone=${defaultTimeZone}&ts=${new Date().getTime()}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}

		return response.data
	})
}
