import { agentConstants } from '..';
import { userService } from '..';
import { alertActions } from '.';
//import { history } from '../_helpers';
// import { history } from '../../../../_helpers';
// import { apiUrl } from '../config'

export const incomingUserAction = {
  getIncomingUsers,
  geUserInformation,
  setIncomingUserSocket,
  removeIncommingUser,
  addUserToAgent,
};

function getIncomingUsers() {
    return dispatch => {
        dispatch(request())
        userService.getIncomingUsers()
            .then(
            agentchat => dispatch(success(agentchat)),
            error => dispatch(failure(error))
            );
    };
    function request() { return { type: agentConstants.INCOMING_USER } }
    function success(agentchat) { return { type: agentConstants.INCOMING_USER_SUCCESS, agentchat } }
    function failure(error) { return { type: agentConstants.INCOMING_USER_FAILURE, error } }
}

function geUserInformation(userId) {
  return dispatch => {
    dispatch(request())
    userService.getUserInformation(userId)
      .then(
        agentchat => dispatch(success(agentchat)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: agentConstants.INCOMING_USER } }
  function success(agentchat) { return { type: agentConstants.INCOMING_USER_SUCCESS, agentchat } }
  function failure(error) { return { type: agentConstants.INCOMING_USER_FAILURE, error } }
}

function setIncomingUserSocket(data) {
  return dispatch => {
    dispatch(success(data))
  }
  function success(agentchat) { return { type: agentConstants.INCOMING_SOCKET_USER, agentchat } }
}

function removeIncommingUser(data) {
  return dispatch => {
    dispatch(success(data))
  }
  function success(agentchat) { return { type: agentConstants.REMOVE_INCOMING_USER, agentchat } }
}

function addUserToAgent(data) {
  return dispatch => {
    dispatch(success(data))
  }
  function success(agentchat) { return { type: agentConstants.ADD_USER_TO_AGENT, agentchat } }
}
