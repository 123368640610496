/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../common/base/entity/entity'
import JobListsearch from './components/job.listsearch'
// import {EntityComponent} from '../entity'
import { collectionSchemas, entitySchema, methodSchemas } from './job.schema'
import { jobService } from './job.service'
import { jobAction } from './jobAction'

// import {    baseConstants} from '../../entity';

/**
 * Instructions:
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 *
 */

class JobEntity extends Entity {}

const fieldsMeta = {
	requestId: {
		label: 'Request Id',
		required: true,
		type: 'number',
		name: 'requestId',
		readOnlyOnEdit: true,
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'InProgress', value: 'InProgress' },
			{ label: 'Completed', value: 'Completed' },
		],
		type: 'select',
		required: true,
		readOnly: true,
	},
	type: {
		label: 'Request Type',
		name: 'type',
		type: 'text',
		required: false,
		readOnly: true,
	},
	successChunk: {
		label: 'Success Message',
		required: true,
		type: 'downloadLink',
		name: 'successChunk',
		readOnlyOnEdit: true,
	},
	failedChunk: {
		label: 'Failure Message',
		required: true,
		type: 'text',
		name: 'failedChunk',
		readOnlyOnEdit: true,
	},
	createdBy: {
		label: 'Created By',
		name: 'createdBy',
		readOnly: true,
		type: 'text',
	},
	createdAt: {
		label: 'Created At',
		name: 'createdAt',
		readOnly: true,
		type: 'datetime-local',
	},
}

const actionsMeta = {
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
}
const sectionsMeta = {
	general: {
		items: [
			{ name: 'status', type: 'field' },
			{ name: 'requestId', type: 'field' },
		],
	},
	basic: {
		title: 'Request Details',
		items: [
			{ name: 'requestId', type: 'field' },
			{ name: 'status', type: 'field' },
			{ name: 'type', type: 'field' },
			{ name: 'successChunk', type: 'field' },
			{ name: 'failedChunk', type: 'field' },
			{ name: 'createdBy', type: 'field' },
			{ name: 'createdAt', type: 'field' },
		],
		classes: 'ticket-form user-queue',
	},
	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},
	editForm: {
		cols: [7, 5],
		items: [{ name: 'basic', type: 'section', col: 1 }],
		classes: 'composite-inner-form',
	},
}

const screens = {
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'editForm', type: 'section' },
		],
		classes: 'composite-form',
		title: 'Request Details',
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'editForm', type: 'section' },
		],
	},
	list: {
		items: [
			{ name: 'requestId', type: 'field' },
			{ name: 'status', type: 'field' },
			{ name: 'type', type: 'field' },
			{ name: 'successChunk', type: 'field' },
			{ name: 'failedChunk', type: 'field' },
			{ name: 'createdAt', type: 'field' },
		],
		orderBy: ['createdAt desc'],
		// actions: ['view', 'update']
		multiselect: false,
	},
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const job = new JobEntity({
	ServiceClass: jobService,
	ActionClass: jobAction,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	screens,
	name: 'job',
	title: 'Request',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	idKey: 'id',
	entityUrlPaths: { default: 'job' },
	fetchDetailByApi: false,
	components: { ListSearch: JobListsearch },
})

const jobListSearchContainer = job.getContainer('ListSearch')
const jobDetailContainer = job.getContainer('Detail')
export { jobListSearchContainer, jobDetailContainer }
