// Actions

import { activeAgentAction } from './actions/activeagent.actions'
import { agentChatAction } from './actions/agentchat.actions'
import { incomingUserAction } from './actions/incominguser.actions'
import * as alertConstants from './actions/alert.actions'




// Constants

import { agentConstants } from './constants/agent.constants'


// Reducers

import * as agentchatReducers from './reducers/agentchat.reducers'


// Services

import * as agentService from './services/agent.services'
import * as userService from './services/user.services'

// Components 

// import AgentchatComponent from './components/AgentchatComponent'

// Containers

import * as AgentchatContainer from './containers/AgentchatContainer'


export {
    // Actions 
    activeAgentAction,
    agentChatAction,
    incomingUserAction,
    alertConstants,

    // Constants
    agentConstants,

    // Reducers
    agentchatReducers,
   // BaseReducer,

    // Services
    agentService,
    userService,

    // // Components
    // AgentchatComponent,

    // Containers
    AgentchatContainer,
    
    
}